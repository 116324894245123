import { FC, useMemo } from "react";
import { HookUpgradePlanStyled } from "./hook-upgrade-plan.styled";
import { Modal } from "@sumerlabs/component-library";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  actions,
  partnerSelector,
  upgradePlanHookSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import { useNavigate } from "react-router-dom";
import Button from "@dashboard/modules/products/components/button/button";
import { HookUpgradePlanState } from "./hook-upgrade-plan.types";

const HookUpgradePlan: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const partner = useSelector(partnerSelector);
  const hookState = useSelector(upgradePlanHookSelector);

  const data = useMemo(() => {
    let data: HookUpgradePlanState = {
      image: t("products.improvePlan.image"),
      title: t("products.improvePlan.title"),
      description: t("products.improvePlan.copy"),
      confirmLabel: t("products.improvePlan.confirm"),
      cancelLabel: t("products.improvePlan.cancel"),
    };
    if (hookState) {
      data = { ...data, ...hookState };
    }
    return data;
  }, [hookState]);

  const handleConfirm = () => {
    if (data.closeOnConfirm) {
      handleClose();
    } else {
      navigate(`/${partner?.userId}/plans`);
      dispatch(actions.hideUpgradePlanHook());
    }
  };

  const handleClose = () => {
    dispatch(actions.hideUpgradePlanHook());
  };

  return hookState ? (
    <Modal
      title=""
      show={true}
      onClose={handleClose}
      content={{
        className: "fadeInDown",
        styles: {
          width: "520px",
        },
      }}
      header={{
        styles: {
          borderBottom: 0,
          boxShadow: "none",
          left: 0,
          paddingBottom: 0,
          paddingLeft: "2rem",
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: 2,
        },
      }}
      body={{
        styles: {
          padding: "2rem",
          paddingTop: 0,
        },
      }}
    >
      <HookUpgradePlanStyled>
        <figure className="image">
          <img src={data.image} loading="lazy" alt="Upgrade plan background" />
        </figure>
        <div className="copies">
          <h1 className="title">{data.title}</h1>
          <p className="description">{data.description}</p>
        </div>
        <div className="options">
          {data.confirmLabel && (
            <Button appearance="dark" onClick={handleConfirm}>
              {data.confirmLabel}
            </Button>
          )}
          {data.cancelLabel && (
            <Button link appearance="dark" onClick={handleClose}>
              {data.cancelLabel}
            </Button>
          )}
        </div>
      </HookUpgradePlanStyled>
    </Modal>
  ) : null;
};

export default HookUpgradePlan;

import { useAnalytics } from "@dashboard/common/analytics";
import { PlatformCountry } from "@dashboard/common/types";
import { useLocalStorage } from "@sumerlabs/component-library";
import {
  countriesSelector,
  partnerSelector,
  planParamsSelector,
} from "@dashboard/config/redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { CoreEvents } from "@dashboard/constants/events";
import { mapPlan } from "@dashboard/modules/plans/mappers/plans.mappers";
import * as Sentry from "@sentry/react";
import { UserRedirectSplitEnum } from "@dashboard/modules/shared/components/onboarding-shared/constants/split.constants";
import { UserRedirectResponse } from "@dashboard/modules/shared/components/onboarding-shared/entities/split.entities";
import { fetchSplitUsers } from "@dashboard/modules/shared/components/onboarding-shared/services/onboarding.services";
import { fetchAllPlans } from "@dashboard/modules/shared/components/onboarding-shared/services/premium.services";
import { couponCodeSelector } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.selectors";
import { PlanGroup } from "@dashboard/modules/plans/plans.types";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  SourceOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { Plan } from "@dashboard/modules/shared/components/onboarding-shared/entities/premium.entities";

const useOnboardingStepPremium = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const partner = useSelector(partnerSelector);
  const countries = useSelector(countriesSelector);
  const coupon = useSelector(couponCodeSelector);
  const navigate = useNavigate();
  const { logEvent, appsFlyerEvent } = useAnalytics();
  const [token] = useLocalStorage("accessToken", "");
  const [plans, setPlans] = useState<PlanGroup[]>([]);
  const [country, setCountry] = useState<PlatformCountry>();
  const [billing, setBilling] = useState("Mensual");
  const planParamsData = useSelector(planParamsSelector);
  const imageBannerPersonalization = "https://sumerlabs.com/prod/web/dashboard/banner-personalizacion.webp"

  const plansBillings = useMemo(() => {
    const billings: { [key: string]: boolean } = {};
    plans.forEach((group) => {
      if (group.plans.length > 0) billings[group.name] = true;
    });
    return Object.keys(billings);
  }, [plans]);

  const handlePlanClick = (plan: Plan | undefined) => {
    if (!!plan) {
      logEvent(EventsOnboarding.SELECT_BUTTON, {
        event_subtype: SubtypeOnboarding.CONTINUE_ONBOARDING,
        source:
          planParamsData?.plan && planParamsData.period
            ? SourceOnboarding.LANDING_PRICING
            : "onboarding",
        option: plan.type,
      });
      logEvent(EventsOnboarding.SYSTEM_ONBOARDING_COMPLETE, {
        event_subtype: SubtypeOnboarding.CONTINUE_ONBOARDING,
      });
      dispatch(
        checkoutActions.setToBuyItem({
          type: "plan",
          plan_name: plan.additional_data.plan_type,
          plan_duration: plan.name,
        })
      );
      navigate(`/${partner?.userId}/checkout?source=onboarding`);
    }
  };

  const handleContinueFree = () => {
    logEvent(EventsOnboarding.SYSTEM_ONBOARDING_COMPLETE, {
      event_subtype: SubtypeOnboarding.CONTINUE_ONBOARDING,
    });
    logEvent(CoreEvents.SelectButton, {
      event_subtype: "expert_plans",
      option: "dismiss_expert",
      source: "onboarding",
    });

    Sentry.captureMessage("ONBOARDING_CONTINUE_FREE", {
      extra: {
        ownerId: partner?.userId,
      },
    });
    appsFlyerEvent({
      eventName: "select_button_web_onboarding_plans",
      eventValue: {
        event_subtype: "expert_plans",
        user_type: "store",
        platform: "web",
        option: "dismiss_expert",
        source: "onboarding",
      },
    });
    fetchSplitUsers({
      userId: partner?.userId || "",
      feature: "screen_after_onboarding_web",
      attributes: {
        country: partner?.userDetail.country || "",
      },
    })
      .then((res: UserRedirectResponse) => {
        switch (res.config.productPath) {
          case UserRedirectSplitEnum.CUSTOMIZATION:
            navigate(`/${partner?.userId}/personalization`);
            logEvent(CoreEvents.ViewScreen, {
              event_subtype: "personalization",
              user_type: "store",
              platform: "web",
              source: "onboarding",
            });
            break;
          case UserRedirectSplitEnum.PRODUCTS:
            navigate(`/${partner?.userId}/products`);
            logEvent(CoreEvents.ViewScreen, {
              event_subtype: "products_main",
              user_type: "store",
              platform: "web",
              source: "onboarding",
            });
            break;
          default:
            navigate(`/${partner?.userId}`);
            break;
        }
      })
      .catch(() => {
        navigate(`/${partner?.userId}`);
      });
  };

  useEffect(() => {
    if (!!partner) {
      fetchAllPlans(partner?.userDetail.country || "Colombia", coupon, token)
        .then((responsePlans) => {
          const currentCountry = countries.find(
            (c) => c.name === partner?.userDetail?.country
          );
          let monthlyPlans = responsePlans
            .filter((plan: any) => plan.name.includes("Mensual"))
            .map(mapPlan);
          let annualPlans = responsePlans
            .filter((plan: any) => plan.name.includes("Anual"))
            .map(mapPlan);
          setPlans([
            { name: "Mensual", plans: monthlyPlans },
            { name: "Anual", plans: annualPlans },
          ]);
          setCountry(currentCountry);
        });
    }
  }, [partner]);

  return {
    t,
    plans,
    country,
    partner,
    plansBillings,
    billing,
    setBilling,
    navigate,
    handlePlanClick,
    handleContinueFree,
    imageBannerPersonalization,
  };
};

export default useOnboardingStepPremium;

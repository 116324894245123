import { FC } from "react";
import SumerIso from "@sumerkit/icons/sumer-iso";
import { StoreDescriptionWrapper } from "./store-description.styled";

export type StoreDescriptionTypes = {
  title: string;
  description: string;
};

const StoreDescription: FC<StoreDescriptionTypes> = ({
  title,
  description,
}) => {
  return (
    <StoreDescriptionWrapper>
      <SumerIso height={40} width={40} />
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </StoreDescriptionWrapper>
  );
};

export default StoreDescription;

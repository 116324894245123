export enum EventsOnboarding {
    VIEW_SCREEN = 'view_screen',
    SELECT_BUTTON = 'select_button',
    SYSTEM_ONBOARDING_COMPLETE = 'system_onboarding_complete'
}

export enum SubtypeOnboarding {
    ONBOARDING_STEPS = 'onboarding_steps',
    REGENERATE_PERSONALIZATION = 'regenerate_personalization',
    EXPERT_PLANS = 'expert_plans',
    CONTINUE_ONBOARDING = 'continue_onboard',
    CONTINUE_FREE = 'continue_free',
    EXPERT_PLAN_ONBOARDING_CONFIRMATION = 'expert_plan_onboarding_confirmation',
    SKIP_ONBOARDING = 'skip_onboarding'
}

export enum OptionOnboarding {
    REGISTER = 'register',
    ECONOMIC_SECTOR = 'economic_sector',
    STORE_INFO = 'store_info',
    PERSONALIZATION = 'personalization',
    STORE_DESING = 'store_design',
    PERSONALIZATION_TEMPLATES = 'personalization_templates',
}

export enum SourceOnboarding {
    LANDING_PRICING = 'landing_pricing',
    PERSONALIZATION = 'personalization',
    AI_GENERATE = 'ai_generate',
    TEMPLATES = 'templates'
}

export enum MethodOnboarding {
    POP_UP = 'pop_up',
    AI_GENERATE = 'ai_generate',
    STANDARD = 'standard',
    TEMPLATES = 'templates'
}
import styled from "styled-components";

export const SpinnerProductWrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export const ProductContentWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 24px;
  justify-content: center;
  .product-detail,
  .product-inventory,
  .product-info {
    scroll-margin-top: 180px;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  max-width: 684px;
  flex-direction: column;
  gap: 25px;
  flex: 1 0 0;
`;

export const ProductMapContentContainer = styled.div`
  width: 250px;
`;

export const ProductSaveButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  position: fixed;
  right: 60px;
  top: 92px;
  z-index: 2;
  .delete-container {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--Grey-B, #24272e);
    &:hover {
      cursor: pointer;
      color: #f93c00;
    }
    > .text {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
  }
  .duplicate-container {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--Grey-B, #24272e);
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
    > .text {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
  }
  .button {
    display: inline-block;
  }
`;

export const addons = {
  "contact_button": {
    "titles": {
      "main": "Whatsapp Button",
      "config": "Contact Settings",
      "messages": "Predefined Messages",
      "position": "Button Position",
      "color": "Color",
      "icon": "Icon",
    },
    "descriptions": {
      "main": "Configure how the Whatsapp button will look in your catalog.",
      "config": "Enter the contact number where your customers will contact you",
      "messages": "Pre-set the messages you will receive when your customer contacts you.",
      "position": "Choose the location of the floating button within your store.",
      "color": "Choose the color of the floating button within your store.",
      "icon": "Choose the icon of the floating button within your store.",
    },
    "notification_ok": "Change made successfully",
    "notification_error": "Error",
    "apply": "Apply",
    "phone": "Phone",
    "country": "Country",
    "hide_button": "Hide button",
    "option": "Option",
    "background": "Background",
    "got_it": "Got it",
    "messages_texts": {
      "message": "Message",
      "write_your_message": "Write your message",
      "characters": "characters",
      "catalogue_title": "Message in Catalog",
      "catalogue_description": "Automatic text for the first message that the user will send you",
      "store_name": "Store name",
      "store_link": "Store link",
      "product_title": "Message in Product",
      "product_description": "Automatic text for the first message that the user will send you",
      "product_name": "Product name",
      "product_link": "Product link"
    },
    "axis": {
      "left": "Left",
      "center": "Center",
      "right": "Right",
      "middle": "Middle",
      "bottom": "Bottom"
    },
  }
};

import { FC } from "react";
import { DisabledChatStyled } from "./disabled-chat.styled";
import Button from "@dashboard/modules/products/components/button/button";
import { useTranslation } from "react-i18next";

type DisabledChatProps = {
  onAcquire?: () => void;
};

const DisabledChat: FC<DisabledChatProps> = ({ onAcquire }) => {
  const { t } = useTranslation();
  return (
    <DisabledChatStyled>
      <h3 className="disabled-chat-title">{t("aiAssistant.disabled.title")}</h3>
      <img
        src="https://sumerlabs.com/prod/assets/sumerweb/ai/evo-disabled.webp"
        alt="Evo disabled"
        className="disabled-chat-image"
      />
      <p className="disabled-chat-description">
        {t("aiAssistant.disabled.description")}
      </p>
      <Button appearance="dark" onClick={onAcquire}>
        {t("aiAssistant.disabled.acquire")}
      </Button>
    </DisabledChatStyled>
  );
};

export default DisabledChat;

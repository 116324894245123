export enum IframeEvent {
  BuyExpert = 'buy_expert',
  PendingChanges = 'pending_changes',
  ChangesSaved = 'changes_saved',
  OpenHook = 'open-hook:customization',
  UpdateUserData = 'update_user_data',
};

export enum PersonalizationEvents {
  personalizationPro = "personalization_pro",
}

import styled from "styled-components";

export const DisabledChatStyled = styled.aside`
  align-items: center;
  color: #3E434E;
  display: flex;
  gap: 2rem;
  flex: 1;
  flex-direction: column;
  font-size: 0.875rem;
  justify-content: center;
  margin: auto;
  padding: 2rem 3rem;
  text-align: center;
  max-width: 375px;
  .disabled-chat-title {
    color: #24272e;
    font-size: 1rem;
    font-weight: 600;
  }
  .disabled-chat-image {
    border-radius: 0.5rem;
    height: auto;
    width: 225px;
  }
  .disabled-chat-description {
    padding: 0 1rem;
  }
`;

import styled from "styled-components";
import { loader } from "../img";

const LoaderStyled = styled.div`
  height: 108px;
  pointer-events: none;
  position: relative;
  width: 108px;
  img {
    animation: rotation 2s infinite linear;
    display: block;
    width: 100%;
  }
  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const Loader = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <LoaderStyled>
      <img src={loader} alt="Loader" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 36 35"
        fill="none"
      >
        <rect x="0.5" width="35" height="35" rx="6.56249" fill="#fff" />
        <path
          d="M20.2055 7.29479C21.3074 7.29053 22.2519 7.29053 22.8901 7.29904V13.6853C19.3631 13.5874 17.5421 14.332 16.6402 16.5572C16.3296 17.3273 16.202 18.1569 16.202 18.9866V27.7086H9.25V18.2888C9.27127 11.2814 14.147 7.31606 20.2055 7.29479Z"
          fill="#24272E"
        />
        <path
          d="M20.3566 21.808C20.3566 19.6339 22.0286 17.8852 24.3176 17.8852C26.6491 17.8852 28.3977 19.6339 28.3977 21.808C28.3977 23.9822 26.6491 25.7308 24.3176 25.7308C22.0286 25.7308 20.3566 23.9822 20.3566 21.808Z"
          fill="#24272E"
        />
      </svg>
    </LoaderStyled>
  );
};

export default Loader;

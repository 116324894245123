import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UiPhone, UiPhoneOption } from "@sumerlabs/component-library";
import { useContactButton } from "../../hooks/contact-button.hook";
import { ContactButtonConfigurationWrapper } from "./contact-button-configuration.styled";
import CustomSwitch from "@dashboard/components/custom-switch/custom-switch";

interface ContactButtonConfigurationProps {

};

const ContactButtonConfiguration: FC<ContactButtonConfigurationProps> = () => {

  const { t } = useTranslation();
  const {
    countries,
    buttonConfig,
    handlePhoneChange,
    handleToggleHide,
  } = useContactButton();

  const phoneOptions: UiPhoneOption[] = useMemo(() => {
    return countries.map((c) => ({
      flagUrl: c.flagImage,
      label: c.prefixPhone || '',
      value: c.prefixPhone || '',
    }));
  }, [countries]);

  return (
    <ContactButtonConfigurationWrapper>
      <div className='phone-field'>
        <UiPhone
          label={t("addons.contact_button.country")}
          prefixValue={buttonConfig.wp_contact?.configuration?.phone?.prefix}
          phoneValue={buttonConfig.wp_contact?.configuration?.phone?.number}
          expandiblePosition='top'
          options={phoneOptions}
          onChange={handlePhoneChange}
        />
      </div>
      <div className='hide-button'>
        <span className={`icon-talk icon`} />
        <div className='text'>
          {t("addons.contact_button.hide_button")}
        </div>
        <CustomSwitch
          isActive={!!buttonConfig.wp_contact?.configuration?.is_hidden}
          onChange={handleToggleHide}
        />
      </div>
    </ContactButtonConfigurationWrapper>
  );
};

export default ContactButtonConfiguration;

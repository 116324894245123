import { HomeBanner, HomeFakeDoor, HomeLearning, HomeNewOrders, HomeStatistics, StatisticsFilter } from "../constants/home.constants";

export interface HomeState {
 ui: HomeUiState;
 data: HomeDataState;
};

export interface HomeUiState {
  isLoading: boolean;
  statisticsFilter: StatisticsFilter;
  showInConstruction: boolean;
  showUltraHook: boolean;
};

export interface HomeDataState {
  banners: HomeBanner[];
  productsQuantity: number;
  newOrders?: HomeNewOrders;
  statistics?: HomeStatistics;
  learnings: HomeLearning[];
  fakeDoors: HomeFakeDoor[];
};

export const initialState: HomeState = {
 ui: {
  isLoading: false,
  statisticsFilter: StatisticsFilter.LastSevenDays,
  showInConstruction: false,
  showUltraHook: false,
 },
 data: {
  banners: [],
  productsQuantity: 0,
  learnings: [],
  fakeDoors: [],
 },
};

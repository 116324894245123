import styled from 'styled-components';

const ContactButtonPositionWrapper = styled.div`
	width: 100%;

	.items {
		gap: 12px;
		display: flex;
		margin: 0 0 12px 0;

		.option {
			flex: 1;

			&.active {
				.box {
					border: 2px solid var(--grey-b, #24272E);
				}
			}

			.box {
				width: 100%;
				height: 48px;
				display: flex;
				cursor: pointer;
				border-radius: 8px;
				align-items: center;
				background: #ffffff;
				justify-content: center;
				border: 2px solid var(--grey-border, #E9E9ED);

				.icon {
					width: 24px;
					height: 24px;
					display: flex;
					font-size: 22px;
					align-items: center;
					justify-content: center;
				}
			}

			.text {
				font-size: 12px;
				font-weight: 400;
				margin: 4px 0 0 0;
				line-height: 18px;
				text-align: center;
			}
		}
	}
`;

export default ContactButtonPositionWrapper;

const PlayCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2417 15.6928 34.527 11.5643 31.4814 8.51864C28.4357 5.47296 24.3072 3.75826 20 3.75ZM25.6875 21.0469L18.1875 26.0469C17.9806 26.1749 17.7432 26.245 17.5 26.25C17.2939 26.2498 17.0906 26.2017 16.9063 26.1094C16.7064 26.0015 16.5397 25.8412 16.4241 25.6458C16.3085 25.4503 16.2483 25.2271 16.25 25V15C16.2483 14.7729 16.3085 14.5497 16.4241 14.3542C16.5397 14.1588 16.7064 13.9985 16.9063 13.8906C17.1069 13.7891 17.3305 13.7414 17.5552 13.7524C17.7798 13.7633 17.9977 13.8325 18.1875 13.9531L25.6875 18.9531C25.8616 19.0667 26.0046 19.2219 26.1036 19.4047C26.2026 19.5875 26.2545 19.7921 26.2545 20C26.2545 20.2079 26.2026 20.4125 26.1036 20.5953C26.0046 20.7781 25.8616 20.9333 25.6875 21.0469Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayCircle;

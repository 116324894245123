import * as Yup from 'yup';
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useCallback, ClipboardEvent, useEffect, Fragment } from "react";
import { LoginVerifyCodeWrapper } from "./login-verify-code.styled";
import { useAuth } from "../../../shared/components/onboarding-shared/hooks/auth.hooks";
import { UiLoader, UiTimer, WEB_ASSETS } from "@sumerlabs/component-library";
import { LoginStep } from '../../../shared/components/onboarding-shared/constants/auth.constants';

interface LoginVerifyCodeProps {
  
}
 
const LoginVerifyCode: FC<LoginVerifyCodeProps> = () => {
  
  const {
    sendPhoneCode,
    verifyCode,
    setUi,
    isLoading,
    requestCodePayload,
    isCodeOk,
    setIsCodeOk,
    isCodeWrong,
    setIsCodeWrong,
    canResendCode,
    setCanResendCode,
  } = useAuth();
  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      one: '',
      two: '',
      three: '',
      four: '',
      five: '',
      six: '',
    },
    validationSchema: Yup.object({
      one: Yup.string().length(1).required(),
      two: Yup.string().length(1).required(),
      three: Yup.string().length(1).required(),
      four: Yup.string().length(1).required(),
      five: Yup.string().length(1).required(),
      six: Yup.string().length(1).required(),
    }),
    validateOnMount: true,
    onSubmit: (v) => {
      const code = Object.values(v).join('');
      verifyCode(code);
    },
  });

  const handleResendCode = () => {
    form.resetForm({
      values: {
        one: '',
        two: '',
        three: '',
        four: '',
        five: '',
        six: '',
      }
    });
    setIsCodeOk(false);
    setIsCodeWrong(false);
    setCanResendCode(false);
    sendPhoneCode(requestCodePayload);
  }

  const handleFieldChanges = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const onlyNumbersRegex = /^[0-9\b]+$/;
    const { value, name, form: currentForm } = e.target;
    if (value !== '' && !onlyNumbersRegex.test(value)) return;
    if (value.length <= 1) form.handleChange(e);
    if (value.length === 1 && form.values) {
      const lastItemName = Object.keys(form.values)?.at(-1);
      if (name !== lastItemName) {
        const index = [...currentForm as any]?.indexOf(e.target);
        const nextSibling: any = currentForm?.elements[index + 1];
        nextSibling?.focus();
      }
    } else if (value.length === 0 && form.values) {
      const firstItemName = Object.keys(form.values)?.at(0);
      if (name !== firstItemName) {
        const index = [...currentForm as any]?.indexOf(e.target);
        const nextLastSibling: any = currentForm?.elements[index - 1];
        nextLastSibling?.focus();
      }
    }
  }, []);

  const handlePaste = useCallback((e: ClipboardEvent<HTMLInputElement>) => {
    const onlyNumbersRegex = /^[0-9\b]+$/;
    const text = e.clipboardData.getData('Text');
    if (!onlyNumbersRegex.test(text)) return;
    if (text.length !== Object.keys(form.values).length) e.preventDefault();
    else {
      const parts = text.match(/.{1}/g);
      parts?.forEach((part, index) => {
        form.setFieldValue(Object.keys(form.values)[index], part);
        setTimeout(() => form.setFieldTouched(Object.keys(form.values)[index], true));
      });
    }
  }, []);

  useEffect(() => {
    if (form.isValid) form.handleSubmit();
  }, [form.isValid]);

  const goBack = () => {
    setUi({ loginStep: LoginStep.SelectMethod });
  }

  return (
    <LoginVerifyCodeWrapper>
      <div className="back" onClick={goBack}>
        <span className="icon-arrow" />
      </div>
      <img alt="Logo" className="logo-back" src={`${WEB_ASSETS}/images/sumer-logo-black.png`} />
      <div className="title">{t('onboarding.login.verifyCode.title')}</div>
      <div className="description">
        {requestCodePayload.channel !== 'email' ? 
          t('onboarding.login.verifyCode.phone') : 
          t('onboarding.login.verifyCode.email')}
      </div>
      <div className="send-info">
        <span className="number">
          {requestCodePayload.channel !== 'email' ? 
            `${requestCodePayload.prefix_send_to || ''} ${requestCodePayload.send_to}` : 
            `${requestCodePayload.send_to}`}
        </span>
      </div>
      <form className={`otp-input ${isCodeWrong && !isLoading && 'error'}`}>
        {Object.keys(form.values).map((k, index) => (
          <Fragment key={`otp-input-box-${k}`}>
            <input 
              name={k}
              key={`otp-input-${k}`}
              type={'text'}
              className="box" 
              placeholder="0"
              pattern="[0-9]*" 
              inputMode="numeric"
              onPasteCapture={handlePaste}
              onChange={handleFieldChanges}
              onBlur={form.handleBlur}
              value={form.values[k as keyof typeof form.values]}
              max={99}
              min={0}
              disabled={isLoading || isCodeOk}
              autoComplete={'new-password'}
              autoFocus={index === 0}
            />
          </Fragment>
        ))}
      </form>
      {isLoading && (
        <div className="loader">
          <UiLoader size={20} />
        </div>
      )}
      {isCodeOk && (
        <div className="ok">
          <span className="icon icon-circle-check" />
        </div>
      )}
      {isCodeWrong && !isLoading && (
        <div className="error-text">{t('onboarding.login.verifyCode.error')}</div>
      )}
      <span className="resend">
        {canResendCode ? (
          <>
            {t('onboarding.login.verifyCode.wrongCode')}
            <span className='action' onClick={handleResendCode}>{t('onboarding.login.verifyCode.wrongCodeAgain')}</span>
          </>
        ) : (
          <>
            {t('onboarding.login.verifyCode.canResend')}
            <UiTimer secText={t("onboarding.login.verifyCode.minutes")} minText={t("onboarding.login.verifyCode.seconds")} className="timer" onTimerEnds={() => setCanResendCode(true)} showText />
          </>
        )}
      </span>
    </LoginVerifyCodeWrapper>
  );
}
 
export default LoginVerifyCode;
import { useAnalytics } from "@dashboard/common/analytics";
import { getEnvVar } from "@dashboard/common/utils";
import { partnerSelector, urlShareSelector } from "@dashboard/config/redux";
import { EVENTS } from "@dashboard/constants/events";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const SharePopupHook = () => {
  const { t } = useTranslation();
  const storeUrlShare = useSelector(urlShareSelector)?.url;
  const { logEvent } = useAnalytics();
  const partner = useSelector(partnerSelector);
  const catalogueIdentifier = partner?.userNetwork.urlSummer || partner?.userId;
  const storeName = partner?.userDetail?.enterpriseName || "";
  const message = t("header.popups.sharePopup.message", {
    storeName,
    storeUrl:
      storeUrlShare ||
      `${getEnvVar("REACT_APP_HOST")}/catalogo/${catalogueIdentifier}`,
  });
  const [showNotification, setShowNotification] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      storeUrlShare ||
        `${getEnvVar("REACT_APP_HOST")}/catalogo/${catalogueIdentifier}`
    );
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
    eventRegister("copyLink");
  };

  const goToWspLink = () => {
    window.open(`https://api.whatsapp.com/send?text=${message}`, "_blank");
    eventRegister("whatsapp");
  };

  const openMail = () => {
    window.location.href = `mailto:user@example.com?subject=${storeName}&body=${message}`;
    eventRegister("email");
  };

  const eventRegister = (option: string) => {
    logEvent(EVENTS.SELECT_BUTTON, {
      event_subtype: "share_catalogue",
      source: "home",
      option: option,
    });
  };

  return {
    copyToClipboard,
    goToWspLink,
    openMail,
    showNotification,
  };
};

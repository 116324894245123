const locale = {
    stepLogin: {
        split2: {
            description: '¡Tu tienda se ve genial! <strong>Regístrate</strong> y continua explorando funcionalidades increíbles.'
        }
    },
    stepInfo: {
        title: '¡Creemos tu tienda!',
        description: 'Esta información nos ayuda a brindarte una mejor experiencia, la podrás modificar después.',
        form: {
            infoTitle: 'Información',
            infoSubTitle: 'Contacto de tu negocio',
            aboutSiteTitle: '¿De qué trata tu sitio?',
            aboutSiteDescription: 'Tu respuesta nos permitirá mostrarte ideas.',
            economicSectorLabelText: '¿Aún no lo sabes?',
            economicSectorLabelLink: 'Mira algunas sugerencias',
            checkboxText: 'Este también es mi número personal.',
            submitButtonText: 'Siguiente'
        }
    },
    stepStore: {
        title: 'Diseña tu tienda online en segundos',
        description: '¿Cómo quieres empezar?',
        options: {
            generateAi: {
                text: {
                    title: 'Generar tienda con IA',
                    description: 'Deja que nuestra inteligencia artificial haga la magia por ti'
                },
                button: 'Generar'
            },
            template: {
                text: {
                    title: 'Selecciona una plantilla',
                    description: 'Selecciona y personaliza una plantilla para tu tienda'
                },
                button: 'Seleccionar'
            }
        }
    },
    stepCustomization: {
        split2: {
            nextButtonText: 'Continuar'
        }
    }
}

export default locale;
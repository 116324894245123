import React from "react";

const Upload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      {...props}
    >
      <path
        d="M22.5 16.6348H28.5C28.7652 16.6348 29.0196 16.7401 29.2071 16.9277C29.3946 17.1152 29.5 17.3695 29.5 17.6348V25.6348C29.5 25.9 29.3946 26.1543 29.2071 26.3419C29.0196 26.5294 28.7652 26.6348 28.5 26.6348H4.5C4.23478 26.6348 3.98043 26.5294 3.79289 26.3419C3.60536 26.1543 3.5 25.9 3.5 25.6348V17.6348C3.5 17.3695 3.60536 17.1152 3.79289 16.9277C3.98043 16.7401 4.23478 16.6348 4.5 16.6348H10.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.6348V3.63477"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9.63477L16.5 3.63477L22.5 9.63477"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 23.1348C24.8284 23.1348 25.5 22.4632 25.5 21.6348C25.5 20.8063 24.8284 20.1348 24 20.1348C23.1716 20.1348 22.5 20.8063 22.5 21.6348C22.5 22.4632 23.1716 23.1348 24 23.1348Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Upload;

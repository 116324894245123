import StepInfo from "@dashboard/modules/shared/components/onboarding-shared/pages/step-info/step-info";
import { FC } from "react";

const OnboardingInfo: FC = () => {

  return (
    <StepInfo/>
  );
};

export default OnboardingInfo;
import { createSelector } from '@reduxjs/toolkit';

import { RootState, GlobalUiState, GlobalDataState } from '.';

export const showGeneralLoaderSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.showGeneralLoader
);

export const menuSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.menu
);

export const reviewsPlansSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.reviewsPlans
);

export const configurationSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.configuration
);

export const hookInfoSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.hookInfo
);

export const partnerSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.partner
);

export const isLoginErrorSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.isLoginError
);

export const countriesSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.countries
);

export const countryInformationSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.countryInformation
);

export const availableFeaturesSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.availableFeatures
);

export const availableAddonsSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.availableAddons
);

export const planSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.plan
);

export const urlShareSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.urlShare
);

export const toBuyAddonInfoSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.toBuyAddonInfo
);

export const showAddonHookSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.showAddonHook
);

export const upgradePlanHookSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.upgradePlanHook
);

export const showAiAssistantSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.showAiAssistant
);

export const messageAiAssistantSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.messageAiAssistant
);

export const showGlobalLoaderSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.globalLoader
);

export const showShareModalSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.showShareModal
);

export const showCustomPlanHookSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.showCustomPlanHook
);

export const customPlanHookFeatureSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.customPlanHookFeature
);

export const customPlanHooksSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.customPlanHooks
);

export const plansSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.plans
);

export const planParamsSelector = createSelector(
    (state: RootState) => state.global.data,
    (state: GlobalDataState) => state.planParams
);

export const isNavigationBarCollapsedSelector = createSelector(
    (state: RootState) => state.global.ui,
    (state: GlobalUiState) => state.isNavigationBarCollapsed
);



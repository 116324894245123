import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  availableAddonsSelector,
  countriesSelector,
  partnerSelector,
} from "@dashboard/config/redux";
import {
  buttonConfigSelector,
  contactButtonConfigurationOptionsSelector,
  contactButtonUiSelector,
  oldButtonConfigSelector,
} from "../store/contact-button.selectors";
import {
  ContactButtonEditPayload,
  ContactButtonPersonalizationOption,
  PreviewMode,
} from "../entities/contact-button.entities";
import { contactButtonActions } from "../store/contact-button.slice";
import {
  ContactButtonIconName,
  ContactButtonPersonalizationOptionId,
  ContactButtonPositionXAxis,
  ContactButtonPositionYAxis,
  DefaultContactButtonColors,
} from "../constants/contact-button.constants";
import {
  createContactButtonInfo,
  fetchLastUserContactAddon,
} from "../services/contact-button.services";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { Feature } from "@dashboard/constants/features";
import { StorageItem } from "@dashboard/constants/storage-items";
import { useLocalStorage } from "@sumerlabs/component-library";
import { fetchAddonInfo } from "@dashboard/services/addon-info";
import { actions as globalActions } from "@dashboard/config/redux/global.slice";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents, WS_BUTTON_EVENTS } from "@dashboard/constants/events";
import { useNotification } from "@dashboard/hooks/use-notification.hook";
import { getEnvVar } from "@dashboard/common/utils";
import { PartnerData } from "@dashboard/common/types";

export const useContactButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { notify } = useNotification();
  const { logEvent } = useAnalytics();
  const [token] = useLocalStorage(StorageItem.Token, "");
  const { isAvailable } = useFeatureAvailability(Feature.ContactButton, true);
  const { selectedOptionId, viewMode, isDirty, showOnboarding } = useSelector(
    contactButtonUiSelector
  );
  const partner = useSelector(partnerSelector);
  const addons = useSelector(availableAddonsSelector);
  const countries = useSelector(countriesSelector);
  const buttonConfig = useSelector(buttonConfigSelector);
  const oldButtonConfig = useSelector(oldButtonConfigSelector);
  const contactButtonConfigurationOptions = useSelector(
    contactButtonConfigurationOptionsSelector
  );

  const showBackButton =
    selectedOptionId !== ContactButtonPersonalizationOptionId.None;
  const catalogueUrl = `https://${
    getEnvVar("REACT_APP_ENV") !== "prod" ? "dev." : ""
  }sumerlabs.com/catalogo/${partner?.userId}`;

  const title = useMemo(() => {
    switch (selectedOptionId) {
      case ContactButtonPersonalizationOptionId.None:
        return "addons.contact_button.titles.main";
      case ContactButtonPersonalizationOptionId.Configuration:
        return "addons.contact_button.titles.config";
      case ContactButtonPersonalizationOptionId.Messages:
        return "addons.contact_button.titles.messages";
      case ContactButtonPersonalizationOptionId.Icon:
        return "addons.contact_button.titles.icon";
      case ContactButtonPersonalizationOptionId.Position:
        return "addons.contact_button.titles.position";
      case ContactButtonPersonalizationOptionId.Color:
        return "addons.contact_button.titles.color";
      default:
        return "addons.contact_button.titles.main";
    }
  }, [selectedOptionId]);

  const description = useMemo(() => {
    switch (selectedOptionId) {
      case ContactButtonPersonalizationOptionId.None:
        return "addons.contact_button.descriptions.main";
      case ContactButtonPersonalizationOptionId.Configuration:
        return "addons.contact_button.descriptions.config";
      case ContactButtonPersonalizationOptionId.Messages:
        return "addons.contact_button.descriptions.messages";
      case ContactButtonPersonalizationOptionId.Icon:
        return "addons.contact_button.descriptions.icon";
      case ContactButtonPersonalizationOptionId.Position:
        return "addons.contact_button.descriptions.position";
      case ContactButtonPersonalizationOptionId.Color:
        return "addons.contact_button.descriptions.color";
      default:
        return "addons.contact_button.titles.main";
    }
  }, [selectedOptionId]);

  const showApplyButton = useMemo(() => {
    return isDirty
      ? JSON.stringify(oldButtonConfig) !== JSON.stringify(buttonConfig)
      : false;
  }, [buttonConfig, oldButtonConfig, isDirty]);

  const init = () => {
    const showOnboarding = localStorage.getItem(
      StorageItem.ShowContactButtonOnboarding
    );
    if (showOnboarding !== "false") {
      dispatch(contactButtonActions.setUi({ showOnboarding: true }));
    }
    logEvent(CoreEvents.ViewScreen, {
      event_subtype: "main",
      source: "wa_addon",
      overlay: showOnboarding !== "false",
    });
    dispatch(globalActions.setShowGeneralLoader(true));
    setTimeout(() => {
      dispatch(globalActions.setShowGeneralLoader(false));
    }, 3000);
  };

  const createDefaultConfig = () => {
    if (!!partner && token) {
      const defaultContactButtonConfig: ContactButtonEditPayload = {
        wp_contact: {
          configuration: {
            phone: {
              prefix: partner.userDetail.prefixPhone || "",
              number: partner.userDetail.phone || "",
            },
            is_hidden: false,
          },
          colors: {
            background: DefaultContactButtonColors.background,
            icon: DefaultContactButtonColors.icon,
          },
          icon: ContactButtonIconName.One,
          messages: {
            general: "",
            product: "",
          },
          position: {
            horizontal: ContactButtonPositionXAxis.Right,
            vertical: ContactButtonPositionYAxis.Center,
          },
        },
      };
      dispatch(
        contactButtonActions.setButtonConfig(defaultContactButtonConfig)
      );
      dispatch(
        contactButtonActions.setOldButtonConfig(defaultContactButtonConfig)
      );
      if (isAvailable)
        createContactButtonInfo(token, defaultContactButtonConfig);
    }
  };

  const verifyUserHasAddon = async () => {
    if (!!partner && !!token) {
      const addonResponse = await checkContactAddon(partner);
      if (!!addonResponse?.isActive) {
        if (!!addonResponse.body?.wp_contact) {
          dispatch(contactButtonActions.setButtonConfig(addonResponse.body));
          dispatch(contactButtonActions.setOldButtonConfig(addonResponse.body));
        } else {
          createDefaultConfig();
        }
      } else {
        createDefaultConfig();
      }
    }
  };

  const checkContactAddon = async (
    partner: PartnerData
  ): Promise<
    | { body: ContactButtonEditPayload; isActive: boolean }
    | undefined
  > => {
    if (addons.length) {
      return Promise.resolve({
        body: addons.find(
          (addon) => addon.addon_type_id === Feature.ContactButton
        )?.addon_user?.body as ContactButtonEditPayload,
        isActive: true,
      });
    } else {
      return await fetchLastUserContactAddon(partner.userId);
    }
  };

  const getAddonInfo = () => {
    if (!!partner && !!token)
      fetchAddonInfo(token, partner?.userDetail.country, Feature.ContactButton)
        .then((r) => {
          if (r) dispatch(globalActions.setToBuyAddonInfo(r));
        })
        .catch();
  };

  const onBackClick = () => {
    dispatch(
      contactButtonActions.setUi({
        selectedOptionId: ContactButtonPersonalizationOptionId.None,
      })
    );
  };

  const handleChangePreviewMode = (mode: PreviewMode) => {
    dispatch(contactButtonActions.setUi({ viewMode: mode }));
  };

  const handleOnOptionClick = (option: ContactButtonPersonalizationOption) => {
    dispatch(contactButtonActions.setUi({ selectedOptionId: option.id }));
  };

  const handlePhoneChange = (phone: {
    prefixPhone?: string;
    phone?: string;
  }) => {
    const newPhoneConfig = { ...buttonConfig.wp_contact?.configuration?.phone };
    if (phone.phone) newPhoneConfig.number = phone.phone;
    if (phone.prefixPhone) newPhoneConfig.prefix = phone.prefixPhone;

    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          configuration: {
            ...buttonConfig.wp_contact?.configuration,
            phone: newPhoneConfig,
          },
        },
      })
    );
  };

  const handleToggleHide = (v: boolean) => {
    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          configuration: {
            ...buttonConfig.wp_contact?.configuration,
            is_hidden: v,
          },
        },
      })
    );
  };

  const handleAxisChange = (
    key: string,
    axisPosition: ContactButtonPositionXAxis | ContactButtonPositionYAxis
  ) => {
    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          position: {
            ...buttonConfig.wp_contact?.position,
            [key]: axisPosition,
          },
        },
      })
    );
  };

  const handleColorChange = (color: string) => {
    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          colors: {
            ...buttonConfig.wp_contact?.colors,
            background: color,
          },
        },
      })
    );
  };

  const handleIconChange = (icon: string) => {
    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          icon,
        },
      })
    );
  };

  const handleMessageChange = (key: string, text: string) => {
    dispatch(contactButtonActions.setUi({ isDirty: true }));
    dispatch(
      contactButtonActions.setButtonConfig({
        wp_contact: {
          ...buttonConfig.wp_contact,
          messages: {
            ...buttonConfig.wp_contact?.messages,
            [key]: text,
          },
        },
      })
    );
  };

  const handleApplyClick = async () => {
    logEvent(CoreEvents.SelectSaveConfiguration, {
      event_subtype: "wa_addon",
    });
    if (token && isAvailable) {
      dispatch(globalActions.setShowGeneralLoader(true));
      const ok = await createContactButtonInfo(token, buttonConfig);
      if (ok) {
        dispatch(
          contactButtonActions.setUi({
            isDirty: false,
            selectedOptionId: ContactButtonPersonalizationOptionId.None,
          })
        );
        dispatch(contactButtonActions.setOldButtonConfig(buttonConfig));
        notify({
          message: t("addons.contact_button.notification_ok"),
          type: "success",
        });
      } else {
        notify({
          message: t("addons.contact_button.notification_error"),
          type: "error",
        });
      }
      dispatch(globalActions.setShowGeneralLoader(false));
    } else {
      logEvent(CoreEvents.SystemExpertWarning, {
        event_subtype: "wa_addon",
      });
      dispatch(globalActions.setShowAddonHook(true));
    }
  };

  const handleToggleOnboarding = () => {
    if (showOnboarding)
      localStorage.setItem(StorageItem.ShowContactButtonOnboarding, "false");
    dispatch(contactButtonActions.setUi({ showOnboarding: !showOnboarding }));
  };

  useEffect(() => {
    if(isAvailable){
      logEvent(WS_BUTTON_EVENTS.EVENT_TYPE, {
        event_subtype: WS_BUTTON_EVENTS.EVENT_SUBTYPE,
      });
    }
  }, []);

  return {
    t,
    showOnboarding,
    showBackButton,
    showApplyButton,
    title,
    description,
    catalogueUrl,
    viewMode,
    partner,
    countries,
    selectedOptionId,
    buttonConfig,
    contactButtonConfigurationOptions,
    init,
    verifyUserHasAddon,
    getAddonInfo,
    onBackClick,
    handleChangePreviewMode,
    handleOnOptionClick,
    handlePhoneChange,
    handleToggleHide,
    handleAxisChange,
    handleColorChange,
    handleIconChange,
    handleApplyClick,
    handleMessageChange,
    handleToggleOnboarding,
  };
};

import { LoginStep } from "../constants/auth.constants";
import { PersonalizationViewMode } from "../constants/personalization.constants";
import { RequestCodePayload } from "../entities/auth.entities";
import { BusinessSector, IpInfo, OnboardingData } from "../entities/onboarding.entities";
import { UserRedirectResponse } from "../entities/split.entities";

export interface OnboardingState {
  ui: OnboardingUiState;
  data: OnboardingDataState;
};

export interface OnboardingUiState {
  loginStep: LoginStep;
  requestCodePayload: RequestCodePayload;
  coupon: string;
  refer: string;
  personalizationViewMode: PersonalizationViewMode;
  isLoading: boolean;
};

export interface OnboardingDataState {
  businessSectors: BusinessSector[];
  onboardingData: OnboardingData;
  couponCode: string;
  params?: {[key: string]: string};
  locales: string;
  storeDesignSplit?: UserRedirectResponse;
  sourcePath?: string;
  onboardingType?: string;
  ipInfo?: IpInfo;
};

export const initialState: OnboardingState = {
  ui: {
    isLoading: false,
    loginStep: LoginStep.SelectMethod,
    requestCodePayload: {
      channel: 'sms',
      send_to: '',
      prefix_send_to: '+57'
    } as RequestCodePayload,
    coupon: '',
    refer: '',
    personalizationViewMode: PersonalizationViewMode.Desktop,
  },
  data: {
    businessSectors: [],
    onboardingData: {},
    couponCode: '',
    locales: 'en',
  },
};

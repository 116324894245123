import { FC, useCallback, useEffect, useRef } from "react";
import { AiAssistantStyled } from "./ai-assistant.styled";
import IaChatHeader from "./components/chat-header";
import FrequentQuestions from "./components/frequent-questions";
import AiChatInput from "./components/chat-input";
import AiChatMessages from "./components/chat-messages";
import { useAiAssistant } from "./ai-assistant.hook";
import Splash from "./components/splash/splash";
import DisabledChat from "./components/disabled-chat";
import { CoreEvents } from "@dashboard/constants/events";
import { AI_ASSISTANT_EVENTS } from "./constants/events.constants";
import Evo from "@sumerkit/icons/evo";

const AiAssistant: FC = () => {
  const {
    t,
    logEvent,
    partner,
    messages,
    currentMessages,
    showSplash,
    chatTitle,
    maximized,
    showAiAssistant,
    feedbacks,
    quickAnswers,
    messageRegenerating,
    showDisabledChat,
    handleToggleMaximized,
    handleSendMessage,
    handleCloseAiAssistant,
    handleCreateFeedback,
    handleQuickAnswer,
    handleRegenerateResponse,
    handleAcquirePlan,
  } = useAiAssistant();

  const optionsRef = useRef<HTMLElement>(null);

  const handleBlurClose = useCallback((event?: Event) => {
    if (!event || !optionsRef.current?.contains(event.target as Node)) {
      handleCloseAiAssistant();
      document.removeEventListener("mousedown", handleBlurClose, false);
    }
  }, []);

  useEffect(() => {
    if (showAiAssistant) {
      logEvent(CoreEvents.ViewScreen, {
        event_subtype: AI_ASSISTANT_EVENTS.AI_MAIN,
        option: "screen_size_max",
      });
    }
  }, [showAiAssistant]);

  useEffect(() => {
    if (showAiAssistant) {
      document.addEventListener("mousedown", handleBlurClose, false);
    }
  }, [showAiAssistant]);

  return showAiAssistant ? (
    <AiAssistantStyled
      ref={optionsRef}
      className={`${maximized ? "ai-assistant-maximized" : ""} fadeInUp`}
    >
      {showSplash && currentMessages === undefined ? (
        <Splash />
      ) : (
        <>
          <IaChatHeader
            maximized={maximized}
            title={chatTitle}
            onToggleMaximize={handleToggleMaximized}
            onClose={handleCloseAiAssistant}
          />
          <section className="ai-assistant-profile">
            <div className="ai-assistant-avatar">
              <Evo />
            </div>
            <div className="ai-assistant-data">
              <h3 className="ai-assistant-title">{t("aiAssistant.title")}</h3>
              <p className="ai-assistant-help">
                {t("aiAssistant.description")}
              </p>
            </div>
          </section>
          <div className="ai-assistant-container">
            {showDisabledChat && <DisabledChat onAcquire={handleAcquirePlan} />}
            {!showDisabledChat && (
              <AiChatMessages
                messages={messages}
                feedbacks={feedbacks}
                partner={partner}
                messageRegenerating={messageRegenerating}
                className="ai-assistant-body"
                onMessageApproved={handleCreateFeedback}
                onMessageRegenerate={handleRegenerateResponse}
              />
            )}
            <footer className="ai-assistant-footer">
              {(messages || []).length === 0 && quickAnswers.length > 0 && (
                <FrequentQuestions
                  answers={quickAnswers}
                  className="ai-assistant-fq"
                  onClickAnswer={handleQuickAnswer}
                />
              )}
              <AiChatInput
                disabled={showDisabledChat}
                onSendMessage={handleSendMessage}
              />
              {false && (
                <div className="ai-assistant-interactions">
                  <span className="icon-comments-outline" /> 100
                </div>
              )}
            </footer>
          </div>
        </>
      )}
    </AiAssistantStyled>
  ) : null;
};

export default AiAssistant;

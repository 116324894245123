import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./products.state";
import { camelizeKeys } from "humps";
import { FilesUploaderPayload } from "@dashboard/common/types";
import {
  UploadFiles,
  fetchUploadImages,
} from "@dashboard/services/file.uploader";
import { BulkUploadProductsResponse } from "../types/bulk-upload-products.types";
import { fetchOrchestrator } from "../fetchers/catalogue.fetchers";
import {
  Category,
  CreateProductPayload,
  DeleteProductPayload,
  Product,
  UpdateProductPayload,
} from "../types/products.types";
import {
  createProducts,
  createUniqueProduct,
  deleteProducts,
  updateProduct,
} from "../fetchers/products.fetchers";
import {
  createUpdateCategory,
  fetchCategories,
} from "../fetchers/product-categories.fetchers";

export const fetchOrchestratorAction = createAsyncThunk(
  "admin/uploadProductsAction",
  async (token: string, { rejectWithValue }): Promise<any> => {
    try {
      const response = await fetchOrchestrator(token);
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateProductAction = createAsyncThunk(
  "admin/updateProductAction",
  async (
    obj: {
      token: string;
      payload: { product: UpdateProductPayload; oldProduct: Product };
    },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await updateProduct(obj.token, obj.payload.product);
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue({
        error: err?.response?.data,
        product: obj.payload.oldProduct,
      });
    }
  }
);

export const deleteProductsAction = createAsyncThunk(
  "admin/deleteProductsAction",
  async (
    obj: { token: string; payload: DeleteProductPayload },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await deleteProducts(obj.token, obj.payload);
      const data = camelizeKeys(await response.data);
      return {
        data,
        ids: obj.payload.ids,
      };
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createProductsAction = createAsyncThunk(
  "admin/createProductsAction",
  async (
    obj: { token: string; payload: { products: CreateProductPayload[] } },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await createProducts(obj.token, obj.payload.products);
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createUniqueProductAction = createAsyncThunk(
  "admin/createUniqueProductAction",
  async (
    obj: { token: string; payload: { product: CreateProductPayload } },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await createUniqueProduct(
        obj.token,
        obj.payload.product
      );
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchCategoryAction = createAsyncThunk(
  "admin/fetchCategoryAction",
  async (token: string, { rejectWithValue }): Promise<any> => {
    try {
      const response = await fetchCategories(token);
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createUpdateCategoryAction = createAsyncThunk(
  "admin/createUpdateCategoryAction",
  async (
    obj: { token: string; payload: { category: Category } },
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await createUpdateCategory(
        obj.token,
        obj.payload.category
      );
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const uploadProductsAction = createAsyncThunk(
  "products/uploadProductsAction",
  async (
    obj: { token: string; files: FilesUploaderPayload[] },
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    const onUploadProgress = (event: ProgressEvent) => {
      const progress = Math.round((event.loaded * 100) / event.total);
      dispatch(
        productsActions.uploadProgress(
          progress === 0 ? progress : progress - 10
        )
      );
    };
    try {
      const response = await UploadFiles(
        obj.token,
        obj.files,
        onUploadProgress
      );
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const uploadImagesAction = createAsyncThunk(
  "products/uploadImagesAction",
  async (
    obj: { token: string; files: FilesUploaderPayload[] },
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    const onUploadProgress = (event: ProgressEvent) => {
      const progress = Math.max(
        Math.round((event.loaded * 100) / event.total),
        0
      );
      dispatch(
        productsActions.uploadProgress(
          progress === 0 ? progress : progress - 10
        )
      );
    };
    try {
      const response = await fetchUploadImages(
        obj.token,
        obj.files,
        onUploadProgress
      );
      return camelizeKeys(await response.data);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateProducts: (state, action: PayloadAction<Product[]>) => {
      (action.payload || []).forEach((productPayload) => {
        const index = state.data.products.findIndex(
          (product: Product) => product.id === productPayload.id
        );
        if (index >= 0) {
          state.data.products[index] = productPayload;
        }
      });
    },
    deleteProducts: (state, action: PayloadAction<Product[]>) => {
      state.data.products = state.data.products.filter(
        (product) => action.payload.findIndex((p) => p.id === product.id) < 0
      );
    },
    uploadProgress: (state, action: PayloadAction<number>) => {
      state.ui.loadingProductsProgress = action.payload;
    },
    clear: (state) => {
      state.ui.loadingProductsProgress = 0;
      state.ui.isLoadingProductsFile = false;
      state.ui.isProductsFileLoaded = false;
      state.data.productsUploaderResponse = null;
      state.ui.isLoadingImagesFile = false;
      state.ui.loadingImagesProgress = 0;
      state.ui.isImagesFilesLoaded = false;
      state.data.imagesUploaderResponse = null;
    },
    setBulkProductsResult: (
      state,
      action: PayloadAction<BulkUploadProductsResponse | undefined>
    ) => {
      state.data.bulkProductsResult = action.payload;
    },
    setShowNotAvailableHook: (state, action: PayloadAction<boolean>) => {
      state.ui.showNotAvailableHook = action.payload;
    },
    setInitProcessMasiveUpload: (state, action: PayloadAction<boolean>) => {
      state.ui.initProcessMasiveUpload = action.payload;
    },
    setActionDiscardProcessUpload: (
      state,
      action: PayloadAction<(() => void) | undefined>
    ) => {
      state.ui.actionDiscardProcessUpload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrchestratorAction.pending, (state, action: any) => {
      state.ui.isFetchingOrchestrator = true;
    });
    builder.addCase(fetchOrchestratorAction.fulfilled, (state, action: any) => {
      state.ui.isFetchingOrchestrator = false;
      state.data.products = action.payload;
    });
    builder.addCase(fetchOrchestratorAction.rejected, (state, action: any) => {
      state.ui.isFetchingOrchestrator = false;
      state.data.products = [];
    });
    builder.addCase(updateProductAction.pending, (state, action: any) => {
      const id = action?.meta?.arg?.payload?.product?.id;
      let index = state.data.products.findIndex(
        (product: any) => product.id === id
      );
      if (index !== -1) {
        state.data.products[index] = action?.meta?.arg?.payload?.product;
      }
    });
    builder.addCase(updateProductAction.rejected, (state, action: any) => {
      const id = action?.payload?.product?.id;
      let index = state.data.products.findIndex(
        (product: Product) => product.id === id
      );
      if (index !== -1) {
        state.data.products[index] = action?.payload?.product;
      }
    });
    builder.addCase(deleteProductsAction.fulfilled, (state, action: any) => {
      state.data.products = state.data.products.filter(
        (product: Product) => !action.payload?.ids.includes(product.id)
      );
    });
    builder.addCase(createProductsAction.fulfilled, (state, action: any) => {
      state.data.products.push(...action.payload);
    });
    builder.addCase(
      createUniqueProductAction.fulfilled,
      (state, action: any) => {
        state.data.products.push(action.payload);
      }
    );
    builder.addCase(fetchCategoryAction.fulfilled, (state, action: any) => {
      state.data.categories = action.payload;
    });
    builder.addCase(uploadProductsAction.pending, (state, action: any) => {
      state.ui.isLoadingProductsFile = true;
      state.ui.isProductsFileLoaded = false;
      state.ui.loadingProductsProgress = 0;
    });
    builder.addCase(uploadProductsAction.fulfilled, (state, action: any) => {
      state.ui.isLoadingProductsFile = false;
      state.ui.isProductsFileLoaded = true;
      state.ui.loadingProductsProgress = 100;
      state.data.productsUploaderResponse = action.payload;
    });
    builder.addCase(uploadProductsAction.rejected, (state, action: any) => {
      state.ui.isLoadingProductsFile = false;
      state.ui.loadingProductsProgress = 0;
      state.ui.isProductsFileLoaded = false;
      state.data.productsUploaderResponse = null;
    });
    builder.addCase(uploadImagesAction.pending, (state, action: any) => {
      state.ui.isLoadingImagesFile = true;
      state.ui.isImagesFilesLoaded = false;
      state.ui.loadingImagesProgress = 0;
    });
    builder.addCase(uploadImagesAction.fulfilled, (state, action: any) => {
      state.ui.isLoadingImagesFile = false;
      state.ui.isImagesFilesLoaded = true;
      state.ui.loadingImagesProgress = 100;
      state.data.imagesUploaderResponse = action.payload;
    });
    builder.addCase(uploadImagesAction.rejected, (state, action: any) => {
      state.ui.isLoadingImagesFile = false;
      state.ui.loadingImagesProgress = 0;
      state.ui.isImagesFilesLoaded = false;
      state.data.imagesUploaderResponse = null;
    });
  },
});

export const productsActions = productsSlice.actions;
export const productsReducer = productsSlice.reducer;

const locale = {
    form: {
        title: "Create your store with Artificial Intelligence",
        description: "Describe the theme of your store and the products you sell.",
        exampleText: "Examples",
        placeholder: "Tell us about your ideal store",
        buttonCreateStore: "Create store",
        defaultDescription: "Create me a minimalist style jewelry store, pastel colors and with 4 golden products.",
        characters: "characters",
        minimumCharacters: "Minimum 10 characters",
    },
    loader: {
        stepOne: "Choosing the best design for your business...",
        stepTwo: "Selecting the best images for your catalog...",
        stepThree: "We are creating your store with AI...",
    },
    footer: {
        leftButton: {
            label: "See other option",
        },
        rightButton: {
            label: "Start",
        }
    }
};

export default locale;
import styled from 'styled-components';

const ContactButtonColorWrapper = styled.div`
	gap: 12px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.selectingCustomColor {
		width: 100%;
		padding: 8px;
		display: flex;
		position: relative;
		align-items: center;
		border-radius: 10px;
		background: #ffffff;
		justify-content: center;
		border: 1px solid #ececed;

		.icon {
			top: 8px;
			right: 8px;
			width: 24px;
			height: 24px;
			display: flex;
			color: #ffffff;
			font-weight: bold;
			position: absolute;
			align-items: center;
			border-radius: 28px;
			background: #262930;
			justify-content: center;
		}

		.selectedColor {
			display: flex;
			position: relative;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.picker {
				position: absolute;
				bottom: calc(100% + 16px);
			}

			.preview {
				width: 40px;
				height: 40px;
				margin: 0 0 10px 0;
				border-radius: 50%;
				border: 0.5px solid #5f636c;
			}

			.value,
			.name {
				color: #262930;
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
			}
		}
	}

	.colorItem {
		width: 100%;
		height: 66px;
		display: flex;
		cursor: pointer;
		max-width: 70px;
		border-radius: 8px;
		align-items: center;
		background: #ffffff;
		box-sizing: border-box;
		justify-content: center;
		border: 1px solid #ececed;

		&.active {
			border: 3px solid #2c68f6;
		}

		.color {
			border-radius: 4px;
			height: calc(100% - 16px);
			width: calc(100% - 16px);
			border: 0.5px solid #5f636c;
		}

		.customIcon {
			font-size: 32px;
			color: #2c68f6;
		}
	}
`;

export default ContactButtonColorWrapper;

import styled from "styled-components";

export const ProductNamePriceWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .label {
        color: var(--Grey-B, #24272e);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  label {
    color: var(--grey-b, #24272e);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .input {
    width: -webkit-fill-available;
  }
  .variation-label {
    .label {
      color: var(--Grey-B, #24272e);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: fit-content;
      .text {
        color: var(--Grey-B, #24272e);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-decoration-line: underline;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
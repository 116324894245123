import { FC } from "react";
import { Outlet } from "react-router-dom";

export type PaymentGatewayLayoutTypes = {};

const PaymentGatewayLayout: FC<PaymentGatewayLayoutTypes> = () => {
  return <Outlet />;
};

export default PaymentGatewayLayout;

export interface OnboardingAiState {
  data: OnboardingAiData;
  ui: OnboardingAiUi;
}

export interface OnboardingAiData {
  url: string;
  aiText: string;
}

export interface OnboardingAiUi {
  showEmptyState: boolean;
  loadIframe: boolean;
}

export const initialState: OnboardingAiState = {
  data: {
    url: "",
    aiText: "",
  },
  ui: {
    showEmptyState: true,
    loadIframe: false,
  },
};

import { useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { actions, planSelector, useAppDispatch } from "@dashboard/config/redux";
import { Feature } from "@dashboard/constants/features";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents } from "@dashboard/constants/events";
import { Plan } from "@dashboard/constants/plan";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { EDiscoutType } from "./constants/discount-type.constants";

export const useLogicWholesale = () => {
  const dispatch = useAppDispatch();
  const { logEvent } = useAnalytics();
  const plan = useSelector(planSelector);
  const { values }: { values: FormProps } = useFormikContext();
  const { setFieldValue } = useFormikContext();
  const [discount, setDiscount] = useState("");
  const [viewDiscount, setViewDiscount] = useState(0);
  const [viewFinalPrice, setViewFinalPrice] = useState(0);

  const disabledDiscount = !values.price;

  const checkFinalPrice = (value?: number, flag?: boolean) => {
    const currentPrice = values.price ?? 0;
    const aux = values.wholesale?.configurations;
    let auxValue = 0;
    if (aux) {
      if (aux[0].discountType === EDiscoutType.PERCENTAGE || flag) {
        auxValue =
          currentPrice - (currentPrice / 100) * (value || +discount || 0);
        setFieldValue("wholesale.configurations[0].unitPrice", value);
      } else {
        auxValue = values.price - (value || 0);
        setFieldValue("wholesale.configurations[0].unitPrice", auxValue);
      }
      setViewFinalPrice(auxValue);
      setViewDiscount(auxValue - values.price);
    }
  };

  const getHasError = (): boolean => {
    if (
      values.wholesale?.configurations[0].discountType ===
      EDiscoutType.PERCENTAGE
    ) {
      return (+discount ?? 0) > 100;
    } else {
      return (+discount ?? 0) > values.price;
    }
  };

  const calculateDiscount = () => {
    const wholesale = values.wholesale?.configurations;
    if (wholesale) {
      if (values.toppings) {
        if (
          wholesale[0].discountType === EDiscoutType.VALUE ||
          !wholesale[0].discountType
        ) {
          const auxPer = Math.round(
            100 - ((wholesale[0].unitPrice || 0) * 100) / values.price
          );
          setFieldValue("wholesale.configurations[0]", {
            ...values.wholesale?.configurations[0],
            unitPrice: auxPer,
            discountType: EDiscoutType.PERCENTAGE,
          });
          setDiscount(String(auxPer));
          checkFinalPrice(auxPer, true);
        } else if (wholesale[0].discountType === EDiscoutType.PERCENTAGE) {
          const value = values.wholesale?.configurations[0].unitPrice || 0;
          setDiscount(String(value));
          checkFinalPrice(value, true);
        }
      } else {
        if (
          wholesale[0].discountType === EDiscoutType.VALUE ||
          !wholesale[0].discountType
        ) {
          const value =
            values.price - (values.wholesale?.configurations[0].unitPrice || 0);
          const valueRound = Math.round(value * 100) / 100;
          setFieldValue("wholesale.configurations[0]", {
            ...values.wholesale?.configurations[0],
            discountType: EDiscoutType.VALUE,
          });
          setDiscount(String(valueRound));
          checkFinalPrice(value);
        } else if (wholesale[0].discountType === EDiscoutType.PERCENTAGE) {
          const value = values.wholesale?.configurations[0].unitPrice || 0;
          setDiscount(String(value));
          checkFinalPrice(value);
        }
      }
    }
  };

  const isWholesaleAvailable = [
    Plan.LEGACY.toLowerCase(),
    Plan.PRO.toLowerCase(),
    Plan.ULTRA.toLowerCase(),
  ].includes(plan?.benefit_plan_name?.toLowerCase() ?? "");

  const handleWholesaleClickWhenNotAvailable = () => {
    dispatch(actions.showCustomPlanHook(Feature.ProductWholesale));
    logEvent(CoreEvents.SystemExpertWarning, {
      event_subtype: "wholesale_enabled",
      source: "hook",
      method: "free",
    });
  };

  return {
    discount,
    disabledDiscount,
    isWholesaleAvailable,
    setDiscount,
    checkFinalPrice,
    getHasError,
    calculateDiscount,
    viewDiscount,
    viewFinalPrice,
    handleWholesaleClickWhenNotAvailable,
  };
};

import { Plan } from "@dashboard/modules/plans/plans.types";
import { useMemo } from "react";
import { EPlanPricing } from "../constants/plan-pricing";

export const usePlanGridShared = (plans: Plan[], view: EPlanPricing) => {
  const plansOrdered = useMemo(() => {
    let auxPlans: Array<{ order: number; plan: Plan }> = [];
    plans.forEach((plan) => {
      plan?.planTypeComponent?.components?.configuration?.some((config) => {
        const auxView = config.views?.find((viewConfig) => viewConfig === view);
        if (auxView) {
          auxPlans.push({
            order: config.component_order || 0,
            plan: { ...plan },
          });
          return true;
        } else {
          return false;
        }
      });
    });
    return auxPlans
      .sort((a, b) => a.order - b.order)
      .map((p) => {
        return {
          ...p.plan,
        };
      });
  }, [plans]);

  return {
    plansOrdered,
  };
};

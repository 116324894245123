import { generateCodeFetch } from "@dashboard/services/session-code.services";
import { useLocalStorage } from "@sumerlabs/component-library";

export const useSessionCode = () => {
  const [token] = useLocalStorage("accessToken", "");

  const generate = () => {
    if (token) {
      return generateCodeFetch(token)
        .then((response) => {
          return response.code;
        })
        .catch(() => {
          return null;
        });
    }
  };

  return { generate };
};

import StepPlan from "@dashboard/modules/shared/components/onboarding-shared/pages/step-plan/step-plan";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingGuestPlan: FC = () => {
  const navigate = useNavigate();
  return (
    <StepPlan onBack={() => navigate("/onboarding-split-two/step-four")} />
  );
};

export default OnboardingGuestPlan;

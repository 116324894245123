import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC } from "react";
import LoginSelectOption from "../login/components/login-select-option/login-select-option";
import LoginSendEmail from "../login/components/login-send-email/login-send-email";
import LoginSendPhone from "../login/components/login-send-phone/login-send-phone";
import LoginVerifyCode from "../login/components/login-verify-code/login-verify-code";
import {
  GOOGLE_CLIENT_ID,
  LoginStep,
} from "../shared/components/onboarding-shared/constants/auth.constants";
import { useAuth } from "../shared/components/onboarding-shared/hooks/auth.hooks";
import { LoginModalWrapper } from "./login-modal.styled";
import { Modal } from "@sumerlabs/component-library";
import { useTranslation } from "react-i18next";

export type LoginModalTypes = {
  showModal: boolean;
  onClose: () => void;
};

const LoginModal: FC<LoginModalTypes> = ({ showModal, onClose }) => {
  const { loginStep } = useAuth();
  const { t } = useTranslation();
  return (
    <Modal
      showHeader={false}
      content={{
        className: "fadeInDown",
        styles: { width: "500px", padding: "10px" },
      }}
      show={showModal}
      onClose={onClose}
    >
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <LoginModalWrapper>
          <div className="icon">
            <figure className="icon-close" onClick={onClose} />
          </div>
          <div className="container">
            <div className="right">
              {loginStep === LoginStep.SelectMethod && (
                <LoginSelectOption
                  descriptionText={t(
                    "sharedOnboarding.stepLogin.split2.description"
                  )}
                />
              )}
              {loginStep === LoginStep.SendPhoneCode && <LoginSendPhone />}
              {loginStep === LoginStep.SendEmailCode && <LoginSendEmail />}
              {loginStep === LoginStep.VerifyCode && <LoginVerifyCode />}
            </div>
          </div>
        </LoginModalWrapper>
      </GoogleOAuthProvider>
    </Modal>
  );
};

export default LoginModal;

export const debounce = (callback: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

export const promiseTimeout = <T>(
  promise: () => Promise<T>,
  timeout: number = 1000
) => new Promise<T>((resolve) => setTimeout(() => resolve(promise()), timeout));

import styled from "styled-components";

export const IaChatHeaderStyled = styled.header`
  background-color: #24272e;
  padding: 0.75rem 1rem;
  position: relative;
  .chat-title {
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
  }
  .chat-header-options-left {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    .chat-option {
      align-items: center;
      background: transparent;
      color: #ffffff;
      display: flex;
      font-size: 1.5rem;
      height: 24px;
      justify-content: center;
      width: 24px;
    }
  }
`;

import { http } from "@dashboard/common/http";
import { decamelizeKeys } from "humps";
import { Category } from "../types/products.types";

export const fetchCategories = (token: string) => {
  return http.get(`/api/ms/catalogue/categories?only-owner=false`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    } as { [key: string]: string },
  });
};

export const createUpdateCategory = (token: string, payload: Category) => {
  return http.post(
    `/api/ms/catalogue/catalogue-categories`,
    decamelizeKeys(payload),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
    }
  );
};

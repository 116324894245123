export enum Deeplinks {
  primePayPreview = "prime-pay-preview",
  evoMessage = "evo-message",
  createProduct = "add-or-edit-product-screen",
  personalization = "personalization",
  orders = "orders",
  domains = "own-domain",
  setPayments = "set-up-payments",
  invoice = "addon-invoice",
  floatingButton = "add-ons",
  abandonedCart = "abandoned-cart-screen",
  profile = "profile",
}

export type DeeplinksModuleData = {
  [key in Deeplinks]: string[];
};

export type DeeplinksModules = Record<Deeplinks, string>;

export const downloadFile = (
  fileErrors: Blob,
  options: {
    file?: { name: string };
    callback?: () => void;
  } = {}
) => {
  const { file, callback } = options;
  if (fileErrors && file) {
    const fileObject = window.URL.createObjectURL(fileErrors);
    const linkObject = document.createElement("a");
    linkObject.style.display = "none";
    linkObject.href = fileObject;
    linkObject.download = file.name;
    document.body.appendChild(linkObject);
    linkObject.click();
    setTimeout(() => window.URL.revokeObjectURL(fileObject), 1e5);

    callback && callback();
  }
};

import { FC, useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginSendEmailWrapper } from "./login-send-email.styled";
import logoSumerImg from '@dashboard/assets/img/logo-sumer.png';
import { UiButton, UiInput } from "@sumerlabs/component-library";
import { useAuth } from "../../../shared/components/onboarding-shared/hooks/auth.hooks";
import { LoginStep } from "../../../shared/components/onboarding-shared/constants/auth.constants";
import { loginSendEmailSchema } from "../../../shared/components/onboarding-shared/validation-schemas/login-send-email.schema";
import { RequestCodeChannel } from "../../../shared/components/onboarding-shared/entities/auth.entities";

interface LoginSendEmailProps {
  email?: string;
}
 
const LoginSendEmail: FC<LoginSendEmailProps> = ({ email }) => {
  
  const { isLoading, setUi, sendEmailCode } = useAuth();
  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validateOnMount: true,
    validationSchema: loginSendEmailSchema(t),
    onSubmit: () => {},
  });

  const sendCode = () => {
    sendEmailCode({
      send_to: email || form.values.email,
      email: email || form.values.email,
      channel: RequestCodeChannel.Email,
    });
  };

  useEffect(() => {
    if (!!email) {
      form.setFieldValue('email', email);
      sendCode();
    };
  }, [email]);

  return (
    <LoginSendEmailWrapper>
      <div className="back" onClick={() => setUi({ loginStep: LoginStep.SelectMethod })}>
        <span className="icon-arrow" />
      </div>
      <img src={logoSumerImg} alt="Sumer" className="logo-back" />
      <div className="send-phone-title">{t('onboarding.login.sendEmail.title')}</div>
      <UiInput
        label=""
        name="email"
        className="input"
        placeholder={t('onboarding.login.sendEmail.title')}
        value={form.values.email}
        hasError={!!form.errors.email && form.dirty}
        errorMessage={form.errors.email}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
      />
      <div className="disclaimer">{t('onboarding.login.sendEmail.disclaimer')}</div>
      <UiButton
        className="button"
        isLoading={isLoading}
        disabled={!form.isValid || isLoading}
        onClick={sendCode}>
        {t('continue')}
      </UiButton>
    </LoginSendEmailWrapper>
  );
}
 
export default LoginSendEmail;

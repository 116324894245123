import StepInfo from "@dashboard/modules/shared/components/onboarding-shared/pages/step-info/step-info";
import { FC } from "react";

const OnboardingGuestInfo: FC = () => {
  return (
    <StepInfo
      ctaNextStepPath="/onboarding-guest/premium"
      hiddeEconomicSector
      isGuest
    />
  );
};

export default OnboardingGuestInfo;

import { primeGradient } from "@dashboard/assets/variables/prime";
import styled from "styled-components";

export const CollapseContainerWrapper = styled.div.attrs((props: { border: string }) => props)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  & > * {
    width: 100%;
  }
  &.disabled {
    background: var(--grey-border, #e9e9ed);
    pointer-events: none;
  }
  .info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-bottom: ${(props) => props.border || '1px solid #D9D9D9'}; ;
    padding: 16px;
    .icons {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: center;
      position: relative;
      width: 32px;
      .icon {
        font-size: 1.25rem;
      }
      .prime {
        ${primeGradient}
        align-items: center;
        bottom: -4px;
        border-radius: 50%;
        display: flex;
        font-size: 11px;
        justify-content: center;
        position: absolute;
        height: 20px;
        right: -4px;
        width: 20px;
      }
    }
    .description {
      width: -webkit-fill-available;
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        display: block;
      }
      .subtitle {
        color: var(--grey-4, #5d606f);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .itemsNumberInfo {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Grey-B, #24272e);
        background: var(--Grey-8, #fcfcfc);
        width: fit-content;
        margin-top: 8px;
        .text {
          color: var(--Grey-B, #24272e);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
    .switchContainer {
      display: flex;
      text-align: right;
      align-items: baseline;
      justify-content: end;
      &.disabled {
        display: none
      }
      .caretRight:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
    .arrowContainer {
      display: flex;
      text-align: right;
      align-items: baseline;
      justify-content: end;
      padding: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .available {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    align-self: stretch;
    margin-top: 12px;
  }
`;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onboardingAiActions } from "../store/onboarding-ai.slice";
import {
  fetchGetAiStore,
  fetchMergeAiStore,
  fetchRegenerateAiStore,
} from "../services/onboarding-ai.service";
import { onboardingAiTextSelector } from "../store/onboarding-ai.selector";
import { useLocalStorage } from "@sumerlabs/component-library";
import { useNotification } from "@dashboard/hooks/use-notification.hook";
import { useNavigate } from "react-router-dom";

export const useOnboardingAi = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [token] = useLocalStorage("accessToken", "");
  const dispatch = useDispatch();
  const aiText = useSelector(onboardingAiTextSelector);
  const { notify } = useNotification();
  const navigate = useNavigate();

  const getCatalogueInfo = () => {
    setShowLoader(true);
    dispatch(onboardingAiActions.setEmptyState(true));
    dispatch(onboardingAiActions.setLoadIframe(false));
    if (sessionStorage.getItem("guestToken")) {
      regenerateCatalogueInfo();
    } else {
      generateCatalogueInfo();
    }
  };

  const generateCatalogueInfo = () => {
    fetchGetAiStore(aiText)
      .then((response) => {
        dispatch(onboardingAiActions.setLoadIframe(true));
        sessionStorage.setItem("guestToken", response.accessToken);
        dispatch(onboardingAiActions.setUrl(`${response.url}&embedded=true&noEvents=true`));
        setTimeout(() => {
          dispatch(onboardingAiActions.setEmptyState(false));
          setShowLoader(false);
        }, 2000);
      })
      .catch((error) => handleErrorMessage(error.status, error.message));
  };

  const regenerateCatalogueInfo = () => {
    setShowLoader(true);
    fetchRegenerateAiStore(aiText, sessionStorage.getItem("guestToken") || "")
      .then((response) => {
        dispatch(onboardingAiActions.setLoadIframe(true));
        dispatch(onboardingAiActions.setUrl(`${response.url}&embedded=true&noEvents=true`));
        setTimeout(() => {
          dispatch(onboardingAiActions.setEmptyState(false));
          setShowLoader(false);
        }, 2000);
      })
      .catch((error) => handleErrorMessage(error.status, error.message));
  };

  const mergeUserGuest = () => {
    fetchMergeAiStore(token || "", sessionStorage.getItem("guestToken") || "")
      .then(() => {})
      .catch((error) => handleErrorMessage(error.status, error.message));
    sessionStorage.removeItem("guestToken");
  };

  const handleErrorMessage = (status: number, message: string) => {
    notify({ message: `(${status}) ${message}`, type: "error" });
    if (status === 401) {
      setTimeout(() => navigate(0), 2000);
    }
  };

  return {
    showLoader,
    setShowLoader,
    getCatalogueInfo,
    mergeUserGuest,
  };
};

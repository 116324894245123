import {
  countryInformationSelector,
  planSelector,
} from "@dashboard/config/redux";
import {
  Configuration,
  Copies,
  Plan,
} from "@dashboard/modules/plans/plans.types";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EPlanPricing } from "../constants/plan-pricing";
import { PlatformCountry } from "@dashboard/common/types";

const usePlanCardShared = (
  plan: Plan,
  view: EPlanPricing,
  country?: PlatformCountry
) => {
  const currency = useSelector(countryInformationSelector)?.currency;
  const currentPlan = useSelector(planSelector);
  const { t } = useTranslation();

  const configuration = useMemo(() => {
    let configFound: Configuration = {};
    plan?.planTypeComponent?.components?.configuration?.some((config) => {
      const auxView = config.views?.find((viewConfig) => viewConfig === view);
      if (auxView) {
        configFound = config;
        return true;
      } else {
        return false;
      }
    });
    return configFound;
  }, [plan]);

  const copies = useMemo(() => {
    const auxPlanView = plan.benefitPlan?.features_grid?.web_plan_view;
    let copiesDummy: Copies = {
      cta_button_label: "cta_button_label_no_config",
      current_plan_label: "current_plan_label_no_config",
      list_description: [],
      hint_list_description: [],
      main_description: "main_description_no_config",
    };
    if (auxPlanView) {
      const auxCopies = auxPlanView[0].copies;
      try {
        copiesDummy = {
          cta_button_label: auxCopies?.cta_button_label || "",
          current_plan_label: auxCopies?.current_plan_label || "",
          list_description: JSON.parse(
            (auxCopies?.list_description as string) || ""
          ),
          hint_list_description: JSON.parse(
            (auxCopies?.hint_list_description as string) || ""
          ),
          main_description: auxCopies?.main_description || "",
        };
      } catch (error) {
        return copiesDummy;
      }
    }
    return copiesDummy;
  }, [plan]);

  const pricing = useMemo(() => {
    const auxUpcomingInvoice =
      plan.cart?.additional_information.upcoming_invoice_v2;
    let totalValue;
    if (auxUpcomingInvoice?.use_upcoming_invoice_values) {
      totalValue = formatCurrency(auxUpcomingInvoice.total_value || 0, country);
    } else {
      totalValue = formatCurrency(plan.cart?.total_value || 0, country);
    }
    return {
      totalValue,
      hint:
        view === EPlanPricing.ONBOARDING
          ? auxUpcomingInvoice?.onboarding_text
          : auxUpcomingInvoice?.show_external_days
          ? auxUpcomingInvoice?.whole_external_text
          : "",
    };
  }, [plan]);

  return {
    t,
    currency,
    currentPlan,
    configuration,
    copies,
    pricing,
  };
};

export default usePlanCardShared;

import { css } from "styled-components";
export const check = css`
  animation: check 0.5s ease 0s 1 normal forwards;
  @keyframes check {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
import { FC, useState } from "react";
import { ProductMapContentWrapper } from "./product-map-content.styled";
import { IMapContent } from "./models/map-content";
import { TFunction } from "react-i18next";
import { EMapContent } from "./constants/map-content.constants";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";

type ProductMapContentTypes = {
  t: TFunction;
  onClick: (scrollTo: EMapContent) => void;
};

const ProducMapContent: FC<ProductMapContentTypes> = ({ t, onClick }) => {
  const { logEvent } = useAnalytics();
  const [contentItems, setContentItems] = useState<Array<IMapContent>>([
    {
      label: t("productComponent.product.mapContent.detailLabel"),
      scrollTo: EMapContent.DETAIL,
      isActive: true,
    },
    {
      label: t("productComponent.product.mapContent.inventoryLabel"),
      scrollTo: EMapContent.INVENTORY,
      isActive: false,
    },
    {
      label: t("productComponent.product.mapContent.productInfoLabel"),
      scrollTo: EMapContent.INFORMATION,
      isActive: false,
    },
  ]);

  const setIsActive = (index: number) => {
    const aux = contentItems.map((content, i) => {
      return { ...content, isActive: i === index ? true : false };
    });
    setContentItems([...aux]);
  };

  return (
    <ProductMapContentWrapper>
      <div className="content">
        {contentItems.map((item, i) => (
          <div
            key={`item-${i}`}
            className="item"
            onClick={() => {
              setIsActive(i);
              onClick(item.scrollTo);
              logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
                event_subtype: PRODUCTS_EVENTS.PRODUCT_DETAIL_INDEX,
                option: `${item.scrollTo}`,
              });
            }}
          >
            <div className={`text ${item.isActive && "isActive"}`}>
              {item.label}
            </div>
          </div>
        ))}
      </div>
    </ProductMapContentWrapper>
  );
};

export default ProducMapContent;

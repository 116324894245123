import { PlatformCountry } from "@dashboard/common/types";

export const formatCurrency = (price: number, currentCountry?: PlatformCountry) => {
  const minimumFractionDigits = ['GTQ'].includes(currentCountry?.currency || '') ? 2 : 0;
  if (currentCountry && currentCountry.locale) {
    return new Intl.NumberFormat(currentCountry.locale.replace('_', '-').substr(0, 5), {
      style: 'currency',
      currency: currentCountry.currency,
      minimumFractionDigits,
      maximumFractionDigits: 2,
    })
      .format(price)
      .replace(/\s+/g, '');
  }

  return price;
};

import styled from "styled-components";

export const AiLoaderWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  .screen-loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    .overlay {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      background: var(--grey-overlay, rgba(12, 18, 32, 0.8));
    }
    .progress {
      z-index: 2;
      position: relative;
      .img {
        width: 108px;
        height: 108px;
        animation: spin 1s linear infinite reverse;
      }
      .logo {
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        border-radius: 8px;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        transform: translate(-50%, -50%);
      }
      .text {
        left: 50%;
        bottom: -46px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        position: absolute;
        width: max-content;
        text-align: center;
        color: var(--grey-w, #fff);
        transform: translateX(-50%);
      }
    }
  }
  .fake-content {
    width: 100%;
    border-radius: 20px;
    background: var(--grey-w, #fff);
    box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
    text-align: center;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

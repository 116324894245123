import { getEnvVar } from "@dashboard/common/utils";
import clevertap from "clevertap-web-sdk";

export const useClevertap = () => {
  const CLEVERTAP_ACCOUNT_ID = getEnvVar("REACT_APP_CLEVERTAP_ACCOUNT_ID");

  const init = () => {
    clevertap.init(CLEVERTAP_ACCOUNT_ID, "us1");
  };

  const login = (id: string) => {
    clevertap.onUserLogin.push({
      Site: {
        Identity: id,
      },
    });
  };

  const sendEvent = (
    event: string,
    payload: { [key: string]: string | number | boolean | undefined } = {}
  ) => {
    clevertap.event.push(event, payload);
  };

  return {
    init,
    login,
    sendEvent,
  };
};

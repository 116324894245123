export enum HomeEvent {
  ViewScreen = "view_screen",
  SelectBanner = "select_banner",
  SelectButton = "select_button",
  SelectNavbar = "select_navbar",
};

export enum HomeEventSubtype {
  ViewHome = "web_view_home",
  HomeWeb = "home_web",
  ViewPlans = "view_plans",
  HomeMainButtons = "web_home_main_buttons",
  HomeTutorials = "web_home_tutorials",
  MainOptions = "web_main_options",
  ViewStore = "store_preview",
};
export const addons = {
  "contact_button": {
    "titles": {
      "main": "Botón de Whatsapp",
      "config": "Configuración de contacto",
      "messages": "Mensajes preestablecidos",
      "position": "Posición del botón",
      "color": "Color",
      "icon": "Icono",
    },
    "descriptions": {
      "main": "Configura cómo se verá el botón de Whatsapp en tu catálogo.",
      "config": "Ingresa el número de contacto donde tus clientes te contactarán",
      "messages": "Preestablece los mensajes que recibirás cuando tu cliente te contacte.",
      "position": "Elige en la ubicación del botón flotante dentro de tu tienda.",
      "color": "Elige el color del botón flotante dentro de tu tienda.",
      "icon": "Elige el ícono del botón flotante dentro de tu tienda.",
    },
    "notification_ok": "Cambio realizado exitosamente",
    "notification_error": "Error, intente nuevamente",
    "apply": "Aplicar",
    "phone": "Teléfono",
    "country": "País",
    "hide_button": "Ocultar botón",
    "option": "Opción",
    "background": "Fondo",
    "got_it": "Entendido",
    "messages_texts": {
      "write_your_message": "Escribe tu mensaje",
      "characters": "caracteres",
      "catalogue_title": "Mensaje desde página principal",
      "catalogue_description": "Es el mensaje que recibirás de tu cliente cuando envíe un mensaje desde la página principal de tu tienda.",
      "store_name": "Nombre tienda",
      "store_link": "Link tienda",
      "product_title": "Mensaje desde un producto",
      "product_description": "Es el mensaje que recibirás de tu cliente cuando envíe un mensaje mientras ve un producto específico.",
      "product_name": "Nombre producto",
      "product_link": "Link producto"
    },
    "axis": {
      "left": "Izquierda",
      "center": "Centrado",
      "right": "Derecha",
      "middle": "Medio",
      "bottom": "Inferior"
    },
  }
};

import { useTranslation } from "react-i18next";
import {
  FilterModalContentWrapper,
  ModalWrapper,
} from "./variations-filter-modal.styled";
import { UiButton } from "@sumerlabs/component-library";
import { useFormikContext } from "formik";
import { useContext } from "react";
import UiModal from "@dashboard/components/ui-modal";
import ProductContext from "../../context/product.context";
import { useLogicVariationsFilterModal } from "./variations-filter-modal.hook";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";

const VariationsFilterModal = () => {
  const { t } = useTranslation();
  const { values }: { values: FormProps } = useFormikContext();
  const { showFilterModal, setShowFilterModal } = useContext(ProductContext);
  const { addItemToFilter, cleanFilters, isFiltered } =
    useLogicVariationsFilterModal();

  return (
    <ModalWrapper>
      <UiModal
        onClose={() => {
          setShowFilterModal(false);
        }}
        show={showFilterModal}
        className="modal"
      >
        <FilterModalContentWrapper>
          <div className="header">
            {t("admin.products.side.inventory.variations.filter.modal.title")}
          </div>
          <div className="body">
            {values.toppings &&
              values.toppings.map((topping, i) => (
                <div className="variation" key={`topping-key-${i}`}>
                  <div className="name">{topping.name}</div>
                  <div className="values">
                    {topping.values?.map((value, j) => (
                      <div
                        key={`topping-value-key-${i}-${j}`}
                        className={`item ${
                          isFiltered(i, value.name) ? "isFiltered" : ""
                        }`}
                        onClick={() => addItemToFilter(i, value.name, false)}
                      >
                        {value.name}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            <div className="controls">
              <div className="link" onClick={cleanFilters}>
                {t(
                  "admin.products.side.inventory.variations.filter.modal.buttonClean"
                )}
              </div>
              <UiButton
                onClick={() => {
                  setShowFilterModal(false);
                }}
              >
                {t(
                  "admin.products.side.inventory.variations.filter.modal.buttonApply"
                )}
              </UiButton>
            </div>
          </div>
        </FilterModalContentWrapper>
      </UiModal>
    </ModalWrapper>
  );
};

export default VariationsFilterModal;

import { useAnalytics } from "@dashboard/common/analytics";
import { actions, isNavigationBarCollapsedSelector, menuSelector, planSelector } from "@dashboard/config/redux";
import {
  HomeEvent,
  HomeEventSubtype,
} from "@dashboard/modules/home/constants/home.events";
import { usePersonalization } from "@dashboard/modules/personalization/hooks/personalization.hooks";
import { MouseEvent, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu } from "@dashboard/entities/menu";
import { CoreEvents } from "@dashboard/constants/events";
import { useProductsNavigation } from "@dashboard/modules/products/hooks/products-navigation.hook";
import {
  initProcessMasiveUploadSelector,
  productsActions,
} from "@dashboard/modules/products/redux";
import { AI_ASSISTANT_EVENTS } from "@dashboard/modules/ai-assistant/constants/events.constants";
import { Feature } from '@dashboard/constants/features';
import { Plan } from "@dashboard/constants/plan";
import { personalizationChangesSelector } from "@dashboard/modules/personalization/store";

export const useNavigationBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logEvent } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const plan = useSelector(planSelector);
  const menu = useSelector(menuSelector);
  const pendingChanges = useSelector(personalizationChangesSelector);
  const initProcessMasiveUpload = useSelector(initProcessMasiveUploadSelector);
  const { checkForPendingChanges } = usePersonalization();
  const { bulkUploadUrl } = useProductsNavigation();
  const isNavigationBarCollapsed = useSelector(isNavigationBarCollapsedSelector);
  const currentPath = useMemo(
    () => location.pathname.split("/")[2] || "",
    [location]
  );

  const isActiveItem = useCallback(
    (link?: string) => {
      let x = false;
      const routeSections = location.pathname.split("/");
      routeSections.splice(0, 2);
      routeSections.forEach((split) => {
        if (`/${split}` === link) x = true;
      });
      return x;
    },
    [location]
  );

  const handleClickItem = (i: Menu, e?: MouseEvent<HTMLDivElement>) => {
    logEvent(HomeEvent.SelectNavbar, {
      event_subtype: HomeEventSubtype.MainOptions,
      option: i.id,
    });
    if (plan?.benefit_plan_name?.toLowerCase() === Plan.FREE.toLowerCase()) {
      if (i.name === 'Orders') {
        dispatch(actions.showCustomPlanHook(Feature.Orders));
        return;
      }
    }
    if (!!i.existInApp && !!i.existInWeb) {
      if (!!i.link) {
        navigate(`/${id}${i.link}`);
      }
      e?.stopPropagation();
      return;
    }
    if (!!i.hookInfo) {
      dispatch(actions.setHookInfo(i.hookInfo));
    }
  };

  const handleClickItemCheck = useCallback(
    (menu: Menu, e?: MouseEvent<HTMLDivElement>) => {
      if (menu.id === "statistics") {
        handleClickItem(menu, e);
      } else if (
        location.pathname.indexOf(bulkUploadUrl) >= 0 &&
        initProcessMasiveUpload
      ) {
        dispatch(
          productsActions.setActionDiscardProcessUpload(() => {
            checkForPendingChanges(() => handleClickItem(menu));
          })
        );
      } else {
        checkForPendingChanges(() => handleClickItem(menu));
      }
    },
    [bulkUploadUrl, location, initProcessMasiveUpload, plan, pendingChanges]
  );

  const handleClickAccount = () => {
    logEvent(HomeEvent.SelectNavbar, {
      event_subtype: HomeEventSubtype.MainOptions,
      option: "profile",
    });
    checkForPendingChanges(() => navigate("profile"));
  };

  const handleClickHelp = () => {
    if (typeof Kustomer !== "undefined") Kustomer.open();
    logEvent(HomeEvent.SelectNavbar, {
      event_subtype: HomeEventSubtype.MainOptions,
      option: "support",
      method: "Kustomer",
    });
  };

  const handleClickAiAssistant = () => {
    dispatch(actions.openAiAssistant());
    logEvent(CoreEvents.SelectButton, {
      event_subtype: AI_ASSISTANT_EVENTS.AI_MAIN,
    });
  };

  const handleNavigationBarCollapse = (state: boolean) => {
    dispatch(actions.setIsNavigationBarCollapsed(state));
  };

  useEffect(() => {
    if (["personalization", "contact-button", "voucher"].includes(currentPath)) {
      dispatch(actions.setIsNavigationBarCollapsed(true));
    }
  }, [currentPath]);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);

  const receiveMessage = (event: { data: string }) => {
    if (event.data === CoreEvents.OpenChat) {
      logEvent(HomeEvent.ViewScreen, {
        event_subtype: CoreEvents.KustomerChat,
        source: "sidebar",
      });
    }
  };

  return {
    t,
    plan,
    menu,
    isNavigationBarCollapsed,
    handleNavigationBarCollapse,
    isActiveItem,
    handleClickItem,
    handleClickItemCheck,
    handleClickAccount,
    handleClickHelp,
    handleClickAiAssistant,
    checkForPendingChanges,
  };
};

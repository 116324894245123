import { FC } from "react";
import { SendEmailConfirmationWrapper } from "./send-email-confirmation.styled";
import { UiButton } from "@sumerlabs/component-library";
import { useTranslation } from "react-i18next";

interface SendEmailConfirmationProps {
  onClose: () => void;
  onButtonClick: () => void;
}
 
const SendEmailConfirmation: FC<SendEmailConfirmationProps> = ({
  onClose,
  onButtonClick,
}) => {

  const { t } = useTranslation();

  return (
    <SendEmailConfirmationWrapper>
      <div className="close" onClick={onClose}>
        <span className="icon-close" />
      </div>
      <div className="main-icon">
        <span className="icon-email-outline" />
      </div>
      <div className="title">{t('abandonedCarts.sendEmailConfirm.title')}</div>
      <div className="description">{t('abandonedCarts.sendEmailConfirm.description')}</div>
      <UiButton
        className="button" 
        onClick={onButtonClick} 
        icon={<span className="icon-arrow" />}>
        {t('abandonedCarts.sendEmailConfirm.send')}
      </UiButton>
    </SendEmailConfirmationWrapper>
  );
}
 
export default SendEmailConfirmation;
import styled from 'styled-components';

export const LayoutContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 250px 1fr;
  transition: grid-template-columns 0.3s;
  &.isCollapse {
    grid-template-columns: var(--navigation-bar-collapse) 1fr;
  }
  .main {
    flex: 1;
    display: flex;
    overflow: auto;
    background: white;
    position: relative;
    flex-direction: column;
    .body-main {
      margin-top: 67px;
      height: 100%;
    }
  }
  .banner {
    z-index: 10;
    width: 100%;
    display: flex;
    column-gap: 24px;
    grid-column: 1 / 3;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #DFF7ED;
    height: var(--bottom-banner-height, 0px);
    .mainText {
      display: flex;
      max-width: 80%;
      font-size: 18px;
      column-gap: 8px;
      font-weight: 400;
      line-height: 26px;
      align-items: center;
      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .icon {
        font-size: 24px;
      }
    }
    .button {
      width: fit-content;
      border-radius: 8px;
    }
    .close {
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      color: #24272E;
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      align-items: center;
      justify-content: center;
    }
  }
  &:has(.navigation-bar__collapse) {
    --navigation-bar-collapse: 64px;
  }
`;
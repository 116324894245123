import styled from "styled-components";

export const StepInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0px 116px;
  height: 100vh;
  align-items: center;
  .description {
    display: flex;
    align-items: center;
  }
  .form {
    display: flex;
    padding: 48px;
    height: fit-content;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    border-radius: 20px;
    border: 1px solid var(--Grey-Border, #e9e9ed);
    background: var(--Grey-W, #fff);
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      align-self: stretch;
      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        .phone-contact {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          .title {
            display: -webkit-box;
            width: 343px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: var(--Grey-B, #24272e);
            text-overflow: ellipsis;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
          .phone-input-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            .phone-input {
              width: 100%;
            }
            .checkbox-container {
              display: flex;
              padding: 4px 0px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              .text {
                color: var(--Grey-B, #24272e);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }
        }
      }

      .economic-sector {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        .about-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          .title {
            display: -webkit-box;
            width: 343px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: var(--Grey-B, #24272e);
            text-overflow: ellipsis;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
          .description {
            display: -webkit-box;
            width: 343px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: var(--Grey-400, #5d606f);
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .sectors-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          width: 100%;
          .search-bar {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            .economic-select {
              width: 100%;
            }
            .label {
              display: flex;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;
              .text {
                color: var(--Grey-400, #5d606f);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
              .link {
                color: var(--Grey-B, #24272e);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                text-decoration-line: underline;
                &:hover {
                  cursor: pointer;
                  opacity: 0.6;
                }
              }
            }
          }
          .list {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            flex-flow: wrap;
            justify-content: flex-start;
            .chip {
              display: flex;
              height: 40px;
              padding: 0px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 36px;
              border: 1px solid var(--Grey-700, #e9e9ed);
              background: var(--Grey-W, #fff);
              &.is-active {
                background: black;
                color: white;
              }
              &:hover {
                cursor: pointer;
                background: black;
                color: white;
              }
            }
          }
        }
      }
    }
    .controls {
      > button {
        > span {
          transform: rotate(-90deg);
        }
      }
    }
  }
`;

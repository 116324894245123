import styled from "styled-components";

export const GptGenerateStyled = styled.div``;

export const GptGenerateButtonStyled = styled.button`
  align-items: center;
  background: transparent;
  color: var(--Grey-B);
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  span {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
  }
  .gpt-icon {
    font-size: 1.5em;
  }
`;

export const GptGenerateModalStyled = styled.div`
  background-color: rgba(12, 18, 32, 0.9);
  border-radius: 1rem;
  color: #ffffff;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  left: 0;
  padding: 1rem 1rem 2rem;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 99;
  .gpt-generate-close {
    background: transparent;
    color: #ffffff;
    font-size: 1.5rem;
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    z-index: 2;
  }
  .gpt-generate-modal-header {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    .modal-header-icon {
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
      color: var(--Grey-B);
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      height: 40px;
      width: 40px;
    }
    .modal-header-title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  }
  .gpt-generate-modal-info {
    border: 1px solid var(--Grey-300);
    border-radius: 0.875rem;
    font-size: 0.875rem;
    padding: 0.75rem;
    position: relative;
    width: 100%;
    button {
      text-transform: lowercase;
      span {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    div {
      display: flex;
      gap: 0.75rem;
      flex-direction: column;
      transition: all 0.2s;
    }
    p {
      font-weight: 400;
      font-size: 1em;
      line-height: 1.5em;
      small {
        color: var(--Grey-600);
        font-size: 0.75rem;
        line-height: 1.5em;
      }
    }
    .gpt-generate-modal__loading {
      opacity: 0;
    }
  }
  .gpt-generate-modal-footer {
    button {
      font-weight: 400;
    }
  }
  &.gpt-generate-modal__top {
    bottom: calc(100% + 0.5rem);
    top: auto;
  }
  &.gpt-generate-modal__bottom {
    top: calc(100% + 0.5rem);
    bottom: auto;
    width: 621px;
  }
`;

export const GptGenerateLoadingStyled = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
  .gpt-loader {
    animation: rotation 2s infinite linear;
    background: url("https://sumerlabs.com/prod/assets/web/loader-ai.webp")
      no-repeat center;
    background-size: contain;
    height: 40px;
    width: 40px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const GptGenerateErrorStyled = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 3rem 2rem 0rem;
  text-align: center;
  .gpt-error-icon {
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
    color: var(--Grey-B);
    display: flex;
    font-size: 2.625rem;
    height: 68px;
    justify-content: center;
    width: 68px;
  }
`;

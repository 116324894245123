import { camelizeKeys } from "humps";
import { ErrorMeli } from "../models/error-meli.model";
import { RecordMeli } from "../models/record-meli.model";
import { RegisterMeli } from "../models/register-meli.model";
import { UserMeli } from "../models/user-meli.model";

const customResponse = async <T>(response: Response, type: T) => {
  if (response.status === 200) {
    return response.json().then((body) => camelizeKeys(body) as typeof type);
  }
  const error = await response.json().then((body: ErrorMeli) => {
    if (body._embedded?.errors) {
      return body._embedded?.errors[0]?.message;
    }
  });
  throw new Error(error);
};

export const fetchGetMeliUrl = async (token: string) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/url-auth`,
    options
  ).then((response) => customResponse(response, {} as { url: string }));
};

export const fetchCheckUserMeli = async (token: string) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/authorization`,
    options
  ).then((response) => customResponse(response, {} as UserMeli));
};

export const fetchGetMeliProductsCount = async (token: string) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/bring-products-count`,
    options
  ).then((response) => customResponse(response, {}));
};

export const fetchGetMeliRecord = async (token: string) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/integration-history`,
    options
  ).then((response) => customResponse(response, [] as RecordMeli[]));
};

export const fetchUnlinkMeliAccount = async (token: string) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/authorization`,
    options
  ).then((response) => response);
};

export const fetchRegisterMeliToken = async (
  token: string,
  meliToken: string
) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/register-user/${meliToken}`,
    options
  ).then(async (response) => customResponse(response, {} as RegisterMeli));
};

export const fetchGetMeliProducts = async (token: string) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue-extension/meli/bring-products`,
    options
  ).then((response) => customResponse(response, {}));
};

import { checkout } from "@dashboard/modules/checkout/locales/es";
import { home } from "@dashboard/modules/home/locales/es";
import products from "@dashboard/modules/products/locales/es";
import onboardingAi from "@dashboard/modules/onboarding-ai/locales/es";
import plans from "@dashboard/modules/plans/locales/es";
import profile from "@dashboard/modules/profile/locales/es";
import orders from "@dashboard/modules/orders/locales/es";
import { addons } from "@dashboard/modules/contact-button/locales/es";
import { voucher } from "@dashboard/modules/voucher/translations/es";
import aiAssistant from "@dashboard/modules/ai-assistant/locales/es";
import { configuration } from "@dashboard/modules/configuration/locales/es";
import { mobile } from "@dashboard/modules/mobile/translations/mobile.es";
import sharedOnboarding from "@dashboard/modules/shared/components/onboarding-shared/locales/es";
import productComponent from "@dashboard/modules/products/components/product-component/locales/es";

export const es = {
  translation: {
    continue: "Continuar",
    cancel: "No,Cancelar",
    next: "Siguiente",
    back: "Atrás",
    skip: "Saltar",
    optional: "Opcional",
    apply: "Aplicar",
    edit: "Editar",
    save: "Guardar",
    regenerateButton: "Regenerar con IA",
    accept: "Aceptar",
    downloadApp: "Descarga nuestra App",
    start: "Comienza tu tienda en linea",
    access: "Obtén acceso completo a todas las herramientas",
    addon: {
      abandoned_cart: "Carrito abandonado",
      floating_button: "Botón de whatsapp",
      voucher_addon: "Comprobante de pago",
      custom_domain: "Dominio",
      ai_product: "IA Sumer",
      gg_shopping: "Google Shopping",
      options: {
        try: "Probar",
      },
    },
    status: {
      loaderText: "¡Validando tu información!",
    },
    addonHook: {
      get: "Obtener",
      getFor: "Obténlo por",
      orChangeTo: "o pásate al plan ",
      description:
        "Disfruta de todas las funcionalidades de Sumer sin límites.",
      getUltraPlan: "Obtener plan Ultra",
    },
    header: {
      explorePlans: "Explorar planes",
      seeMyStore: "Ver mi tienda",
      shareStore: "Compartir tienda",
      popups: {
        sharePopup: {
          title: "Compartir mi tienda",
          description:
            "¡Bienvenido/a a {{storeName}}! Descubre los tesoros que tenemos para ti. 🌟✨",
          linkCopy: "Copiar enlace",
          linkWsp: "Whatsapp",
          linkFacebook: "Facebook",
          linkEmail: "Correo electrónico",
          message:
            "¡Bienvenido/a a {{storeName}}! Descubre los tesoros que tenemos para" +
            " ti✨⭐: Sigue estos sencillos pasos para realizar tu pedido:%0A %0A" +
            "Ingresa a nuestro catálogo en el siguiente enlace: {{storeUrl}}%0A %0A" +
            "Explora y selecciona tus productos favoritos%0A %0A" +
            "¡Listo! Completa tu pedido y deja que la magia comience. ✨🛒 ¡Gracias por elegirnos!",
        },
        actions: {
          successCopy: "Enlace copiado",
        },
      },
    },
    login2: {
      loginWithQr: "Iniciar con código QR",
      signUpNow: "Regístrate en Sumer",
      login_qr: {
        title: "Iniciar sesión en Sumer Web por código QR",
        step_1: "Ingresa a la sección de ver más herramientas.",
        step_2:
          "Dentro de esta lista de opciones ve a la sección de gestión de tu negocio y da clic en Sumer Web.",
        step_3: "Haz clic en el botón Vincular un dispositivo.",
        step_4: "Cuando se active la cámara escanea el código QR.",
        help: "¿Necesitas ayuda?",
      },
    },
    loginQr: {
      title: "Ingresa a Sumer desde tu escritorio",
      step1: "1. Ve a la sección de SumerWeb en tu App.",
      step2:
        "2. Haz clic en el botón <strong>Vincular un dispositivo.</strong>",
      step3: "3. Escanea el código QR.",
    },
    logout: {
      title: "Cerrar sesión",
      message: "¿Estás seguro que deseas cerrar sesión?",
      confirm: "Sí",
      cancel: "No",
    },
    onboarding: {
      login: {
        selectOption: {
          description:
            "Crea tu tienda online con <strong>Inteligencia Artificial</strong> en segundos",
          title: "Inicia sesión o crea tu cuenta",
          withGoogle: "Continuar con Google",
          withFacebook: "Continuar con Facebook",
          withPhone: "Continuar con tu número de teléfono",
          withEmail: "Continuar con correo",
          withQr: "Vincular con mi celular",
          terms: "Al continuar, aceptas los",
          and: "y la",
          termsLink: "Términos y Condiciones",
          data_policy: "Política de Datos",
          otherOptions: "Otras opciones",
          seeOtherOptions: "Mira otras opciones",
          sumer: "de Sumer",
          yupSchema: {
            character_number: "Debe ser mínimo de 6 caracteres.",
            require: "Número celular es requerido.",
            require_prefix: "El prefijo es requerido.",
          },
        },
        sendPhone: {
          title: "Ingresa tu número de teléfono",
          label: "Teléfono",
          disclaimer:
            "Te enviaremos un código para confirmar tu número de teléfono.",
          sms: "Recibir código por SMS",
          wa: "Recibir código por WhatsApp",
        },
        sendEmail: {
          title: "Ingresa tu correo electrónico",
          disclaimer: "Te enviaremos un código para confirmar tu email.",
        },
        verifyCode: {
          title: "Código de verificación",
          phone:
            "Hemos enviado un código de verificación a este número de teléfono:",
          email: "Hemos enviado un código de verificación vía email a:",
          error: "El código no coincide, inténtalo de nuevo.",
          wrongCode: "El código no funciona.",
          wrongCodeAgain: "Solicitar otro código",
          canResend: "Solicita otro código dentro de:",
          minutes: "minutos",
          seconds: "segundos",
          yupSchema: {
            email_validation: "Debe ser un email valido.",
            require: "El email es requerido.",
          },
        },
      },
      info: {
        title: "1. ¡Creemos tu tienda!",
        description:
          "Esta información nos ayuda a brindarte una mejor experiencia, la podrás modificar después.",
        languageLabel: "Idioma del catálogo",
        languageLabelOptional: "Opcional",
        country: "País",
        countryPlaceholder: "Selecciona un país",
        city: "Ciudad",
        cityPlaceholder: "Selecciona el nombre de tu ciudad",
        phonePlaceholder: "Escribe tu número de celular",
        errorPhone: "El número ingresado ya está en uso.",
        yupSchema: {
          min_require: "Debe ser mínimo de 10 caracteres",
          max_require: "Debe ser máximo de 11 caracteres",
          require: "Número celular es requerido.",
          require_country: "El país es requerido.",
          require_prefix: "El prefijo es requerido.",
          require_sector_min: "El texto debe incluir más de dos caracteres.",
          require_sector: "El sector es requerido.",
        },
      },
      sector: {
        title: "2. Información de tu negocio",
        description:
          "Elige el sector económico para tu tienda. Guíate de la categoría a la que pertenecen tus productos. Lo podrás modificar después.",
        type: "¿Qué tipo de tienda deseas tener en linea?",
        ownType: "¿No ves lo que estás buscando?",
        placeholder: "Escribe aquí tu tipo de tienda",
        hint: "Ej: Ropa y accesorios, comida, hogar, ropa para mujer...",
        button: "Siguiente",
      },
      business: {
        title: "3. Información de tu negocio",
        description:
          "Elige el nombre con el que quieres que tus clientes te identifiquen. Lo podrás modificar después.",
        economicLabel: "¿Qué vas a vender en tu tienda?",
        economicPlaceholder: "-Selecciona un sector económico-",
        storeNameLabel: "¿Cuál es el nombre de tu tienda?",
        storeLinkLabel: "Escoge el link de tu tienda online",
        storeTo: "Tienda de {{name}}",
        button: "Generar tu tienda",
      },
      store: {
        title: "4. Diseñemos tu tienda",
        description: "¿Cómo quieres diseñar tu sitio web?",
        generateAi: {
          title: "Generar tienda con IA",
          description:
            "Deja que nuestra inteligencia artificial haga la magia por ti",
          button: "Generar",
        },
        createNew: {
          title: "Crear desde cero",
          description: "Selecciona y personaliza una plantilla para tu tienda",
          button: "Seleccionar",
        },
      },
      templates: {
        navbar: {
          skipButton: "Saltar",
        },
        title: "Selecciona y personaliza tu plantilla con facilidad",
        description:
          "Descubre la plantilla ideal para tu sitio web <strong class='site-name'>{{siteName}}</strong>. Con una amplia variedad de opciones para todos los estilos.",
        message:
          "Explora plantillas ideales para destacar en tu sector económico <strong>{{sector}}</strong>.",
        generateAi: {
          text: {
            title: "Generar tienda con IA",
            description:
              "Deja que nuestra inteligencia artificial haga la magia por ti",
          },
          button: "Generar",
        },
        template: {
          text: "Plantilla",
          buttonControl: "Elegir plantilla",
        },
        banner: {
          title: "Generar tienda con IA",
          description:
            "Usa nuestra personalización para crear tu propia plantilla y darle un estilo único a tu tienda.",
          button: "Continuar",
        },
      },
      coupons: {
        text: "Tengo un código de cupón o referidos",
        couponLabel: "Código de cupón",
        couponPlaceholder: "Ingresa código de cupón",
        referredLabel: "Código de referido",
        referredPlaceholder: "Ingresa código de referidos",
        invalidCode: "Código invalido.",
      },
      personalize: {
        title: "3. Personaliza tu tienda",
        description:
          "Diseña tu tienda online con plantillas profesionales, portada única, los colores de tu marca y el tipo de letra que más te guste.",
        ia: {
          one: "Estamos creando tu tienda IA…",
          two: "Generando un hermoso banner…",
          three: "Eligiendo el mejor diseño para tu negocio…",
          four: "Creando una galería…",
          five: "Creando imágenes…",
        },
      },
      premium: {
        title: "4. Selecciona un plan",
        description:
          "Para guardar tu personalización, selecciona el plan ideal para tu negocio",
        continueFree: "Continuar con mi tienda gratis",
        monthly: "Mensual",
        annual: "Anual",
        acquire: "Adquirir",
        popular: "Más popular",
        month: "Mes",
        period__Mensual: "Mes",
        period__Anual: "Año",
        firstPeriod__Mensual: "días",
        firstPeriod__Anual: "Año",
        loading: "¡Buscando los mejores precios para tí!",
      },
      popups: {
        customizationValue: {
          title: "¡Te quedó genial! No pierdas tu personalización",
          description: "Conserva la personalización por {{value}} por un mes",
          confirmLabel: "Conservar personalización",
          linkLabel: "Continuar",
        },
        customization: {
          title: "¡Te quedó genial! No pierdas tu personalización",
          description:
            "Si continuas con el Plan limitado perderás los cambios que hiciste en tu tienda",
          confirmLabel: "Conservar personalización",
          linkLabel: "Continuar con Plan Limitado",
        },
      },
    },
    personalization: {
      changesPopup: {
        title: "Cambios no guardados",
        description:
          "Realizaste cambios que no has guardado ¿Qué deseas hacer?",
        buttonLabel: "Guardar y salir",
        linkLabel: "Salir sin guardar",
      },
    },
    navbar: {
      home: "Inicio",
      statistics: "Estadísticas",
      meli_integration: "Mercado Libre",
      gg_shopping: "Google Shopping",
      orders: "Pedidos",
      "abandoned-cart": "Carritos abandonados",
      "all-products": "Productos",
      voucher: "Comprobante de pago",
      configuration: "Configuración",
      personalization: "Personalizar tienda",
      contact_button: "Botón de Whatsapp",
      mobile: "Móvil",
      myAccount: "Mi cuenta",
      planTag: "Plan {{plan}}",
      plan_free: "Limitado",
      plan_legacy: "Experto",
      plan_basic: "Basic",
      plan_pro: "Pro",
      plan_ultra: "Ultra",
      sections: {
        management: "Administrar",
        custom: "Configura tu tienda",
        integrations: "Integraciones",
      },
    },
    stats: {
      title: "Mis estadísticas",
      visits: "Visitas a tu catálogo",
      sells: "Ventas totales",
      orders: "Órdenes recibidas",
      conversion: "Tasa de conversión",
      conversionTooltip:
        "Porcentaje de visitantes a tu tienda que se convierten en clientes.",
      seeGraph: "Ver gráfica de:",
      selectRange: "Seleccionar rango:",
      thisMonth: "Este mes:",
      today: "Hoy:",
      yesterday: "Ayer:",
      thisWeek: "Esta semana:",
      lastMonth: "Mes anterior:",
      lastWeek: "Semana pasada:",
    },
    fileSelector: {
      title: "Fotos de los productos",
      description:
        "Selecciona la carpeta de fotos de tus productos. Ten en cuenta que el nombre de la foto debe coincidir con el nombre en la plantilla de Excel.",
      folder: "Cargar carpeta de fotos",
      eachPhoto:
        "Sube las fotos de cada uno uno de tus productos de forma manual.",
      normal: "Cargar fotos normal",
    },
    uploader: {
      title: "Carga exitosa de productos",
      success: "Productos subidos",
      photos: "Fotos de productos subidos",
      disclaimer:
        "Podrás ver o editar tus productos directamente desde la app o desde la web",
      my: "Mis productos",
    },
    admin: {
      breadcrumb: {
        products: "Productos",
        admin: "Administrar mis productos",
      },
      columns: {
        photos: "Fotos",
        productName: "Nombre del producto",
        unitPrice: "Precio por unidad",
        category: "Categoría",
        inventory: "Inventario",
        quantity: "Unidades",
        available: "Disponible",
        description: "Descripción",
      },
      inventory: {
        ALWAYS: "Siempre disponible",
        LIMITED: "Unidad limitada",
        BUILD_TO_ORDER: "Venta por catálogo",
        NOT_AVAILABLE: "Agotado",
      },
      images: {
        delete: "Eliminar imagen",
        delete_message: "¿Estás seguro que deseas eliminar esta imagen?",
      },
      products: {
        emptyPopup: {
          title: "Mejora tu plan",
          description:
            "Crea promociones para productos específicos, categorías o sub categorías. Herramienta disponible en Plan Pro y Plan Ultra. Haz upgrade para tener funciones ilimitadas.",
          buttonLeft: "Quizá más tarde",
          buttonRight: "Conoce los planes",
        },
        side: {
          title: "Editar producto",
          photos: {
            title: "Fotos",
            edit: "Editar fotos",
            itemImage: "Agregar una imagen",
            itemImageText1: "La primera foto será la principal.",
            itemImageText2: "¿Cómo tomar una buena foto para mi producto?",
            textInEditImage:
              "Arrastra y suelta las fotos para cambiar el orden",
            maxPhotos:
              "Lo sentimos, has llegado al límite de fotos permitidas.",
            maxPhotosHookTitle: "Destaca con fotos ilimitadas",
            maxPhotosHookDescription: 'Capta la atención de tus clientes mostrando cada detalle de tus productos.',
            validateErrorSize: 'Peso máximo admitido: {{size}} MB',
            validateErrorType: 'Formatos permitidos: png, jpg, jpeg, web.',
            emptyStateImage: "Agregar foto"
          },
          form: {
            productNameLabel: "Nombre del producto",
            productNamePlaceholder: 'Escribe un nombre...',
            productNameErrorMsg: 'El nombre está vacío. Ingresa el nombre del producto.',
            productValueLabel: "Precio",
            productValuePlaceholder: 'Escribe un precio...',
            productPriceErrorMsg: 'El precio está vacío o es cero. Añade un valor válido.',
            variationsLabel: {
              label: "Configura el precio y stock en tus variaciones",
              link: "Configurar precio",
            },
            infoTitle: "Venta al por mayor",
            infoSubTitle: "Configura el precio al por mayor",
            infoButton: "Encuentralo dentro de la app",
          },
          productInfo: {
            title: "Información del producto",
            labelCategory: "Categoría",
            labelSubCategory: "Subcategoría",
            selectPlaceholderCategory: "Selecciona una categoría",
            selectPlaceholderSubCategory: "Escribe/selecciona una subcategoría",
            inputDescriptionTitle: "Descripción",
            inputDescription:
              "Escribe una descripción, teniendo en cuenta los materiales de tu producto, indicaciones de cuidado",
            inputDescriptionLimit: "caracteres",
            skusHookTitle: "Mantén todo en orden",
            skusHookDescription:
              "Registra y organiza fácilmente los códigos SKU de tus productos para una gestión eficiente.",
          },
          wholeSale: {
            title: "Precio al por mayor",
            subTitle: "Configura el precio al por mayor",
            counterText: "Cantidad minima",
            description: "Definir descuento de mayorista",
            errorInput: "El valor es superior al unitario",
            finalPrice: "Precio final",
            alertMsg: "El descuento se aplicará al precio de tus variaciones.",
            alertMsgWholesale: "El descuento se aplicará al precio de tus características.",
            wholesaleHookTitle: 'Amplía tus oportunidades de venta',
            wholesaleHookDescription: 'Fija precios exclusivos para ventas al por mayor y atrae a clientes comerciales con ofertas.',
            priceDetail: "Precio del producto",
            discountDetail: "Descuento"
          },
          inventory: {
            title: "Inventario",
            info: {
              title: "Venta por encargo",
              subTitle: "Tu producto se vende por encargo",
              days: "Días promedio para realizar la entrega",
            },
            stock: {
              title: "Stock",
              subTitle: "Que cantidad tienes disponible",
              subTitleWithVariations: "Configura el stock en tus características",
              availableUnits: "Unidades disponibles",
              alert:
                "Si no activas el stock, el producto siempre estará disponible",
            },
            variations: {
              title: "Características",
              editTitle: "Editar Variaciones",
              subTitle: "Configura variaciones del producto",
              createdItems: "items creados",
              addFeatures: "Añadir características",
              addVariationsDescriptionNoEmpty: "Configura las características de tu producto",
              addVariationsDescription:
                "Agrega Características como colores, tallas, dimensiones...",
              addVariationsButton: "Agregar variaciones",
              addSide: {
                description:
                  "Configura variaciones de tu producto como colores o tallas, recuerda que todas serán obligatorias de seleccionar para habilitar la compra.",
                newVariationTitle: "Nombre de la característica",
                alert:
                  "Asegúrate de que la información de la variación sea la correcta no podrás editarla o modificarla desde la web.",
                variationName: "Nombre de la característica",
                variants: "Variantes",
                addButton: "Agregar",
                addAction: "Agregar variación",
                applyButton: "Guardar",
                suggestion: {
                  color: "Color",
                  size: "Talla",
                  taste: "Sabor",
                  size2: "Tamaño",
                  weight: "Peso",
                  dimension: "Dimensión",
                },
              },
              edit: {
                title: "Variaciones",
                button: "Editar",
              },
              filter: {
                showItems: "Mostrando {{num}} items",
                modal: {
                  title: "Filtrar",
                  buttonClean: "Limpiar filtros",
                  buttonApply: "Aplicar filtros",
                },
                dropdown: {
                  unlimited: "Sin limite",
                  limited: "Limitado",
                  spend: "Agotado",
                },
              },
              hooks: {
                upgrade: {
                  title: "¡Obtén ésta funcionalidad!",
                  description:
                    "Mejora tu plan ahora para tener ésta y más funcionalidades.",
                  confirmLabel: "Obtener ahora",
                  cancelLabel: "Quizá más tarde",
                },
                upgradeWithLimits: {
                  title: "Crece sin límites",
                  description:
                    "Alcanzaste el límite de {{items}} en tu plan actual. Obtén funciones ilimitadas mejorando tu plan.",
                  confirmLabel: "Obtener ahora",
                  cancelLabel: "Quizá más tarde",
                },
                variations: {
                  title: "Crea productos a medida",
                  description:
                    "Especifica los atributos de cada uno tus productos y dale a tus clientes la posibilidad de elegir.",
                  confirmLabel: "Obtener ahora",
                },
              },
              alertPopup: {
                title: "Límite de variaciones",
                titleVariant: "Límite de variantes",
                description:
                  "Lo sentimos, has llegado al límite de variaciones permitidas.",
                descriptionVariant:
                  "Lo sentimos, has llegado al límite de variantes permitidas.",
                confirmLabel: "Continuar",
              },
              saveChangesPopup: {
                title: "Cambios sin aplicar",
                description:
                  "Hiciste cambios que aún no has guardado. Aplica los cambios o vuelve más tarde para realizarlos.",
                alternativeLabel: "Salir sin aplicar",
                confirmLabel: "Aplicar cambios",
              },
              alertPopupCombinations: {
                title: "Limite alcanzado",
                description:
                  "Lo sentimos si creas esta variante sobrepasas el limite de combinaciones permitidas.",
                confirmLabel: "Aceptar",
              },
            },
          },
          supplementaryContent: {
            section: {
              title: "Contenido complementario",
              description:
                "Configura contenido complementario para tu producto",
            },
          },
          addButtonText: "Crear producto",
          updateButtonText: "Guardar",
          removeButtonText: "Eliminar",
          duplicateButtonText: "Duplicar",
          formAlert: {
            success: "Producto ingresado con éxito",
            error: "Ocurrió un error agregando el producto",
          },
          loadingTextDuplicateProduct: "Duplicando producto...",
        },
        createTitle: "Editar nuevo producto",
        createDescription: "Descripción de prueba",
        delete: "Eliminar producto",
        deleteConfirm: "¿Estás seguro que quieres eliminar el producto?",
        yes: "Sí, eliminar",
        no: "Cancelar",
        deleteMessage: "Eliminado correctamente.",
        duplicate: "Duplicar producto",
        add: "Agregar un producto",
        massiveCharge: "Carga masiva",
        search: "Buscar",
      },
    },
    abandonedCarts: {
      title: "Carrito Abandonado",
      description:
        "Recupera ventas perdidas a través de WhatsApp y correo electrónico. ¡De indecisos a clientes fieles!",
      acquire: "Adquirir",
      columns: {
        date: "Fecha",
        client: "Cliente",
        email: "Email",
        products: "Productos",
        total: "Total",
        state: "Estado",
        contact: "Contactar",
      },
      empty: {
        title: "Carrito abandonado vacío",
        description: "No hay productos en el carrito abandonado",
      },
      sendEmailConfirm: {
        title: "Enviaremos un correo a tu comprador",
        description:
          "Tu comprador recibirá un e-mail de parte de tienda para que finalice su orden.",
        send: "Enviar correo",
      },
      recover: "Recuperado",
      notRecover: "No recuperado",
      confirm: "Confirmar compra",
      total: "Total",
      contactMessage:
        "¿Algo te detuvo? Nuestros productos de alta calidad en tu carrito están a un clic de cambiar tu día. ¡Finaliza la compra ahora!",
    },
    orderDetail: {
      breadcrumbs: {
        orders: "Pedidos",
        detail: "Detalle del pedido",
      },
      states: {
        payed: "Pagado",
        packaged: "Empacado",
        sended: "Enviado",
        delivered: "Entregado",
        cancelled: "Cancelado",
        created: "Creado",
        paymentError: "Error en el pago",
        unknown: "Desconocido",
      },
      sendTo: "Enviar a",
      orderState: "Estado del pedido",
      products: "Productos",
      payment: {
        label: "Detalle del cobro",
        total: "Total",
        cancel: "Cancelar pedido",
      },
    },
    downloadModal: {
      title: "Abre la aplicación",
      subTitle: "Abre la aplicación Sumer en tu celular.",
      description: "Escanea el QR para descargarla si aún no la tienes.",
    },
    support: {
      help: "Ayuda y soporte",
      message: "¡Hola! Sumer hoy quisiera preguntarles acerca de:",
    },
    productsLoading: {
      products: "Productos",
      loading: "Cargando productos",
      success: "Plantilla cargada exitosamente",
    },
    ImagesUploader: {
      select: "Selecciona o arrastra las fotos (.png, .jpg)",
      success: "Fotos cargadas exitosamente",
      maxFilesError: "Solo se pueden subir 10 fotos por producto",
    },
    handleFileChange: {
      title: "Sube la carpeta con las fotos de tus productos",
      sub_title:
        "Selecciona la carpeta de fotos de tus productos. Ten en cuenta que el nombre de la foto debe coincidir con el nombre en la plantilla de Excel.",
      type_error: "El archivo seleccionado no es un archivo .png o .jpg",
    },
    integrations: {
      message: "Hola Sumer, quiero que me brinden ayuda con:",
      store_connected:
        "Tu tienda de Sumer está conectada con Facebook Shopping",
      store_not_connected:
        "Tu tienda de Sumer aún no está conectada con Facebook Shopping",
      store_connected_instagram:
        "Tu tienda de Sumer está conectada con Instagram Shopping",
      store_not_connected_instagram:
        "Tu tienda de Sumer aún no está conectada con Instagram Shopping",
      how_link: "¿Cómo vincular tu catálogo con Facebook?",
      how_link_instagram: "¿Cómo vincular tu catálogo con Instagram?",
      copy_catalogue: "Copia la URL de tu catálogo, accede a Facebook ",
      copy_catalogue_instagram:
        "Copia la URL de tu catálogo, accede a Instagram ",
      business: "Business ingrésalo en el campo solicitado.",
      step: "Ir al paso a paso",
      sumer: "Sumer",
      facebook: "Facebook",
      instagram: "Instagram",
      options:
        " ¡Aprovecha las opciones que Facebook te da para que tu negocio se potencie y siga creciendo!",
      options_instagram:
        "Vincula tu catálogo a Instagram, gana seguidores e impulsar tus ventas.",
      publish: "Hacer publica tu URL",
      copy_url: "Copia la URL de tu catálogo",
      help: "¿Necesitas ayuda?",
      view_facebook: "Ver en Facebook",
      connect_facebook: "Conectar con Facebook",
      copied: "URL copiado en el portapapeles",
      connect_instagram: "Conectar con Instagram",
      verification_code: "Código de verificación",
      add_code_copy:
        "Agrega y pega el código de Facebook Business para vincular tu catálogo a Instagram.",
      add_code_button: "Agregar",
      edit_code_button: "Editar",
      addMetadata: {
        copy_1: "1. Copia la URL de tu tienda",
        copy_2:
          "en la configuración de tu negocio de Facebook, haz clic en Agregar y pega la <strong>URL.</strong>",
        copy_3:
          "3. Elige la opción <strong>“Agregar una meta etiqueta a tu código fuente HTML”</strong> copia el <strong>Meta-tag.</strong> Cuando lo tengas regresa acá y pega el código.",
      },
      meli: {
        banner: {
          title: "Sincroniza Sumer con Mercado Libre",
          errorSyncTitle: "Algo salió mal en la sincronización",
          synchronizedTitle: "Cuenta sincronizada con Mercado Libre",
          description:
            "Importa tus productos de Mercado Libre a Sumer. Publica los productos de tu tienda en Mercado Libre.",
          synchronizedDescription:
            "Cada vez que hagas una sincronización se importará el 100% de tus productos de Mercado Libre, con sus respectivos precios e inventario.",
          lastSync: "Última sincronización",
          syncButton: "Iniciar sincronización",
          errorSyncButton: "Reintentar sincronización",
          synchronizedButton: "Sincronizar nuevos productos",
          unlinkButton: "Desvincular",
          syncModal: {
            title: "¿Quieres desvincular Sumer de Mercado Libre?",
            description:
              "Tu catálogo en Sumer conservará los productos actuales. Para volver a sincronizar tendrás que hacer la conexión nuevamente.",
            declineButton: "No, mantener vinculación",
            acceptButton: "Sí, desvincular",
          },
        },
        cards: {
          title: "Recomendaciones para una sincronización exitosa",
          card1: {
            title: "Nombra igual los productos",
            description:
              "Los productos de Mercado Libre se enlazarán a los productos de Sumer de acuerdo al nombre.",
          },
          card2: {
            title: "Actualiza stock y precios",
            description:
              "Actualiza el precio y el stock de los productos en Mercado Libre antes de la sincronización.",
          },
          card3: {
            title: "Actualiza las variantes",
            description:
              "Tus productos mantendrán las variaciones que hayas configurado en Mercado Libre.",
          },
          card4: {
            title: "Elimina los productos duplicados",
            description:
              "Si tienes productos en Sumer que también tienes en Mercado Libre, elimina los de Sumer.",
          },
        },
        productsCounter: {
          title:
            "Hemos importado exitosamente {{counter}} productos desde Mercado Libre.",
          description:
            "Asegúrate de que no existan productos duplicados y verifica que tanto el inventario como el precio sean correctos.",
          showButton: "Ver productos importados",
        },
        popups: {
          plan: {
            title: "Simplifica la gestión de tu negocio",
            description:
              "Ahorra tiempo y maximiza la eficiencia. Con esta herramienta, la sincronización es única y sin complicaciones.",
            buttonAccept: "Obtener ahora",
            buttonLink: "Quizá más tarde",
          },
        },
        loader: {
          copy1: "Importando productos de Mercadolibre...",
          copy2: "Creando productos en Sumer...",
          copy3: "Creando variantes...",
          copy4: "Sincronizando...",
        },
      },
      google_shopping: {
        banner: {
          title: "Vende más con Google Shopping",
          subtitle:
            "Un mercado gratuito para anunciar, promocionar y vender los productos de tu tienda Sumer directamente en Google.",
          text_button: "Conectar Google Shopping",
        },
        how_works: {
          title: "Cómo funciona Google Shopping",
          step1: {
            title: "Información al consumidor",
            description: "Tus compradores encontrarán más fácil tus productos, basado en la relevancia de búsqueda y actividad en Google. Las ofertas patrocinadas, pueden influir.",
          },
          step2: {
            title: "Información del producto",
            description: "Los vendedores y anunciantes que muestran sus productos en Google Shopping participan en más actividades comerciales.",
          },
          step3: {
            title: "Opiniones y calificaciones",
            description: "Para ayudar a que más personas encuentren tu producto, recopilamos opiniones del programa Reseñas de Google.",
          },
          step4: {
            title: "Cómo realizar una venta",
            description: "Cuando un comprador encuentra tu producto, verá su precio y podrá comprarlo haciendo clic en él.",
          },
        },
        alert:{
          title: "Hemos conectado exitosamente tu Google Shopping.",
          description: "Si no cumplen con los requisitos de calidad, es posible que Google rechace tus productos, corrige sus datos y vuelve a subirlos.",
          button: "Ver productos importados",
        },
        configuration:{
          title: "Conecta Sumer con Google Shopping"
        },
        cards_configuration: {
          card1: {
            title: "1. Crea tu cuenta en Google",
            description:
              "Proporciona información básica sobre tu tienda para registrarte.",
            button: "Crear cuenta",  
          },
          card2: {
            title: "2. Verifica tu tienda en Google",
            description:
              "Copia este enlace y pégalo en la opción Confirma la tienda online de tu negocio de Google Merchant.",
            button: "tutienda.com", 
          },
          card3: {
            title: "3. Agrega el HTML tag",
            description:
              "Copia el HTML tag proporcionado por google y pégalo en la siguiente casilla.",
            button: "Enviar",
            placeholder: "Pega el HTML tag",  
          },
          card4: {
            title: "4. Agrega tus productos",
            description:
              "Copia y pega este enlace XML en Google Merchant Center (recuerda que debes haber configurar los detalles de envío y del producto en Sumer previamente).",
            button: "Copiar",
          },
        },
      },
    },

    login: {
      account: "Inicia sesión o crea tu cuenta en Sumer",
      phone: "Celular de tu negocio",
      agree: "Acepto los",
      terms: "términos y condiciones",
      sms: "Recibir código por SMS",
      whatsapp: "Recibir código por WhatsApp",
      name: "Nombre",
      "last-name": "Apellido",
      ready: "Listo",
      code: "Ingresa el código que te enviamos",
      send: "El código fue enviado al",
      "send-again": "Volver a enviar",
      next: "Siguiente",
      incorrect: "El código no coincide, inténtalo de nuevo.",
      resend: "Código enviado correctamente",
      logout: "Cerrar sesión",
      helpMessage: "¡Hola! Sumer hoy quisiera preguntarles acerca de:",
      support: "Vengo de ver la pagina web de SUMER y tengo dudas",
      codeQuestion: "¿No recibiste tú código?",
      loginQuestion: "¿Ya tienes una cuenta?",
      loginAction: "Iniciar sesión",
      invalidPhoneNumber: "El número de teléfono no es válido",
      invalidEmail: "El Email no es válido",
      logoutTitle: "Cerrar sesión",
      logoutMessage: "¿Estás seguro que deseas cerrar sesión?",
      logoutConfirm: "Sí",
      logoutCancel: "No",
      error: "Ocurrió un error, intente nuevamente",
      facebook_login: "Continuar con Facebook",
      google_login: "Continuar con Google",
      apple_login: "Continuar con Apple",
      phone_login: "Continuar con teléfono",
      email: "Continuar con correo",
      title: "Iniciar sesión",
      number_phone:
        "Escribe tu número de celular con el que creaste tu cuenta en Sumer",
      continue: "Continuar",
      do_not_have_account: "¿No tienes una cuenta?",
      register_now: "Regístrate ahora",
      register: "Regístrate en Sumer",
      code_verification: "Código de verificación",
      code_verification_send:
        "Hemos enviado un código de verificación via SMS a:",
      change_phone: "Cambiar",
      login_sumer: "Iniciar sesión en Sumer",
      welcome: "Iniciar sesión en Sumer",
    },
    register: {
      not_account: "Aún no tienes una cuenta en Sumer.",
      download_app: "Descarga la app y crea tu cuenta",
    },
    download_app: "Descargar la app",
    formCheckout: {
      phone_placeholder: "Número de celular",
      email_placeholder: "Correo electrónico",
    },

    upgrade: {
      title: "Mejora tu plan",
      copy: "Desbloquea el 100% de nuestra experiencia, inventario, variantes, productos, fotos, complementos y mas.",
      link: "Adquirir",
    },
    featureSoon: {
      title: "¡Herramienta en desarrollo!",
      copy: "Próximamente podrás tener esta herramienta, disponible por ahora en la app.",
      accept: "Aceptar",
    },
    maxImagesModalAlert: {
      copy: "No puedes subir más fotos a tu producto debido a la capacidad de tu plan, revisa la cantidad de fotos permitidas en tu plan actual.",
    },
    confirm: {
      title: "Confirmar",
      copy: "¿Está seguro de continuar?",
    },
    toggle: {
      yearly: "Anual",
      monthly: "Mensual",
    },
    hookAgileConversion: {
      title: "¡Obtén ésta funcionalidad!",
      copy: "Mejora tu plan ahora para tener ésta y más funcionalidades.",
      confirm: "Suscribirme al plan",
      plans: "Ver detalles de los planes",
      plan: "Plan {{plan}}",
      month: "mes",
      year: "año",
    },

    products,
    onboardingAi,
    home,
    checkout,
    plans,
    addons,
    profile,
    orders,
    voucher,
    aiAssistant,
    configuration,
    sharedOnboarding,
    mobile,
    productComponent
  },
};

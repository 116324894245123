import styled from "styled-components";

export const FakeDoorsWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  .item {
    height: 186px;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    width: calc(50% - 10px);
    background: var(--grey-8, #FCFCFC);
    transition: box-shadow 0.2s ease-in-out;
    border: 1px solid var(--grey-border, #E9E9ED);
    &:hover {
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18), 2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    }
    .img {
      margin: 8px;
      height: 108px;
      object-fit: cover;
      border-radius: 8px;
      width: calc(100% - 16px);
    }
    .name {
      margin: 0 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: var(--grey-3, #3E434E);
    }
    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin: 0 16px 8px 16px;
      color: var(--grey-b, #24272E);
    }
  }
`;

import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { StepDemoWrapper } from "./step-demo.styled";
import ArrowUp from "@sumerkit/icons/arrow-up";
import Button from "@sumerkit/ui/button";
import { useNavigate } from "react-router-dom";
import BannerIa from "./components/banner-ia";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  OptionOnboarding,
  MethodOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { Template } from "@dashboard/modules/shared/components/onboarding-shared/constants/template.constants";
import { useStepDemo } from "../../hooks/step-demo.hook";
import { UiButton } from "@sumerlabs/component-library";

export type StepDemoTypes = {
  ctaNextStep?: string;
};

const StepDemo: FC<StepDemoTypes> = ({ ctaNextStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    goToPersonalization,
    getEnonomicSectorText,
    sortTemplates,
    selectTemplate,
    hoverAction,
    logEvent,
  } = useStepDemo(ctaNextStep || "generating");

  return (
    <StepDemoWrapper>
      <div className="navbar">
        <ArrowUp
          className="back-arrow"
          width={32}
          height={32}
          onClick={() => navigate(-1)}
        />
        <Button
          appearance="tertiary"
          onClick={() => {
            logEvent(EventsOnboarding.SELECT_BUTTON, {
              event_subtype: SubtypeOnboarding.SKIP_ONBOARDING,
              option: OptionOnboarding.PERSONALIZATION_TEMPLATES,
            });
            goToPersonalization();
          }}
        >
          {t("onboarding.templates.navbar.skipButton")}
        </Button>
      </div>
      <div className="body">
        <div className="header-content">
          <div className="header">
            <div className="title">{t("onboarding.templates.title")}</div>
          </div>
          <div className="message">
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: t("onboarding.templates.message", {
                  sector: getEnonomicSectorText,
                }),
              }}
            ></div>
          </div>
        </div>
        <div className="container">
          {sortTemplates &&
            sortTemplates.map(
              (templ, i) =>
                templ.id !== Template.STANDARD && (
                  <Fragment key={templ.id}>
                    {i === 0 && (
                      <div className="generate-ai fadeInUp">
                        <img
                          src="https://sumerlabs.com/prod/sumerweb/step-store/generate-ia.png"
                          alt="Generate store with ai"
                        />
                        <div className="text">
                          <div className="title">
                            {t("onboarding.templates.generateAi.text.title")}
                          </div>
                          <div className="description">
                            {t(
                              "onboarding.templates.generateAi.text.description"
                            )}
                          </div>
                        </div>
                        <UiButton
                          className="button"
                          appereance="outline"
                          onClick={() => {
                            logEvent(EventsOnboarding.SELECT_BUTTON, {
                              event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
                              option: OptionOnboarding.PERSONALIZATION,
                              method: MethodOnboarding.TEMPLATES,
                              other_attributes: "GENERATE_AI",
                            });
                            goToPersonalization();
                          }}
                        >
                          {t("onboarding.templates.generateAi.button")}
                        </UiButton>
                      </div>
                    )}
                    <div
                      className="template fadeInUp"
                      onClick={() => selectTemplate(templ.id)}
                    >
                      <img
                        src={templ.onboardingImage}
                        alt={templ.id}
                        onMouseEnter={() => hoverAction(i, true)}
                        onMouseLeave={() => hoverAction(i, false)}
                      />
                      {templ.showControls && (
                        <div
                          className="controls fadeIn"
                          onMouseEnter={() => hoverAction(i, true)}
                          onMouseLeave={() => hoverAction(i, false)}
                        >
                          <Button
                            className="custom-button"
                            appearance="tertiary"
                            size="small"
                            fullWidth
                          >
                            {t("onboarding.templates.template.buttonControl")}
                          </Button>
                        </div>
                      )}
                      <div className="description">
                        <div className="text-template">
                          {t("onboarding.templates.template.text")} /
                        </div>
                        <div className="text-name">
                          {templ.title.toUpperCase()}
                        </div>
                      </div>
                    </div>
                    {i === 7 && (
                      <div className="banner fadeInUp">
                        <BannerIa
                          onContinue={() => {
                            logEvent(EventsOnboarding.SELECT_BUTTON, {
                              event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
                              option: OptionOnboarding.PERSONALIZATION,
                              method: MethodOnboarding.TEMPLATES,
                              other_attributes: "GENERATE_AI",
                            });
                            goToPersonalization();
                          }}
                        />
                      </div>
                    )}
                  </Fragment>
                )
            )}
        </div>
      </div>
    </StepDemoWrapper>
  );
};

export default StepDemo;

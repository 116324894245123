import styled from "styled-components";

export const ProductCategoryDescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  > div {
    width: 100%;
  }
`;

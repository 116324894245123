import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useFormikContext } from "formik";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { useSelector } from "react-redux";
import { planSelector } from "@dashboard/config/redux";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import { ProductVariationsWrapper } from "./product-features.styled";
import Plus from "@sumerkit/icons/plus";
import SquaresFour from "@sumerkit/icons/squares-four";
import FeaturesOptions from "./components/features-options/features-options";
import { useNavigate } from "react-router-dom";

type ProductFeaturesTypes = {
  setSectionsValid?: (values: { [key: string]: boolean }) => void;
};

const ProductFeatures: FC<ProductFeaturesTypes> = ({ setSectionsValid }) => {
  const { t } = useTranslation();
  const { values }: { values: FormProps } = useFormikContext();
  const { logEvent } = useAnalytics();
  const plan = useSelector(planSelector);
  const navigate = useNavigate();

  const showVariations = () => {
    logEvent(PRODUCTS_EVENTS.VIEW_SCREEN, {
      event_subtype: "variations",
      source: `${values.id ? "edit" : "create"}`,
      membership_type: plan?.benefit_plan_name ?? "",
    });
    navigate("features");
  };

  return (
    <ProductVariationsWrapper>
      <CollapseContainerWrapper border="none">
        <div className="info">
          <figure className="icons">
            <SquaresFour width={27} height={27} />
          </figure>
          <div className="description">
            <strong className="title">
              {t("admin.products.side.inventory.variations.title")}
            </strong>
            <small className="subtitle">
              {t(
                `admin.products.side.inventory.variations.addVariationsDescription${
                  values.productToppingVariations ? "NoEmpty" : ""
                }`
              )}
            </small>
          </div>
        </div>
      </CollapseContainerWrapper>
      <div className="features-content">
        <div className="add-features-link" onClick={showVariations}>
          <Plus width={20} height={20} />
          <div className="text">
            {t("admin.products.side.inventory.variations.addFeatures")}
          </div>
        </div>
        <FeaturesOptions />
      </div>
    </ProductVariationsWrapper>
  );
};

export default ProductFeatures;

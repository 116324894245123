import { Modal, UiButton } from "@sumerlabs/component-library";
import { FC, useState } from "react";
import { ModalWrapper } from "./change-popup.styled";
import { useTranslation } from "react-i18next";

export type ChangePopupType = {
  onAction: () => void;
  onSkip: () => void;
  handleClose: () => void;
};

const ChangePopup: FC<ChangePopupType> = ({ onAction, onSkip, handleClose }) => {
  const { t } = useTranslation();
  const handleCloseModal = () => {
    handleClose();
    setShow(false);
  };
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      showHeader={false}
      content={{
        styles: {
          width: "520px",
        },
      }}
      body={{
        styles: {
          padding: "0px",
        },
      }}
      onClose={handleCloseModal}
    >
      <ModalWrapper>
        <figure className="close icon-close" onClick={handleCloseModal}></figure>
        <div className="icon">
          <figure className="icon-circle-exclamation"></figure>
        </div>
        <div className="title">{t("personalization.changesPopup.title")}</div>
        <div className="description">
          {t("personalization.changesPopup.description")}
        </div>
        <UiButton className="confirmButton" onClick={onAction}>
          {t("personalization.changesPopup.buttonLabel")}
        </UiButton>
        <div className="link">
          <span onClick={onSkip}>{t("personalization.changesPopup.linkLabel")}</span>
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export default ChangePopup;

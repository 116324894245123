const locale = {
  name: "Pedidos",
  empty: {
    title: "Tu lista de pedidos está vacía",
    description:
      "¡Comparte tu tienda! darte a conocer para recibir tu primer pedido",
  },
  filters: {
    title: "Filtrar por",
    options: {
      clear: "Limpiar filtros",
      apply: "Aplicar filtros",
    },
    groups: {
      all: "Todos los pedidos",
      new: "Nuevos",
      payedWithoutLogistic: "Pagados sin enviar",
      delivered: "Enviados",
      deliveryStore: "Para recoger en tienda",
      finished: "Finalizados",
      cancelled: "Cancelados",
    },
    paymentState: {
      title: "Estado de pago",
      payed: "Pagado",
      notPayed: "Sin pagar",
    },
    deliveryTypes: {
      title: "Tipo de entrega",
      deliveryHome: "Envío",
      pickup: "Recogida en tienda",
    },
    shippingState: {
      title: "Estado del envío",
      packed: "Empacado",
      notPacked: "Sin empacar",
      send: "Enviado",
      withoutSend: "Sin enviar",
    },
    deliveryState: {
      title: "Estado de la entrega",
      pickedUp: "Entregado",
      withoutPickup: "Sin entregar",
    },
    orderState: {
      title: "Estado de pedido",
      cancelled: "Cancelado",
      finished: "Calificado",
      pendingReview: "Sin calificar",
    },
    orderDates: {
      label: "Filtrar fecha",
      values: "Del {{from}} al {{to}}",
      title: "Seleccionar fecha",
    },
  },
  detail: {
    title: "Detalle del pedido",
    score: {
      title: "¡Bien hecho!<br>Entregaste el pedido",
      comments: "Ver comentario",
      pendingReview: "Pedir calificación al comprador",
      delivered: "Recibido el {{date}}",
      orderNumber: "Pedido # {{orderId}}",
      requestReview: `¡Hola, {{name}}! %0a %0a
Se te ha enviado un correo en donde podrás calificar la experiencia de tu compra, para nosotros es importante tu opinión`,
    },
    info: {
      deliveryTo: `Enviar a <span>{{address}}, {{city}}</span>`,
      pickUp: `Entregar en la tienda <span>{{address}} {{city}}</span>`,
      copyConfirm: "Se ha copiado el mensaje exitosamente",
    },
    states: {
      title: "Estado del pedido",
      notPayed: "Sin pagar",
      payed: "Pagado",
      packaged: "Empacado",
      send: "Enviado",
      delivered: "Entregado",
      cancelled: "Cancelado",
      labels: {
        notPayed: "Pedido sin pagar",
        payed: "Pedido pagado",
        packaged: "Pedido empacado",
        send: "Pedido enviado",
        delivered: "Pedido entregado",
        cancelled: "Pedido cancelado",
      },
      notifyPackaged: `Notificaremos a tu cliente cuando marques el pedido como <strong>empacado y enviado</strong>`,
    },
    products: {
      title: "Productos",
      noVariants: "Sin variantes",
    },
    summary: {
      title: "Detalle del cobro",
      total: "Total",
      subtotal: "Subtotal",
      delivery: "Envío",
      deliveryFree: "Envío gratis",
      discount: "Descuento",
    },
    payment: {
      copy: "Pago recibido en {{provider}}",
      payer: "Enviado por {{customerName}}",
      help: `¿Tienes algún problema? <a href="{{url}}" target="_blanck">
        Ir a {{provider}}
      </a>`,
    },
  },
  order: {
    id: "Pedido # {{orderId}}",
    states: {
      new: "!Nuevo pedido¡",
      cancelled: "Cancelado",
      payed: "Pagado",
      notPayed: "Sin pagar",
      pickupPacked: "Recogido",
      pickupNotPacked: "Sin recoger",
      send: "Enviado",
      withoutSend: "Sin enviar",
      pendingReview: "Finalizado sin calificar",
    },
    options: {
      cancel: "Cancelar pedido",
    },
    cancelConfirm: {
      title: "¿Cancelar pedido?",
      copy: "Notificaremos a tu comprador que su pedido ha sido cancelado.",
      continue: "Cancelar pedido",
      cancel: "No, volver",
    },
  },
  improvePlan: {
    confirmUnlock: "Desbloquear ahora",
  },
};

export default locale;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PlanHookWrapper } from "./plan-hook.styled";
import UiModal from "@dashboard/components/ui-modal";
import { useHome } from "../../hooks/home.hooks";
import planHookImg from "@dashboard/assets/img/plan-hook.webp";

interface PlanHookProps {
  
}
 
const PlanHook: FC<PlanHookProps> = () => {

  const { t } = useTranslation();
  const { showUltraHook, handleToggleShowUltraHook, handleGoToPlansPage } = useHome();
  
  return (
    <PlanHookWrapper>
      <UiModal show={showUltraHook} onClose={handleToggleShowUltraHook} className="modal">
        <img src={planHookImg} alt="En construcción" className="img" />
        <div className="title">{t('home.planHook.title')}</div>
        <div className="description">{t('home.planHook.description')}</div>
        <div className="button" onClick={() => {
          handleGoToPlansPage();
          handleToggleShowUltraHook();
        }}>
          {t('home.planHook.button')}
        </div>
        <div className="later" onClick={handleToggleShowUltraHook}>{t('home.planHook.later')}</div>
      </UiModal>
    </PlanHookWrapper>
  );
}
 
export default PlanHook;
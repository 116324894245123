import styled from "styled-components";

export const AlertPopupWrapper = styled.div`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #24272e;
    :hover {
      cursor: pointer;
    }
  }
  .icon {
    margin-bottom: 16px;
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 200px;
    background: #eaf0fe;
    > figure {
      font-size: 40px;
      color: #1458f5;
    }
  }
  .title {
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .description {
    margin-top: 8px;
    margin-bottom: 16px;
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .alternative {
    display: flex;
    height: 48px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Grey-B, #24272e);
    margin-top: 16px;
    .text {
      color: var(--Grey-B, #24272e);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  GOOGLE_CLIENT_ID,
  LoginStep,
} from "../shared/components/onboarding-shared/constants/auth.constants";
import { useAuth } from "../shared/components/onboarding-shared/hooks/auth.hooks";
import { useAnalytics } from "@dashboard/common/analytics";
import { LoginWrapper } from "./login.styled";
import LoginSelectOption from "./components/login-select-option/login-select-option";
import LoginSendEmail from "./components/login-send-email/login-send-email";
import LoginSendPhone from "./components/login-send-phone/login-send-phone";
import LoginVerifyCode from "./components/login-verify-code/login-verify-code";
import { useDispatch, useSelector } from "react-redux";
import { actions as onboardingActions } from "../shared/components/onboarding-shared/store/onboarding.slice";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { PlanParams } from "../plans/plans.types";
import { actions, countriesSelector, partnerSelector } from "@dashboard/config/redux";
import { useTranslation } from "react-i18next";
import { fetchGetCountryByIp } from "@dashboard/services/countries.services";
import { useLoginHook } from "./login-hook";

const Login: FC = () => {
  const { logEvent } = useAnalytics();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { loginStep, setUi } = useAuth();
  const email = searchParams.get("email");
  const { validSessionOnLogin, decodedToken } = useSession();
  const params = Object.fromEntries(searchParams.entries());
  const partner = useSelector(partnerSelector);
  const countries = useSelector(countriesSelector);
  const { i18n } = useTranslation();
  const sumerLanding = searchParams.get("landing");
  const {IMAGES_BACKGROUND, IMAGE_MOBILE } = useLoginHook();
  const [currentImage, setCurrentImage] = useState(IMAGES_BACKGROUND[0]);
  const [currentImageMobile, setCurrentImageMobile] = useState(IMAGE_MOBILE[0]);

  useEffect(() => {
    if (params?.plan && params?.period) {
      dispatch(actions.setPlanParams(params as PlanParams))
    } 
    if (decodedToken?.user_id) {
      validSessionOnLogin();
    }
  }, [decodedToken]);

  const source = useMemo(() => {
    if (params?.plan && params?.period) {
      return 'landing_pricing';
    } if (sumerLanding !== null) {
      return 'landing_sumer';
    }
    return 'other';
  }, [sumerLanding, params]);

  useEffect(() => {
    logEvent("view_screen", {
      event_subtype: "web_onboarding_login",
      email: `${!!email}`,
      source: source,
    });
  }, []);

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    if (!!Object.keys(params).length) {
      dispatch(onboardingActions.setParams(params));
    }
    dispatch(
      onboardingActions.setUi({ coupon: params.coupon, refer: params.refer })
    );
  }, []);

  useEffect(() => {
    if (!!email) {
      setUi({ loginStep: LoginStep.SendEmailCode });
    }
  }, [email]);

  useEffect(() => {
    if (!partner) {
      fetchGetCountryByIp().then((res) => {
        const initialCountryByIP = String(res.countryId || "").toUpperCase();
        const country = countries.find(
          (c) => String(c.id).toUpperCase() === initialCountryByIP
        );
        if (country && country.locale) {
          const [language] = country.locale.split("_");
          const languages = Object.keys(i18n.options.resources || {});
          if (languages.includes(language)) {
            i18n.changeLanguage(language);
          }
        }
      });
    }
  }, [countries, partner]);
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(IMAGES_BACKGROUND[(IMAGES_BACKGROUND.indexOf(currentImage) + 1) % IMAGES_BACKGROUND.length]);
      setCurrentImageMobile(IMAGE_MOBILE[(IMAGE_MOBILE.indexOf(currentImageMobile) + 1) % IMAGE_MOBILE.length]);
    }, 4000);
    return () => clearInterval(intervalId);
  }, [currentImage]);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <LoginWrapper>
        <div className="container">
          <div className="left">
            <div className="bg-image">
              <img src={currentImage} alt="sumer" />
            </div>
            <div className="phone">
              <img src={currentImageMobile} alt="sumer" />
            </div>
          </div>
          <div className="right">
            <div className="container-right">
            {loginStep === LoginStep.SelectMethod && <LoginSelectOption/>}
            {loginStep === LoginStep.SendPhoneCode && <LoginSendPhone />}
            {loginStep === LoginStep.SendEmailCode && (<LoginSendEmail email={email!} />)}
            {loginStep === LoginStep.VerifyCode && <LoginVerifyCode />}
            </div>
          </div>
        </div>
      </LoginWrapper>
    </GoogleOAuthProvider>
  );
};

export default Login;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  facebookLogin,
  sendPhoneCode as sendPhoneCodeService,
  verifyCode as verifyCodeService
} from "../services/auth.services";
import { StorageItem } from "@dashboard/constants/storage-items";
import { LoginStep } from "../constants/auth.constants";
import { countriesSelector } from "@dashboard/config/redux";
import { RequestCodePayload, VerifyCodePayload } from "../entities/auth.entities";
import { actions } from "../store/onboarding.slice";
import { onboardingUiSelector, paramsSelector } from "../store/onboarding.selectors";
import { OnboardingUiState } from "../store/onboarding.state";
import { getPartnerByToken } from "@dashboard/services/partner.service";
import { actions as globalActions } from '@dashboard/config/redux/global.slice';
import { camelizeKeys } from "humps";
import { useAnalytics } from "@dashboard/common/analytics";

export const useAuth = (props?: { useGoogleLoginFn: () => void; }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onboardingSplit = searchParams.get('onboarding');
  const { appsFlyerEvent, logEvent } = useAnalytics();
  const countries = useSelector(countriesSelector);
  const params = useSelector(paramsSelector);
  const { loginStep, requestCodePayload } = useSelector(onboardingUiSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeOk, setIsCodeOk] = useState(false);
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [canResendCode, setCanResendCode] = useState(false);

  const loginGoogle = () => {
    props?.useGoogleLoginFn();
  };
  
  const loginFacebook = async (token?: string) => {
    if (!!token) {
      try {
        setIsLoading(true);
        const response = await facebookLogin(token);
        if (!!response) {
          localStorage.setItem(StorageItem.Token, `"${response.access_token}"`);
          getPartnerByToken(response.access_token)
            .then(r => r.json())
            .then(r => dispatch(globalActions.setPartnerData(camelizeKeys(r))))
            .catch()
            .finally(() => {
              navigate(`/status${onboardingSplit ? `?onboarding=${onboardingSplit}` : ''}`);
            });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const sendPhoneCode = (payload: RequestCodePayload) => {
    setIsLoading(true);
    setUi({ loginStep: LoginStep.VerifyCode, requestCodePayload: payload });
    setIsLoading(false);
    sendPhoneCodeService(payload)
      .then((r) => {
        if (r) setUi({ loginStep: LoginStep.VerifyCode });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendEmailCode = (payload: RequestCodePayload) => {
    setIsLoading(true);
    setUi({ loginStep: LoginStep.VerifyCode, requestCodePayload: payload });
    setIsLoading(false);
    sendPhoneCodeService(payload)
      .then((r) => {
        if (r) setUi({ loginStep: LoginStep.VerifyCode });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyCode = (code: string) => {
    setIsLoading(true);
    const payload: VerifyCodePayload = {
      channel: requestCodePayload.channel,
      send_to: requestCodePayload.send_to,
      prefix_send_to: requestCodePayload.prefix_send_to,
      code,
    };
    if (!!params && !!Object.keys(params).length) {
      payload.parameter_check_code_user = params;
    }
    verifyCodeService(payload)
      .then(r => {
        setIsCodeOk(true);
        setIsCodeWrong(false);
        localStorage.setItem(StorageItem.Token, `"${r.access_token}"`);
        getPartnerByToken(r.access_token)
          .then(r => r.json())
          .then(r => dispatch(globalActions.setPartnerData(camelizeKeys(r))))
          .catch()
          .finally(() => {
            dispatch(actions.setParams({}));
            logEvent('system_complete_registration');
            appsFlyerEvent({
              eventName: 'system_complete_registration', 
              eventValue: {
                user_type: 'store',
                platform: 'web',
              }
            });
            dispatch(actions.setUi({ loginStep: LoginStep.SelectMethod }));
            navigate(`/status${onboardingSplit ? `?onboarding=${onboardingSplit}` : ''}`);
          });
      }).catch(e => {
        setIsCodeOk(false);
        setIsCodeWrong(true);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const setUi = (ui: Partial<OnboardingUiState>) => {
    dispatch(actions.setUi(ui));
  };

  return {
    countries,
    isLoading,
    loginStep,
    requestCodePayload,
    setUi,
    setIsLoading,
    loginGoogle,
    loginFacebook,
    sendPhoneCode,
    sendEmailCode,
    verifyCode,
    isCodeOk,
    setIsCodeOk,
    isCodeWrong,
    setIsCodeWrong,
    canResendCode,
    setCanResendCode,
  };
}
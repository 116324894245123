import { getApiUrl } from "@dashboard/common/utils";
import { Plan } from "@dashboard/entities/plan-funnel";

export const fetchLastPremiumPlan = async ({ token }: { token: string }): Promise<Plan | null> => {
  const lastMembershipResponse = await fetch(
    `${getApiUrl()}/api/ms/prime/user-membership/funnel`, {
    headers: { 'Authorization': `Bearer ${token}`, },
  });
  if (lastMembershipResponse.status !== 200) return null;
  return await lastMembershipResponse.json();
};
import { http } from "@dashboard/common/http";
import { camelizeKeys, decamelizeKeys } from "humps";
import {
  ProductSkuComparePayload,
  ProductSkuCompareResponse,
} from "../types/product-sku.types";

export const compareSkuFetch = (
  token: string,
  payload: ProductSkuComparePayload
) => {
  return http
    .post(
      `/api/ms/catalogue/product/sku/compare`,
      {
        ...decamelizeKeys(payload),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        } as { [key: string]: string },
      }
    )
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as ProductSkuCompareResponse);
};

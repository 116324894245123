import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import ProductContext from "../../context/product.context";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { useProductGpt } from "@dashboard/modules/products/hooks/products-gpt.hook";
import { CoreEvents } from "@dashboard/constants/events";
import { useSelector } from "react-redux";
import { countryInformationSelector } from "@dashboard/config/redux";
import { useNavigate } from "react-router-dom";

export const useProductDetail = () => {
  const { t } = useTranslation();
  const [showSideImages, setShowSideImages] = useState(false);
  const { setOverflow, showAiGenerator } = useContext(ProductContext);
  const currency = useSelector(countryInformationSelector)?.currency;
  const handleBackdropClick = () => {
    setShowSideImages(false);
    setOverflow("is-overflow");
  };
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const openOrganizeImages = () => {
    logEvent(PRODUCTS_EVENTS.SELECT_LINK, {
      event_subtype: "edit_product_pictures",
    });
    navigate("images");
  };
  const { errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const hasVariations = useMemo(() => {
    return values.toppings && values.toppings.length ? true : false;
  }, [values.toppings]);
  const { loading: loadingPredictProduct, predictProduct } = useProductGpt();

  const handleClickGenerate = (field: string) => {
    logEvent(CoreEvents.ViewScreen, {
      event_subtype: PRODUCTS_EVENTS.IA_GENERATE,
      source: "add_product",
      option: field,
    });
  };

  const predictProductData = useCallback(() => {
    if (values.imagesForm && values.imagesForm.length > 0) {
      return predictProduct({
        name: values.name,
        description: values.description,
        justDescription: false,
        image: values.imagesForm[0].image,
      });
    }
  }, [values.imagesForm]);

  const deleteImageTag = useCallback(
    (tag: string) => {
      const tags = [...(values.calculatedTags || [])];
      setFieldValue(
        "calculatedTags",
        tags.filter((currentTag) => currentTag !== tag)
      );
    },
    [values.calculatedTags]
  );

  const predictNameProduct = useCallback(() => {
    logEvent(CoreEvents.SelectButton, {
      event_subtype: PRODUCTS_EVENTS.IA_GENERATE,
      source: "add_product",
      option: "title",
    });
    return predictProductData()?.then((data) => {
      setFieldValue("name", data.name);
      return data;
    });
  }, [predictProductData]);

  const predictTagsImages = useCallback(() => {
    logEvent(CoreEvents.SelectButton, {
      event_subtype: PRODUCTS_EVENTS.IA_GENERATE,
      source: "add_product",
      option: "tags",
    });
    return predictProductData()?.then((data) => {
      setFieldValue("calculatedTags", data.metatag);
      return data;
    });
  }, [predictProductData]);

  return {
    showSideImages,
    values,
    showAiGenerator,
    loadingPredictProduct,
    errors,
    touched,
    currency,
    predictTagsImages,
    handleClickGenerate,
    handleBackdropClick,
    openOrganizeImages,
    deleteImageTag,
    predictNameProduct,
    handleBlur,
    handleChange,
    logEvent,
    hasVariations,
    setFieldValue,
    t,
  };
};

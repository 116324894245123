import { FC } from "react";
import { StatsInfoBoxWrapper } from "./stats-info-box.styled";
import { useSelector } from "react-redux";
import { planSelector } from "@dashboard/config/redux";
import UiTooltip from "@dashboard/components/ui-tooltip";
import { useTranslation } from "react-i18next";

interface StatsInfoBoxProps {
  value: number | string;
  label: string;
  percentage?: number;
  tooltipText?: string;
  className?: string;
  addProducts?: boolean;
  name?: string;
}
 
const StatsInfoBox: FC<StatsInfoBoxProps> = ({
  value,
  label,
  percentage,
  tooltipText,
  className,
  addProducts,
  name,
}) => {

  const { t } = useTranslation();
  const plan = useSelector(planSelector);
  
  return (
    <StatsInfoBoxWrapper className={className}>
      <div className={`value ${name} ${plan?.benefit_plan_name?.toLowerCase()}`}>{value}</div>
      <div className="label">
        {label}
        {!!tooltipText && (
          <UiTooltip text={tooltipText}>
            <span className="icon-info-alert-icon" />
          </UiTooltip>
        )}
      </div>
      {percentage !== undefined && (
        <div className={`percentage ${percentage > 0 ? 'green' : 'red'}`}>
          {percentage}% <span className="icon-arrow-3" />
        </div>
      )}
      {!!addProducts && (
        <div className="add-products">
          {t('home.inventory.addProducts')}
        </div>
      )}
    </StatsInfoBoxWrapper>
  );
}
 
export default StatsInfoBox;
import React, { Dispatch, SetStateAction } from 'react';

interface IProductContext {
    overflow: string;
    setOverflow: Dispatch<SetStateAction<string>>;
    showFilterModal: boolean;
    setShowFilterModal: Dispatch<SetStateAction<boolean>>;
    filterOn: boolean;
    setFilterOn: Dispatch<SetStateAction<boolean>>;
    filterCount: number;
    setFilterCount: Dispatch<SetStateAction<number>>
    showAiGenerator?: boolean;
    showProductsAiHook?: boolean;
    setShowProductsAiHook: Dispatch<SetStateAction<boolean>>;
}

const ProductContext = React.createContext<IProductContext>({
    overflow: 'is-overflow',
    setOverflow: () => {},
    showFilterModal: false,
    setShowFilterModal: () => {},
    filterOn: false,
    setFilterOn: () => {},
    filterCount: 0,
    setFilterCount: () => {},
    setShowProductsAiHook: () => {},
});

export default ProductContext;
const locale = {
  name: "EVO Assistant",
  title: "Hello, I'm Evo",
  description: "How can I help you today?",
  dates: {
    today: "Today",
    yesterday: "Yesterday",
  },
  chat: {
    title: "New Chat",
    placeholder: "Write here...",
    expired:
      "Oops! Looks like the time has expired. Please come back and try one more time.",
  },
  message: {
    options: {
      copy: "Copy",
      approved: "Good answer",
      unApproved: "Bad answer",
      regenerate: "Regenerate response",
    },
  },
  alert: {
    copySuccess: "The message has been copied successfully",
  },
  disabled: {
    title: "It seems we have exhausted our talks for today.",
    description:
      "If you want to continue chatting with EVO, purchase our Ultra plan to have unlimited chats,",
    acquire: "Acquire Ultra plan",
  },
};

export default locale;

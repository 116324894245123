import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ProductInventoryWrapper } from "./product-inventory.styled";
import ProductFeatures from "../product-features/product-features";
import ProductStock from "../product-stock/product-stock";

const ProductInventory: FC = () => {
  const { t } = useTranslation();

  return (
    <ProductInventoryWrapper>
      <div className="title">
        <div className="text">
          {t("productComponent.product.productInventory.title")}
        </div>
      </div>
      <div className="content">
        <ProductFeatures />
        <ProductStock />
      </div>
    </ProductInventoryWrapper>
  );
};

export default ProductInventory;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./contact-button.state";
import { ContactButtonEditPayload } from "../entities/contact-button.entities";

const contactButtonSlice = createSlice({
  name: "contact-button",
  initialState,
  reducers: {
    setUi: (state, action) => {
      state.ui = { ...state.ui, ...action.payload };
    },
    setButtonConfig: (state, { payload }: PayloadAction<ContactButtonEditPayload>) => {
      state.data.buttonConfig = payload;
    },
    setOldButtonConfig: (state, { payload }: PayloadAction<ContactButtonEditPayload>) => {
      state.data.oldButtonConfig = payload;
    }
  },
});

export const contactButtonActions = contactButtonSlice.actions;
export default contactButtonSlice.reducer;

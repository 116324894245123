import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./onboarding-ai.state";

export const onboardingAiSlice = createSlice({
  name: "onboarding-ai",
  initialState,
  reducers: {
    setUrl: (state, { payload }: PayloadAction<string>) => {
      state.data.url = payload;
    },
    setAiText: (state, { payload }: PayloadAction<string>) => {
      state.data.aiText = payload;
    },
    setEmptyState: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showEmptyState = payload;
    },
    setLoadIframe: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.loadIframe = payload;
    },
  },
});

export const onboardingAiActions = onboardingAiSlice.actions;
export const onboardingAiReducer = onboardingAiSlice.reducer;

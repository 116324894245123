import styled from "styled-components";

export const AlertWrapper = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-w, #fff);
  box-shadow: 0px 2px 4px -2px rgba(135, 135, 136, 0.5);
  border-left: 8px solid #FFB237;
  padding: 20px 15px 16px;
  width: 100%;
  .icon {
    color: #FFB237;
    font-size: 20px;
  }
  .copys {
    .title {
      color: var(--Grey-B, #24272e);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
    .alertDescription {
      color: var(--grey-b, #24272e);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

export const voucher = {
  list_title: 'Comprobante de pago',
  list_cta: 'Crear nuevo comprobante',
  table: {
    detail: 'Detalle del comprobante',
    amount: 'Monto',
    date: 'Fecha de creación',
    voucher: 'Comprobante',
  },
  list_empty_title: 'Crea tu primer comprobante de pago',
  list_empty_description: 'Envía comprobantes de pago profesionales para cobrar a tus clientes sin dificultad.',
  list_empty_cta: 'Crear nuevo comprobante',
  right_modal_title: 'Visualización de factura',
  preview: {
    store_name_not_fill: 'Nombre de la tienda',
    voucher_number: 'Número de comprobante:',
    voucher_date: 'Fecha:',
    client_info: 'Información del cliente:',
    client_name: 'Nombre del cliente',
    client_email: 'Email del cliente',
    client_country: 'País',
    client_city: 'Ciudad',
    client_phone: 'Número de teléfono',
    product_units: 'Unidades:',
    payment_details: 'Detalles de pago:',
    payment_subtotal: 'Subtotal',
    payment_discount: 'Descuento',
    payment_total: 'Total',
    created_with: 'Creado con',
    not_fill: 'Sin diligenciar',
  },
  configuration: {
    back: 'Atrás',
    save: 'Guardar',
    titles: {
      main: 'Crear comprobante',
      date: 'Fecha de pago',
      client: 'Información del cliente',
      details: 'Detalles del pedido',
      discounts: 'Descuentos',
    },
    descriptions: {
      main: 'Configura tu comprobante de pago para que se adapte a tus necesidades',
      date: 'Selecciona la fecha de pago que quieres registrar en el comprobante',
      client: 'Diligencia la información del cliente que aparecerá en el comprobante',
      details: 'Agrega los productos relacionados a este comprobante de pago',
      discounts: 'Activa esta opción y especifica la promoción que aplicó a esta compra. Verifica el precio final en la factura.',
    },
    options: {
      date: 'Fecha de pago',
      client: 'Información del cliente',
      details: 'Detalles del pedido',
      discounts: 'Descuentos',
    },
    client_info: {
      name_placeholder: 'Nombre del cliente',
      email_placeholder: 'Correo del cliente',
      country_placeholder: 'País',
      city_placeholder: 'Ciudad',
      phone_placeholder: 'Número de teléfono',
      not_show: 'No mostrar información del cliente',
    },
    products_detail: {
      title: 'Agregar productos',
      search_placeholder: 'Buscar productos',
      empty: 'No se encontraron productos',
      not_show_images: 'No mostrar imágenes de los productos',
    },
    discounts: {
      shouldApply: 'Aplicar un descuento',
      input_placeholder: 'Ingresa el valor a descontar',
    }
  },
  delete_modal: {
    title: '¿Eliminar comprobante?',
    description: 'El comprobante y los detalles que configuraste en él desaparecerán y no se podrán recuperar.',
    cancel: 'No, volver',
    delete: 'Eliminar',
  }
};

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions } from "@dashboard/config/redux/global.slice";
import { countryInformationSelector, partnerSelector, showAddonHookSelector, toBuyAddonInfoSelector } from "@dashboard/config/redux";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { Feature } from "@dashboard/constants/features";
import abandonedCartHookImg from '@dashboard/assets/img/abandoned-cart-hook.webp';
import contactButtonHookImg from '@dashboard/assets/img/contact-button-hook.webp';
import voucherHookImg from '@dashboard/assets/img/voucher-hook.webp';

export const useAddonHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partner = useSelector(partnerSelector);
  const country = useSelector(countryInformationSelector);
  const addonInfo = useSelector(toBuyAddonInfoSelector);
  const showAddonHook = useSelector(showAddonHookSelector);
  const googleShoppingHookImg = 'https://sumerlabs.com/prod/web/dashboard/goggle-sh.webp';

  const addonImg = useMemo(() => {
    switch (addonInfo?.addon_type_id) {
      case Feature.AbandonedCart:
        return abandonedCartHookImg;
      case Feature.ContactButton:
        return contactButtonHookImg;
      case Feature.Voucher:
        return voucherHookImg;
      case Feature.GoogleShopping:
        return googleShoppingHookImg;  
      default:
        return abandonedCartHookImg;
    }
  }, [addonInfo]);

  const handleToggleShowAddonHook = () => {
    dispatch(globalActions.setShowAddonHook(!showAddonHook));
  };

  const handleGoToAddonCheckout = () => {
    dispatch(checkoutActions.setToBuyItem({ type: 'addon', addon_type_id: addonInfo?.addon_type_id }));
    handleToggleShowAddonHook();
    navigate(`/${partner?.userId}/checkout`);
  };

  const handleGoToPlansPage = () => {
    navigate(`/${partner?.userId}/plans`);
  };
  
  return {
    addonImg,
    partner,
    country,
    addonInfo,
    showAddonHook,
    handleToggleShowAddonHook,
    handleGoToAddonCheckout,
    handleGoToPlansPage,
  };
};

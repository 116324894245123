export const goToWhatApp = (phone: string, message?: string) => {
  const helpText = message || `¡Hola! Tengo dudas sobre el estado de mi Orden <<Realizar Pregunta>>`;
  let url = `https://wa.me/${phone.replace('+', '').replace(' ', '')}?text=${helpText}`;
  const link = document.createElement("a");
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

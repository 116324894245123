const locale = {
    stepLogin: {
        split2: {
            description: 'Your store looks great! <strong>Register<strong> to continue exploring amazing features.'
        }
    },
    stepInfo: {
        title: "Let's create your store!",
        description: 'This information helps us provide you with a better experience, and you can modify it later.',
        form: {
            infoTitle: 'Information',
            infoSubTitle: 'Contact of your business',
            aboutSiteTitle: 'What is your site about?',
            aboutSiteDescription: 'Your response will allow us to show you ideas.',
            economicSectorLabelText: "You still don't know?",
            economicSectorLabelLink: 'See some suggestions',
            checkboxText: 'This is also my personal number.',
            submitButtonText: 'Next'
        }
    },
    stepStore: {
        title: 'Design your online store in seconds',
        description: 'How do you want to start?',
        options: {
            generateAi: {
                text: {
                    title: 'Generate store with AI',
                    description: 'Let our artificial intelligence do the magic for you'
                },
                button: 'Generate'
            },
            template: {
                text: {
                    title: 'Select a template',
                    description: 'Select and customize a template for your store'
                },
                button: 'Select'
            }
            
        },
    },
    stepCustomization: {
        split2: {
            nextButtonText: 'Continue'
        }
    }
}

export default locale;
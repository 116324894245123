import { FC, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginSendPhoneWrapper } from "./login-send-phone.styled";
import logoSumerImg from '@dashboard/assets/img/logo-sumer.png';
import { UiPhone, UiPhoneOption, allowOnlyNumber } from "@sumerlabs/component-library";
import { loginSendPhoneSchema } from "../../../shared/components/onboarding-shared/validation-schemas/login-send-phone.schema";
import { useAuth } from "../../../shared/components/onboarding-shared/hooks/auth.hooks";
import { RequestCodeChannel } from "../../../shared/components/onboarding-shared/entities/auth.entities";
import { LoginStep } from "../../../shared/components/onboarding-shared/constants/auth.constants";
import { fetchGetCountryByIp } from "@dashboard/services/countries.services";
import { useDispatch } from "react-redux";
import { actions } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.slice";

interface LoginSendPhoneProps {
  
}
 
const LoginSendPhone: FC<LoginSendPhoneProps> = () => {

  const { countries, isLoading, sendPhoneCode, setUi } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      prefixPhone: '',
      phone: '',
    },
    validateOnMount: true,
    validationSchema: loginSendPhoneSchema(t),
    onSubmit: () => {},
  });

  const prefixCodeOptions: UiPhoneOption[] = useMemo(() =>
    countries.map(c => 
      ({ label: c.prefixPhone || '', value: c.name || '', flagUrl: c.flagImage, })
    ),
  [countries]);

  const sendCode = (channel: RequestCodeChannel) => {
    sendPhoneCode({
      send_to: form.values.phone,
      prefix_send_to: getPrefix(form.values.prefixPhone),
      channel,
    });
  };

  const getPrefix = (country: string) => {
    return countries.find((c) => c.name === country)?.prefixPhone || '';
  }

  useEffect(() => {
    fetchGetCountryByIp().then((res) => {
      form.setFieldValue("prefixPhone", res.country || "");
      dispatch(actions.setIpInfo(res));
    });
  }, []);

  return (
    <LoginSendPhoneWrapper>
      <div className="back" onClick={() => setUi({ loginStep: LoginStep.SelectMethod })}>
        <span className="icon-arrow" />
      </div>
      <img src={logoSumerImg} alt="Sumer" className="logo-back" />
      <div className="send-phone-title">{t('onboarding.login.sendPhone.title')}</div>
      <UiPhone
        options={prefixCodeOptions}
        hasError={!!form.errors.phone && (form.touched.phone || form.touched.prefixPhone)}
        errorMessage={form.errors.phone || form.errors.prefixPhone}
        phoneValue={allowOnlyNumber(form.values.phone)}
        placeholder={t('onboarding.login.sendPhone.title')}
        prefixValue={form.values.prefixPhone}
        label={t('onboarding.login.sendPhone.label')}
        onBlur={() => {
          form.setFieldTouched("prefixPhone");
        }}
        onChange={v => {
          if (v.phone) form.setFieldValue("phone", v.phone);
          if (v.prefixPhone) form.setFieldValue("prefixPhone", v.prefixPhone);
        }}
      />
      <div className="disclaimer">{t('onboarding.login.sendPhone.disclaimer')}</div>
      <button 
        className="button"
        disabled={!form.isValid || isLoading}
        onClick={() => sendCode(RequestCodeChannel.Sms)}>
        <span className="icon-comments-outline" />
        <span className="text">{t('onboarding.login.sendPhone.sms')}</span>
      </button>
      <button
        className="button whatsapp"
        disabled={!form.isValid || isLoading}
        onClick={() => sendCode(RequestCodeChannel.Whatsapp)}>
        <span className="icon-whatsapp-color" />
        <span className="text">{t('onboarding.login.sendPhone.wa')}</span>
      </button>
    </LoginSendPhoneWrapper>
  );
}
 
export default LoginSendPhone;

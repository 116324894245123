import styled from "styled-components";

export const DeleteProductsConfirmStyled = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  .delete-confirm-icon {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 200px;
    background: var(--Light-Error-300, #feece6);
    color: #f93c00;
  }
  .delete-confirm-title {
    color: var(--Grey-B, #24272e);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .delete-confirm-copy {
    color: var(--Grey-B, #24272e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .delete-confirm-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    button {
      flex: 1;
    }
  }
`;

import styled from "styled-components";

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .banners {
    width: 100%;
    height: 322px;
    flex: 0 0 auto;
    position: relative;
    margin: 0 0 10px 0;
  }
  .stats-tools {
    display: flex;
    flex: 0 0 auto;
    column-gap: 22px;
    margin: 0 16px 20px 16px;
    .stats {
      flex: 1;
      .head {
        width: 100%;
        display: flex;
        margin: 0 0 10px 0;
        align-items: center;
        .title {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          margin: 0 20px 0 0;
          color: var(--grey-b, #24272E);
        }
        .dropdown {
          width: 180px;
          border: none;
          > div {
            background: #FAFAFA;
            border: 1px solid #FAFAFA;
          }
        }
      }
    }
    .tools {
      flex: 1;
      .head {
        width: 100%;
        display: flex;
        height: 56px;
        margin: 0 0 10px 0;
        align-items: center;
        .title {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          margin: 0 20px 0 0;
          color: var(--grey-b, #24272E);
        }
      }
    }
  }
  .learning {
    margin: 0 24px 20px 40px;
    .head {
      margin: 0 0 20px 0;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: var(--grey-b, #24272E);
      }
    }
  }
`;

import { FC, Fragment } from "react";
import { BreadcrumbsItem, BreadcrumbsProps } from "./breadcrumbs.types";
import { BreadcrumbsStyled } from "./breadcrumbs.styled";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, backButton, className }) => {
  const navigate = useNavigate();

  const goToBack = () => {
    navigate(-1);
  };

  const BreadcrumbsItem: FC<{ item: BreadcrumbsItem }> = ({ item }) => {
    return item.link ? (
      <Link
        to={item.link}
        className="breadcrumbs-item breadcrumbs-link"
        onClick={item.onClick}
      >
        {item.label}
      </Link>
    ) : item.onClick ? (
      <button className="breadcrumbs-item breadcrumbs-link">
        {item.label}
      </button>
    ) : (
      <span className="breadcrumbs-item">{item.label}</span>
    );
  };

  return (
    <BreadcrumbsStyled className={className || ""}>
      {backButton && (
        <button className="back-button" onClick={goToBack}>
          <span className="arrow icon-arrow" />
        </button>
      )}
      {items.map((item, index) => {
        return (
          <Fragment key={`BreadcrumbsItem__${index}`}>
            {index !== 0 && <span>|</span>}
            <BreadcrumbsItem item={item} />
          </Fragment>
        );
      })}
    </BreadcrumbsStyled>
  );
};

export default Breadcrumbs;

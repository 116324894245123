import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PorductBreadcrumbWrapper } from "./product-breadcrumb.styled";
import Breadcrumbs from "@dashboard/components/breadcrumbs/breadcrumbs";

const ProductBreadcrumb: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const breadcrumbRoutes = useMemo(() => {
    let auxRoutes = [
      { label: t("productComponent.product.name") },
      {
        label: pathname.includes("new")
          ? t("productComponent.product.breadcrumb.createPath")
          : t("productComponent.product.breadcrumb.editPath"),
      },
    ];
    if (pathname.includes("images")) {
      auxRoutes.push({
        label: t("productComponent.product.breadcrumb.editPhotos"),
      });
    }
    if (pathname.includes("features")) {
      auxRoutes.push({
        label: t("productComponent.product.breadcrumb.editFeatures"),
      });
    }
    if (pathname.includes("stock")) {
      auxRoutes.push({
        label: t("productComponent.product.breadcrumb.editStock"),
      });
    }
    return auxRoutes;
  }, [pathname]);
  
  return (
    <PorductBreadcrumbWrapper>
      <Breadcrumbs items={breadcrumbRoutes} backButton />
    </PorductBreadcrumbWrapper>
  );
};

export default ProductBreadcrumb;

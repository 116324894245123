export const PRODUCTS_EVENTS = {
  SELECT_SAVE_CONFIGURATION: "select_save_configuration",
  SELECTED_CARD: "select_card",
  SELECT_LINK: "select_link",
  SELECT_UPLOAD: "select_upload",
  SELECT_INPUT: "select_input",
  SELECT_BUTTON: "select_button",
  SELECT_TOGGLE: "select_toggle",
  SELECT_OPTION: "select_option",
  SELECT_DROPDOWN: "select_dropdown",
  CREATE_PRODUCT: "create_product",
  BULK_PRODUCTS_UPLOAD: "bulk_product_upload",
  BULK_UPLOAD: "bulk_upload",
  DOWNLOAD_PRODUCTS_SHEET: "download_products_sheet",
  UPLOAD_PRODUCTS_SHEET: "upload_products_sheet",
  CONTINUE_PRODUCT_UOLOAD: "continue_product_upload",
  RETRY_PRODUCTS_SHEET_UPLOAD: "retry_products_sheet_upload",
  VIEW_PRODUCTS_ERRORS: "view_product_errors",
  VIEW_SCREEN: "view_screen",
  DOWNLOAD_PRODUCTS_ERROR_SHEET: "download_products_error_sheet",
  CONFIRM_BULK_EXIT: "select_confirm_bulk_exit",
  SELECT_CONFIRM_DELETE: "select_confirm_delete",
  SYSTEM_UPLOAD_COMPLETE: "system_upload_complete",
  UPLOAD_IMAGE_FOLDER: "upload_image_folder",
  USE_TEST_IMAGES: "use_test_images",
  CONTINUE_IMAGES_UPLOAD: "continue_images_upload",
  RETRY_IMAGES_UPLOAD: "retry_images_upload",
  VIEW_IMAGES_ERRORS: "view_images_errors",
  DOWNLOAD_IMAGES_ERROR_SHEET: "download_images_error_sheet",
  SYSTEM_EXPERT_WARNING: "system_expert_warning",
  BULK_UPLOAD_RETRY: "bulk_upload_retry",
  BULK_UPLOAD_FINISH: "bulk_upload_finish",
  BULK_PRODUCT_HELP: "bulk_product_help",
  PRODUCT_IMAGES_LIMIT_ALERT: "product_images_limit_alert",
  SELECT_SHARE: "select_share",
  FEATURE_PRODUCT: "feature_product",
  PRODUCT_STATUS: "product_status",
  SELECT_BULK_PRODUCTS: "select_bulk_products",
  APPLY_MASSIVE_ACTIONS: "apply_massive_actions",
  EXPORT_INVENTORY: "export_inventory",
  PRODUCT_SKU: "product_sku",
  IA_GENERATE: "ia_text_generate",
  SELECT_ADD_PRODUCT_COMPLETE:"select_add_product_complete",
  PRODUCT_VALUE: "product_value",
  PRODUCT_INVENTORY_QUANTITY: "product_inventory_quantity",
  WHOLESALE_INVENTORY_QUANTITY: "wholesale_inventory_quantity",
  DELETE_VARIATION: "delete_variation",
  PRODUCT_VARIATION_DETAIL: "product_variation_detail",
  DELETE_PRODUCT_IMAGE: "delete_product_image",
  DAYS_SALE_ON_REQUEST: "days_sale_on_request",
  PRODUCT_DETAIL_INDEX: "product_detail_index",
  DUPLICATE_PRODUCT: "duplicate_product",
};

const locale = {
  name: "Orders",
  empty: {
    title: "Your order list is empty",
    description:
      "Share your store! make yourself known to receive your first order",
  },
  filters: {
    title: "Filter by",
    options: {
      clear: "Clear filters",
      apply: "Apply filters",
    },
    groups: {
      all: "All orders",
      new: "New",
      payedWithoutLogistic: "Paid without sending",
      delivered: "Delivered",
      deliveryStore: "To pick up in store",
      finished: "Finished",
      cancelled: "Cancelled",
    },
    paymentState: {
      title: "Payment status",
      payed: "Paid",
      notPayed: "Not paid",
    },
    deliveryTypes: {
      title: "Delivery type",
      deliveryHome: "Delivery",
      pickup: "Pick up in store",
    },
    shippingState: {
      title: "Shipping status",
      packed: "Packed",
      notPacked: "Unpacked",
      send: "Sent",
      withoutSend: "Without sending",
    },
    deliveryState: {
      title: "Delivery status",
      pickedUp: "Delivered",
      withoutPickup: "Undelivered",
    },
    orderState: {
      title: "Order status",
      cancelled: "Cancelled",
      finished: "Qualified",
      pendingReview: "Unrated",
    },
    orderDates: {
      label: "Filter date",
      values: "From {{from}} to {{to}}",
      title: "Select date",
    },
  },
  detail: {
    title: "Order detail",
    score: {
      title: "Well done!<br>You delivered the order",
      comments: "See comment",
      pendingReview: "Ask buyer for rating",
      delivered: "Received on {{date}}",
      orderNumber: "Order # {{orderId}}",
      requestReview: `Hello, {{name}}! %0a %0a
You have been sent an email where you can rate your purchase experience, your opinion is important to us`,
    },
    info: {
      deliveryTo: `Send to <span>{{address}}, {{city}}</span>`,
      pickUp: `Deliver to store <span>{{address}} {{city}}</span>`,
      copyConfirm: "It has been copied successfully",
    },
    states: {
      title: "Order status",
      notPayed: "Unpaid",
      payed: "Paid",
      packaged: "Packaged",
      send: "Sent",
      delivered: "Delivered",
      cancelled: "Cancelled",
      labels: {
        notPayed: "Unpaid order",
        payed: "Order paid",
        packaged: "Packaged order",
        send: "Order sent",
        delivered: "Order delivered",
        cancelled: "Order cancelled",
      },
      notifyPackaged: `We will notify your customer when you mark the order as <strong>packaged and shipped</strong>.`,
    },
    products: {
      title: "Products",
      noVariants: "No variants",
    },
    summary: {
      title: "Detail of payment",
      total: "Total",
      subtotal: "Subtotal",
      delivery: "Shipping",
      deliveryFree: "Free shipping",
      discount: "Discount",
    },
    payment: {
      copy: "Payment received at {{provider}}",
      payer: "Sent by {{customerName}}",
      help: `Do you have a problem? <a href="{{url}}" target="_blanck">
        Go to {{provider}}
      </a>`,
    },
  },
  order: {
    id: "Order #{{orderId}}",
    states: {
      new: "!New order!",
      canceled: "Cancelled",
      payed: "Paid",
      notPayed: "Not paid",
      pickupPacked: "Pickup",
      pickupNotPacked: "Unpicked",
      send: "Sent",
      withoutSend: "Without sending",
      pendingReview: "Completed without rating",
    },
    options: {
      cancel: "Cancel order",
    },
    cancelConfirm: {
      title: "Cancel order?",
      copy: "We will notify your buyer that their order has been cancelled.",
      continue: "Cancel order",
      cancel: "No, return",
    },
  },
  improvePlan: {
    confirmUnlock: "Unlock now",
  },
};

export default locale;

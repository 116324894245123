export type Product = {
  id: string;
  name: string;
  description?: string;
  price: number;
  images: string[];
  category: string | null;
  subCategory?: string;
  available: boolean;
  videoLink?: string;
  inventory?: Inventory;
  toppings?: Topping[];
  productToppingVariations?: Array<ToppingVariation>;
  version: number;
  createdAt: string;
  updatedAt: string;
  highlight: boolean;
  ownerId: string;
  friendlyUrl: string;
  priceWithDiscount?: number;
  percentageDiscount?: number;
  type?: ProductType;
  schedule?: ProductServiceSchedule;
  wholesale?: {
    configurations: WholesaleConfiguration[];
  };
  improveImage?: boolean;
  aiProduct?: AiProduct;
  withAd?: boolean;
  fake?: boolean;
  sku?: string;
};

export type ProductVariation = {
  available?: boolean;
  canImproveDescription?: boolean;
  canImproveName?: boolean;
  createdAt?: string;
  fake?: boolean;
  friendlyUrl?: string;
  highlight?: boolean;
  inventory?: VariationInventory;
  price?: number;
  priority?: number;
  productToppingVariations: Array<ToppingVariation>;
  score?: number;
  seoScore?: number;
  toppings: Topping[];
  updateAt: string;
  version: number
};

export type ToppingVariation = {
  available: boolean;
  createdAt: string;
  id: string;
  price: number;
  updatedAt: string;
  variations: Array<Variation>;
  inventory?: Inventory;
  sku?: string;
};

export type Variation = {
  name: string;
  toppingId: string;
  toppingValueName: string;
  value: string;
};

export type AiProduct = {
  descriptionAi: boolean;
  nameAi: boolean;
};

export type Inventory = {
  units?: number;
  type: string;
  deliveryDays?: number;
  delivery_days?: number;
  stockColor?: string;
  alertFewUnits?: number;
  variationInventories?: VariationInventory[];
};

export type VariationInventory = {
  type: string;
  units: number;
  variationCount: number;
};

export type ProductServiceSchedule = {
  maxReservationHours: number;
  activeHolidays: boolean;
  scheduleDaysConfiguration: {
    dayOfWeek: number;
    openTime: string;
    closeTime: string;
  }[];
};

export enum ProductType {
  Service = "SERVICE",
  Product = "PRODUCT",
}

export type Topping = {
  id: string;
  name: string;
  values: ToppingValue[];
  isRequired: boolean;
  isCollapse?: boolean;
  filters?: Array<string>;
};

export type ToppingValue = {
  additionalPrice: number;
  value: string;
  name: string;
  enabled: boolean;
  inventory?: Inventory;
};

export type WholesaleConfiguration = {
  minUnits: number;
  unitPrice: number;
  discountType: string;
};

export type FetchOrchestratorPayload = {
  ownerId: string;
};

export type UpdateProductPayload = FetchOrchestratorPayload &
  Product & {
    id: string;
    imagesDetail?: { url: string }[];
  };

export type DeleteProductPayload = {
  ids: string[];
  action: string;
};

export type CreateProductPayload = Omit<
  Product,
  | "id"
  | "price"
  | "createdAt"
  | "updatedAt"
  | "version"
  | "ownerId"
  | "highlight"
  | "friendlyUrl"
> & {
  price?: number;
  imagesDetail?: { url: string }[];
};

export type Row = {
  id: string;
  name: string;
  price: number;
  available: boolean;
  version: number;
  highlight: boolean;
  video_link: string;
  inventory: Inventory;
  images: string[];
  images_detail: { url: string }[];
  category: string;
  toppings?: Topping[];
};

export type Category = {
  id?: string;
  name: string;
  description?: string;
  subCategories?: Array<string>;
  image?: string;
  imagesDetail?: Array<ImagesDetail>;
  productsCount?: 2;
  isUserCategory?: boolean;
};

export type ImagesDetail = {
  url: string;
  width: number;
  height: number;
};

export interface Filter {
  name: string;
  category: string;
}

export enum InventoryType {
  ALWAYS_AVAILABLE = "ALWAYS",
  LIMITED = "LIMITED",
  LIMITED0 = "LIMITED0",
  BUILD_TO_ORDER = "BUILD_TO_ORDER",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

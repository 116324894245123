import styled from "styled-components";

export const LoginVerifyCodeWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  .back {
    top: -10px;
    left: -20px;
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    .icon-arrow {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .logo-back {
    width: 94px;
    margin: 0 auto 60px auto;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin: 0 0 18px 0;
  }
  .description {
    color: #646464;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px 0;
  }
  .send-info {
    display: flex;
    margin: 0 0 40px 0;
    justify-content: space-between;
    .number {
      color: #0C1220;
      font-size: 18px;
      font-weight: 400;
      line-height: 135%;
    }
  }
  .otp-input {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    margin: 0 auto 30px auto;
    &.error {
      .box {
        border: 1px solid #F93C00;
      }
    }
    .box {
      padding: 0;
      width: 44px;
      height: 44px;
      display: flex;
      outline: none;
      color: #0C1220;
      font-size: 20px;
      font-weight: 400;
      line-height: 140%;
      text-align: center;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      border: 1px solid #DDE0F5;
      -moz-appearance: textfield;
      &::placeholder {
        color: #878788;
        font-size: 24px;
        font-weight: 400;
        line-height: 140%;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        caret-color: #24272E;
        border: 1px solid #24272E;
      }
      &:disabled {
        color: #ACADAF;
      }
    }
    .dash {
      display: flex;
      color: #646464;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      align-items: center;
      letter-spacing: -0.01em;
    }
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ok {
    text-align: center;
  }
  .error-text {
    color: #F93C00;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .resend {
    display: flex;
    color: #646464;
    font-size: 14px;
    column-gap: 6px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    margin: 76px auto 0 auto;
    .action {
      color: #24272E;
      cursor: pointer;
      text-decoration: underline;
    }
    .timer {
      color: #0C1220;
    }
  }
`;

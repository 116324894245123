import { useSelector } from "react-redux";
import { countryInformationSelector } from '../config/redux/global.selectors';
import { formatCurrency as currency } from '@dashboard/utils/format-currency';
import dayjs from "dayjs";

export const useFormatters = () => {

  const country = useSelector(countryInformationSelector);

  const formatCurrency = (number: number = 0) => {
    return currency(number, country);
  };

  const formatDate = (date?: string | Date, format: string = 'DD/MM/YYYY') => {
    return !!date ? dayjs(date).format(format) : '';
  };

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return {
    formatCurrency,
    formatDate,
    capitalizeFirstLetter
  };
};

export const home = {
  informsTitle: 'Informes',
  toolsTitle: 'Herramientas',
  learningTitle: 'Aprende con sumer videos',
  yesterday: 'Ayer',
  lastSevenDays: 'Últimos 7 días',
  lastFifteenDays: 'Últimos 15 días',
  lastThirtyDays: 'Últimos 30 días',
  planHook: {
    title: 'Mejora tu plan',
    description: 'Funcionalidad disponible en plan Pro y en Ultra. Haz upgrade para tener funcionalidades ilimitadas.',
    button: 'Desbloquear ahora',
    later: 'Quizá más tarde',
  },
  inConstruction: {
    title: '¡Herramienta en desarrollo!',
    description: 'Próximamente podrás tener esta herramienta',
    button: 'Aceptar',
  },
  inventory: {
    title: 'Mis productos',
    tooltip: 'Número de productos creados',
    addProducts: 'Agregar productos',
  },
  learning: {
    title: 'Tutoriales',
    description: 'Ver todos los videos',
    visits: 'Visitas',
  },
  orders: {
    title: 'Mis pedidos',
    youHas: 'Tienes',
    newOrdersPlural: 'nuevos pedidos',
    newOrdersSingular: 'nuevo pedido',
    noNewOrders: 'No tienes nuevos pedidos',
    newOrder: 'Nuevo pedido',
  },
  stats: {
    visits: 'Visitas',
    visitsTooltip: 'Muestra la cantidad de sesiones en tu tienda online',
    totalSales: 'Total ventas',
    totalSalesTooltip: 'Es el monto generado por todas tus ventas en el período seleccionado.',
    totalOrders: 'Total de pedidos',
    totalOrdersTooltip: 'Muestra la cantidad de pedidos que se realizaron',
    totalUnits: 'Unidades vendidas',
    totalUnitsTooltip: 'Número de productos vendido por unidad',
    abandonedCarts: 'Carrito abandonado',
    abandonedCartsTooltip: 'Número de usuarios que inician el proceso de compra en tu tienda online, pero luego abandonan la transacción sin completarla',
  }
};

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AddonHookWrapper } from "./addon-hook.styled";
import UiModal from "@dashboard/components/ui-modal";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { useAddonHook } from "@dashboard/hooks/use-addon-hook.hook";

interface AddonHookProps {
  
}
 
const AddonHook: FC<AddonHookProps> = () => {

  const { t } = useTranslation();
  const {
    addonImg,
    addonInfo,
    country,
    showAddonHook,
    handleToggleShowAddonHook,
    handleGoToAddonCheckout,
    handleGoToPlansPage,
  } = useAddonHook();

  return (
    <AddonHookWrapper>
      <UiModal show={showAddonHook} onClose={handleToggleShowAddonHook} className="modal">
        <img src={addonImg} alt="Plan Ultra" className="img" />
        <div className={'forPrice'}>
          {t('addonHook.getFor')}{" "}
          {formatCurrency(addonInfo?.price || 0, country)}
        </div>
        <div className={'ultra'}>
          {t('addonHook.orChangeTo')}<strong>Ultra</strong>
        </div>
        <div className={'description'}>
          {t('addonHook.description')}
        </div>
        <div
          role='button'
          aria-label='acquire'
          className={'actionAcquire'}
          onClick={handleGoToAddonCheckout}
        >
          {t('addonHook.get')} {t(`addon.${addonInfo?.addon_type_id}`)}
        </div>
        <div
          role='button'
          aria-label='cta'
          className={'actionUltra'}
          onClick={() => {
            handleGoToPlansPage();
            handleToggleShowAddonHook();
          }}
        >
          {t('addonHook.getUltraPlan')}
        </div>
      </UiModal>
    </AddonHookWrapper>
  );
}
 
export default AddonHook;
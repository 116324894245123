export const configuration = {
  breadcrumb: {
    configuration: 'Configuration',
    domain: 'Domain',
    payments: 'Payments',
    connect: 'Connect {{gateway}}'
  },
  menu: {
    personalizeTitle: 'Personalize your store',
    personalizeDescription: 'Configure and customize your store design',
    paymentsTitle: 'Configure your payments',
    paymentsDescription: 'Configure your payments',
    domainTitle: 'Change store URL',
    domainDescription: 'Configure and acquire the domain for your store',
  },
  domain: {
    placeholder: 'search your domain',
    activeDomain: 'Active Domain',
    activeDomainTitle: 'Edit your URL',
    activeDomainDescription: 'This is your integrated Sumer URL. You can always access your site from this link and it never expires.',
    integratedUrl: 'Integrated URL',
    editButton: 'Edit',
    saveChanges: 'Save changes',
    hookTitle: 'Get this functionality!',
    hookDescription: 'Upgrade your plan now to have this and more features.',
    hookConfirmLabel: 'Get now',
    help: 'I need help with my own domain',
    copied: 'Link copied to clipboard',
    getYourOwnDomain: 'Get your domain today',
    buyDomain: 'Buy domain',
    promo: 'Get up to <strong>30%</strong> off the purchase of your domain for being part of Sumer premium.',
    transferDomain: 'Already have a domain? Transfer it here',
    editYourActiveDomain: 'Edit your active domain',
    urlInProcess: 'URL in process',
    configuringTitle: 'We are setting up your domain!',
    configuringDescription: 'This setup may take up to 1 day to be reflected.',
    configuringDisclaimer: 'Once the domain is purchased, it cannot be modified or unlinked from your store.',
    configuredCongrats: 'Congratulations! You now have your own domain',
    activeUntil: 'Active until',
    share: 'Share',
    copy: 'Copy',
    seeStore: 'See store',
    needHelp: 'Need help?',
    helpDisclaimer: 'Once the domain is purchased, it cannot be modified or unlinked from your store.',
    search: {
      title: 'Find your perfect domain',
      description: 'To have a good domain, do not exceed 20 characters, avoid special characters and avoid numbers.',
      search: 'Search',
      notAvailable: 'Not available',
      noAvailable: 'This domain is already taken',
      year: '/year',
      otherDomains: 'Other available domains',
    },
    labelsBreadcrumbs: {
      configuration: "Configuration",
      domain: "Domain",
      search: "Search domain",
    }
  },
  paymentGateway: {
    info: {
      title: 'Activate online payment methods',
      description: 'Choose a payment provider from our list',
      buttonConnect: 'Connect'
    },
    loader: {
      step1: "We are connecting with {{gateway}}",
      step2: "Thanks for signing up! Your connection with {{gateway}} was successful",
      step2Error: "Your connection with {{gateway}} was not successful"
    },
    errorAuth: {
      title: 'Problem activating payment method!',
      description: 'Connection could not be made',
      retryButton: 'Try again',
      closeButton: 'Close'
    },
    mercadoPago: {
      title: 'Connect Mercado Pago',
      cardCredentials: {
        description: 'Enter your Mercado Pago credentials (<strong>Public Key and AccesToken</strong>) to connect your account.',
        link: 'Where do I find this information?'
      },
      cardDetails: {
        title: 'Integration detail',
        description: 'Receive all payment methods'
      },
      alert: {
        link: 'Change the price of your products'
      },
      cardAccountInfo: {
        title: "Don't have a Mercado Pago account?" + 
               ' <a href="https://www.mercadopago.com.co/hub/registration/splitter?contextual=normal&entity=pf&redirect_url=https://www.mercadopago.com.co/cuenta" target="_blank"><u>Create it here</u></a>',
        infoOne: 'The collection of fees depends on the negotiation between the user and Mercado Pago. Sumer is not a collection intermediary' +
                 ' <a href="https://www.mercadopago.com.co/ayuda/Cu-nto-cuesta-recibir-pagos_220" target="_blank"><strong><u>Know the payment market rates</u></strong></a>',
        infoTwo: 'The information provided is treated confidentially and will only be used to connect your Mercado Pago account with your Sumer account.'     
      },
      form: {
        title: 'Enter credentials',
        publicKeyField: 'Public Key',
        accessTokenField: 'Access Token',
        activateButton: 'Activate mercado pago',
        validations: {
          clientId: 'Enter the Public Key',
          clientSecret: 'Enter the Access Token'
        }
      }
    },
    popups: {
      unlink: {
        title: 'Disconnect {{gateway}}?',
        description: 'Do you want to unlink your {{gateway}} account and remove this payment method from your store?',
        buttonText: 'Not, return',
        linkText: 'Yes, unlink {{gateway}}'
      },
      deactivate: {
        title: 'Temporarily deactivate?',
        description: 'Do you want to temporarily deactivate your {{gateway}} account? Your clients will not be able to use this payment method. You can reactivate it whenever you want.',
        buttonText: 'Not, return',
        linkText: 'Yes, deactivate {{gateway}}'
      },
      info: {
        description:
          '1. Log in from a computer to your Mercado Pago account at <a href="https://www.mercadopago.com"><u>https://www.mercadopago.com</u></a> <br />' +
          '2. In the left side menu, click on the "Your business" option. <br />' +
          '3. A series of options will be displayed, click on the "Settings" option. <br />' +
          '4. Under "Management and Administration," click the "Credentials" option. <br />' +
          '5. Click on the "Production Credentials" option. <br />' +
          "6. Copy and paste your Public key and Access token in the corresponding spaces. <br />" +
          "7. Store your credentials securely. <br />" +
          '8. Remember that this data is confidential and must be treated with care. For more details, see the ' + 
          '<a href="https://www.mercadopago.com.ar/developers/es/docs/resources-faqs/credentials"><u>official Mercado Pago documentation</u></a> or contact their customer service.'
      }
    }
  }
}; 
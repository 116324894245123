import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { configurationActions } from "../store/configuration.slice";

interface ConfigurationLayoutProps {
  
}
 
const ConfigurationLayout: FC<ConfigurationLayoutProps> = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(configurationActions.reset());
    }
  }, []);
  return (
    <Outlet />
  );
}
 
export default ConfigurationLayout;
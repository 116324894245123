import { FC } from "react";
import { MobileWrapper } from "./mobile.styled";
import { ASSETS_PATH } from "@dashboard/constants/constants";
import { useTranslation } from "react-i18next";

interface MobileProps {
  
}
 
const Mobile: FC<MobileProps> = () => {

  const { t } = useTranslation();
  
  return (
    <MobileWrapper>
      <div className="main-content">
        <div className="title">{t('mobile.title')}</div>
        <ul className="benefits">
          <li>{t('mobile.benefit1')}</li>
          <li>{t('mobile.benefit2')}</li>
          <li>{t('mobile.benefit3')}</li>
        </ul>
        <div className="qr">
          <img src={`${ASSETS_PATH}/web/qr.webp`} alt="Qr" />
          <div className="text">{t('mobile.qr')}</div>
        </div>
        <div className="platforms">
          <img src={`${ASSETS_PATH}/web/ios.webp`} alt="Platforms" />
          <img src={`${ASSETS_PATH}/web/android.webp`} alt="Platforms" />
        </div>
      </div>
      <div className="right">
        <img src={`${ASSETS_PATH}/web/sumerweb-download-app-section-bg.webp`} alt="Qr1" className="bg" />
        <img src={`${ASSETS_PATH}/web/sumerweb-download-app-section-bg-3.webp`} alt="Qr2" className="bg bg2" />
      </div>
    </MobileWrapper>
  );
}
 
export default Mobile;
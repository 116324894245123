import styled from "styled-components";

export const CartsListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 0 40px;
  flex-direction: column;
  .buy-addon {
    display: flex;
    column-gap: 24px;
    width: fit-content;
    border-radius: 8px;
    padding: 18px 24px;
    margin: 20px 0 0 0;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #ECECED;
    box-shadow: 0px 0px 6px 1px #EDECFE;
    .img {
      width: 68px;
      height: 68px;
      object-fit: cover;
      border-radius: 8px;
      background-color: #FFFFFF;
      border: 1px solid #ECECED;
      box-shadow: 0px 0px 6px 1px #EDECFE;
    }
    .texts {
      .title {
        color: #262930;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
      .description {
        color: #262930;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .buy {
      font-size: 16px;
      width: fit-content;
      background-color: #2C68F6;
    }
  }

  .list-header {
    display: flex;
    align-items: center;
    margin: 80px 0 40px 0;
    justify-content: space-between;
    .title {
      color: #262930;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .action-button {
      height: 46px;
      display: flex;
      color: #2C68F6;
      padding: 0 24px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      border-radius: 8px;
      align-items: center;
      border: 1px solid #2C68F6;
    }
  }
  .list-items {
    background: #FFFFFF;
    &.blur {
      filter: blur(3px);
      pointer-events: none;
    }
    tbody {
      tr {
        display: grid;
        align-items: center;
        border-bottom: 1px solid #E7EAEE;
        grid-template-columns: 76px 112px 170px 1fr 216px 110px 114px 100px;
        .head-cell {
          height: 32px;
          display: flex;
          padding: 0 8px;
          color: #262930;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          align-items: center;
        }
        .cell {
          height: 64px;
          display: flex;
          color: #191D23;
          padding: 0 8px;
          column-gap: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          align-items: center;
          &.scroll {
            overflow-x: auto;
          }
          .ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .img {
            width: 54px;
            height: 50px;
            object-fit: cover;
            border-radius: 8px;
          }
          .badge {
            height: 28px;
            display: flex;
            padding: 0 4px;
            color: #8F9298;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            border-radius: 8px;
            align-items: center;
            box-sizing: border-box;
            border: 1px solid #8F9298;
            &.recovered {
              border: none;
              color: #3ABD7C;
              background-color: #EBF8F2;
            }
          }
          .action {
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            &.blue {
              color: #2C68F6;
              background-color: #EAF0FE;
            }
            &.green {
              color: #3ABD7C;
              background-color: #EBF8F2;
            }
          }
        }
      }
    }
  }
  .empty-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 80px - 160px);
    &.blur {
      filter: blur(3px);
      pointer-events: none;
    }
    .img {
      width: 102px;
      height: 102px;
      display: flex;
      color: #2C68F6;
      font-size: 60px;
      margin: 0 0 24px 0;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #E5EDFF;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      font-style: normal;
      margin: 0 0 12px 0;
    }
    .description {
      color: #262930;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-style: normal;
      text-align: center;
    }
  }
  .modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    .overlay {
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.75);
    }

  }

  .empty {
    flex: 1;
    display: flex;
    margin: 0 16px;
    flex-direction: column;
    justify-content: center;
    .img {
      width: 112px;
      height: 112px;
      margin: 0 auto 26px auto;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin: 0 0 16px 0;
    }
    .button {
      margin: 0 auto;
      font-size: 16px;
      max-width: 190px;
      background-color: #2C68F6;
    }
  }

  .right-modal {
    right: 0;
    top: 54px;
    z-index: 10;
    width: 390px;
    padding: 20px;
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: #FFFFFF;
    height: calc(100vh - 81px);
    .back {
      right: 20px;
      width: 24px;
      height: 24px;
      display: flex;
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      align-items: center;
      justify-content: center;
    }
    .item {
      display: flex;
      margin: 88px 0 0 0;
      .img {
        width: 74px;
        height: 74px;
        object-fit: cover;
        margin: 0 16px 0 0;
        border-radius: 8px;
        border: 1px solid #ECECED;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6px 1px #EDECFE;
      }
      .texts {
        .title {
          color: #262930;
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
        }
        .description {
          color: ß#262930;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .bottom {
      row-gap: 16px;
      display: grid;
      margin: auto 0 0 0;
      padding: 16px 0 0 0;
      border-top: 1px solid #E6E8F9;
      grid-template-columns: 1fr 1fr;
      .label, .value {
        color: #11142D;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
      .value {
        text-align: right;
      }
      .button {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
`;

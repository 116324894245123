import styled from "styled-components";

export const CustomSearchSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 16px;
  position: relative;
  label {
    color: var(--grey-b, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .input-container {
    width: -webkit-fill-available;
    .left-icon {
      position: absolute;
      margin-top: 18px;
      font-size: 20px;
      left: 15px;
    }
    .right-icon {
      position: absolute;
      margin-top: 18px;
      font-size: 20px;
      right: 15px;
    }
    input {
      width: -webkit-fill-available;
      display: flex;
      height: 56px;
      padding: 10px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--grey-border, #e9e9ed);
      background: var(--grey-w, #fff);
      padding: 0px 45px;
    }
  }
`;

export const CustomerSearchSelectData = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  .item {
    display: flex;
    width: 344px;
    height: 64px;
    padding: 16px 8px;
    align-items: center;
    border-bottom: 1px solid var(--grey-border, #e9e9ed);
    &:hover {
      background: var(--grey-border, #e9e9ed);
      cursor: pointer;
    }
  }
`;

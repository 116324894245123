import { http } from "@dashboard/common/http";
import { camelizeKeys } from "humps";
import {
  PredictProductPayload,
  PredictProductResponse,
} from "../types/products-gpt.types";

export const getPredictProductFetch = (
  token: string,
  payload: PredictProductPayload,
  options: {
    abort?: AbortController;
    onUploadProgress?: (event: ProgressEvent) => void;
  } = {}
) => {
  const { abort, onUploadProgress } = options;
  let formData = new FormData();

  formData.append("name", payload.name || "");
  formData.append("description", payload.description || "");
  formData.append("just_description", String(!!payload.justDescription));
  !!payload.image && formData.append("image", payload.image);
  !!payload.tags && formData.append("tags", payload.tags || "");

  return http
    .post("/api/ms/visual-gpt-products/predict-product/", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
      ...(abort ? { signal: abort.signal } : {}),
    })
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as PredictProductResponse);
};

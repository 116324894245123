import { FC } from "react";
import { NavigationBarStyled } from "./navigation-bar-2.styled";
import { useNavigationBar } from "../../navigation-bar.hook";
import { NavigationBarProps } from "./navigation-bar-2.types";
import Evo from "@sumerkit/icons/evo";
import MeliIcon from "@sumerkit/icons/meli";
import ShoppingBagOpen from "@sumerkit/icons/shopping-bag-open";
import { Devices } from "@phosphor-icons/react";

const NavigationBar: FC<NavigationBarProps> = () => {
  const {
    t,
    plan,
    menu,
    isNavigationBarCollapsed,
    handleNavigationBarCollapse,
    isActiveItem,
    handleClickItemCheck,
    handleClickAccount,
    handleClickHelp,
    handleClickAiAssistant,
  } = useNavigationBar();

  return (
    <NavigationBarStyled
      className={isNavigationBarCollapsed ? `navigation-bar__collapse` : ""}
    >
      <header className="navigation-bar-header">
        <span className="navigation-bar-icon icon-sumer-logo" />
        <button
          className="navigation-bar-toggle"
          onClick={() => handleNavigationBarCollapse(!isNavigationBarCollapsed)}
        >
          <span className="icon-arrow-go-up" />
        </button>
      </header>
      <hr className="navigation-bar-divider" />
      <div className="navigation-bar-menu navigation-bar-menu__principal">
        <ul className="navigation-bar-menu">
        {menu.map((item) => {
          if (!item.isActive) {
            return null;
          }
          return (
            <li
              key={`navigation-bar-menu-item-${item.id}`}
              className="navigation-bar-menu-item"
            >
              <button
                className={`navigation-bar-menu-link ${isActiveItem(item.link)
                    ? "navigation-bar-menu-link__active"
                    : ""
                  }`}
                onClick={() => handleClickItemCheck(item)}
              >
                {item.icon === "icon-meli" && (
                  <span className={`icon`}>
                    <MeliIcon width={18} height={18} />
                  </span>
                )}
                {item.icon === "icon-google" && (
                  <span className={`icon`}>
                    <ShoppingBagOpen width={18} height={18} />
                  </span>
                )}
                {item.icon === "icon-devices" && (
                  <span className={`icon`}>
                    <Devices width={16} height={16} />
                  </span>
                )}
                {item.icon !== "icon-meli" && item.icon !== "icon-google" && item.icon !== "icon-devices" && item.icon && (
                  <span className={`icon ${item.icon}`} />
                )}
                <span className="label">{t(`navbar.${item.id}`)}</span>
              </button>
            </li>
          );
        })}
        </ul>
      </div>
      <hr className="navigation-bar-divider navigation-bar-divider__full" />
      <ul className="navigation-bar-menu">
        <li className="menu-item__evo navigation-bar-menu-item">
          <button
            className="navigation-bar-menu-link"
            onClick={handleClickAiAssistant}>
            <span className={`icon`}>
              <Evo />
            </span>
            <span className="label">{t("aiAssistant.name")}</span>
          </button>
        </li>
        <li className="navigation-bar-menu-item">
          <button
            className={`navigation-bar-menu-link ${
              isActiveItem("/profile") ? "navigation-bar-menu-link__active" : ""
            }`}
            onClick={handleClickAccount}
          >
            <span className={`icon icon-account-circle-outline`} />
            <div className="label">
              {t("navbar.myAccount")}
              <span
                className={`navigation-bar-plan navigation-bar-plan__${
                  plan?.benefit_plan_name?.toLowerCase() || "free"
                }`}
              >
                {t(`navbar.planTag`, {
                  plan: t(
                    `navbar.plan_${plan?.benefit_plan_name?.toLowerCase()}`
                  ),
                })}
              </span>
            </div>
          </button>
        </li>
        <li className="navigation-bar-menu-item">
          <button
            className="navigation-bar-menu-link"
            onClick={handleClickHelp}
          >
            <span className={`icon icon-talk`} />
            <span className="label">{t("support.help")}</span>
          </button>
        </li>
      </ul>
    </NavigationBarStyled>
  );
};

export default NavigationBar;

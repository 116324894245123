const locale = {
    form: {
        title: "Crea tu tienda con Inteligencia Artificial",
        description: "Describe el tema de tu tienda y los productos que vendes.",
        exampleText: "Ejemplos",
        placeholder: "Cuéntanos sobre tu tienda ideal",
        buttonCreateStore: "Crear tienda",
        defaultDescription: "Creame una tienda de joyas de estilo minimalista, colores pasteles y con 4 productos dorados.",
        characters: "caracteres",
        minimumCharacters: "Mínimo 10 caracteres",
    },
    loader: {
        stepOne: "Eligiendo el mejor diseño para tu negocio...",
        stepTwo: "Seleccionando las mejores imágenes para tu catalogo...",
        stepThree: "Estamos creando tu tienda con IA...",
    },
    footer: {
        leftButton: {
            label: "Ver otra opción",
        },
        rightButton: {
            label: "Comenzar",
        }
    }
};

export default locale;
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "@sumerlabs/component-library";
import { fetchHomeConfig, fetchHomeStatistics } from "../services/home.services";
import { homeBannersSelector, homeFakeDoorsSelector, homeLearningsSelector, homeNewOrdersSelector, homeProductsQuantitySelector, homeStatisticsSelector, homeUiSelector } from "../store/home.selectors";
import { homeActions } from "../store/home.slice";
import { HomeFakeDoor, StatisticsFilter } from "../constants/home.constants";
import { partnerSelector, planSelector } from "@dashboard/config/redux";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { Feature } from "@dashboard/constants/features";
import { useAnalytics } from "@dashboard/common/analytics";
import { HomeEvent, HomeEventSubtype } from "../constants/home.events";
import { actions as globalActions } from "@dashboard/config/redux/global.slice";
import { CoreEvents } from "@dashboard/constants/events";
import { Plan } from "@dashboard/constants/plan";
import { fetchAddonInfo } from "@dashboard/services/addon-info";

export const useHome = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const partner = useSelector(partnerSelector);
  const plan = useSelector(planSelector);
  const banners = useSelector(homeBannersSelector);
  const statistics = useSelector(homeStatisticsSelector);
  const productsQuantity = useSelector(homeProductsQuantitySelector);
  const newOrders = useSelector(homeNewOrdersSelector);
  const learnings = useSelector(homeLearningsSelector);
  const fakeDoors = useSelector(homeFakeDoorsSelector);
  const { isAvailable: isAbandonedCartAddonAvailable } = useFeatureAvailability(Feature.AbandonedCart, true);
  const { isLoading, statisticsFilter, showInConstruction, showUltraHook } = useSelector(homeUiSelector);
  const [accessToken] = useLocalStorage('accessToken', null);

  const filteredStatistics = useMemo(() => {
    return {
      visits: {
        value: statistics?.[`count_visit_${statisticsFilter}`] || 0,
        percentage: statistics?.[`visit_${statisticsFilter}_percentage`] || 0,
      },
      orders: {
        value: statistics?.[`count_orders_${statisticsFilter}`] || 0,
        percentage: statistics?.[`orders_${statisticsFilter}_percentage`] || 0,
      },
      ordersUnits: {
        value: statistics?.[`count_orders_units_${statisticsFilter}`] || 0,
        percentage: statistics?.[`orders_units_${statisticsFilter}_percentage`] || 0,
      },
      ordersTotal: {
        value: statistics?.[`orders_total_${statisticsFilter}`] || 0,
        percentage: statistics?.[`orders_total_${statisticsFilter}_percentage`] || 0,
      },
      abandonedCarts: {
        value: statistics?.[`count_abandoned_cart_${statisticsFilter}`] || 0,
        percentage: statistics?.[`abandoned_cart_${statisticsFilter}_percentage`] || 0,
      },
    };
  }, [statistics, statisticsFilter]);

  const handleToggleShowInConstruction = () => {
    dispatch(homeActions.setShowInConstruction(!showInConstruction));
  };

  const handleToggleShowUltraHook = () => {
    dispatch(homeActions.setShowUltraHook(!showUltraHook));
  };

  const handleStatisticsFilterChange = (filter: StatisticsFilter) => {
    dispatch(homeActions.setStatisticsFilter(filter));
  };

  const fetchHomeData = () => {
    if (!accessToken) return;
    dispatch(homeActions.setLoading(true));
    Promise.all([
      fetchHomeStatistics(accessToken),
      fetchHomeConfig(accessToken),
    ])
      .then(([
        statisticsResponse,
        configResponse,
      ]) => {
        dispatch(homeActions.setNewOrders(statisticsResponse?.new_orders));
        dispatch(homeActions.setProductsQuantity(statisticsResponse?.product_count?.count));
        dispatch(homeActions.setStatistics(statisticsResponse?.statistics));
        dispatch(homeActions.setBanners(configResponse?.banners));
        dispatch(homeActions.setLearnings(configResponse?.learnings));
        dispatch(homeActions.setFakeDoors(configResponse?.fake_doors));
      })
      .finally(() => {
        dispatch(homeActions.setLoading(false));
      });
  };

  const goToProducts = () => {
    navigate(`/${partner?.userId}/products`);
  }

  const goToOrders = () => {
    navigate(`/${partner?.userId}/orders`);
  }

  const goToAbandonedCarts = () => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.HomeMainButtons,
      option: 'abandoned_cart',
    });
    navigate(`/${partner?.userId}/abandoned-carts`);
    if (
      !isAbandonedCartAddonAvailable
    ) {
      logEvent('system_expert_warning', {
        event_subtype: 'abandoned_cart',
        source: 'addon',
        legacy_event: 'Home - Sumer Web',
      });
      if (!!partner && !!accessToken)
        fetchAddonInfo(
          accessToken,
          partner?.userDetail.country,
          Feature.AbandonedCart
        )
          .then((r) => {
            if (r) dispatch(globalActions.setToBuyAddonInfo(r));
            dispatch(globalActions.setShowAddonHook(true));
          })
          .catch();
    }
  };

  const goToYoutubeSumer = () => {
    window.open('https://www.youtube.com/@Sumerapp', '_blank');
  };

  const handleOnStatsClick = () => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.HomeMainButtons,
      option: 'statistics',
    });
    if ([Plan.FREE, Plan.BASIC, Plan.PRO]
        .map(i => i.toLowerCase())
        .includes(plan?.benefit_plan_name.toLowerCase() || '')
      ) {
      logEvent(CoreEvents.SystemExpertWarning, {
        event_subtype: 'statistics',
        source: 'hook',
        method: plan?.benefit_plan_name.toLowerCase() === 'free' ? 'free' : 'upgrade',
        legacy_event: 'Home - Sumer Web',
      });
      dispatch(globalActions.showCustomPlanHook(Feature.Statistics));
      // handleToggleShowUltraHook();
    } else {
      handleToggleShowInConstruction();
    }
  };

  const handleProductsClick = () => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.HomeMainButtons,
      option: 'products',
    });
    goToProducts();
    if (['free', 'basic'].includes(plan?.benefit_plan_name.toLowerCase() || '')) {
      logEvent('system_expert_warning', {
        event_subtype: 'products',
        source: 'hook',
        method: plan?.benefit_plan_name.toLowerCase() === 'free' ? 'free' : 'upgrade',
        legacy_event: 'Home - Sumer Web',
      });
    }
  };

  const handleOnOrdersClick = () => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.HomeMainButtons,
      option: 'orders',
    });
    goToOrders();
    if (['free', 'basic', 'legacy'].includes(plan?.benefit_plan_name.toLowerCase() || '')) {
      logEvent('system_expert_warning', {
        event_subtype: 'orders',
        source: 'hook',
        method: plan?.benefit_plan_name.toLowerCase() === 'free' ? 'free' : 'upgrade',
        legacy_event: 'Home - Sumer Web',
      });
      handleToggleShowUltraHook();
    }
  };

  const handleGoToPlansPage = () => {
    navigate(`/${partner?.userId}/plans`);
  };

  const handleFakeDoorClick = (fakeDoor: HomeFakeDoor) => {
    logEvent(CoreEvents.SelectButton, {
      event_subtype: "web_fake_doors",
      option: fakeDoor.name.toLowerCase(),
    });
    if (!fakeDoor.route) {
      handleToggleShowInConstruction();
      return;
    }
    if (fakeDoor.isExternalRoute) {
      window.open(fakeDoor.route, '_blank');
    } else {
      navigate(`/${partner?.userId}/${fakeDoor.route}`);
    }
  };

  return {
    fakeDoors,
    isLoading,
    banners,
    statisticsFilter,
    filteredStatistics,
    productsQuantity,
    newOrders,
    learnings,
    showInConstruction,
    handleToggleShowInConstruction,
    handleOnStatsClick,
    showUltraHook,
    handleToggleShowUltraHook,
    handleProductsClick,
    fetchHomeData,
    handleOnOrdersClick,
    goToProducts,
    goToOrders,
    goToAbandonedCarts,
    goToYoutubeSumer,
    handleStatisticsFilterChange,
    handleGoToPlansPage,
    handleFakeDoorClick,
  };
};


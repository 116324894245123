import { FC } from "react";
import { useSelector } from "react-redux";
import { UiGeneralLoaderWrapper } from "./ui-general-loader.styled";
import loaderImg from "@dashboard/assets/img/loader.png";
import { showGeneralLoaderSelector } from "@dashboard/config/redux";

interface UiGeneralLoaderProps {
  text?: string;
}
 
const UiGeneralLoader: FC<UiGeneralLoaderProps> = ({
  text,
}) => {

  const show = useSelector(showGeneralLoaderSelector);

  return show ? (
    <UiGeneralLoaderWrapper>
      <div className="overlay" />
      <div className="progress">
        <img src={loaderImg} alt="Loader progress" className="img" />
        <div className="logo">
          <span className="icon-isotipo-sumer" />
        </div>
        <div className="text">{text}</div>
      </div>
    </UiGeneralLoaderWrapper>
  ) : null;
}
 
export default UiGeneralLoader;
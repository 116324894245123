import { createSelector } from "@reduxjs/toolkit";

import { ProductsUiState, ProductsDataState } from ".";
import { RootState } from "@dashboard/config/redux";
import { Product } from "../types/products.types";

export const productsUiSelector = createSelector(
  (state: RootState) => state.products.ui,
  (state: ProductsUiState) => state
);

export const productsDataSelector = createSelector(
  (state: RootState) => state.products.data,
  (state: ProductsDataState) => state
);

export const isFetchingOrchestratorSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.isFetchingOrchestrator
);

export const isLoadingProductsFileSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.isLoadingProductsFile
);

export const isProductsFileLoadedSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.isProductsFileLoaded
);

export const loadingProductsProgressSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.loadingProductsProgress
);

export const isLoadingImagesFileSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.isLoadingImagesFile
);

export const isImagesFilesLoadedSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.isImagesFilesLoaded
);

export const loadingImagesProgressSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.loadingImagesProgress
);

export const productsSelector = createSelector(
  productsDataSelector,
  (state: ProductsDataState) =>
    state?.products
      ? [...state.products]?.sort((a: Product, b: Product) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
      : []
);

export const categorySelector = createSelector(
  productsDataSelector,
  (state: ProductsDataState) => state?.categories
);

export const productsUploaderResponseSelector = createSelector(
  (state: RootState) => state.products.data,
  (state: ProductsDataState) => state.productsUploaderResponse
);

export const imagesUploaderResponseSelector = createSelector(
  (state: RootState) => state.products.data,
  (state: ProductsDataState) => state.imagesUploaderResponse
);

export const bulkProductsResultSelector = createSelector(
  (state: RootState) => state.products.data,
  (state: ProductsDataState) => state.bulkProductsResult
);

export const showNotAvailableHookSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.showNotAvailableHook
);

export const initProcessMasiveUploadSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.initProcessMasiveUpload
);

export const actionDiscardProcessUploadSelector = createSelector(
  productsUiSelector,
  (state: ProductsUiState) => state.actionDiscardProcessUpload
);

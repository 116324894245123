import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigurationUiState, initialState } from "./configuration.state";
import { AddonInfo } from "@dashboard/common/types";
import { SearchDomainResult } from "../entities/search-domain.entity";
import { DomainInfo } from "../entities/domain-state.entity";
import { PaymentGatewayErrorAuth, PaymentGatewayOptions } from "../entities/payment-gateway.entity";

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setUi: (state, action: PayloadAction<Partial<ConfigurationUiState>>) => {
      state.ui = { ...state.ui, ...action.payload };
    },
    setDomainState: (state, action: PayloadAction<DomainInfo>) => {
      state.data.domainState = action.payload;
    },
    setAddonInfo: (state, action: PayloadAction<AddonInfo>) => {
      state.data.addonInfo = action.payload;
    },
    setSearchDomainResults: (state, action: PayloadAction<SearchDomainResult[]>) => {
      state.data.searchDomainResults = action.payload;
    },
    reset: () => initialState,
    setPaymentGateway: (state, action: PayloadAction<PaymentGatewayOptions[]>) => {
      state.data.paymentGateway = action.payload;
    },
    setPaymentGatewayErrorAuth: (state, action: PayloadAction<PaymentGatewayErrorAuth | undefined>) => {
      state.data.paymentGatewayErrorAuth = action.payload;
    },
  },
});

export const configurationActions = configurationSlice.actions;
export default configurationSlice.reducer;

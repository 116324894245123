import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  actions,
  customPlanHookFeatureSelector,
  customPlanHooksSelector,
  partnerSelector,
  planSelector,
  plansSelector,
  showCustomPlanHookSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import { useFormatters } from "@dashboard/hooks/use-formatters.hook";
import { useNavigate } from "react-router-dom";
import { Plan } from "@dashboard/constants/plan";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { useTranslation } from "react-i18next";
import { ToBuyItemPlan } from "@dashboard/modules/checkout/entities/to-buy-item.entity";
import Hook, { HOOK_IMAGES_VERTICAL, HookType } from "@sumerkit/ui/hook";

interface CustomPlanHookProps {}

const CustomPlanHook: FC<CustomPlanHookProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatCurrency } = useFormatters();
  const partner = useSelector(partnerSelector);
  const userPlan = useSelector(planSelector);
  const plans = useSelector(plansSelector);
  const customHooks = useSelector(customPlanHooksSelector);
  const showCustomPlanHook = useSelector(showCustomPlanHookSelector);
  const customFeature = useSelector(customPlanHookFeatureSelector);

  const hook = customHooks.find((hook) => customFeature === hook.feature);

  const plansFiltered = useMemo(() => {
    let filteredPlans = plans.filter((plan) => plan.name === "Mensual");
    if (!filteredPlans.length) {
      filteredPlans = plans.filter((plan) => plan.name === "Anual");
    }
    if (userPlan?.benefit_plan_name === Plan.FREE) {
      return filteredPlans;
    } else if (userPlan?.benefit_plan_name === Plan.BASIC) {
      return filteredPlans.filter(
        (plan) => plan.additional_data.plan_type !== Plan.BASIC
      );
    } else if (userPlan?.benefit_plan_name === Plan.PRO) {
      return filteredPlans.filter(
        (plan) => plan.additional_data.plan_type === Plan.ULTRA
      );
    } else {
      return filteredPlans;
    }
  }, [plans, userPlan]);

  const plansToShow = useMemo(() => {
    return plansFiltered.map((plan) => {
      let priceFormat = `${formatCurrency(
        plan.cart?.total_value || 0
      )}<small> / ${t("hookAgileConversion.month")}</small>`;
      if (
        plan.cart?.additional_information?.upcoming_invoice_v2
          ?.use_upcoming_invoice_values
      ) {
        priceFormat = (
          plan.cart.additional_information.upcoming_invoice_v2.internal_text ||
          ""
        )
          .replace(
            ":price",
            String(
              formatCurrency(
                plan.cart.additional_information.upcoming_invoice_v2
                  .total_value || 0
              )
            )
          )
          .replace(
            ":days",
            String(
              plan.cart.additional_information.upcoming_invoice_v2.enjoyed_days
            )
          );
      }
      return { ...plan, priceFormat };
    });
  }, [plansFiltered]);

  const handleClose = () => {
    dispatch(actions.hideCustomPlanHook());
  };

  const handleGoToCheckout = (plan?: string) => {
    if (plan) {
      dispatch(
        checkoutActions.setToBuyItem({
          type: "plan",
          plan_name: plan as ToBuyItemPlan,
          plan_duration: "Mensual",
        })
      );
      navigate(
        `/${partner?.userId}/checkout?source=hook&feature=${customFeature}`
      );
      handleClose();
    }
  };

  const handleSecondaryCtaClick = () => {
    navigate(`/${partner?.userId}/plans?source=hook&feature=${customFeature}`);
    handleClose();
  };

  return showCustomPlanHook && !!hook ? (
    <Hook
      show
      title={hook.title || t("hookAgileConversion.title")}
      description={hook.description || t("hookAgileConversion.copy")}
      image={hook.image || HOOK_IMAGES_VERTICAL.IMAGE_4}
      type={HookType.OPTIONS}
      options={plansToShow.map((plan) => ({
        value: plan.type,
        label: (
          <>
            {t("hookAgileConversion.plan", { plan: plan.type })}{" "}
            <p dangerouslySetInnerHTML={{ __html: plan.priceFormat }} />
          </>
        ),
      }))}
      primaryOptionLabel={t("hookAgileConversion.confirm")}
      secondaryOptionLabel={t("hookAgileConversion.plans")}
      onClickPrimaryOptionLabel={(plan) => handleGoToCheckout(plan as string)}
      onClickSecondaryOptionLabel={handleSecondaryCtaClick}
      onClose={handleClose}
    />
  ) : null;
};

export default CustomPlanHook;

import {
  DeeplinksModuleData,
  DeeplinksModules,
} from "@dashboard/entities/deeplinks.types";

export const DEEPLINKS_QUERYPARAM = "deep_link_value";
export const DEEPLINKS_SESSIONS = {
  DEEPLINK: "deeplink",
  DEEPLINK_DATA: "deeplink-data",
};

export const DEEPLINKS_MODULES: DeeplinksModules = {
  "prime-pay-preview": "/checkout",
  "evo-message": "",
  "add-or-edit-product-screen": "/products",
  "personalization": "/personalization",
  "orders": "/orders",
  "own-domain": "/configuration/domain",
  "set-up-payments": "/configuration/pasarelas",
  "addon-invoice": "/voucher",
  "add-ons": "/contact-button",
  "abandoned-cart-screen": "/abandoned-carts",
  "profile": "/profile",
};

export const DEEPLINKS_MODULES_DATA: DeeplinksModuleData = {
  "prime-pay-preview": [
    "benefitName",
    "durationTag",
    "couponCode",
    "forcePayment",
  ],
  "evo-message": ["message"],
  "add-or-edit-product-screen": [],
  "personalization": [],
  "orders": [],
  "own-domain": [],
  "set-up-payments": [],
  "addon-invoice": [],
  "add-ons": [],
  "abandoned-cart-screen": [],
  "profile": [],
};

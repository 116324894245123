import styled from "styled-components";

export const StatsWrapper = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  position: relative;
  .icon-arrow {
    top: 20px;
    right: 12px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    position: absolute;
    margin: 0 0 0 12px;
    transform: rotate(-90deg);
    color: var(--grey-3, #3E434E);
  }
  .stats-grid, .abandoned-cart, .products {
    width: 100%;
    display: flex;
    padding: 20px;
    row-gap: 36px;
    flex: 0 0 auto;
    flex-wrap: wrap;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    align-items: center;
    background: var(--grey-w, #FFF);
    border: 1px solid var(--grey-7, #E9E9ED);
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18), 2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    }
    .item {
      width: 50%;
      flex: 0 0 auto;
      position: relative;
      &:nth-child(even) {
        padding-left: 30px;
        &::before {
          top: 0;
          left: 0px;
          width: 1px;
          content: "";
          height: 100%;
          position: absolute;
          background: var(--grey-7, #E9E9ED);
        }
      }
    }
  }
  .abandoned-cart, .products {
    flex: 0 0 auto;
    width: calc(50% - 10px);
  }
`;

export const configuration = {
  breadcrumb: {
    configuration: 'Configuración',
    domain: 'Dominio',
    payments: 'Pagos',
    connect: 'Conecta {{gateway}}'
  },
  menu: {
    personalizeTitle: 'Personalizar tu tienda',
    personalizeDescription: 'Configura y personaliza el diseño de tu tienda',
    paymentsTitle: 'Configura tus pagos',
    paymentsDescription: 'Configura tus pagos',
    domainTitle: 'Cambiar URL de la tienda',
    domainDescription: 'Configura y adquiere el dominio para tu tienda',
  },
  domain: {
    placeholder: 'busca tu dominio',
    activeDomain: 'Active Domain',
    activeDomainTitle: 'Edita tu URL',
    activeDomainDescription: 'Este es tu URL integrada de Sumer. Siempre podrás acceder a tu sitio desde este link y no caduca nunca.',
    integratedUrl: 'URL integrada',
    editButton: 'Editar',
    saveChanges: 'Guardar cambios',
    hookTitle: '¡Obtén ésta funcionalidad!',
    hookDescription: 'Mejora tu plan ahora para tener ésta y más funcionalidades.',
    hookConfirmLabel: 'Obtener ahora',
    help: 'Necesito ayuda con mi dominio propio',
    copied: 'Link copiado al portapapeles',
    getYourOwnDomain: 'Ten tu dominio hoy mismo',
    buyDomain: 'Comprar dominio',
    promo: 'Obtén hasta un <strong>30%</strong> de descuento en la compra de tu dominio por ser parte de Sumer premium.',
    transferDomain: '¿Ya tienes un dominio? Transfiérelo acá',
    editYourActiveDomain: 'Edita tu dominio activo',
    urlInProcess: 'URL en proceso',
    configuringTitle: '¡Estamos configurando tu dominio!',
    configuringDescription: 'Esta configuración puede tomar hasta 1 día en verse reflejada.',
    configuringDisclaimer: 'Una vez comprado el dominio no puede ser modificado ni desvinculado de tu tienda.',
    configuredCongrats: '¡Felicitaciones! Ahora tienes dominio propio',
    activeUntil: 'Activo hasta',
    share: 'Compartir',
    copy: 'Copiar',
    seeStore: 'Ver tienda',
    needHelp: '¿Necesitas ayuda?',
    helpDisclaimer: 'Una vez comprado el dominio no puede ser modificado ni desvinculado de tu tienda.',
    search: {
      title: 'Encuentra tu dominio perfecto',
      description: 'Para tener un buen dominio no excedas los 20 caracteres, evitar caracteres especiales y evita números.',
      search: 'Buscar',
      notAvailable: 'No disponible',
      noAvailable: 'Este dominio ya está tomado',
      year: '/año',
      otherDomains: 'Otros dominios disponibles',
    },
    labelsBreadcrumbs: {
      configuration: "Configuración",
      domain: "Dominio",
      search: "Buscar dominio",
    }
  },
  paymentGateway: {
    info: {
      title: 'Activa formas de pago en línea',
      description: 'Elija un proveedor de pagos de nuestra lista',
      buttonConnect: 'Conectar'
    },
    loader: {
      step1: "Estamos conectando con {{gateway}}",
      step2: "¡Gracias por registrarte! Tu conexion con {{gateway}} fue exitosa",
      step2Error: "Tu conexion con {{gateway}} no fue exitosa"
    },
    errorAuth: {
      title: '¡Problema al activar metodo de pago!',
      description: 'No se pudo realizar la conexión',
      retryButton: 'Volver a intentar',
      closeButton: 'Cerrar'
    },
    mercadoPago: {
      title: 'Conecta Mercado Pago',
      cardCredentials: {
        description: 'Ingresa tus credenciales de Mercado Pago (<strong>Public Key y AccesToken</strong>) para conectar tu cuenta.',
        link: '¿Dónde encuentro esta información?'
      },
      cardDetails: {
        title: 'Detalles de la integración',
        description: 'Recibe todos los métodos de pago'
      },
      alert: {
        link: 'Cambia el precio de tus productos'
      },
      cardAccountInfo: {
        title: '¿No tienes una cuenta de Mercado Pago? <a href="https://www.mercadopago.com.co/hub/registration/splitter?contextual=normal&entity=pf&redirect_url=https://www.mercadopago.com.co/cuenta" target="_blank"><u>Créala aquí</u></a>',
        infoOne: 'El cobro de tarifas depende de la negociación entre el usuario y Mercado pago. Sumer no es intermediario de cobros' +
                 ' <a href="https://www.mercadopago.com.co/ayuda/Cu-nto-cuesta-recibir-pagos_220" target="_blank"><strong><u>Conoce las tarifas de mercado pago</u></strong></a>',
        infoTwo: 'La información suministrada es tratada de forma confidencial y solo será utilizada para conectar tu cuenta de Mercado Pago con tu cuenta Sumer.'
      },
      form: {
        title: 'Ingresar credenciales',
        publicKeyField: 'Public Key',
        accessTokenField: 'Access Token',
        activateButton: 'Activar mercado pago',
        validations: {
          clientId: 'Ingresa la Public Key',
          clientSecret: 'Ingresa el Access Token'
        }
      }
    },
    popups: {
      unlink: {
        title: '¿Desvincular {{gateway}}?',
        description: '¿Deseas desvincular tu cuenta de {{gateway}} y quitar este método de pago de tu tienda?',
        buttonText: 'No, volver',
        linkText: 'Sí, desvincular {{gateway}}'
      },
      deactivate: {
        title: '¿Desactivar temporalmente?',
        description: '¿Deseas desactivar temporalmente tu cuenta de {{gateway}}? Tus clientes no podrán usar este método de pago. Puedes reactivarlo cuando desees.',
        buttonText: 'No, volver',
        linkText: 'Sí, desactivar {{gateway}}'
      },
      info: {
        description:
          '1. Inicia sesión desde un computador en tu cuenta de Mercado Pago en <a href="https://www.mercadopago.com" target="_blank"><u>https://www.mercadopago.com</u></a> <br />' +
          '2. En el menú lateral izquierdo, haz clic en la opción "Tu negocio". <br />' +
          '3. Se desplegarán una serie de opciones, haz clic en la opción "Configuración". <br />' +
          '4. En "Gestión y administración", haz clic en la opción “Credenciales”. <br />' +
          '5. Haz clic en la opción "Credenciales de producción". <br />' +
          "6. Copia y pega tu Public key y Access token en los espacios correspondientes. <br />" +
          "7. Guarda tus credenciales de forma segura. <br />" +
          '8. Recuerda que estos datos son confidenciales y deben tratarse con cuidado. Para más detalles, consulta la ' + 
          '<a href="https://www.mercadopago.com.ar/developers/es/docs/resources-faqs/credentials" target="_blank"><u>documentación oficial de Mercado Pago</u></a> o ponte en contacto con su servicio de atención al cliente.'
      }
    }
  }
};

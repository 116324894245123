import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getEnvVar } from "@dashboard/common/utils";
import { partnerSelector } from "@dashboard/config/redux";
import {
  personalizationActions,
  personalizationChangesSelector,
} from "../store";

export const usePersonalization = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partner = useSelector(partnerSelector);
  const pendingChanges = useSelector(personalizationChangesSelector);
  const catalogueId = partner?.userId || id;
  const domain =
    getEnvVar("REACT_APP_ENV") === "local" ? "https://dev.sumerlabs.com" : "";

  const gotToCheckout = () => {
    navigate(`/${partner?.userId}/plans`);
  };

  const checkForPendingChanges = (onAction: () => void) => {
    if (pendingChanges) {
      dispatch(
        personalizationActions.requestExit({
          exit: true,
          onAction,
        })
      );
    } else {
      onAction();
    }
  };

  return {
    domain,
    catalogueId,
    gotToCheckout,
    checkForPendingChanges,
  };
};

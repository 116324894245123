import { getApiUrl } from "@dashboard/common/utils";
import { Plan } from "../entities/premium.entities";


export const fetchAllPlans = (country: string, coupon: string, token: string): Promise<Plan[]> => {
  const language = localStorage.getItem('i18nextLng') || 'es';
  const settings = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key-sumer": process.env.REACT_APP_KEY,
      'Authorization': `Bearer ${token}`,
      "Accept-Language": language,
    } as any,
    body: JSON.stringify({
      'coupon_code': coupon,
      "screen_view": "onboarding_view_plans_v2"
    }),
  };
  return fetch(`${getApiUrl()}/api/ms/prime/membership/${country}/calculate?with-benefits=true`, settings)
    .then(response => {
      if (response.status !== 200) throw new Error("Error fetching plans");
      return response.json();
    });
};

export const createPlanPurchase = (
  token: string, plan: Plan, coupon: string = '', successUrl: string = '',
): Promise<{ url?: string }> => {
  return new Promise(async (resolve, reject) => {
    if (!token) reject("Error with token or id");
    const settings = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key-sumer": process.env.REACT_APP_KEY,
        "Authorization": `Bearer ${token}`,
      } as any,
      body: JSON.stringify({
        coupon_code: coupon, 
        callback_success: successUrl,
      }),
    };
    const onboardingPlanResponse = await fetch(`${getApiUrl()}/api/ms/prime/order-membership-payment/onboarding`, settings)
      .then(response => {
        if (response.status !== 200) return null;
        return response.json();
      }).catch(() => null);
      if (onboardingPlanResponse === null || onboardingPlanResponse?.total_value !== 0) {
        const r = await fetch(`${getApiUrl()}/api/ms/prime/order-membership-payment/${plan.id}`, settings)
          .then(r => r.status === 200 ? r.json() : null)
          .catch(() => null);
        if (!!r) resolve({ url: r.url || r.url_stripe });
        else reject('Error')
      } else {
        resolve({ url: 'dashboard' });
      }
  });
};

const FolderStarLight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9375 7.63477C2.9375 7.32411 3.18934 7.07227 3.5 7.07227H20.75C21.0981 7.07227 21.4319 7.21055 21.6781 7.45669C21.9242 7.70283 22.0625 8.03667 22.0625 8.38477V11.3848C22.0625 11.6954 21.8107 11.9473 21.5 11.9473C21.1893 11.9473 20.9375 11.6954 20.9375 11.3848V8.38477C20.9375 8.33504 20.9177 8.28735 20.8826 8.25218C20.8474 8.21702 20.7997 8.19727 20.75 8.19727H4.0625V18.941C4.0625 18.9583 4.06589 18.9753 4.07249 18.9912C4.07909 19.0072 4.08876 19.0216 4.10094 19.0338C4.11313 19.046 4.1276 19.0557 4.14352 19.0623C4.15945 19.0689 4.17651 19.0723 4.19375 19.0723H11.8062C12.1169 19.0723 12.3687 19.3241 12.3687 19.6348C12.3687 19.9454 12.1169 20.1973 11.8062 20.1973H4.19375C4.02878 20.1973 3.86542 20.1648 3.713 20.1016C3.56059 20.0385 3.4221 19.946 3.30545 19.8293C3.18879 19.7127 3.09626 19.5742 3.03313 19.4218C2.96999 19.2693 2.9375 19.106 2.9375 18.941V7.63477Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 5.19727C4.14645 5.19727 4.0625 5.28121 4.0625 5.38477V7.63477C4.0625 7.94543 3.81066 8.19727 3.5 8.19727C3.18934 8.19727 2.9375 7.94543 2.9375 7.63477V5.38477C2.9375 4.65989 3.52513 4.07227 4.25 4.07227H9.18934C9.53744 4.07227 9.87128 4.21055 10.1174 4.45669L12.8977 7.23702C13.1174 7.45669 13.1174 7.81284 12.8977 8.03251C12.6781 8.25218 12.3219 8.25218 12.1023 8.03251L9.32192 5.25218C9.28676 5.21702 9.23907 5.19727 9.18934 5.19727H4.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.125 11.5723C18.3497 11.5723 18.5529 11.706 18.6417 11.9124L19.7543 14.4972L22.6687 14.724C22.8973 14.7417 23.0922 14.8965 23.1613 15.1152C23.2305 15.3338 23.16 15.5725 22.9832 15.7185L20.7814 17.5367L21.4553 20.2491C21.5099 20.469 21.4273 20.7002 21.2457 20.8356C21.0642 20.9711 20.819 20.9844 20.6238 20.8694L18.125 19.397L15.6262 20.8694C15.431 20.9844 15.1859 20.9711 15.0043 20.8356C14.8227 20.7002 14.7401 20.469 14.7948 20.2491L15.4687 17.5367L13.2669 15.7185C13.0901 15.5725 13.0196 15.3338 13.0887 15.1152C13.1578 14.8965 13.3528 14.7417 13.5814 14.724L16.4958 14.4972L17.6084 11.9124C17.6972 11.706 17.9003 11.5723 18.125 11.5723ZM18.125 13.5575L17.3948 15.254C17.3122 15.4459 17.1301 15.5762 16.9218 15.5924L15.0568 15.7376L16.4582 16.8948C16.6251 17.0326 16.6981 17.254 16.6459 17.4642L16.2097 19.2198L17.8395 18.2595C18.0157 18.1557 18.2344 18.1557 18.4106 18.2595L20.0403 19.2198L19.6041 17.4642C19.5519 17.254 19.6249 17.0326 19.7919 16.8948L21.1933 15.7376L19.3283 15.5924C19.12 15.5762 18.9378 15.4459 18.8552 15.254L18.125 13.5575Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FolderStarLight;

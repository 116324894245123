import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExitItems, initialState } from "./personalization.state";

export const personalizationSlice = createSlice({
    name: "personalization",
    initialState,
    reducers: {
        enablePendingChanges: (state) => {
            state.pendingChanges = true;
        },
        disablePendingChanges: (state) => {
            state.pendingChanges = false;
        },
        requestExit: (state, { payload }: PayloadAction<ExitItems>) => {
            state.requestExit = payload;
        },
    }
});

export const personalizationActions = personalizationSlice.actions;
export const personalizationReducer = personalizationSlice.reducer;
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const ButtonCss = css`
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  color: #24272e;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1em;
  min-height: 48px;
  padding: 0.5rem 1rem;
  width: 100%;

  svg {
    height: 24px;
    width: 24px;
  }
  [class^="icon"] {
    font-size: 1.875em;
  }

  &.button__primary {
    background-color: #24272e;
    border-color: #24272e;
    color: #ffffff;
    font-weight: 600;
  }
  &.button__dark {
    background-color: #24272e;
    border-color: #24272e;
    color: #ffffff;
    font-weight: 600;
  }
  &.button__danger {
    background-color: #f93c00;
    border-color: #f93c00;
    color: #ffffff;
    font-weight: 600;
  }
  &.button__outline {
    background: transparent;
    color: #24272e;
    font-weight: 400;
    &.button__primary {
      color: #24272e;
    }
    &.button__dark {
      color: #24272e;
    }
    &.button__danger {
      color: #f93c00;
    }
  }
  &.button__link {
    background: transparent;
    border: 0;
    color: #24272e;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    &.button__dark {
      color: #24272e;
    }
    &.button__danger {
      color: #f93c00;
    }
  }
  &.button__short {
    font-size: 0.875rem;
    min-height: 40px;
    width: auto;
    svg {
      height: 20px;
      width: 20px;
    }
    [class^="icon"] {
      font-size: 1.375em;
    }
  }
  &:disabled {
    background-color: #e9e9ed;
    border-color: #e9e9ed;
    color: #b0b2b6;
  }
`;

export const ButtonStyled = styled.button`
  ${ButtonCss}
`;

export const ButtonLinkStyled = styled(Link)`
  ${ButtonCss}
`;

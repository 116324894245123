import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoginSelectOptionWrapper } from "./login-select-option.styled";
import logoSumerImg from "@dashboard/assets/img/logo-sumer.png";
import LoginOptions from "../login-options/login-options";

interface LoginSelectOptionProps {
  descriptionText?: string;
}

const LoginSelectOption: FC<LoginSelectOptionProps> = ({ descriptionText }) => {
  const { t } = useTranslation();

  return (
    <LoginSelectOptionWrapper>
      <div className="login-texts-container">
        <img src={logoSumerImg} alt="Sumer" className="logo" />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html:
              descriptionText || t("onboarding.login.selectOption.description"),
          }}
        />
      </div>
      <div className="login-options-container">
        <div className="container-title">
          <div className="title">
            {t("onboarding.login.selectOption.title")}
          </div>
          <hr className="line" />
        </div>
        <LoginOptions />
      </div>
    </LoginSelectOptionWrapper>
  );
};

export default LoginSelectOption;

import styled from "styled-components";

export const SplashStyled = styled.div`
  align-items: center;
  background: url(https://sumerlabs.com/prod/assets/sumerweb/ai/evo-bg.webp)
    no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
  img {
    height: 180px;
    object-fit: contain;
    width: 180px;
  }
`;

import { useDispatch, useSelector } from "react-redux";
import { partnerSelector } from "@dashboard/config/redux";
import { useLocalStorage } from "@sumerlabs/component-library";
import { PersonalizationViewMode } from "../constants/personalization.constants";
import { actions } from "../store/onboarding.slice";
import { personalizationViewModeSelector } from "../store/onboarding.selectors";
import { getEnvVar } from "@dashboard/common/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const usePersonalization = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const partner = useSelector(partnerSelector);
  const personalizationViewMode = useSelector(personalizationViewModeSelector);
  const [token] = useLocalStorage('accessToken', null);
  const { i18n } = useTranslation();

  const catalogueId = partner?.userId || id;

  const domain = getEnvVar('REACT_APP_ENV') === 'local' ? 'https://dev.sumerlabs.com' : '';
  const customizationDesktopUrl = (!!catalogueId) ?
    `${domain}/customization/${token}/${catalogueId}/customize/onb-desktop-web/init?lang=${i18n.language}` : '';
  const customizationMobileUrl = (!!catalogueId) ?
    `${domain}/customization/${token}/${catalogueId}/customize/onb-mobile-web/init?lang=${i18n.language}` : '';

  const changePersonalizationViewMode = (viewMode: PersonalizationViewMode) => {
    dispatch(actions.setPersonalizationViewMode(viewMode));
  };

  return {
    customizationDesktopUrl,
    customizationMobileUrl,
    personalizationViewMode,
    changePersonalizationViewMode,
  };
};

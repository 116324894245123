import { Modal } from "@sumerlabs/component-library";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeleteProductsConfirmStyled } from "./delete-products-confirm.styled";
import { Product } from "../../types/products.types";
import TrashSimple from "@sumerkit/icons/trash-simple";
import Button from "@sumerkit/ui/button";

type DeleteProductsConfirmProps = {
  products?: Product[];
  onContinue?: () => void;
  onClose?: () => void;
};

const DeleteProductsConfirm: FC<DeleteProductsConfirmProps> = ({
  products = [],
  onContinue,
  onClose,
}) => {
  const { t } = useTranslation();

  const isMultipleDelate = useMemo(
    () => products && products.length > 1,
    [products]
  );

  const handleClose = () => {
    onClose && onClose();
  };

  const handleContinue = () => {
    onContinue && onContinue();
  };

  return (
    <Modal
      title=""
      show={true}
      onClose={handleClose}
      content={{
        className: "fadeInDown",
        styles: {
          width: "520px",
        },
      }}
      header={{
        styles: {
          borderBottom: 0,
          boxShadow: "none",
          paddingBottom: 0,
          paddingLeft: "2rem",
        },
      }}
      body={{
        styles: {
          padding: "2rem",
          paddingTop: 0,
        },
      }}
    >
      <DeleteProductsConfirmStyled>
        <div className="delete-confirm-icon">
          <TrashSimple width={60} height={60} />
        </div>
        <h4 className="delete-confirm-title">
          {isMultipleDelate
            ? t("products.deleteProduct.multiple.title")
            : t("products.deleteProduct.title")}
        </h4>
        <p className="delete-confirm-copy">
          {isMultipleDelate
            ? t("products.deleteProduct.multiple.copy", {
                count: products.length,
              })
            : t("products.deleteProduct.copy")}
        </p>
        <div className="delete-confirm-options">
          <Button
            appearance="secondary"
            size="small"
            fullWidth
            onClick={handleContinue}
          >
            {t("products.deleteProduct.confirm")}
          </Button>
          <Button
            appearance="tertiary"
            size="small"
            fullWidth
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </div>
      </DeleteProductsConfirmStyled>
    </Modal>
  );
};

export default DeleteProductsConfirm;

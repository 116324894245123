import { FC } from "react";
import { useContactButton } from "../../hooks/contact-button.hook";
import ContactButtonConfiguredWrapper from "./contact-button-configured.styled";

interface ContactButtonConfiguredProps {
  
}

const ContactButtonConfigured: FC<ContactButtonConfiguredProps> = () => {
  
  const { buttonConfig } = useContactButton();

  return (
    <ContactButtonConfiguredWrapper
      className={`
        ${buttonConfig.wp_contact?.configuration?.is_hidden && "hide"}
        ${buttonConfig.wp_contact?.position?.horizontal || ""}
        ${buttonConfig.wp_contact?.position?.vertical || ""}
      `}
      style={{
        backgroundColor: buttonConfig.wp_contact?.colors?.background,
        color: buttonConfig.wp_contact?.colors?.icon,
      }}
    >
      <span className={`icon-${buttonConfig.wp_contact?.icon}`} />
    </ContactButtonConfiguredWrapper>
  );
};

export default ContactButtonConfigured;

import styled from "styled-components";

export const StepStoreWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 0px 116px;
  height: 100vh;
  align-items: center;
  .description {
    display: flex;
    align-items: center;
  }
  .options {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 48px;
    gap: 50px;
    height: fit-content;
    flex-direction: column;
    align-items: flex-end;
    .option {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      flex-shrink: 0;
      border-radius: 16px;
      border: 1px solid var(--Grey-Border, #e9e9ed);
      background: var(--Grey-W, #fff);
      > img {
        height: 142px;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        .title {
          color: var(--Grey-B, #24272e);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
        .description {
          color: var(--Grey-300, #3e434e);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .button {
        width: fit-content;
      }
    }
  }
`;

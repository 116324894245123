import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { LoginOptionsWrapper } from "./login-options.styled";
import googleImg from "@dashboard/assets/img/google-logo.png";
import facebookImg from "@dashboard/assets/img/facebook-logo.png";
import { FACEBOOK_APP_ID, LoginStep } from "../../../shared/components/onboarding-shared/constants/auth.constants";
import { useAuth } from "../../../shared/components/onboarding-shared/hooks/auth.hooks";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../../shared/components/onboarding-shared/services/auth.services";
import { StorageItem } from "@dashboard/constants/storage-items";
import { actions } from "@dashboard/config/redux";
import { getPartnerByToken } from "@dashboard/services/partner.service";
import { camelizeKeys } from "humps";
import { useAnalytics } from "@dashboard/common/analytics";
import { EVENTS } from "@dashboard/constants/events";
import Stack from "@sumerkit/icons/stack";
import { LoginSource } from "../../../shared/components/onboarding-shared/constants/login-source.constants";

interface LoginOptionsProps {}

const LoginOptions: FC<LoginOptionsProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onboardingSplit = searchParams.get("onboarding");
  const [openOptions, setOpenOptions] = useState(false);
  const { t } = useTranslation();
  const termsLink = "https://sumerlabs.com/terms/terminos-y-condiciones";
  const dataPolicyLink =
    "https://sumerlabs.com/terms/politica-de-proteccion-y-tratamiento-de-datos-personales";
  const { logEvent } = useAnalytics();
  const login = useGoogleLogin({
    onSuccess: async (googleResponse) => {
      try {
        setIsLoading(true);
        const response = await googleLogin(googleResponse.access_token);
        if (!!response) {
          localStorage.setItem(StorageItem.Token, `"${response.access_token}"`);
          getPartnerByToken(response.access_token)
            .then((r) => r.json())
            .then((r) => dispatch(actions.setPartnerData(camelizeKeys(r))))
            .catch()
            .finally(() => {
              navigate(
                `/status${
                  onboardingSplit ? `?onboarding=${onboardingSplit}` : ""
                }`
              );
            });
        }
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    },
    onError: (e) => {
      setIsLoading(false);
    },
  });

  const { isLoading, setIsLoading, loginGoogle, loginFacebook, setUi } =
    useAuth({ useGoogleLoginFn: login });

  const setLoginStep = (loginStep: LoginStep) => {
    setUi({ loginStep });
  };

  const goToActivate = () => {
    loginBy(LoginSource.QRCODE);
    logEvent(EVENTS.SELECT_BUTTON, {
      event_subtype: "login_qr_code",
      source: "login",
    });
    navigate(`/activate`);
  };

  const loginBy = (source: LoginSource) => {
    localStorage.setItem(StorageItem.LoggedBy, source);
  };

  const handleClickOtherOptions = () => {
    setOpenOptions(!openOptions);
  };

  return (
    <LoginOptionsWrapper>
      <button
        disabled={isLoading}
        className="option"
        onClick={() => {
          loginBy(LoginSource.GOOGLE);
          loginGoogle();
        }}
      >
        <img src={googleImg} alt="Google" className="option-logo" />
        {t("onboarding.login.selectOption.withGoogle")}
      </button>
      <button
        disabled={isLoading}
        className="option"
        onClick={() => {
          loginBy(LoginSource.PHONE);
          setLoginStep(LoginStep.SendPhoneCode);
        }}
      >
        <span className="option-icon icon-light-mobile" />
        {t("onboarding.login.selectOption.withPhone")}
      </button>
      <button
        onClick={handleClickOtherOptions}
        className={openOptions ? "option-texts-decoration" : "option"}
      >
        {openOptions ? (
          <div className="texts-decoration-container">
            <hr className="line-decoration" />
            <p className="text-decoration">{t("onboarding.login.selectOption.otherOptions")}</p>
            <hr className="line-decoration" />
          </div>
        ) : (
          <div className="option-others">
            <Stack />
            {t("onboarding.login.selectOption.seeOtherOptions")}
          </div>
        )}
      </button>
      <div
        className={openOptions ? "open-other-options" : "close-other-options"}
      >
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          onSuccess={(r) => {
            loginBy(LoginSource.FACEBOOK);
            loginFacebook(r?.accessToken);
          }}
          onFail={(e) => console.error(e)}
          render={({ onClick }) => (
            <button disabled={isLoading} className="option" onClick={onClick}>
              <img src={facebookImg} alt="Facebook" className="option-logo" />
              {t("onboarding.login.selectOption.withFacebook")}
            </button>
          )}
        />
        <button
          disabled={isLoading}
          className="option"
          onClick={() => {
            loginBy(LoginSource.EMAIL);
            setLoginStep(LoginStep.SendEmailCode);
          }}
        >
          <span className="option-icon icon-email-outline" />
          {t("onboarding.login.selectOption.withEmail")}
        </button>
        <button disabled={isLoading} className="option" onClick={goToActivate}>
          <span className="option-icon icon-link-light" />
          {t("onboarding.login.selectOption.withQr")}
        </button>
      </div>

      <div className="terms">
        {t("onboarding.login.selectOption.terms")}{" "}
        <a className="link-terms" href={termsLink} target="_blank" rel="noreferrer">
          {t("onboarding.login.selectOption.termsLink")}
        </a>{" "}
        {t("onboarding.login.selectOption.and")}{" "}
        <a className="link-terms" href={dataPolicyLink} target="_blank" rel="noreferrer">
          {t("onboarding.login.selectOption.data_policy")}
        </a>{" "}
        {t("onboarding.login.selectOption.sumer")}
      </div>
    </LoginOptionsWrapper>
  );
};

export default LoginOptions;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VoucherUiState, initialState } from "./voucher.state";
import { Voucher } from "../entities/voucher.entity";
import { Product } from "@dashboard/modules/products/types/products.types";

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setUi: (state, { payload }: PayloadAction<Partial<VoucherUiState>>) => {
      state.ui = { ...state.ui, ...payload };
    },
    setVouchers: (state, { payload }: PayloadAction<Voucher[]>) => {
      state.data.vouchers = payload;
    },
    deleteVoucher: (state, { payload }: PayloadAction<Voucher>) => {
      state.data.vouchers = state.data.vouchers.filter(voucher => voucher.id !== payload.id);
    },
    setSelectedVoucher: (state, { payload }: PayloadAction<Voucher | undefined>) => {
      state.data.selectedVoucher = payload;
    },
    setConfiguredVoucher: (state, { payload }: PayloadAction<Partial<Voucher>>) => {
      state.data.configuredVoucher = {
        ...state.data.configuredVoucher,
        ...payload,
      };
    },
    setConfiguredClientVoucher: (state, { payload }: PayloadAction<Partial<Voucher>>) => {
      state.data.configuredVoucher.client = {
        ...state.data.configuredVoucher.client,
        ...payload.client,
      };
    },
    setConfiguredClientPhoneVoucher: (state, { payload }: PayloadAction<Partial<Voucher>>) => {
      if (!!state.data.configuredVoucher.client) {
        state.data.configuredVoucher.client.phone = {
          ...state.data.configuredVoucher.client.phone,
          ...payload.client?.phone,
        };
      } else {
        state.data.configuredVoucher.client = {
          phone: {
            ...payload.client?.phone,
          },
        };
      }
    },
    setConfiguredProductsVoucher: (state, { payload }: PayloadAction<Partial<Voucher>>) => {
      state.data.configuredVoucher.products = payload.products;

      const subtotal = state.data.configuredVoucher.products?.reduce((acc, product) => acc + (product.price * product.quantity), 0) || 0;
      const discountType = state.data.configuredVoucher?.payment?.discountType;
      const discountValue = discountType === 'amount' ?
        (state.data.configuredVoucher?.payment?.discountValue || 0) :
          discountType === 'percentage' ?
            subtotal * ((state.data.configuredVoucher?.payment?.discountPercentage || 0) / 100) : 0;
      const total = subtotal - discountValue;

      state.data.configuredVoucher.payment = {
        ...state.data.configuredVoucher.payment,
        subtotal,
        discountValue,
        total,
      };
    },
    setConfiguredDiscountsVoucher: (state, { payload }: PayloadAction<Partial<Voucher>>) => {
      const discountType = payload.payment?.discountType || state.data.configuredVoucher.payment?.discountType;
      const subtotal = state.data.configuredVoucher.products?.reduce((acc, product) => acc + (product.price * product.quantity), 0) || 0;
      if (discountType === 'percentage') {
        const discountPercentage = (payload.payment?.discountValue || 0) > 100 ? 100 : payload.payment?.discountValue || 0;
        const discountValue = subtotal * discountPercentage / 100;
        const total = subtotal - discountValue < 0 ? 0 : subtotal - discountValue;

        state.data.configuredVoucher.payment = {
          ...state.data.configuredVoucher.payment,
          ...payload.payment,
          discountPercentage,
          discountValue,
          subtotal,
          total,
        };
      } else {
        const discountValue = payload.payment?.discountValue || 0;
        const total = subtotal - discountValue < 0 ? 0 : subtotal - discountValue;
        state.data.configuredVoucher.payment = {
          ...state.data.configuredVoucher.payment,
          ...payload.payment,
          discountPercentage: 0,
          discountValue,
          subtotal,
          total,
        };
      }
    },
    setProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.data.products = payload;
    },
    resetVoucher: (state) => {
      state.data.configuredVoucher = {} as Voucher;
    },
  },
});

export const voucherActions = voucherSlice.actions;
export default voucherSlice.reducer;

import { Breakpoints } from "@sumerlabs/component-library";
import styled from "styled-components";

export const StepPlanSkin2Wrapper = styled.section`
  align-items: center;
  color: #5d606f;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: #ffffff;
  .onboarding-step-header {
    min-height: 48px;
    position: absolute;
  }
  .onboarding-container {
    background-color: #ffffff;
    border-radius: 1.25rem;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    max-width: 632px;
    padding: 2rem;
    width: 100%;
  }
  .onboarding-step-container {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
  }
  .onboarding-step-info {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    font-size: 1.125rem;
    line-height: 1.25em;
    width: 100%;
    img {
      height: 40px;
      object-fit: cover;
      width: 40px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .onboarding-step-title {
    color: #000000;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1em;
    margin: 0;
  }
  .onboarding-step-continue {
    width: 288px;
    [class^="icon"] {
      font-size: 1.25em;
      transform: rotate(-90deg);
    }
    &:hover {
      background-color: #000000;
      color: #ffffff !important;
    }
  }

  @media screen and (min-width: ${Breakpoints.LaptopShort}) {
    .onboarding-container {
      gap: 2rem;
      max-width: 1200px;
    }
    .onboarding-step-container {
      gap: 3rem;
    }
    .onboarding-step-info {
      align-items: center;
      font-size: 1.5rem;
      text-align: center;
      img {
        display: none;
      }
    }
    .onboarding-step-title {
      font-size: 2.125rem;
    }
    .onboarding-step-copy {
      max-width: 558px;
    }
  }
`;

export const OnboardingStepPlans = styled.section`
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${Breakpoints.LaptopShort}) {
    align-items: stretch;
    flex-direction: row;
    justify-content: center;
  }
`;

export const OnboardingStepPlan = styled.article`
  border: 1px solid var(--grey-border, #e9e9ed);
  border-radius: 0.5rem;
  background: var(--grey-w, #fff);
  box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
  color: #24272e;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.75rem;
  padding: 1rem;
  position: relative;
  width: 260px;
  .plan-popular-tag {
    background-color: #24272e;
    border-radius: 0.25rem;
    bottom: 100%;
    color: #ffffff;
    line-height: 1em;
    margin-bottom: -20px;
    padding: 0.25rem 0.5rem;
    position: absolute;
    right: 10px;
    color: var(--Grey-W, #fff);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
  .plan-title,
  .plan-value {
    align-items: center;
    color: #24272e;
    display: flex;
    gap: 0 0.25rem;
    flex-wrap: wrap;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25em;
    .plan-value-without-discount {
      color: #b0b2b6;
      font-size: 0.625rem;
      text-decoration: line-through;
    }
    .plan-value-time__tag {
      color: #24272e;
      font-weight: 400;
      font-size: 0.75rem;
    }
  }
  .plan-description {
  }
  .plan-features {
    color: #5d606f;
    flex: 1;
    font-size: 0.625rem;
    padding: 0.5rem 0.875rem;
    [class^="icon"] {
      font-size: 1.5em;
    }
    .plan-feature {
      align-items: center;
      display: flex;
      gap: 0.5rem;
    }
  }
  .plan-acquire {
    &:active {
      background-color: #3e434e;
      color: #ffffff !important;
    }
  }
  .button-popular {
    background-color: #24272e !important;
    color: #ffffff !important;
    &:active {
      background-color: #3e434e !important;
    }
  }
  &.onboarding-plan__popular {
    margin-top: 10px;
  }
  &:hover {
    border-color: #24272e;
  }
  .title-container {
    width: 60px;
    height: 28.209px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bolder;
    color: white;
    margin-bottom: 8px;
  }
  &.onboarding-plan__YELLOW {
    .title-container {
      background: #f6bf93;
    }
    &.onboarding-plan__popular {
      border: 2px solid #f6bf93;
      .plan-popular-tag {
        background-color: #f6bf93;
      }
    }
    &:hover {
      border: 2px solid #f6bf93;
    }
  }
  &.onboarding-plan__GREEN {
    .title-container {
      background: #3abd77;
    }
    &.onboarding-plan__popular {
      border: 2px solid #3abd77;
      .plan-popular-tag {
        background-color: #3abd77;
      }
    }
    &:hover {
      border: 2px solid #3abd77;
    }
  }
  &.onboarding-plan__PURPLE {
    .title-container {
      background: linear-gradient(90deg, #c143f7 0%, #682ec2 100%);
    }
    &.onboarding-plan__popular {
      border: 2px solid #c143f7;
      .plan-popular-tag {
        background: linear-gradient(90deg, #c143f7 0%, #682ec2 100%);
      }
    }
    &:hover {
      border: 2px solid #c143f7;
    }
  }

  @media screen and (min-width: ${Breakpoints.LaptopShort}) {
    &.onboarding-plan__popular {
      margin-top: 0;
    }
  }
`;

export const OnboardingStepBack = styled.button`
  align-items: center;
  background: var(--grey-w, #fff);
  border: 1px solid #000000;
  border-radius: 0.5rem;
  box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
    2px 4px 10px 0px rgba(53, 53, 53, 0.04);
  display: flex;
  font-size: 1.75rem;
  height: 48px;
  justify-content: center;
  transform: rotate(90deg);
  width: 48px;
`;

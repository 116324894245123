import styled, { css } from "styled-components";

export const FieldTextLabelCss = css<{ labelOutside?: boolean }>`
  background-color: #ffffff;
  color: #5f636c;
  display: -webkit-box;
  font-size: 0.75rem;
  left: 0.5rem;
  overflow: hidden;
  padding: 0.125rem 0.5rem;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  z-index: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${({ labelOutside }) =>
    labelOutside
      ? css`
          background: transparent;
          color: #24272e;
          font-size: 1rem;
          font-weight: 600;
          left: 0;
          margin-bottom: 0.25rem;
          padding: 0;
          position: relative;
          transform: none;
        `
      : null}
`;

export const FieldTextInputCss = css`
  background-color: #ffffff;
  border: 1px solid #e6e8f9;
  border-radius: 0.5rem;
  color: #262930;
  font-family: var(--font-family);
  font-size: 1rem;
  min-height: 56px;
  padding: 16px;
  width: 100%;

  &[type="textarea"] {
    min-height: 80px;
    max-height: 50vh;
    resize: vertical;
  }

  &:not([type="textarea"]) {
    overflow: hidden;
    white-space: nowrap;
  }

  &:focus {
    border-color: #262930;
  }
  &:placeholder {
    color: #9b9eab;
  }
  &:read-only {
    background-color: transparent;
    border-color: #e6e8f9 !important;
  }
  &:disabled,
  &.field-input__disabled {
    background-color: #fcfcfc;
    border-color: #fcfcfc !important;
  }
`;

export const FielTextHelpStyled = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  .button {
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    font-size: 1.125rem;
    height: 2rem;
    justify-content: center;
    outline: none;
    width: 2rem;
    &:active,
    &:hover {
      & + .tooltip {
        display: block;
      }
    }
  }
  .tooltip {
    background-color: #24272e;
    border-radius: 0.5rem;
    bottom: calc(100% + 0.125rem);
    color: #ffffff;
    display: none;
    font-size: 0.75rem;
    min-width: 240px;
    padding: 0.5rem 0.75rem;
    position: absolute;
    right: 0;
    z-index: 2;
    &:after {
      border-color: #24272e transparent transparent transparent;
      border-bottom-width: 0px;
      border-style: solid;
      border-width: 0.375rem;
      content: "";
      height: 0px;
      position: absolute;
      right: 0.625rem;
      top: calc(100% - 2px);
      width: 0px;
    }
  }
`;

export const FieldTextStyled = styled.div<{ labelOutside?: boolean }>`
  position: relative;

  .sw-field-label {
    ${FieldTextLabelCss}
  }
  .sw-field-input {
    ${FieldTextInputCss}
  }
  .sw-field-error {
    color: #f1450f;
    font-size: 0.75rem;
  }
  .sw-field-error-help {
    color: #5D606F;
    font-size: 0.75rem;
  }
  .sw-field-max-length {
    color: #5d606f;
    font-size: 0.75rem;
  }
  .sw-field-control {
    position: relative;
    .sw-field-icon-left,
    .sw-field-icon-error {
      align-items: center;
      color: #24272e;
      display: flex;
      font-size: 1.25em;
      left: 1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      & + .sw-field-input {
        padding-left: 2.75rem;
      }
    }
    .sw-field-icon-error {
      color: #f1450f;
      font-size: 1.75rem;
      left: auto;
      right: 1rem;
    }
  }

  &.sw-field__error {
    .sw-field-label {
      color: #f1450f;
    }
    .sw-field-input {
      border-color: #f1450f;
    }
  }
`;

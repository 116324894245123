import { FC } from "react";
import { IaChatHeaderStyled } from "./chat-header.styled";
import { useTranslation } from "react-i18next";

type IaChatHeaderProps = {
  title?: string;
  maximized?: boolean;
  onToggleMaximize?: () => void;
  onClose?: () => void;
};

const IaChatHeader: FC<IaChatHeaderProps> = ({
  title,
  maximized,
  onToggleMaximize,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <IaChatHeaderStyled>
      <h1 className="chat-title">{title || t("aiAssistant.chat.title")}</h1>
      <div className="chat-header-options-left">
        <button className="chat-option" onClick={onToggleMaximize}>
          <span
            className={
              maximized ? "icon-arrows-in-simple" : "icon-arrows-out-simple"
            }
          />
        </button>
        <button className="chat-option" onClick={onClose}>
          <span className="icon-close" />
        </button>
      </div>
    </IaChatHeaderStyled>
  );
};

export default IaChatHeader;

import { check } from "@dashboard/assets/styles/animations.styled";
import styled from "styled-components";

export const CustomSwitchContainer = styled.div`
  width: 40px;
  height: 24px;
  position: relative;
  border-radius: 16px;
  background-color: #5f636c;
  transition: background-color 0.3s;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.05) inset;
  &::before {
    top: 3px;
    content: "";
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    border-radius: 16px;
    transition: transform 0.3s;
    background-color: #ffffff;
    transform: translateX(3px);
  }
  &.active {
    background: var(--Grey-B, #24272E);
    .check {
      left: 20px;
      position: absolute;
      top: 3px;
      z-index: 1;
      ${check}
    }
    &::before {
      transform: translateX(19px);
    }
  }
  &.disabled {
    background-color: #c4c4c4;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer
  }
`;

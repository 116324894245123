import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ImageProductForm } from "@dashboard/modules/products/types/product-form.types";
import {
  CreateProductPayload,
  UpdateProductPayload,
} from "@dashboard/modules/products/types/products.types";
import { productValidationSchema } from "@dashboard/modules/products/utils/product-form.utils";
import HookUpgradeIa from "../../hook-upgrade-ia";
import ProductContext from "../context/product.context";
import VariationsFilterModal from "../views/variations-filter-modal/variations-filter-modal";
import { useProducts } from "@dashboard/modules/products/hooks/products.hook";
import { Outlet, useParams } from "react-router-dom";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import ProductBreadcrumb from "../components/product-breadcrumb/product-breadcrumb";
import { ProductContentWrapper } from "./product-form.styled";

const ProductForm: FC = () => {
  const [overflow, setOverflow] = useState<string>("is-overflow");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showProductsAiHook, setShowProductsAiHook] = useState(false);
  const { generateNewProduct } = useProducts();
  const [newProduct, setNewProduct] = useState<
    CreateProductPayload | UpdateProductPayload
  >();
  const { t } = useTranslation();
  const value = {
    overflow,
    setOverflow,
    showFilterModal,
    setShowFilterModal,
    filterOn,
    setFilterOn,
    filterCount,
    setFilterCount,
    showAiGenerator: true,
    setShowProductsAiHook,
  };
  const { productId } = useParams();
  const { logEvent, getProduct } = useProducts();

  useEffect(() => {
    if (productId && productId !== "new") {
      getProduct({ id: productId })?.then((res) => {
        setNewProduct(res);
        logEvent(PRODUCTS_EVENTS.SELECTED_CARD, {
          event_subtype: "product_detail",
        });
      });
    } else {
      setNewProduct(generateNewProduct());
    }
  }, [productId]);

  useEffect(() => {
    logEvent(PRODUCTS_EVENTS.VIEW_SCREEN, {
      event_subtype: PRODUCTS_EVENTS.CREATE_PRODUCT,
    });
  }, []);

  return (
    <ProductContext.Provider value={value}>
      {newProduct && (
        <Formik
          initialValues={{
            ...newProduct,
            imagesFiles: [],
            imagesForm: [...(newProduct.images || [])].map((image, index) => ({
              id: index,
              type: "url",
              image,
            })) as ImageProductForm[],
          }}
          validateOnMount={true}
          validationSchema={productValidationSchema(t)}
          onSubmit={() => {}}
        >
          <>
            <ProductBreadcrumb />
            <ProductContentWrapper className="fadeIn">
              <Outlet />
            </ProductContentWrapper>
            <VariationsFilterModal />
            {showProductsAiHook && (
              <HookUpgradeIa onClose={() => setShowProductsAiHook(false)} />
            )}
          </>
        </Formik>
      )}
    </ProductContext.Provider>
  );
};

export default ProductForm;

import { useAnalytics } from "@dashboard/common/analytics";
import { actions } from "@dashboard/config/redux";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export const useLogicSupplementaryContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values }: { values: FormProps } = useFormikContext();
  const { logEvent } = useAnalytics();

  const copiesHook = {
    bgImg: "https://sumerlabs.com/prod/web/dashboard/tool-in-development-qr.webp",
    title: t("featureSoon.title"),
    description: t("featureSoon.copy"),
    ctaText: t("featureSoon.accept"),
    ctaLink: "http://sumerlabs.com",
  };

  const openHookTool = () => {
    logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
      event_subtype: "product_additional_content",
      source: `${values.id ? "edit" : "create"}`,
    });
    dispatch(
      actions.setHookInfo({
        bgImg: copiesHook.bgImg,
        title: copiesHook.title,
        description: copiesHook.description,
        ctaText: copiesHook.ctaText,
        hookType: "hook-information",
      })
    );
  };

  return {
    openHookTool,
  };
};

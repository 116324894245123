const locale = {
  title: "Plans",
  compare: {
    title: "Compare our plans",
    description: "MEMBERSHIP WITH SUMER",
    continue: "Continue",
    monthly: "Monthly",
    annual: "Annual",
    acquire: "Acquire",
    popular: "Most popular",
    current: "My current plan",
    period__Mensual: "Month",
    period__Anual: "Year",
    firstPeriod__Mensual: "Month",
    firstPeriod__Anual: "Yearly",
    discount: {
      monthly: "<strong>{{pricing}}</strong> for your first month",
      yearly: "<strong>{{pricing}}</strong> for your first year",
    },
  },
};

export default locale;

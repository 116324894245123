import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './config/translations/en';
import { es } from './config/translations/es';

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      es
    },
    fallbackLng: "es",
    load: 'languageOnly',
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    }
  });

export const language = i18n.language;

import { Plan } from "@dashboard/modules/plans/plans.types";
import CardPricing from "@sumerkit/ui/card-pricing";
import { FC } from "react";
import { EPlanPricing } from "../../constants/plan-pricing";
import { PlatformCountry } from "@dashboard/common/types";
import usePlanCardShared from "../../hooks/plan-card-shared.hook";
import { PlanPeriod } from "@dashboard/modules/checkout/constants/plan-period";
import { Plan as PlanNames } from "@dashboard/constants/plan";

export type PlanCardSharedTypes = {
  plan: Plan;
  view: EPlanPricing;
  country?: PlatformCountry;
  horizontalCard?: boolean;
  hint?: string;
  onClick?: (plan: Plan) => void;
};

const PlanCardShared: FC<PlanCardSharedTypes> = ({
  plan,
  country,
  view,
  horizontalCard,
  hint,
  onClick,
}) => {
  const { currency, currentPlan, t, configuration, copies, pricing } =
    usePlanCardShared(plan, view, country);

  const discountLabel = t(
    String(PlanPeriod.ANUAL) === plan.name
      ? "plans.compare.discount.yearly"
      : "plans.compare.discount.monthly",
    {
      pricing: pricing?.totalValue,
    }
  );

  return (
    <CardPricing
      horizontal={horizontalCard}
      noActions={horizontalCard}
      benefits={
        configuration.show_list_item
          ? (copies.list_description as []).slice(
              0,
              configuration.list_item_quantity
            )
          : []
      }
      benefitsHint={
        configuration.show_hint_list_item
          ? (copies.hint_list_description as [])
          : []
      }
      buyLabel={configuration.show_cta_button ? copies.cta_button_label : ""}
      currency={currency}
      currentPlanActive={
        configuration.current_plan_enabled &&
        currentPlan?.membership_id === plan.id
      }
      currentPlanLabel={copies.current_plan_label}
      description={configuration.show_subtitle_price && copies.main_description}
      discount={
        currentPlan?.benefit_plan_name ===
          String(PlanNames.FREE).toLowerCase() &&
        configuration.show_promo_top_bar
      }
      discountLabel={discountLabel}
      hint={hint ? hint : pricing.hint}
      planLabel={plan.type}
      popular={configuration.show_highlight}
      popularLabel={t("plans.compare.popular")}
      price={String(pricing?.totalValue) || "0"}
      onBuyClick={() => onClick && onClick(plan)}
    />
  );
};

export default PlanCardShared;

import { FC } from "react";
import { useSelector } from "react-redux";
import { StatsWrapper } from "./stats.styled";
import StatsInfoBox from "../stats-info-box";
import { useHome } from "../../hooks/home.hooks";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { countryInformationSelector } from "@dashboard/config/redux";

interface StatsProps {
  
}
 
const Stats: FC<StatsProps> = () => {

  const { t } = useTranslation();
  const country = useSelector(countryInformationSelector);
  const {
    filteredStatistics,
    productsQuantity,
    goToAbandonedCarts,
    handleOnStatsClick,
    handleProductsClick,
  } = useHome();

  return (
    <StatsWrapper>
      <div className="stats-grid" onClick={handleOnStatsClick}>
        <StatsInfoBox
          className="item"
          value={filteredStatistics.visits.value}
          label={t('home.stats.visits')}
          tooltipText={t('home.stats.visitsTooltip')}
          percentage={filteredStatistics.visits.percentage}
        />
        <StatsInfoBox
          className="item"
          value={filteredStatistics.orders.value}
          label={t('home.stats.totalSales')}
          tooltipText={t('home.stats.totalSalesTooltip')}
          percentage={filteredStatistics.orders.percentage}
        />
        <StatsInfoBox
          className="item"
          value={formatCurrency(filteredStatistics.ordersTotal.value, country)}
          label={t('home.stats.totalOrders')}
          tooltipText={t('home.stats.totalOrdersTooltip')}
          percentage={filteredStatistics.ordersTotal.percentage}
        />
        <StatsInfoBox
          className="item"
          value={filteredStatistics.ordersUnits.value}
          label={t('home.stats.totalUnits')}
          tooltipText={t('home.stats.totalUnitsTooltip')}
          percentage={filteredStatistics.ordersUnits.percentage}
        />
        <span className="icon-arrow" />
      </div>
      <div className="abandoned-cart" onClick={goToAbandonedCarts}>
        <StatsInfoBox
          value={filteredStatistics.abandonedCarts.value}
          label={t('home.stats.abandonedCarts')}
          tooltipText={t('home.stats.abandonedCartsTooltip')}
          percentage={filteredStatistics.abandonedCarts.percentage}
          name={'abandoned-carts'}
        />
        <span className="icon-arrow" />
      </div>
      <div className="products" onClick={handleProductsClick}>
        <StatsInfoBox
          value={productsQuantity}
          label={t('home.inventory.title')}
          tooltipText={t('home.inventory.tooltip')}
          name={'all-products'}
          addProducts
        />
        <span className="icon-arrow" />
      </div>
    </StatsWrapper>
  );
}
 
export default Stats;
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CONTACT_BUTTON_POSITION_X_AXIS_OPTIONS,
  CONTACT_BUTTON_POSITION_Y_AXIS_OPTIONS,
} from "../../constants/contact-button.constants";
import ContactButtonPositionWrapper from "./contact-button-position.styled";
import { useContactButton } from "../../hooks/contact-button.hook";

interface ContactButtonPositionProps {
  
}

const ContactButtonPosition: FC<ContactButtonPositionProps> = () => {

  const { t } = useTranslation();
  const {
    buttonConfig,
    handleAxisChange,
  } = useContactButton();
  const KEYS = { x: "horizontal", y: "vertical" };

  return (
    <ContactButtonPositionWrapper>
      <div className="items">
        {CONTACT_BUTTON_POSITION_X_AXIS_OPTIONS.map((option) => (
          <div
            key={`contact-button-position-x-${option.text}`}
            className={`option ${
              buttonConfig.wp_contact?.position?.horizontal === option.id &&
              "active"
            }`}
            onClick={() => handleAxisChange(KEYS.x, option.id)}
          >
            <div className="box">
              <span className={`icon-${option.icon} icon`} />
            </div>
            <div className="text">
              {t(`addons.contact_button.axis.${option.text}`)}
            </div>
          </div>
        ))}
      </div>
      <div className="items">
        {CONTACT_BUTTON_POSITION_Y_AXIS_OPTIONS.map((option) => (
          <div
            key={`contact-button-position-y-${option.text}`}
            className={`option ${
              buttonConfig.wp_contact?.position?.vertical === option.id &&
              "active"
            }`}
            onClick={() => handleAxisChange(KEYS.y, option.id)}
          >
            <div className="box">
              <span className={`icon-${option.icon} icon`} />
            </div>
            <div className="text">
              {t(`addons.contact_button.axis.${option.text}`)}
            </div>
          </div>
        ))}
      </div>
    </ContactButtonPositionWrapper>
  );
};

export default ContactButtonPosition;

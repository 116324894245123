import { FC, KeyboardEvent, useState } from "react";
import { AiChatInputStyled } from "./chat-input.styled";
import { useTranslation } from "react-i18next";

type AiChatInputProps = {
  disabled?: boolean;
  onSendMessage?: (message: string) => void;
};

const AiChatInput: FC<AiChatInputProps> = ({ disabled, onSendMessage }) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState("");

  const handleSendMessage = () => {
    onSendMessage && onSendMessage(localValue.trim());
    setLocalValue("");
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <AiChatInputStyled
      className={localValue.trim() ? `ai-chat-input__active` : ""}
    >
      <input
        type="text"
        placeholder={t("aiAssistant.chat.placeholder")}
        value={localValue}
        disabled={disabled}
        onChange={(event) => setLocalValue(event.target?.value || "")}
        onKeyDown={handleKeyDown}
      />
      <button
        className="chat-input-option"
        disabled={disabled}
        onClick={handleSendMessage}
      >
        <span className="icon-paper-plane-tilt" />
      </button>
    </AiChatInputStyled>
  );
};

export default AiChatInput;

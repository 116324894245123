import { FC, useEffect, useState } from "react";
import { ToggleStyled } from "./toggle.styled";
import { Plan } from "@dashboard/modules/plans/plans.types";

type ToggleProps = {
  value?: boolean;
  appearance?: "primary" | "success";
  className?: string;
  plan?: Plan[];
  onChange?: (value: boolean) => void;
  children?: string | React.ReactNode | React.ReactNode[];
};

const Toggle: FC<ToggleProps> = ({
  value,
  appearance,
  className,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(false);

  const handleClick = () => {
    onChange && onChange(!localValue);
    setLocalValue(!localValue);
  };

  useEffect(() => {
    setLocalValue(!!value);
  }, [value]);


  return (
    <ToggleStyled>
      <div
        className={`container-toggle ${className || ""} ${localValue ? "toggle__active" : ""} ${
          appearance ? `toggle__${appearance}` : ""
        }`}
        onClick={() => handleClick()}
      >
        <span className="toggle-button-pricing" />
        <span
          className={`${localValue ? "toggle__active icon-check check" : ""}`}
        />
      </div>
    </ToggleStyled>
  );
};

export default Toggle;

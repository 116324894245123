const locale = {
  name: "EVO Asistente",
  title: "Hola, Soy Evo",
  description: "¿En que te puedo ayudar hoy?",
  dates: {
    today: "Hoy",
    yesterday: "Ayer",
  },
  chat: {
    title: "Nuevo Chat",
    placeholder: "Escribe aquí...",
    expired:
      "¡Ups! Parece que el tiempo ha expirado. Por favor, regresa e inténtalo una vez más.",
  },
  message: {
    options: {
      copy: "Copiar",
      approved: "Buena respuesta",
      unApproved: "Mala respuesta",
      regenerate: "Regenerar respuesta",
    },
  },
  alert: {
    copySuccess: "Se ha copiado el mensaje exitosamente",
  },
  disabled: {
    title: "Parece que hemos agotado nuestras charlas por hoy.",
    description:
      "Si quieres seguir conversando con EVO, adquiere nuestro plan Ultra para tener charlas ilimitadas",
    acquire: "Adquiere plan Ultra",
  },
};

export default locale;

import { FC, useCallback, useRef, useState } from "react";
import {
  ProductContent,
  ProductContentWrapper,
  ProductMapContentContainer,
  ProductSaveButtonWrapper,
  SpinnerProductWrapper,
} from "./product.styled";
import { useTranslation } from "react-i18next";
import ProductDetail from "../../components/product-detail/product-detail";
import ProducMapContent from "../../components/product-map-content/product-map-content";
import ProductInventory from "../../components/product-inventory/product-inventory";
import ProductInformation from "../../components/product-information/product-information";
import { EMapContent } from "../../components/product-map-content/constants/map-content.constants";
import Button from "@sumerkit/ui/button";
import { useProduct } from "./product.hook";
import TrashSimple from "@sumerkit/icons/trash-simple";
import DeleteProductsConfirm from "../../../delete-products-confirm/delete-products-confirm";
import { Product as ProductEntity } from "@dashboard/modules/products/types/products.types";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { useFormikContext } from "formik";
import { useProducts } from "@dashboard/modules/products/hooks/products.hook";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { useNavigate } from "react-router-dom";
import { planSelector } from "@dashboard/config/redux";
import { useSelector } from "react-redux";
import AlertPopup from "../../../alert-popup/alert-popup";
import { Loader } from "@dashboard/assets/icons";

const Product: FC = () => {
  const { t } = useTranslation();
  const productDetailRef = useRef<HTMLDivElement>(null);
  const productInventoryRef = useRef<HTMLDivElement>(null);
  const productInformationRef = useRef<HTMLDivElement>(null);
  const [productsToDelete, setProductsToDelete] = useState<ProductEntity[]>([]);
  const {
    handleSaveProduct: handleSaveProductFn,
    isLoading,
    setIsLoading,
    validateCountVariations,
  } = useProduct();
  const { values }: { values: FormProps } = useFormikContext() ?? {};
  const { handleDeleteProducts: handleDeleteProductsFn } = useProducts();
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();
  const [showPopupAlert, setShowPopupAlert] = useState(false);
  const plan = useSelector(planSelector);
  const [showSpinner] = useState(false);

  const scrollTo = (scroll: EMapContent) => {
    switch (scroll) {
      case EMapContent.DETAIL:
        productDetailRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case EMapContent.INVENTORY:
        productInventoryRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case EMapContent.INFORMATION:
        productInformationRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        productDetailRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  const handleDeleteProducts = (products: ProductEntity[]) => {
    setProductsToDelete(products);
  };

  const handleDeleteCancel = () => {
    setProductsToDelete([]);
  };

  const handleConfirmDelete = useCallback(() => {
    handleDeleteProductsFn(productsToDelete);
    setProductsToDelete([]);
    logEvent(PRODUCTS_EVENTS.SELECT_CONFIRM_DELETE, {
      event_subtype: "delete_product",
    });
    navigate(-1);
  }, [productsToDelete]);

  const handleSaveProduct = () => {
    setIsLoading(true);
    if (validateCountVariations()) {
      handleSaveProductFn();
    } else {
      setShowPopupAlert(true);
      logEvent(PRODUCTS_EVENTS.VIEW_SCREEN, {
        event_subtype: "stock_warning",
        source: `${values.id ? "edit" : "create"}`,
        membership_type: plan?.benefit_plan_name ?? "",
      });
    }
  };

  return (
    <>
      {showSpinner && (
        <SpinnerProductWrapper>
          <div className="container fadeInUp">
            <Loader />
            <div className="spinnerText">
              {t("admin.products.side.loadingTextDuplicateProduct")}
            </div>
          </div>
        </SpinnerProductWrapper>
      )}
      {!showSpinner && (
        <ProductContentWrapper>
          <AlertPopup
            show={showPopupAlert}
            title={t("products.alertPopup.title")}
            confirmLabel={t("products.alertPopup.confirmLabel")}
            description={t("products.alertPopup.description")}
            onConfirm={() => {
              setShowPopupAlert(false);
              handleSaveProductFn();
            }}
            onClose={() => {
              setShowPopupAlert(false);
              setIsLoading(false);
            }}
          />
          <ProductContent>
            <div className="product-detail" ref={productDetailRef}>
              <ProductDetail />
            </div>
            <div className="product-inventory" ref={productInventoryRef}>
              <ProductInventory />
            </div>
            <div className="product-info" ref={productInformationRef}>
              <ProductInformation />
            </div>
          </ProductContent>
          <ProductMapContentContainer></ProductMapContentContainer>
          <ProducMapContent t={t} onClick={scrollTo} />
          <ProductSaveButtonWrapper>
            {values.id && (
              <>
                <div
                  className="delete-container"
                  onClick={() => handleDeleteProducts([values])}
                >
                  <TrashSimple width={20} height={20} />
                  <div className="text">
                    {t("admin.products.side.removeButtonText")}
                  </div>
                </div>
                {/* <div
                  className="duplicate-container"
                  onClick={handleDuplicateProduct}
                >
                  <CopySimple width={20} height={20} />
                  <div className="text">
                    {t("admin.products.side.duplicateButtonText")}
                  </div>
                </div> */} {/* Commented out because it's for next deploy ,depends infra */}
              </>
            )}
            <Button
              appearance="secondary"
              className="button"
              onClick={handleSaveProduct}
              size="small"
              disabled={!values.name || isLoading}
            >
              {t("admin.products.side.updateButtonText")}
            </Button>
          </ProductSaveButtonWrapper>
          {productsToDelete.length > 0 && (
            <DeleteProductsConfirm
              products={productsToDelete}
              onClose={handleDeleteCancel}
              onContinue={handleConfirmDelete}
            />
          )}
        </ProductContentWrapper>
      )}
    </>
  );
};

export default Product;

import { getApiUrl } from "@dashboard/common/utils";
import {
  LoginResponse,
  SessionResponse,
} from "@dashboard/entities/session-code.types";
import { camelizeKeys } from "humps";

export const generateCodeFetch = (token: string) => {
  return fetch(`${getApiUrl()}/api/ms/user/session-codes/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    } as { [key: string]: string },
  })
    .then((response) => {
      if (response.ok) return response;
      throw new Error(response.statusText);
    })
    .then((response) => response.json())
    .then((res) => camelizeKeys(res) as SessionResponse);
};

export const loginByCode = (code: string) => {
  return fetch(`${getApiUrl()}/api/ms/user/session-codes/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    } as { [key: string]: string },
    body: JSON.stringify({ code }),
  })
    .then((response) => {
      if (response.ok) return response;
      throw new Error(response.statusText);
    })
    .then((response) => response.json())
    .then((res) => camelizeKeys(res) as LoginResponse);
};

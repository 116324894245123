import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import globalReducer from "./global.slice";
import { productsReducer } from "@dashboard/modules/products/redux";
import { onboardingSlice } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.slice";
import { homeSlice } from "@dashboard/modules/home/store/home.slice";
import { checkoutReducer } from "@dashboard/modules/checkout/store/checkout.slice";
import contactButtonReducer from "@dashboard/modules/contact-button/store/contact-button.slice";
import { personalizationReducer } from "@dashboard/modules/personalization/store/personalization.slice";
import voucherSlice from "@dashboard/modules/voucher/store/voucher.slice";
import { meliReducer } from "@dashboard/modules/meli/store/meli.slice";
import configurationSlice from "@dashboard/modules/configuration/store/configuration.slice";
import { onboardingAiReducer } from "@dashboard/modules/onboarding-ai/store/onboarding-ai.slice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    products: productsReducer,
    onboarding: onboardingSlice.reducer,
    home: homeSlice.reducer,
    checkout: checkoutReducer,
    contactButton: contactButtonReducer,
    personalization: personalizationReducer,
    voucher: voucherSlice,
    meli: meliReducer,
    configuration: configurationSlice,
    onboardingAi: onboardingAiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

import {
  bulkProductsResultSelector,
  createProductsAction,
  isFetchingOrchestratorSelector,
  productsActions,
  productsSelector,
} from "../redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { searchProductByName } from "../utils/search.utils";
import {
  actions,
  countryInformationSelector,
  partnerSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import { useLocalStorage } from "@sumerlabs/component-library";
import {
  calculateVariationsProductFetch,
  getProductDetailFetch,
  updateProductFetch,
  productsExportFetch,
} from "../fetchers/products.fetchers";
import { handleActionProductsFetch } from "../fetchers/products-massive.fetchers";
import { ActionUpdateProductsPayload } from "../types/products-massive.types";
import { FeatureProducts } from "../constants/features.constants";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents } from "@dashboard/constants/events";
import { PRODUCTS_EVENTS } from "../constants/events.products";
import {
  CreateProductPayload,
  Product,
  Topping,
} from "../types/products.types";
import { decodeBase64ToExcel } from "@dashboard/utils/base64.utils";
import { downloadFile } from "@dashboard/utils/upload.utils";
import { useToast } from "@dashboard/hooks/use-toast.hook";

export const useProducts = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { logEvent } = useAnalytics();
  const [accessToken] = useLocalStorage("accessToken", null);
  const { toast } = useToast();
  const { isAvailable: moduleIsAvailable } = useFeatureAvailability(
    FeatureProducts.Available
  );
  const {
    isAvailable: createProductsIsAvailable,
    feature: createProductsFeature,
  } = useFeatureAvailability(FeatureProducts.CreateProducts);
  const { isAvailable: massiveIsAvailable } = useFeatureAvailability(
    FeatureProducts.MassiveUpload
  );
  const { isAvailable: exportIsAvailable } = useFeatureAvailability(
    FeatureProducts.ExportInventory
  );
  const partnerData = useSelector(partnerSelector);
  const countryInformation = useSelector(countryInformationSelector);
  const products = useSelector(productsSelector);
  const productsLoading = useSelector(isFetchingOrchestratorSelector);
  const bulkProductsResult = useSelector(bulkProductsResultSelector);

  const [filter, setFilter] = useState("");
  const [loadings, setLoading] = useState(0);

  const filteredProducts = useMemo(() => {
    let newList = [...products];
    const uniqueIds: string[] = [];
    if (filter && filter.trim()) {
      const filteredProducts = searchProductByName(filter, products)?.map(
        (product) => product.id
      );
      newList = products.filter((product) =>
        filteredProducts.includes(product.id)
      );
    }
    newList = newList.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    if (!moduleIsAvailable) {
      return newList.slice(0, 4);
    }
    return newList;
  }, [products, filter, moduleIsAvailable]);

  const loading = useMemo(() => loadings > 0, [loadings]);

  const handleSearch = (value: string) => {
    setFilter(value);
  };

  const showLoading = () => setLoading((loadings) => loadings + 1);
  const hideLoading = () => setLoading((loadings) => loadings - 1);

  const generateNewProduct = (product?: Product): CreateProductPayload => ({
    name: "",
    description: "",
    category: product ? product.category : "",
    images: [],
    available: true,
  });

  const handleCreateProduct = () => {
    const product = products.slice(0, 1)[0];
    const productToCreate: CreateProductPayload = generateNewProduct(product);
    if (accessToken) {
      dispatch(
        createProductsAction({
          token: accessToken,
          payload: {
            products: [productToCreate],
          },
        })
      );
    }
  };

  const handleChangeAvailableProduct = (
    product: Product,
    available: boolean
  ) => {
    if (accessToken) {
      const newProduct = { ...product, available };
      updateProduct(newProduct);
    }
  };

  const handleChangeHighlightProduct = (
    product: Product,
    highlight: boolean
  ) => {
    const newProduct = { ...product, highlight };
    return updateProduct(newProduct);
  };

  const handleDeleteProducts = (products: Product[]) => {
    return updateWithActionProducts(
      products.map((product) => product.id),
      ActionUpdateProductsPayload.DELETE
    )?.then((response) => {
      dispatch(productsActions.deleteProducts(products));
      return response;
    });
  };

  const handleChangeHighlightProducts = (
    products: Product[],
    highlight: boolean
  ) => {
    return updateWithActionProducts(
      products.map((product) => product.id),
      highlight
        ? ActionUpdateProductsPayload.ENABLE_HIGHLIGHT
        : ActionUpdateProductsPayload.DISABLE_HIGHLIGHT
    )?.then((response) => {
      dispatch(
        productsActions.updateProducts(
          products.map((product) => ({ ...product, highlight }))
        )
      );
      return response;
    });
  };

  const handleChangeAvailableProducts = (
    products: Product[],
    available: boolean
  ) => {
    return updateWithActionProducts(
      products.map((product) => product.id),
      available
        ? ActionUpdateProductsPayload.ENABLE
        : ActionUpdateProductsPayload.DISABLE
    )?.then((response) => {
      dispatch(
        productsActions.updateProducts(
          products.map((product) => ({ ...product, available }))
        )
      );
      return response;
    });
  };

  const updateProduct = useCallback(
    (newProduct: Product) => {
      if (accessToken) {
        return updateProductFetch(accessToken, newProduct).then((product) => {
          dispatch(productsActions.updateProducts([product]));
          return product;
        });
      }
    },
    [accessToken]
  );

  const handleUpdateProduct = (product: Product) => {
    return updateProduct(product)?.then((productEdited) => {
      dispatch(productsActions.updateProducts([productEdited]));
      return productEdited;
    });
  };

  const updateWithActionProducts = useCallback(
    (productIds: string[], action: ActionUpdateProductsPayload) => {
      if (accessToken) {
        return handleActionProductsFetch(accessToken, {
          ids: productIds,
          action,
        });
      }
    },
    [accessToken]
  );

  const calculateVariations = useCallback(
    (data: { toppings: Topping[] } | Product) => {
      if (accessToken) {
        return calculateVariationsProductFetch(accessToken, data).then(
          (variations) => {
            return variations;
          }
        );
      }
    },
    [accessToken]
  );

  const getProduct = useCallback(
    (payload: { id: string }) => {
      if (accessToken) {
        return getProductDetailFetch(accessToken, payload).then((product) => {
          return product;
        });
      }
    },
    [accessToken]
  );
  const handleExportInventory = useCallback(() => {
    if (exportIsAvailable) {
      if (accessToken) {
        showLoading();
        productsExportFetch(accessToken)
          .then((response) => {
            if (response.file) {
              decodeBase64ToExcel(response.file).then((file) => {
                downloadFile(file, {
                  file: {
                    name: `${
                      partnerData
                        ? `${partnerData.userDetail.enterpriseName}-`
                        : ""
                    }products.xlsx`.replace(/ /g, "-"),
                  },
                });
              });
            }
          })
          .catch(() => {
            toast.danger({
              text: t("products.bulkUploadFile.downloadProducts.error"),
            });
          })
          .finally(() => {
            hideLoading();
          });
      }
    } else {
      logEvent(CoreEvents.SystemExpertWarning, {
        event_subtype: "export_inventory",
        source: "hook",
        method: "free",
      });
      dispatch(
        actions.showUpgradePlanHook({
          title: t("products.getFunctionality.title"),
          description: t("products.getFunctionality.copy"),
          image: t("products.getFunctionality.image"),
          confirmLabel: t('admin.products.side.inventory.variations.hooks.upgrade.confirmLabel'),
        })
      );
    }
    logEvent(CoreEvents.SelectButton, {
      event_subtype: PRODUCTS_EVENTS.EXPORT_INVENTORY,
    });
  }, [exportIsAvailable, accessToken, partnerData]);

  return {
    t,
    logEvent,
    accessToken,
    countryInformation,
    moduleIsAvailable,
    exportIsAvailable,
    products,
    productsLoading,
    bulkProductsResult,
    filteredProducts,
    createProductsIsAvailable,
    createProductsFeature,
    massiveIsAvailable,
    loading,
    generateNewProduct,
    showLoading,
    hideLoading,
    handleSearch,
    handleCreateProduct,
    handleUpdateProduct,
    handleChangeAvailableProduct,
    handleChangeHighlightProduct,
    handleDeleteProducts,
    handleChangeAvailableProducts,
    handleChangeHighlightProducts,
    calculateVariations,
    getProduct,
    handleExportInventory,
  };
};

import { useTranslation } from "react-i18next";
import { FC} from "react";
import { useFormikContext } from "formik";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { CoreEvents } from "@dashboard/constants/events";
import CustomSwitch from "@dashboard/components/custom-switch/custom-switch";
import CustomCounter from "@dashboard/components/custom-counter/custom-counter";
import SupplementaryContent from "../supplementary-content/supplementary-content";
import { ProductInformationWrapper } from "./product-information.styled";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import ProductSku from "../product-sku/product-sku";
import Truck from "@sumerkit/icons/truck";
import ProductCategoryDescription from "../product-category-description/product-category-description";
import { useLogicInformation } from "./product-information.hook";

type InformationProps = {
  setSectionsValid?: (values: { [key: string]: boolean }) => void;
};

const ProductInformation: FC<InformationProps> = ({ setSectionsValid }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const { logEvent } = useAnalytics();
  const {
    alreadyExistSku,
    skusIsAvailable,
    onChangeSku,
    onClickSku,
    showDeliveryDays,
    setShowDeliveryDays,
  } = useLogicInformation({ setSectionsValid });

  return (
    <ProductInformationWrapper>
      <div className="title">
        <div className="text">
          {t("productComponent.product.productInformation.title")}
        </div>
      </div>
      <div className="content">
        <ProductCategoryDescription />
        <ProductSku
          data={values.sku ? { sku: values.sku } : undefined}
          alreadyExist={alreadyExistSku}
          disabled={!skusIsAvailable}
          showHeader
          onChange={onChangeSku}
          onClick={onClickSku}
          onChangeValid={(isValid) => {
            setSectionsValid && setSectionsValid({ inventory: isValid });
          }}
          onFocus={() => {
            logEvent(CoreEvents.SelectInput, {
              event_subtype: PRODUCTS_EVENTS.PRODUCT_SKU,
              option: "general",
            });
          }}
          onError={() => {
            setSectionsValid && setSectionsValid({ inventory: false });
            logEvent(CoreEvents.StateErrorWarning, {
              event_subtype: PRODUCTS_EVENTS.PRODUCT_SKU,
              option: "limit_error",
            });
          }}
        />
        <CollapseContainerWrapper>
          <div className="info">
            <Truck width={32} height={32} />
            <div className="description">
              <strong className="title">
                {t("admin.products.side.inventory.info.title")}
              </strong>
              <small className="subtitle">
                {t("admin.products.side.inventory.info.subTitle")}
              </small>
            </div>
            <div className="switchContainer">
              <CustomSwitch
                isActive={showDeliveryDays}
                onChange={(state) => {
                  logEvent(PRODUCTS_EVENTS.SELECT_TOGGLE, {
                    event_subtype: "sale_on_request",
                    source: `${values.id ? "edit" : "create"}`,
                    option: `${state ? "on" : "off"}`,
                  });
                  setShowDeliveryDays(state);
                  if (!state) setFieldValue("inventory.delivery_days", 0);
                }}
              />
            </div>
          </div>
          {showDeliveryDays && (
            <div className="available">
              <small>{t("admin.products.side.inventory.info.days")}</small>
              <CustomCounter
                value={values.inventory?.deliveryDays}
                onChange={(count) =>
                  setFieldValue("inventory.delivery_days", count)
                }
                onClick={(source) => {
                  logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
                    event_subtype: PRODUCTS_EVENTS.DAYS_SALE_ON_REQUEST,
                    option: `${source}`,
                  });
                }}
              />
            </div>
          )}
        </CollapseContainerWrapper>
        <SupplementaryContent />
      </div>
    </ProductInformationWrapper>
  );
};

export default ProductInformation;

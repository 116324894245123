import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MainHeader from "@dashboard/components/main-header/main-header";
import NavigationBar from "@dashboard/components/navigation-bar";
import { LayoutContainer } from "@dashboard/components/main-layout/main-layout.styled";
import {
  actions,
  countriesSelector,
  fetchConfiguration,
  fetchPartnerByToken,
  fetchUrlShare,
  isNavigationBarCollapsedSelector,
  partnerSelector,
  planParamsSelector,
  showGlobalLoaderSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import AddonHook from "@dashboard/modules/home/components/addon-hook";
import PlanHook from "@dashboard/modules/home/components/plan-hook";
import HookInfo from "../hook-information-modal";
import UiGeneralLoader from "../ui-general-loader";
import { fetchOrchestratorAction } from "@dashboard/modules/products/redux";
import HookUpgradePlan from "../hook-upgrade-plan";
import { useSession } from "@dashboard/hooks/use-session.hook";
import AiAssistant from "@dashboard/modules/ai-assistant";
import GlobalLoader from "../global-loader/global-loader";
import { useSelector } from "react-redux";
import CustomPlanHook from "../custom-plan-hook";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { useTranslation } from "react-i18next";
import { useKustomer } from "@dashboard/hooks/kustomer.hooks";
import { X } from "@phosphor-icons/react";
import { UiButton } from "@sumerlabs/component-library";
import { usePlansCtaBanner } from "@dashboard/hooks/use-plans-cta-banner.hook";
import { useClevertap } from "@dashboard/hooks/clevertap.hook";

function Layout() {
  const dispatch = useAppDispatch();
  const { banner, MainIcon, handleCtaClick } = usePlansCtaBanner();
  const { token, validSession, webStatus, decodedToken } = useSession();
  const globalLoader = useSelector(showGlobalLoaderSelector);
  const planParamsData = useSelector(planParamsSelector);
  const [showGlobalLoader, setShowGLobalLoader] = useState(false);
  const [textGlobalLoader, setTextGLobalLoader] = useState<Array<string>>([]);
  const partner = useSelector(partnerSelector);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const countries = useSelector(countriesSelector);
  const { init: initKustomer } = useKustomer();
  const { login: loginClevertap } = useClevertap();
  const isNavigationBarCollapsed = useSelector(
    isNavigationBarCollapsedSelector
  );

  useEffect(() => {
    validSession();
  }, [token]);

  useEffect(() => {
    if (planParamsData?.plan && planParamsData.period) {
      dispatch(
        checkoutActions.setToBuyItem({
          type: "plan",
          plan_name: planParamsData?.plan,
          plan_duration: planParamsData.period,
        })
      );
      dispatch(actions.resetPlanParams());
      navigate(`/${partner?.userId}/checkout`);
    }
  }, [planParamsData]);

  useEffect(() => {
    setShowGLobalLoader(globalLoader.showLoader);
    setTextGLobalLoader(globalLoader.copys);
  }, [globalLoader]);

  useEffect(() => {
    if (decodedToken?.user_id) {
      webStatus(decodedToken.user_id);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (!!token) {
      dispatch(fetchPartnerByToken(token));
      dispatch(fetchConfiguration(token));
      dispatch(fetchOrchestratorAction(token));
      dispatch(fetchUrlShare(token));
    }
  }, [token]);

  const stopLoading = () => {
    dispatch(
      actions.setShowGlobalLoader({
        showLoader: false,
        copys: [],
      })
    );
  };

  useEffect(() => {
    if (!!partner?.userDetail.country) {
      const country = countries.find(
        (c) => c.id === partner?.userDetail.country
      );
      if (country && country.locale !== i18n.language) {
        i18n.changeLanguage(country.locale);
      }
    }
  }, [partner, countries, i18n]);

  useEffect(() => {
    if (partner) {
      initKustomer();
      loginClevertap(partner.userId);
    }
  }, [partner]);

  return (
    <LayoutContainer className={isNavigationBarCollapsed ? "isCollapse" : ""}>
      {showGlobalLoader && (
        <GlobalLoader copys={textGlobalLoader} onFinish={stopLoading} />
      )}
      <NavigationBar />
      <MainHeader />
      <div className="main">
        <div className="body-main">
          <Outlet />
        </div>
        <UiGeneralLoader />
        <AiAssistant />
      </div>
      {!!banner && (
        <div className="banner" style={{ backgroundColor: banner.bannerColor }}>
          <div className="mainText">
            {banner.showMainTextIcon && !!MainIcon && (
              <MainIcon
                className="icon"
                weight="fill"
                style={{ color: banner.mainTextIconColor }}
              />
            )}
            <div className="text">{banner.mainText}</div>
          </div>
          <UiButton className="button" onClick={handleCtaClick}>
            {banner.ctaText}
          </UiButton>
          {banner.showCloseIcon && (
            <div className="close">
              <X />
            </div>
          )}
        </div>
      )}
      <AddonHook />
      <PlanHook />
      <HookInfo />
      <HookUpgradePlan />
      <CustomPlanHook />
    </LayoutContainer>
  );
}

export default Layout;

import styled from "styled-components";

export const AiChatMessageStyled = styled.li`
  align-items: flex-start;
  color: #24272e;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  font-size: 0.875rem;
  .profile-avatar {
    align-items: center;
    background-color: #e9e9ed;
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    span {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .message-profile {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
  .message-slider {
    display: block;
    width: 100%;
    .slick-slide:not(.slick-current) {
      .message-container {
        .message-content {
          box-shadow: none;
        }
      }
    }
  }
  .message-slider-navigation {
    align-items: center;
    color: #5d606f;
    display: flex;
    gap: 0.25rem;
    font-size: 0.75rem;
    button {
      background: transparent;
      font-size: 1em;
      height: 16px;
      width: 16px;
      span {
        display: block;
      }
      &:first-child {
        transform: rotate(180deg);
      }
    }
  }
  .message-container {
    display: block;
    max-width: 100%;
    .message-layout {
      align-items: center;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      width: 100%;
    }
    .message-content {
      background-color: #fcfcfc;
      border-radius: 0.25rem;
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
        2px 4px 10px 0px rgba(53, 53, 53, 0.04);
      color: #24272e;
      font-size: 0.75rem;
      line-height: 1.5em;
      max-width: 100%;
      order: 1;
      padding: 1rem;
      position: relative;
      a {
        color: var(--Highlight-Main, #1458F5);
      }
      img {
        width: 100%;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        font-size: 1em;
        font-weight: 600;
      }
      ol, ul {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        list-style: initial;
        padding-left: 1rem;
        li {
          &::marker {
            font-weight: bold;
          }
        }
      }
      ol {
        list-style: decimal;
      }
    }
    .message-content-action {
      background: transparent;
      font-size: 1rem;
      height: 16px;
      min-width: 16px;
      order: 2;
      white-space: nowrap;
      width: 16px;
      span {
        display: none;
      }
    }
    .message-qualification {
      align-items: center;
      color: #5d606f;
      display: flex;
      gap: 0.25rem;
      font-size: 0.75rem;
      margin-top: 0.5rem;
      span {
        font-size: 1.25em;
      }
      &__good {
        span {
          color: #3abd7c;
        }
      }
      &__bad {
        span {
          color: #f93c00;
        }
      }
    }
    &__active-options,
    &:hover {
      .message-content-action {
        span {
          display: block;
        }
      }
    }
  }
  .message-error {
    .message-error-alert {
      align-items: center;
      background: #feece6;
      border-radius: 0.25rem;
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
        2px 4px 10px 0px rgba(53, 53, 53, 0.04);
      color: #24272e;
      font-size: 0.75rem;
      padding: 0.75rem 1rem;
      &::before {
        display: none;
      }
      .alert-card-icon {
        background: transparent;
        color: #f1450f;
        font-size: 1.5rem;
      }
    }
    .message-error-retry {
      align-items: center;
      background: transparent;
      color: #5d606f;
      display: flex;
      font-size: 0.75rem;
      padding: 0.5rem 0;
      span {
        display: block;
        font-size: 1.25em;
        margin-right: 0.25rem;
      }
    }
  }
  .message-options {
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    font-size: 0.75rem;
    .message-option {
      align-items: center;
      background: transparent;
      display: flex;
      gap: 0.75rem;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      width: 100%;
      span {
        font-size: 1rem;
      }
    }
  }
  &.ai-chat-message__client {
    align-items: flex-end;
    .message-container {
      .message-content {
        order: 2;
      }
      .message-content-action {
        order: 1;
      }
    }
  }
`;

export const MessageWaitResponse = styled.div`
  @keyframes l3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }

  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  aspect-ratio: 2;
  animation: l3 1s infinite linear;
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 6) 50%;
  width: 16px;
`;

import { FC, useEffect } from "react";
import { LoginWrapper } from "./login-qr-page.styled";
import { useLogicLoginQrPage } from "./login-qr-page.hook";
import { Trans, useTranslation } from "react-i18next";
import { Loader } from "@dashboard/assets/icons";

const LoginQrPage: FC = () => {
  const { t } = useTranslation();
  const { qrData, IMAGEPATH, getData } = useLogicLoginQrPage();

  useEffect(() => {
    getData();
  }, []);

  return (
    <LoginWrapper>
      <div className="contentWrapper">
        <div className="image-login">
          <img src={IMAGEPATH} alt="Login QR" />
        </div>
        
        <div className="content">
          <div className="qrContent">
            {qrData?.url_image ? (
              <img className="qr-img" src={qrData?.url_image} alt="QR" />
            ) : (
              <div className="loading">
                <Loader />
              </div>
            )}
            <div className="description">
              <div className="title">{t("loginQr.title")}</div>
              <hr />
              <div className="step">{t("loginQr.step1")}</div>
              <div className="step">
                <Trans i18nKey="loginQr.step2" />
              </div>
              <div className="step">{t("loginQr.step3")}</div>
            </div>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default LoginQrPage;

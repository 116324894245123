import { http } from "@dashboard/common/http";
import { FilesUploaderPayload } from "@dashboard/common/types";

export const UploadFiles = (
  token: string,
  files: FilesUploaderPayload[],
  onUploadProgress: (event: ProgressEvent) => void
) => {
  let formData = new FormData();
  files.forEach((item) => {
    formData.append(item.name, item.file);
  });
  return http.post("/api/ms/catalogue/product/massive/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress,
  });
};

const generateRandom = (): string => {
  return (
   `${Math.random().toString(36).substring(2)}${new Date().getTime().toString(36)}`
  );
};

export const fetchUploadImages = (
  token: string,
  files: FilesUploaderPayload[],
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  let formData = new FormData();
  files.forEach((item, index) => {
    const fileName = index === 0 ? `file` : `file${index + 1}`;

    formData.append(
      fileName,
      item.file,
      `image${index + 1}${generateRandom()}.${item.file.name
        .split(".")
        .pop()}`
    );
  });
  return http.post(
    "/api/ms/s3-database/file-uploader/upload/catalogue",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
    }
  );
};

import styled from "styled-components";

export const BreadcrumbsStyled = styled.nav`
  align-items: center;
  color: #24272e;
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 1em;
  padding: 1.5rem 0;
  .breadcrumbs-item {
    background: transparent;
    line-height: 1em;
    &:last-child {
      font-weight: bold;
    }
  }
  .breadcrumbs-link {
    color: #24272e;
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }
  .back-button {
    border-radius: 50%;
    background: #fff;
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    height: 40px;
    margin-right: 0.5rem;
    width: 40px;
    .arrow {
      display: block;
      font-size: 1.5rem;
      transform: rotate(90deg);
    }
  }
`;

import { Addon } from "@dashboard/entities/addon";
import { ToBuyItem } from "../entities/to-buy-item.entity";
import { UserCard } from "../entities/user-card.entity";
import { PlanCalculate } from "../entities/plan-calculate.entity";
import { Status } from "../entities/status.entity";

export interface CheckoutState {
 ui: CheckoutUiState;
 data: CheckoutDataState;
};

export interface CheckoutUiState {
  isLoadingAllData: boolean;
};
export interface CheckoutDataState {
  toBuyItem?: ToBuyItem;
  checkoutSessionSecret?: string;
  cardSessionSecret?: string;
  setOrderId?: number;
  status?: Status;
  allAddons: Addon[];
  allPlans: PlanCalculate[];
  userCards: UserCard[];
};

export const initialState: CheckoutState = {
 ui: {
  isLoadingAllData: true,
 },
 data: {
  allAddons: [],
  allPlans: [],
  userCards: [],
 },
};

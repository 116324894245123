import { FC, useCallback, useEffect, useState } from "react";
import { Modal, useLocalStorage } from "@sumerlabs/component-library";
import { useTranslation } from "react-i18next";
import { HookUpgradeIaStyled } from "./hook-upgrade-ia.styled";
import Button from "@sumerkit/ui/button";
import { useSelector } from "react-redux";
import {
  countryInformationSelector,
  partnerSelector,
  planSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import { couponCodeSelector } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.selectors";
import { Plan as PlanTypes } from "@dashboard/constants/plan";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { useNavigate } from "react-router-dom";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { FeatureProducts } from "../../constants/features.constants";
import { fetchAllPlans } from "@dashboard/modules/shared/components/onboarding-shared/services/premium.services";
import { Plan } from "@dashboard/modules/shared/components/onboarding-shared/entities/premium.entities";

type HookUpgradeIaProps = {
  onClose?: () => void;
};

const HookUpgradeIa: FC<HookUpgradeIaProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [token] = useLocalStorage("accessToken", "");
  const partner = useSelector(partnerSelector);
  const country = useSelector(countryInformationSelector);
  const coupon = useSelector(couponCodeSelector);
  const plan = useSelector(planSelector);
  const [planUltra, setPlanUltra] = useState<Plan>();

  const handleConfirm = () => {
    dispatch(
      checkoutActions.setToBuyItem({
        type: "plan",
        plan_name: PlanTypes.ULTRA,
        plan_duration: planUltra?.name,
      })
    );
    handleClose();
    setTimeout(() => {
      navigate(`/${partner?.userId}/checkout?source=hook`);
    }, 200);
  };

  const handleBuyAddon = () => {
    dispatch(
      checkoutActions.setToBuyItem({
        type: "addon",
        addon_type_id: FeatureProducts.ProductsAi,
      })
    );
    handleClose();
    setTimeout(() => {
      navigate(`/${partner?.userId}/checkout?source=hook`);
    }, 200);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const loadPlans = useCallback(() => {
    fetchAllPlans(
      partner?.userDetail.country || "Colombia",
      coupon,
      token,
    ).then((plans) => {
      const ultra = plans.find(
        (plan) => plan.additional_data.plan_type === PlanTypes.ULTRA
      );
      setPlanUltra(ultra);
    });
  }, [token, partner, coupon]);

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <Modal
      title=""
      show={true}
      onClose={handleClose}
      content={{
        className: "fadeInDown",
        styles: {
          overflow: "hidden",
          width: "450px",
        },
      }}
      header={{
        styles: {
          borderBottom: 0,
          boxShadow: "none",
          left: 0,
          paddingBottom: 0,
          paddingLeft: "2rem",
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: 2,
        },
      }}
      body={{
        styles: {
          padding: 0,
        },
      }}
    >
      <HookUpgradeIaStyled>
        <figure className="image">
          <img
            src="https://sumerlabs.com/prod/assets/web/sumer-ia.webp"
            loading="lazy"
            alt="Upgrade plan background"
          />
        </figure>
        <div className="hook-body">
          <div className="copies">
            <h1
              className="title"
              dangerouslySetInnerHTML={{
                __html: t("products.gptGenerate.hook.title"),
              }}
            />
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: t("products.gptGenerate.hook.copy", {
                  price: planUltra?.price
                    ? formatCurrency(planUltra?.price, country)
                    : "...",
                }),
              }}
            />
          </div>
          <div className="options">
            {(plan?.benefit_plan_name || "").toUpperCase() !==
              String(PlanTypes.ULTRA).toUpperCase() && (
              <Button
                appearance="secondary"
                darkMode
                fullWidth
                onClick={handleConfirm}
              >
                {t("products.gptGenerate.hook.subscribe")}
              </Button>
            )}
            <Button
              appearance="secondary"
              link
              darkMode
              fullWidth
              className="option-link"
              onClick={handleBuyAddon}
            >
              {t("products.gptGenerate.hook.buy")}
            </Button>
          </div>
          <div className="footer">
            <p>{t("products.gptGenerate.hook.subscriptionHelp")}</p>
          </div>
        </div>
      </HookUpgradeIaStyled>
    </Modal>
  );
};

export default HookUpgradeIa;

import styled from "styled-components";

export const PopupWrapper = styled.div`
  display: flex;
  width: 600px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  gap: 20px;
  background: var(--grey-w, #fff);
  box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
    2px 4px 10px 0px rgba(53, 53, 53, 0.04);
  .icon {
    display: flex;
    height: 32px;
    justify-content: right;
    align-items: center;
    align-self: stretch;
    figure {
      font-size: 20px;
      :hover {
        cursor: pointer;
      }
    }
  }
  .title {
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .description {
    display: flex;
    width: 472px;
    align-items: flex-start;
    gap: 16px;
    .img-store {
      img {
        width: 47.656px;
        height: 47.656px;
        flex-shrink: 0;
        border-radius: 47.656px;
        border: 2.508px solid rgba(233, 236, 239, 0.4);
      }
    }
    .text {
      color: #000;
      font-size: 15.885px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.828px;
    }
  }
  .actions {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: -webkit-fill-available;
    .button {
      display: flex;
      width: -webkit-fill-available;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--grey-b, #24272e);
      background: var(--grey-8, #fcfcfc);
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
        2px 4px 10px 0px rgba(53, 53, 53, 0.04);
      .icon {
      }
      .text {
        color: var(--grey-b, #24272e);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &:hover {
        cursor: pointer;
        background: var(--grey-7, #e9e9ed);
      }
    }
  }
`;

export const NotificationSuccess = styled.div`
  display: flex;
  width: 343px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-transparency, rgba(12, 18, 32, 0.9));
  position: fixed;
  z-index: 99999999999999999;
  position: fixed;
  margin-top: 39px;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
    2px 4px 10px 0px rgba(53, 53, 53, 0.04);
  .text {
    color: var(--grey-w, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

const FolderDottedLight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 17.5723C3.81066 17.5723 4.0625 17.8241 4.0625 18.1348V18.941C4.0625 18.9583 4.06589 18.9753 4.07249 18.9912C4.07909 19.0072 4.08876 19.0216 4.10094 19.0338C4.11313 19.046 4.1276 19.0557 4.14352 19.0623C4.15945 19.0689 4.17651 19.0723 4.19375 19.0723H8.75C9.06066 19.0723 9.3125 19.3241 9.3125 19.6348C9.3125 19.9454 9.06066 20.1973 8.75 20.1973H4.19375C4.02878 20.1973 3.86542 20.1648 3.713 20.1016C3.56059 20.0385 3.4221 19.946 3.30545 19.8293C3.18879 19.7127 3.09626 19.5742 3.03313 19.4218C2.96999 19.2693 2.9375 19.106 2.9375 18.941V18.1348C2.9375 17.8241 3.18934 17.5723 3.5 17.5723Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9375 19.6348C11.9375 19.3241 12.1893 19.0723 12.5 19.0723H15.5C15.8107 19.0723 16.0625 19.3241 16.0625 19.6348C16.0625 19.9454 15.8107 20.1973 15.5 20.1973H12.5C12.1893 20.1973 11.9375 19.9454 11.9375 19.6348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 13.8223C21.8107 13.8223 22.0625 14.0741 22.0625 14.3848V18.9691C22.0625 19.2949 21.9331 19.6072 21.7028 19.8376C21.4725 20.0679 21.1601 20.1973 20.8344 20.1973H19.25C18.9393 20.1973 18.6875 19.9454 18.6875 19.6348C18.6875 19.3241 18.9393 19.0723 19.25 19.0723H20.8344C20.8617 19.0723 20.888 19.0614 20.9073 19.0421C20.9266 19.0227 20.9375 18.9965 20.9375 18.9691V14.3848C20.9375 14.0741 21.1893 13.8223 21.5 13.8223Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6875 7.63477C15.6875 7.32411 15.9393 7.07227 16.25 7.07227H20.75C21.0981 7.07227 21.4319 7.21055 21.6781 7.45669C21.9242 7.70283 22.0625 8.03667 22.0625 8.38477V10.6348C22.0625 10.9454 21.8107 11.1973 21.5 11.1973C21.1893 11.1973 20.9375 10.9454 20.9375 10.6348V8.38477C20.9375 8.33504 20.9177 8.28735 20.8826 8.25218C20.8474 8.21702 20.7997 8.19727 20.75 8.19727H16.25C15.9393 8.19727 15.6875 7.94543 15.6875 7.63477Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26089 5.21062C9.23923 5.20172 9.21602 5.19718 9.1926 5.19727L9.19062 5.19727H4.25C4.20027 5.19727 4.15258 5.21702 4.11742 5.25219C4.08225 5.28735 4.0625 5.33504 4.0625 5.38477V7.07227H11.142L9.31886 5.24913C9.31875 5.24902 9.31864 5.24891 9.31854 5.2488C9.302 5.23243 9.28242 5.21946 9.26089 5.21062ZM9.18977 4.07227C9.36075 4.07182 9.53014 4.10502 9.6883 4.16997C9.84681 4.23507 9.99092 4.3308 10.1124 4.45166L10.1134 4.45265L12.8977 7.23702C13.0586 7.3979 13.1067 7.63984 13.0197 7.85003C12.9326 8.06022 12.7275 8.19727 12.5 8.19727H3.5C3.18934 8.19727 2.9375 7.94543 2.9375 7.63477V5.38477C2.9375 5.03667 3.07578 4.70283 3.32192 4.45669C3.56806 4.21055 3.9019 4.07227 4.25 4.07227H9.18977Z"
        fill="#5D606F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 10.8223C3.81066 10.8223 4.0625 11.0741 4.0625 11.3848V14.3848C4.0625 14.6954 3.81066 14.9473 3.5 14.9473C3.18934 14.9473 2.9375 14.6954 2.9375 14.3848V11.3848C2.9375 11.0741 3.18934 10.8223 3.5 10.8223Z"
        fill="#5D606F"
      />
    </svg>
  );
};

export default FolderDottedLight;

import { useAnalytics } from "@dashboard/common/analytics";
import { fetchPartnerByToken, useAppDispatch } from "@dashboard/config/redux";
import { EVENTS } from "@dashboard/constants/events";
import { db } from "@dashboard/firebase";
import { LoginSource } from "@dashboard/modules/shared/components/onboarding-shared/constants/login-source.constants";
import { getTokenBycode } from "@dashboard/services/qr.service";
import {
  ValidateCodeResponse,
  useLocalStorage,
} from "@sumerlabs/component-library";
import { ref, onValue } from "firebase/database";
import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getQRdata } from "@dashboard/services/qr.service";

export const useLogicLoginQrPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logEvent } = useAnalytics();
  const [searchParams] = useSearchParams();
  const onboardingSplit = searchParams.get('onboarding');
  const [, setLocalStorage] = useLocalStorage("accessToken", null);
  const [, setExpiresIn] = useLocalStorage("expiresIn", 0);
  const [, setRefreshToken] = useLocalStorage("refreshToken", "");
  const [qrData, setQrData] = useState<{
    id: number;
    channel: string;
    url_image: string;
  } | null>(null);
  const IMAGEPATH =
    "https://sumerlabs.com/prod/sumerweb/login/login_qr_image.png";

  const success = useCallback(async (loginData: ValidateCodeResponse) => {
    dispatch(fetchPartnerByToken(loginData.accessToken))
      .unwrap()
      .then(() => {
        logEvent(EVENTS.SUCCESS_LOGIN);
        navigate(`/status${onboardingSplit ? `?onboarding=${onboardingSplit}` : ''}`);
      })
      .catch(() => {
        navigate(`/status${onboardingSplit ? `?onboarding=${onboardingSplit}` : ''}`);
        logEvent(EVENTS.WEB_SCREEN, {
          name: "sumerweb_store_description",
        });
      });
  }, []);

  const createChannel = (channel: string) => {
    const qrCollectionref = ref(
      db,
      `${process.env.REACT_APP_ENV}/qr/${channel}`
    );
    onValue(qrCollectionref, async (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        if (data.code) {
          const token = await getTokenBycode(data.code);
          if (token) {
            logEvent(EVENTS.USER_LOGIN, {
              event_subtype: "sumer_web",
              source: "login",
              method: LoginSource.QRCODE
            });
            setLocalStorage(token.accessToken);
            setExpiresIn(token.expiresIn);
            setRefreshToken(token.refreshToken);
            success(token);
          }
        }
      }
    });
  };

  const getData = () => {
    logEvent(EVENTS.VIEW_SCREEN, {
      event_subtype: "login_qr_code",
      source: "login",
    });
    (async () => {
      await getQRdata().then((res) => {
        setQrData(res);
        if (res.channel) createChannel(res.channel);
      });
    })();
  }

  return {
    createChannel,
    qrData,
    IMAGEPATH,
    setQrData,
    getData
  };
};

import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  actionDiscardProcessUploadSelector,
  initProcessMasiveUploadSelector,
  productsActions,
} from "../redux";

export const useProductsNavigation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const initProcessMasiveUpload = useSelector(initProcessMasiveUploadSelector);
  const actionDiscardProcessUpload = useSelector(
    actionDiscardProcessUploadSelector
  );

  const productsUrl = useMemo(() => `/${id}/products`, [id]);

  const showPrompsDiscard = useMemo(
    () => !!actionDiscardProcessUpload,
    [actionDiscardProcessUpload]
  );

  const bulkUploadUrl = useMemo(
    () => `${productsUrl}/bulk-upload`,
    [productsUrl]
  );

  const bulkUploadImagesUrl = useMemo(
    () => `${bulkUploadUrl}/images`,
    [bulkUploadUrl]
  );

  const bulkProductsUploadImagesUrl = useMemo(
    () => `${bulkUploadImagesUrl}/bulk-upload`,
    [bulkUploadImagesUrl]
  );

  const bulkCreateProductsUrl = useMemo(
    () => `${bulkUploadUrl}/create`,
    [bulkUploadUrl]
  );

  const bulkRetryProductsUrl = useMemo(
    () => `${bulkUploadUrl}/summary`,
    [bulkUploadUrl]
  );

  const bulkUploadCustomUrl = useMemo(
    () => `${bulkUploadUrl}/custom-file`,
    [bulkUploadUrl]
  );

  const handleCancelDiscardProcessUpload = () => {
    dispatch(productsActions.setActionDiscardProcessUpload(undefined));
  };

  const handleDiscardProcessUpload = useCallback(
    (action?: () => void) => {
      if (initProcessMasiveUpload) {
        dispatch(productsActions.setActionDiscardProcessUpload(action));
      } else {
        action && action();
      }
    },
    [initProcessMasiveUpload]
  );

  const handleConfirmDiscardProcessUpload = useCallback(() => {
    !!actionDiscardProcessUpload && actionDiscardProcessUpload();
    handleCancelDiscardProcessUpload();
  }, [actionDiscardProcessUpload]);

  return {
    productsUrl,
    bulkUploadUrl,
    bulkUploadImagesUrl,
    bulkProductsUploadImagesUrl,
    bulkCreateProductsUrl,
    bulkRetryProductsUrl,
    bulkUploadCustomUrl,
    showPrompsDiscard,
    handleDiscardProcessUpload,
    handleCancelDiscardProcessUpload,
    handleConfirmDiscardProcessUpload,
  };
};

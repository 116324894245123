import { FC } from "react";
import { AlertWrapper } from "./custom-alert.styled";
import { AlertProps } from "./models/alert.model";
import WarningCircle from "@sumerkit/icons/warning-circle";

const CustomAlert: FC<AlertProps> = ({
  title,
  description,
  className,
  icon,
}) => {
  return (
    <AlertWrapper className={className}>
      <div className="icon">{icon || <WarningCircle weight="fill" />}</div>
      <div className="copys">
        {title && <div className="title">{title}</div>}
        <div className="alertDescription">{description}</div>
      </div>
    </AlertWrapper>
  );
};

export default CustomAlert;

import { FC } from "react";
import { LearningItemsWrapper } from "./learning-items.styled";
import { useHome } from "../../hooks/home.hooks";
import { HomeLearning } from "../../constants/home.constants";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "@dashboard/common/analytics";
import { HomeEvent, HomeEventSubtype } from "../../constants/home.events";

interface LearningItemsProps {
  
}
 
const LearningItems: FC<LearningItemsProps> = () => {

  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const { learnings, goToYoutubeSumer } = useHome();

  const handleItemClick = (learning: HomeLearning) => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.HomeTutorials,
    });
    window.open(learning.link, '_blank');
  };
  
  return (
    <LearningItemsWrapper>
      <div className="first" onClick={goToYoutubeSumer}>
        <div className="texts">
          <div className="icon">
            <span className="icon-arrow-2" />
          </div>
          <div className="title">{t('home.learning.title')}</div>
          <div className="description">{t('home.learning.description')}</div>
        </div>
      </div>
      {learnings.map((learning, i) => (
        <div
          key={`learning-item-${i}`}
          className="item"
          onClick={() => handleItemClick(learning)}>
          <img src={learning.img} alt="Learning" className="img" />
          <div className="name">{learning.title}</div>
          <div className="watch-quantity">{t('home.learning.visits')} {learning.views}</div>
        </div>
      ))}
    </LearningItemsWrapper>
  );
}
 
export default LearningItems;
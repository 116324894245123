import { useAnalytics } from "@dashboard/common/analytics";
import { actions, useAppDispatch } from "@dashboard/config/redux";
import { CoreEvents } from "@dashboard/constants/events";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";
import { compareSkuFetch } from "@dashboard/modules/products/fetchers/product-sku.fetchers";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { SkuInputForm } from "@dashboard/modules/products/types/product-sku.types";
import { Category } from "@dashboard/modules/products/types/products.types";
import { debounce } from "@dashboard/utils/debounce";
import { useLocalStorage } from "@sumerlabs/component-library";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";

type hookInformationProps = {
  setSectionsValid?: (values: { [key: string]: boolean }) => void;
};

export const useLogicInformation = ({ setSectionsValid }: hookInformationProps) => {
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [categoriesBackup, setCategoriesBackup] = useState<Array<Category>>([]);
  const [subCategories, setSubCategories] = useState<Array<string>>([]);
  const [subCategoriesBackup, setSubCategoriesBackup] = useState<Array<string>>(
    []
  );
  const [accessToken] = useLocalStorage("accessToken", null);
  const { logEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const [alreadyExistSku, setAlreadyExistSku] = useState(false);
  const { isAvailable: skusIsAvailable } = useFeatureAvailability(
    FeatureProducts.SkuConfiguration
  );
  const { setFieldValue } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const [showDeliveryDays, setShowDeliveryDays] = useState(
    values.inventory?.deliveryDays ? true : false
  );

  const getCategoryNames = (array: Category[]): Array<string> =>
    array.length !== 0 ? array.map((item: Category) => item.name) : [];

  const searchFilterCategories = (input: string) => {
    setCategories(categoriesBackup);
    if (input) {
      const filter = categoriesBackup.filter((item) =>
        item.name?.toLowerCase().includes(input.toLowerCase())
      );
      setCategories(filter);
      setSubCategories(getSubCategories(input, filter));
      setSubCategoriesBackup(getSubCategories(input, filter));
    }
  };

  const searchFilterSubCategories = (input: string) => {
    setSubCategories(subCategoriesBackup);
    if (input) {
      const filter = subCategoriesBackup.filter((item) =>
        item.toLowerCase().includes(input.toLowerCase())
      );
      setSubCategories(filter);
    }
  };

  const getSubCategories = (catName: string, cat: Category[]): string[] => {
    return cat.find((c) => c.name === catName)?.subCategories ?? [];
  };

  const onClickSku = useCallback(() => {
    if (!skusIsAvailable) {
      dispatch(actions.showCustomPlanHook(FeatureProducts.SkuConfiguration));
      logEvent(CoreEvents.SystemExpertWarning, {
        event_subtype: PRODUCTS_EVENTS.PRODUCT_SKU,
        option: "general",
      });
    }
  }, [skusIsAvailable]);

  const onChangeSku = useCallback(
    debounce((data: SkuInputForm) => {
      if (accessToken && data.sku) {
        const skuList = (values.productToppingVariations || [])
          .filter((item) => !!item.sku)
          .map((item) => item.sku!!);
        compareSkuFetch(accessToken, {
          skuToCompare: data.sku,
          id: values.id || "",
          skuList,
        }).then((response) => {
          setAlreadyExistSku(response.alreadyExists);
          if (response.alreadyExists) {
            setSectionsValid && setSectionsValid({ inventory: false });
            logEvent(CoreEvents.StateErrorWarning, {
              event_subtype: PRODUCTS_EVENTS.PRODUCT_SKU,
              option: "repeated",
            });
          }
        });
      } else {
        setAlreadyExistSku(false);
      }
      setFieldValue("sku", data.sku);
    }, 400),
    [accessToken, values]
  );

  return {
    categories,
    setCategories,
    categoriesBackup,
    setCategoriesBackup,
    subCategories,
    setSubCategories,
    getCategoryNames,
    searchFilterCategories,
    searchFilterSubCategories,
    getSubCategories,
    alreadyExistSku,
    skusIsAvailable,
    onChangeSku,
    onClickSku,
    showDeliveryDays,
    setShowDeliveryDays,
  };
};

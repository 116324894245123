import { getApiUrl } from "@dashboard/common/utils";
import {
  ContactButtonEditPayload,
} from "../entities/contact-button.entities";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
} as HeadersInit;

export const createContactButtonInfo = async (
  token: string,
  data: ContactButtonEditPayload,
): Promise<boolean> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/addon/addon-user/body-group/floating_button`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { ...headers, Authorization: `Bearer ${token}` },
    },
  );
  return response.status === 200;
};

export const fetchLastUserContactAddon = async (
  id: string,
): Promise<
  { body: ContactButtonEditPayload; isActive: boolean } | undefined
> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/addon/addon-user/last/${id}/floating_button`,
    {
      method: "GET",
      headers: headers,
    },
  );
  return response.status === 200
    ? response.json().then((r) => ({ body: r.body, isActive: r.is_active }))
    : undefined;
};

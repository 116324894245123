const PlusLine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.6875C12.2335 6.6875 5.9375 12.9835 5.9375 20.75C5.9375 28.5165 12.2335 34.8125 20 34.8125C27.7665 34.8125 34.0625 28.5165 34.0625 20.75C34.0625 12.9835 27.7665 6.6875 20 6.6875ZM4.0625 20.75C4.0625 11.948 11.198 4.8125 20 4.8125C28.802 4.8125 35.9375 11.948 35.9375 20.75C35.9375 29.552 28.802 36.6875 20 36.6875C11.198 36.6875 4.0625 29.552 4.0625 20.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8125 20.75C12.8125 20.2322 13.2322 19.8125 13.75 19.8125H26.25C26.7678 19.8125 27.1875 20.2322 27.1875 20.75C27.1875 21.2678 26.7678 21.6875 26.25 21.6875H13.75C13.2322 21.6875 12.8125 21.2678 12.8125 20.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 13.5625C20.5178 13.5625 20.9375 13.9822 20.9375 14.5V27C20.9375 27.5178 20.5178 27.9375 20 27.9375C19.4822 27.9375 19.0625 27.5178 19.0625 27V14.5C19.0625 13.9822 19.4822 13.5625 20 13.5625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusLine;

const Images = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 5.88477C6.36193 5.88477 6.25 5.99669 6.25 6.13477V26.1348C6.25 26.2728 6.36193 26.3848 6.5 26.3848H26.5C26.6381 26.3848 26.75 26.2728 26.75 26.1348V6.13477C26.75 5.99669 26.6381 5.88477 26.5 5.88477H6.5ZM4.75 6.13477C4.75 5.16827 5.5335 4.38477 6.5 4.38477H26.5C27.4665 4.38477 28.25 5.16827 28.25 6.13477V26.1348C28.25 27.1013 27.4665 27.8848 26.5 27.8848H6.5C5.5335 27.8848 4.75 27.1013 4.75 26.1348V6.13477Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8241 13.9346C21.0382 13.845 21.2679 13.7988 21.5 13.7988C21.7321 13.7988 21.9618 13.845 22.1759 13.9346C22.3889 14.0238 22.5821 14.1543 22.7445 14.3185C22.7453 14.3193 22.7461 14.3201 22.7469 14.3209L28.0303 19.6043C28.3232 19.8972 28.3232 20.3721 28.0303 20.665C27.7374 20.9579 27.2626 20.9579 26.9697 20.665L21.6781 15.3734C21.6548 15.3498 21.6271 15.331 21.5966 15.3182C21.566 15.3054 21.5332 15.2988 21.5 15.2988C21.4668 15.2988 21.434 15.3054 21.4034 15.3182C21.3729 15.331 21.3451 15.3498 21.3219 15.3734L21.3178 15.3775L15.7446 20.9507C15.5823 21.115 15.389 21.2455 15.1759 21.3347C14.9618 21.4243 14.7321 21.4705 14.5 21.4705C14.2679 21.4705 14.0382 21.4243 13.8241 21.3347C13.6111 21.2455 13.4178 21.115 13.2554 20.9507L10.6781 18.3734C10.6548 18.3498 10.6271 18.331 10.5966 18.3182C10.566 18.3054 10.5332 18.2988 10.5 18.2988C10.4668 18.2988 10.434 18.3054 10.4034 18.3182C10.3729 18.331 10.3451 18.3498 10.3219 18.3734L10.3178 18.3775L6.03033 22.665C5.73744 22.9579 5.26256 22.9579 4.96967 22.665C4.67678 22.3721 4.67678 21.8972 4.96967 21.6043L9.25543 17.3186C9.4178 17.1543 9.61107 17.0238 9.82412 16.9346C10.0382 16.845 10.2679 16.7988 10.5 16.7988C10.7321 16.7988 10.9618 16.845 11.1759 16.9346C11.3889 17.0238 11.5822 17.1543 11.7446 17.3186L14.3219 19.8959C14.3452 19.9195 14.3729 19.9383 14.4034 19.9511C14.434 19.9639 14.4669 19.9705 14.5 19.9705C14.5331 19.9705 14.566 19.9639 14.5966 19.9511C14.6271 19.9383 14.6549 19.9195 14.6781 19.8959L14.6822 19.8918L20.2554 14.3186C20.4177 14.1543 20.611 14.0238 20.8241 13.9346Z"
        fill="currentColor"
      />
      <path
        d="M13 12.8848C13.6904 12.8848 14.25 12.3251 14.25 11.6348C14.25 10.9444 13.6904 10.3848 13 10.3848C12.3096 10.3848 11.75 10.9444 11.75 11.6348C11.75 12.3251 12.3096 12.8848 13 12.8848Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Images;

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepCustomizationWrapper } from "./step-customization.styled";
import { UiButton } from "@sumerlabs/component-library";
import { useAnalytics } from "@dashboard/common/analytics";
import frameImg from "@dashboard/assets/img/onboarding-personalize-frame.webp";
import { CoreEvents } from "@dashboard/constants/events";
import { useSelector } from "react-redux";
import { countryInformationSelector } from "@dashboard/config/redux";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { usePersonalization } from "@dashboard/modules/shared/components/onboarding-shared/hooks/personalization.hooks";
import KeepCustomizationPopup from "@dashboard/modules/shared/components/onboarding-shared/components/keep-customization-popup/keep-customization-popup";
import { PersonalizationViewMode } from "@dashboard/modules/shared/components/onboarding-shared/constants/personalization.constants";
import { useStepCustomization } from "../../hooks/step-customization";
import AiLoader from "../ai-loader/ai-loader";
import LoginModal from "@dashboard/modules/login-modal/login-modal";
import useOnboardingStepPremium from "../../hooks/step-plan.hook";

export type StepCustomizationTypes = {
  planPath?: string;
  onNext?: () => void;
  noSave?: boolean;
  nextButtonText?: string;
  modalLogin?: boolean;
};

const StepCustomization: FC<StepCustomizationTypes> = ({
  planPath,
  onNext,
  noSave,
  nextButtonText,
  modalLogin
}) => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const {
    customizationDesktopUrl,
    customizationMobileUrl,
    personalizationViewMode,
    changePersonalizationViewMode,
  } = usePersonalization();
  const { handlePlanClick } = useOnboardingStepPremium();
  const [showPopup, setShowPopup] = useState(false);
  const country = useSelector(countryInformationSelector);
  const {
    handleNext,
    handleBack,
    reloadFrame,
    finishOnb,
    getDemoTemplate,
    showLoader,
    planUltra,
  } = useStepCustomization(planPath || "/onboarding/premium", noSave);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);

  return (
    <>
      {showLoader && <AiLoader />}
      <StepCustomizationWrapper>
        <LoginModal showModal={showModalLogin} onClose={() => setShowModalLogin(false)}/>
        <KeepCustomizationPopup
          image="https://sumerlabs.com/prod/sumerweb/onboarding/onb-customization-value-plan.png"
          title={t("onboarding.popups.customizationValue.title")}
          description={t("onboarding.popups.customizationValue.description", {
            value: formatCurrency(planUltra?.price || 0, country),
          })}
          confirmLabel={t("onboarding.popups.customizationValue.confirmLabel")}
          linkLabel={t("onboarding.popups.customizationValue.linkLabel")}
          show={showPopup}
          onClose={() => setShowPopup(false)}
          onConfirmLabel={() => {
            handlePlanClick(planUltra);
            logEvent(CoreEvents.SelectButton, {
              event_subtype: "expert_plan_onboarding_confirmation",
              source: "personalization",
            });
          }}
          onLinkLabel={() => {
            handleNext();
            logEvent(CoreEvents.SelectButton, {
              event_subtype: "continue_plans",
              source: "personalization",
              method: "onboarding",
            });
          }}
        />
        <div className="header">
          <div className="back" onClick={handleBack}>
            <span className="arrow icon-arrow-top" />
          </div>
          <div className="toggle-buttons">
            <button
              className={
                personalizationViewMode === PersonalizationViewMode.Desktop
                  ? "active"
                  : ``
              }
              onClick={() =>
                changePersonalizationViewMode(PersonalizationViewMode.Desktop)
              }
            >
              <span className="icon icon-light-desktop" />
            </button>
            <button
              className={
                personalizationViewMode === PersonalizationViewMode.Mobile
                  ? "active"
                  : ``
              }
              onClick={() =>
                changePersonalizationViewMode(PersonalizationViewMode.Mobile)
              }
            >
              <span className="icon icon-light-mobile" />
            </button>
          </div>
          <div className="controlsIA">
            <div className="buttonIA" onClick={reloadFrame}>
              <figure className="icon-refresh"></figure>
              <span>{t("regenerateButton")}</span>
            </div>
          </div>
          <div className="next">
            <UiButton
              className="button"
              onClick={() => {
                onNext && onNext();
                if (modalLogin) {
                  setShowModalLogin(true);
                } else {
                  finishOnb();
                }
              }}
            >
              {`${nextButtonText || t("save")}`}
              <span className="arrow icon-arrow" />
            </UiButton>
          </div>
        </div>
        <div className="container">
          {personalizationViewMode === PersonalizationViewMode.Desktop &&
            !!customizationDesktopUrl && (
              <div className="preview">
                <iframe
                  className="iframe"
                  src={`${customizationDesktopUrl}${getDemoTemplate}`}
                />
              </div>
            )}
          {personalizationViewMode === PersonalizationViewMode.Mobile &&
            !!customizationMobileUrl && (
              <div className="preview-mobile">
                <div className="frame">
                  <img src={frameImg} alt="Bg" className="bg" />
                  <iframe
                    className="iframe"
                    src={`${customizationMobileUrl}${getDemoTemplate}`}
                  />
                </div>
              </div>
            )}
        </div>
      </StepCustomizationWrapper>
    </>
  );
};

export default StepCustomization;

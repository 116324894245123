import { FC, useCallback, useEffect, useState } from "react";
import { InputCurrencyProps } from "./models/input-currency.model";
import { InputWrapper, Wrapper } from "./custom-input-currency.styled";
import CurrencyInput from "react-currency-input-field";
import { debounce } from "@dashboard/utils/debounce";

const CustomInputCurrency: FC<InputCurrencyProps> = ({
  value,
  name,
  hasError,
  errorMessage,
  onChange,
  sign,
  className,
  placeholder,
  disabled,
  id,
  onFocus
}) => {
  const [localValue, setLocalValue] = useState(value);

  const debounceChange = useCallback(
    debounce(() => {
      setLocalValue((value) => {
        return value;
      });
    }, 1000),
    [localValue]
  );

  const handleChange = (value?: string) => {
    const values = (value || "").split(".");
    if (!(values.length === 2 && (values[1] === "" || values[1] === "0"))) {
      onChange(value || "");
    } else {
      setLocalValue(value);
      debounceChange();
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Wrapper>
      <InputWrapper className={`${className}`}>
        <CurrencyInput
          id={id || "input-example"}
          name={name}
          className={`${hasError && "hasError"} ${disabled && "disabled"}`}
          placeholder={placeholder}
          value={localValue}
          decimalsLimit={2}
          decimalSeparator="."
          prefix={`${sign ? `${sign} $` : ""}`}
          onValueChange={handleChange}
          groupSeparator=","
          disabled={disabled}
          onFocus={onFocus}
        />
      </InputWrapper>
      {hasError && <small className="hasError">{errorMessage}</small>}
    </Wrapper>
  );
};

export default CustomInputCurrency;

import styled from "styled-components";

export const MobileWrapper = styled.div`
  height: 100%;
  display: flex;
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column; 
    .title {
      color: #24272E;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin: 30px 60px;
    }
    .benefits {
      display: flex;
      row-gap: 20px;
      list-style: disc;
      flex-direction: column;
      margin: 0 60px 30px 86px;
      li {
        color: #24272E;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .qr {
      width: 150px;
      display: flex;
      flex-direction: column;
      margin: 0 60px 30px 60px;
      .text {
        padding: 0 10px;
        color: #24272E;
        font-weight: 400;
        font-size: 21.84px;
        line-height: 32.75px;
      }
    }
    .platforms {
      display: flex;
      column-gap: 20px;
      margin: 0 60px 30px 60px;
      img {
        width: 154px;
      }
    }
  }
  .right {
    flex: 1;
    position: relative;
    .bg {
      top: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      position: absolute;
    }
    .bg2 {
      animation-delay: 5s;
      animation-fill-mode: forwards;
      animation: oneByOne 5s infinite;
    }
  }

  @media screen and (min-width: 1440px) {
    .main-content {
      .title {
        margin: 30px 48px;
      }
      .benefits {
        margin: 0 48px 30px 60px;
      }
      .qr {
        margin: 0 48px 30px 48px;
      }
      .platforms {
        margin: 0 48px 30px 48px;
      }
    }
  }

  @keyframes oneByOne {
    0% {
      z-index: 2;
    }
    100% {
      z-index: 0;
    }
  }
`;

import { RootState } from "@dashboard/config/redux";
import { createSelector } from "@reduxjs/toolkit";

export const contactButtonUiSelector = createSelector(
  (state: RootState) => state.contactButton.ui,
  (ui) => ui
);

export const buttonConfigSelector = createSelector(
  (state: RootState) => state.contactButton.data.buttonConfig,
  (buttonConfig) => buttonConfig
);

export const oldButtonConfigSelector = createSelector(
  (state: RootState) => state.contactButton.data.oldButtonConfig,
  (oldButtonConfig) => oldButtonConfig
);

export const contactButtonConfigurationOptionsSelector = createSelector(
  (state: RootState) => state.contactButton.data.contactButtonConfigurationOptions,
  (contactButtonConfigurationOptions) => contactButtonConfigurationOptions
);

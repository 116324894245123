import styled from "styled-components";

export const ToastCustomStyled = styled.aside`
  align-items: center;
  background: #ffffff;
  border-left: 0.5rem solid #1458f5;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px -2px rgba(135, 135, 136, 0.5);
  color: #24272e;
  display: flex;
  gap: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5em;
  min-height: 60px;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  [class^="icon-"] {
    color: #1458f5;
    font-size: 2em;
  }
  &.toast-custom__danger {
    border-left-color: #f93c00;
    [class^="icon-"] {
      color: #f93c00;
    }
  }
  &.toast-custom__success {
    border-left-color: #3abd77;
    [class^="icon-"] {
      color: #3abd77;
    }
  }
  &.toast-custom__warning {
    border-left-color: #ffb237;
    [class^="icon-"] {
      color: #ffb237;
    }
  }
`;

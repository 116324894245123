import {
  AddonInfo,
  Configuration,
  PlatformCountry,
  PartnerData,
  StoreUrlShare,
} from "@dashboard/common/types";
import { HookUpgradePlanState } from "@dashboard/components/hook-upgrade-plan/hook-upgrade-plan.types";
import { CustomPlanHook } from "@dashboard/constants/custom-plan-hook";
import { HookInfo } from "@dashboard/entities/hook-info";
import { Menu } from "@dashboard/entities/menu";
import { PlanBenefit } from "@dashboard/entities/plan-benefit";
import { Plan } from "@dashboard/entities/plan-funnel";
import { UserAddon } from "@dashboard/entities/user-addon";
import { Plan as PlanI, PlanParams, ReviewsPlansTypes } from "@dashboard/modules/plans/plans.types";

export interface GlobalState {
  ui: GlobalUiState;
  data: GlobalDataState;
}

export interface GlobalUiState {
  showGeneralLoader: boolean;
  isLoadingProductsFile: boolean;
  loadingProductsProgress: number;
  isLoadingConfiguration: boolean;
  showAddonHook: boolean;
  upgradePlanHook?: HookUpgradePlanState;
  showAiAssistant?: boolean;
  messageAiAssistant?: string;
  globalLoader: GlobalLoader;
  showShareModal: boolean;
  showCustomPlanHook: boolean;
  customPlanHookFeature?: string;
  isNavigationBarCollapsed: boolean;
}

export interface GlobalLoader {
  showLoader: boolean;
  copys: Array<string>;
}

export interface GlobalDataState {
  partner?: PartnerData;
  isLoginError: boolean;
  configuration: Configuration | null;
  urlShare?: StoreUrlShare;
  countries: PlatformCountry[];
  countryInformation?: PlatformCountry;
  plan?: Plan;
  availableFeatures: PlanBenefit[];
  availableAddons: UserAddon[];
  toBuyAddonInfo?: AddonInfo;
  menu: Menu[];
  reviewsPlans: ReviewsPlansTypes[];
  hookInfo?: HookInfo;
  customPlanHooks: CustomPlanHook[];
  plans: PlanI[];
  planParams?: PlanParams
}

export const initialState: GlobalState = {
  ui: {
    showGeneralLoader: false,
    isLoadingProductsFile: false,
    loadingProductsProgress: 0,
    isLoadingConfiguration: false,
    showAddonHook: false,
    globalLoader: {
      showLoader: false,
      copys: []
    },
    showShareModal: false,
    showCustomPlanHook: false,
    customPlanHookFeature: "",
    isNavigationBarCollapsed: false,
  },
  data: {
    isLoginError: false,
    configuration: null,
    countries: [],
    availableFeatures: [],
    availableAddons: [],
    menu: [],
    reviewsPlans: [],
    customPlanHooks: [],
    plans: [],
  },
};

import styled from "styled-components";

export const InConstructionWrapper = styled.div`
  .modal {
    width: 524px;
    overflow: hidden;
    border-radius: 16px;
    background: var(--grey-w, #FFF);
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18), 2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    .img {
      width: 100%;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin: 24px 24px 8px 24px;
      color: var(--grey-b, #24272E);
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin: 0 24px 24px 24px;
      color: var(--grey-b, #24272E);
    }
    .button {
      height: 50px;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      margin: 0 24px 24px 24px;
      color: var(--grey-8, #FCFCFC);
      background: var(--grey-b, #24272E);
    }
  }
`;

import { FC, useEffect, useState } from "react";
import { CustomCounterContainer } from "./custom-counter.styled";
import { CounterProps } from "./models/counter.type";
import Minus from "@sumerkit/icons/minus"
import { ECounterSource } from "./constants/counter.constants";

const CustomCounter: FC<CounterProps> = ({ value, onChange, minimum, onClick }) => {
  const [counter, setCounter] = useState(value ?? 0);
  const increaseCounter = () => {
    const auxCounter = +counter + 1;
    setCounter(auxCounter);
    onClick && onClick(ECounterSource.INCREASE);
    onChange(auxCounter);
  };
  const reduceCounter = () => {
    const auxCounter = +counter === (minimum || 0) ? (minimum || 0) : +counter - 1;
    setCounter(auxCounter);
    onClick && onClick(ECounterSource.REDUCE);
    onChange(auxCounter);
  };
  const asignValue = (value: number) => {
    setCounter(value);
    onChange(value);
  };

  useEffect(() => {
    setCounter(value ?? 0);
  }, [value]);


  return (
    <CustomCounterContainer>
      <Minus weight="bold" className={`icon minus icon-minus ${counter === 0 && 'disabled'}`} onClick={reduceCounter} />
      <small className="counter">
        <input
          type="number"
          value={counter}
          onChange={(val) => asignValue(+val.target.value)}
          onClick={() => onClick && onClick(ECounterSource.CUSTOM)}
        />
      </small>
      <span className="icon plus icon-plus" onClick={increaseCounter}></span>
    </CustomCounterContainer>
  );
};

export default CustomCounter;


import {
  ContactButtonIconOption,
  ContactButtonPersonalizationOption,
  ContactButtonPositionOption,
} from "../entities/contact-button.entities";

export enum ContactButtonPersonalizationOptionId {
  None = "none",
  Configuration = "configuration",
  Messages = "messages",
  Position = "position",
  Icon = "icon",
  Color = "color",
};

export const CONTACT_BUTTON_CONFIGURATION_OPTIONS: ContactButtonPersonalizationOption[] =
  [
    {
      id: ContactButtonPersonalizationOptionId.Configuration,
      icon: "puzzle-piece",
      text: "config",
    },
    {
      id: ContactButtonPersonalizationOptionId.Messages,
      icon: "comments-outline",
      text: "messages",
    },
    {
      id: ContactButtonPersonalizationOptionId.Position,
      icon: "full-screen",
      text: "position",
    },
    {
      id: ContactButtonPersonalizationOptionId.Icon,
      icon: "image",
      text: "icon",
    },
    {
      id: ContactButtonPersonalizationOptionId.Color,
      icon: "light-paintBrush",
      text: "color",
    },
  ];

export enum ContactButtonIconName {
  One = "talk",
  Two = "chat-circle-text",
  Three = "chat-circle-dots",
  Four = "chats-circle",
};

export const CONTACT_BUTTON_ICONS: ContactButtonIconOption[] = [
  { text: "1", icon: ContactButtonIconName.One },
  { text: "2", icon: ContactButtonIconName.Two },
  { text: "3", icon: ContactButtonIconName.Three },
  { text: "4", icon: ContactButtonIconName.Four },
];

export enum ContactButtonPositionXAxis {
  Left = "left-x",
  Center = "center-x",
  Right = "right-x",
};

export enum ContactButtonPositionYAxis {
  Center = "center-y",
  Bottom = "bottom-y",
};

export const CONTACT_BUTTON_POSITION_X_AXIS_OPTIONS: ContactButtonPositionOption[] =
  [
    {
      id: ContactButtonPositionXAxis.Left,
      text: "left",
      icon: "light-align-left",
    },
    {
      id: ContactButtonPositionXAxis.Center,
      text: "center",
      icon: "light-align-center-horizontal",
    },
    {
      id: ContactButtonPositionXAxis.Right,
      text: "right",
      icon: "light-align-right",
    },
  ];
export const CONTACT_BUTTON_POSITION_Y_AXIS_OPTIONS: ContactButtonPositionOption[] =
  [
    {
      id: ContactButtonPositionYAxis.Center,
      text: "middle",
      icon: "align-vertical-center",
    },
    {
      id: ContactButtonPositionYAxis.Bottom,
      text: "bottom",
      icon: "align-vertical-bottom",
    },
  ];

export enum DefaultContactButtonColors {
  background = "#4CCA5B",
  icon = "#FFFFFF",
};

export const CONTACT_BUTTON_COLOR_OPTIONS: string[] = [
  "custom",
  "#0078D2",
  "#D43B60",
  "#EE354D",
  "#703FFF",
  "#DE00DE",
  "#2C68F6",
  "#F36538",
  "#FFC300",
  DefaultContactButtonColors.background,
];

export enum ContactButtonMessageWordsToReplace {
  StoreName = "[store_name]",
  StoreLink = "[store_link]",
  ProductName = "[product_name]",
  ProductLink = "[product_link]",
};

export enum ContactButtonEvents {
  SelectButton = "select_button",
  SelectSaveConfiguration = "select_save_configuration",
};

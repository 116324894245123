import { FC, useEffect, useState } from "react";
import { StepInfoWrapper } from "./step-info.styled";
import StoreDescription from "../../components/store-description/store-description";
import { useTranslation } from "react-i18next";
import {
  allowOnlyNumber,
  UiAutocomplete,
  UiButton,
  UiCheckbox,
  UiPhone,
} from "@sumerlabs/component-library";
import { useStepInfo } from "../../hooks/step-info.hook";
import { CoreEvents } from "@dashboard/constants/events";
import { useOnboarding } from "@dashboard/modules/shared/components/onboarding-shared/hooks/onboarding.hook";

export type StepInfoTypes = {
  ctaNextButtonText?: string;
  ctaNextStepPath?: string;
  hiddeEconomicSector?: boolean;
  isGuest?: boolean
};

const StepInfo: FC<StepInfoTypes> = ({
  ctaNextButtonText,
  ctaNextStepPath,
  hiddeEconomicSector,
  isGuest
}) => {
  const { t } = useTranslation();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const {
    form,
    prefixCodeOptions,
    autocompleteOptions,
    logEvent,
    handleSectorAutocompleteClick,
    handleAutocompleteInputChange,
    isLoading,
    sectorList,
    activateSector,
  } = useStepInfo(ctaNextStepPath, hiddeEconomicSector, isGuest);
  const { getEconomicSectors } = useOnboarding();

  useEffect(() => {
    logEvent(CoreEvents.ViewScreen, {
      event_subtype: "onboarding_steps",
      option: "economic_sector_other",
    });
    getEconomicSectors();
  }, []);

  return (
    <StepInfoWrapper className="fadeInRight">
      <div className="description">
        <StoreDescription
          title={t("sharedOnboarding.stepInfo.title")}
          description={t("sharedOnboarding.stepInfo.description")}
        />
      </div>
      <div className="form">
        <div className="content">
          <div className="info">
            <div className="phone-contact">
              <div className="title">
                {t("sharedOnboarding.stepInfo.form.infoTitle")}
              </div>
              <div className="phone-input-container">
                <UiPhone
                  label={t("onboarding.info.country")}
                  placeholder={t("onboarding.info.phonePlaceholder")}
                  className="phone-input"
                  options={prefixCodeOptions}
                  hasError={
                    !!form.errors.phone &&
                    (form.touched.phone || form.touched.prefixPhone)
                  }
                  errorMessage={form.errors.phone || form.errors.prefixPhone}
                  phoneValue={allowOnlyNumber(form.values.phone)}
                  prefixValue={form.values.prefixPhone}
                  onBlur={() => {
                    form.setFieldTouched("prefixPhone");
                    form.setFieldTouched("phone");
                  }}
                  onChange={(v) => {
                    if (v.phone) form.setFieldValue("phone", v.phone);
                    if (v.prefixPhone) {
                      form.setFieldValue("prefixPhone", v.prefixPhone);
                      form.setFieldValue("country", v.prefixPhone);
                    }
                  }}
                />
                <div className="checkbox-container">
                  <UiCheckbox active={true} onChange={(v) => {}} />
                  <small className="text">
                    {t("sharedOnboarding.stepInfo.form.checkboxText")}
                  </small>
                </div>
              </div>
            </div>
          </div>
          {!hiddeEconomicSector && (
            <div className="economic-sector">
              <div className="about-text">
                <div className="title">
                  {t("sharedOnboarding.stepInfo.form.aboutSiteTitle")}
                </div>
                <div className="description">
                  {t("sharedOnboarding.stepInfo.form.aboutSiteDescription")}
                </div>
              </div>
              <div className="sectors-list">
                <div className="search-bar">
                  <UiAutocomplete
                    name="economicSector"
                    className="economic-select"
                    options={autocompleteOptions}
                    value={form.values.economicSector}
                    showNoResults={false}
                    hasError={
                      !!form.touched.economicSector &&
                      !!form.errors.economicSector
                    }
                    errorMessage={
                      form.touched.economicSector
                        ? form.errors.economicSector
                        : ""
                    }
                    placeholder={t("onboarding.sector.placeholder")}
                    hint={t("onboarding.sector.hint")}
                    onFocus={() => {
                      logEvent(CoreEvents.SelectInput, {
                        event_subtype: "onboarding_steps",
                        option: "economic_sector_other",
                      });
                    }}
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      handleSectorAutocompleteClick(e);
                    }}
                    onInputChange={(e) => {
                      form.setFieldValue("economicSector", e, true);
                      handleAutocompleteInputChange(e);
                    }}
                  />
                  <div className="label">
                    <div className="text">
                      {t(
                        "sharedOnboarding.stepInfo.form.economicSectorLabelText"
                      )}
                    </div>
                    <div
                      className="link"
                      onClick={() => setShowSuggestions(true)}
                    >
                      {t(
                        "sharedOnboarding.stepInfo.form.economicSectorLabelLink"
                      )}
                    </div>
                  </div>
                </div>
                {showSuggestions && (
                  <div className="list fadeInUp">
                    {sectorList.map((sector) => (
                      <div
                        key={sector.value}
                        className={`chip ${sector.isActive && "is-active"}`}
                        onClick={() => {
                          activateSector(sector.value);
                          handleSectorAutocompleteClick(sector.value);
                        }}
                      >
                        {sector.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="controls">
          <UiButton
            className="button"
            icon={<span className="icon-arrow" />}
            disabled={!form.isValid || isLoading}
            onClick={() => form.handleSubmit()}
          >
            {`${
              ctaNextButtonText ||
              t("sharedOnboarding.stepInfo.form.submitButtonText")
            }`}
          </UiButton>
        </div>
      </div>
    </StepInfoWrapper>
  );
};

export default StepInfo;

import { getEnvVar } from "@dashboard/common/utils";
import { StorageItem } from "@dashboard/constants/storage-items";
import { FC, useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import Header from "../shared/components/onboarding-shared/components/header/header";
import { useDispatch } from "react-redux";
import { fetchPartnerByToken } from "@dashboard/config/redux";
import { fetchUserDetail } from "../shared/components/onboarding-shared/services/onboarding.services";
import { actions, actions as onboardingActions } from "../shared/components/onboarding-shared/store/onboarding.slice";
import { useLocalStorage } from "@sumerlabs/component-library";
import { AbOnboarding } from "../shared/components/onboarding-shared/constants/split.constants";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { QUERY_PARAMS_TEMPLATES } from "../shared/components/onboarding-shared/constants/params.constants";
import { useOnboardingAi } from "../onboarding-ai/hooks/onboarding-ai.hook";

const OnboardingGuest: FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [token, setLocalStorage] = useLocalStorage(StorageItem.Token, "");
  const tokenGuess = getEnvVar("REACT_APP_TOKEN_GUESS");
  const { webStatus, decodedToken } = useSession();
  const [searchParams] = useSearchParams();
  const templateLandingQueryToken = searchParams.get(QUERY_PARAMS_TEMPLATES.TOKEN);
  const { mergeUserGuest } = useOnboardingAi();

  useEffect(() => {
    userDetails(token);
  }, [dispatch, token]);

  useEffect(() => {
    if (
      !pathname.includes("onboarding-guest/info") &&
      !pathname.includes("onboarding-guest/premium")
    ) {
      setLocalStorage(templateLandingQueryToken || tokenGuess);
    }
    dispatch(actions.setOnboardingType(AbOnboarding.AB_ONBOARDING_GUEST));
    if (sessionStorage.getItem("guestToken")) {
      mergeUserGuest();
    }
  }, []);

  useEffect(() => {
    if (decodedToken?.user_id) {
      webStatus(decodedToken?.user_id);
    }
  }, [decodedToken]);

  const userDetails = (currentToken: string) => {
    if (!!currentToken) {
      dispatch(fetchPartnerByToken(currentToken));
      fetchUserDetail(currentToken)
        .then((response) => {
          dispatch(onboardingActions.setOnboardingData(response));
        })
        .catch();
    }
  };

  return (
    <>
      {(!pathname.includes("step-one") && !pathname.includes("step-five"))&& <Header />}
      <Outlet />
    </>
  );
};

export default OnboardingGuest;

export const isServer = typeof window == 'undefined';

export function getEnvVar(key: string) {
    return process.env[key] || key;
}

export const isMobile = () => {
    const ua = navigator.userAgent;
    return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
}

export const getApiUrl = () => {
    return getEnvVar('REACT_APP_BASE_URL');
};

export const getDomain = () => {
  return getEnvVar("REACT_APP_HOST");
};


import styled from "styled-components";

export const LoginSelectOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  .login-texts-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .logo {
      width: 166px;
    }
    .description {
      color: #262930;
      font-size: 20px;
      line-height: 28px;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
  .login-options-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .container-title {
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
      hr.line {
        margin-top: 16px;
        border: 0;
        height: 0;
        border-top-color: #edecfe;
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-bottom: 1px solid #edecfe;
      }
    }
  }
`;

import { getApiUrl } from "@dashboard/common/utils";
import { camelizeKeys, decamelizeKeys } from "humps";
import { getAssistantId } from "../utils/constants.utils";
import { AiMessage, AiMessageCreateResponse } from "../types/messages.types";
import { Feedback } from "../types/feedbacks.types";

export const fetchGetRecentMessages = async (
  token: string
): Promise<AiMessage[]> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/get-recent-messages`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseJson = await response.json();
    return camelizeKeys(responseJson) as AiMessage[];
  }
};

export const fetchCreateMessage = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    ownerId: string;
    message: string;
    history?: AiMessage[];
  };
}): Promise<AiMessageCreateResponse> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        decamelizeKeys({
          ...payload,
          assistantId: getAssistantId(),
        })
      ),
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseJson = await response.json();
    return camelizeKeys(responseJson) as AiMessageCreateResponse;
  }
};

export const fetchGetFeedbacks = async (token: string): Promise<Feedback[]> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/feedback`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseJson = await response.json();
    return camelizeKeys(responseJson) as Feedback[];
  }
};

export const fetchCreateFeedback = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    conversationId: string;
    messageId: string;
    approvedResponse: boolean;
  };
}): Promise<void> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/feedback`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(decamelizeKeys(payload)),
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    return;
  }
};

export const fetchRegenerateMessage = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    message: string;
    inputMessageId: string;
    outputMessageId: string;
    ownerId: string;
    conversationId: string;
  };
}): Promise<AiMessageCreateResponse> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/regenerate-message`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        decamelizeKeys({
          ...payload,
          assistantId: getAssistantId(),
        })
      ),
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseJson = await response.json();
    return camelizeKeys(responseJson) as AiMessageCreateResponse;
  }
};

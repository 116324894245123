import { Product } from "@dashboard/modules/products/types/products.types";
import { ConfigurationOptionId } from "../entities/configuration-option.entity";
import { Voucher } from "../entities/voucher.entity";

export interface VoucherState {
 ui: VoucherUiState;
 data: VoucherDataState;
};

export interface VoucherUiState {
  showVoucherDetails: boolean;
  selectedOptionId?: ConfigurationOptionId;
  showSelectProductsModal: boolean;
  searchProductTerm: string;
  shouldShowDiscounts: boolean;
  isLoadingSaveVoucher: boolean;
  showDeleteVoucherConfirmation: boolean;
  downloadVoucher: 'download' | 'none';
};
export interface VoucherDataState {
  selectedVoucher?: Voucher;
  configuredVoucher: Partial<Voucher>;
  products: Product[];
  vouchers: Voucher[];
};

export const initialState: VoucherState = {
 ui: {
  showVoucherDetails: false,
  selectedOptionId: ConfigurationOptionId.None,
  showSelectProductsModal: false,
  searchProductTerm: "",
  shouldShowDiscounts: false,
  isLoadingSaveVoucher: false,
  showDeleteVoucherConfirmation: false,
  downloadVoucher: 'none',
 },
 data: {
  configuredVoucher: {} as Voucher,
  products: [],
  vouchers: [],
 },
};

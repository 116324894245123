export const checkout = {
  plan: {
    free: 'Plan Limitado',
    legacy: 'Plan Limitado',
    basic: 'Plan Básico',
    pro: 'Plan Pro',
    ultra: 'Plan Ultra',
  },
  cartItem: {
    yearly: 'anual',
    domainDescription: 'Luce como una tienda más profesional con tu domino propio.',
  },
  checkout: {
    subtotal: 'Subtotal',
    total: 'Total',
    couponText: 'Cupón',
    addOtherPaymentCard: 'Agregar otro medio de pago',
    terms: 'Al realizar este pago estás de acuerdo con los {{link}} de la plataforma',
    pay: 'Pagar',
    card: {
      nextPayment: 'Próximo pago',
      copyTitle: 'Algunos beneficios del plan',
      editButton: 'Editar'
    },
    termsAndConditions: "Términos y Condiciones",
    message: "Esta es una suscripción de renovación automática que puedes cancelar en cualquier momento.",
    coupon: {
      inputText: "Ingresar el código del cupón",
      errorText: "Ingresa un código de cupón valido",
      buttonText: "Aplicar"
    },
    plan :{
      nextPayment: "Próximo pago será por",
      month: "mes",
      year: "año",
      days: "días"
    }
  },
  status: {
    title_checkout: '¡Estamos procesando!',
    title_created: '¡Estamos procesando!',
    title_success: '¡Pago Exitoso!',
    title_error: '¡Problema al procesar tu pago!',
    title_failed: '¡Problema al procesar tu pago!',
    description_checkout: 'Estamos procesando el pago para completar la transacción.',
    description_created: 'Estamos procesando el pago para completar la transacción.',
    description_success: 'Hemos recibido tu pago y confirmamos tu compra.',
    description_error: 'Te recomendamos verificar los detalles de tu método de pago e intentarlo nuevamente.',
    description_failed: 'Te recomendamos verificar los detalles de tu método de pago e intentarlo nuevamente.'
  }
};

import { checkout } from "@dashboard/modules/checkout/locales/en";
import { home } from "@dashboard/modules/home/locales/en";
import products from "@dashboard/modules/products/locales/en";
import onboardingAi from "@dashboard/modules/onboarding-ai/locales/en";
import plans from "@dashboard/modules/plans/locales/en";
import profile from "@dashboard/modules/profile/locales/en";
import orders from "@dashboard/modules/orders/locales/en";
import aiAssistant from "@dashboard/modules/ai-assistant/locales/en";
import { addons } from "@dashboard/modules/contact-button/locales/en";
import { configuration } from "@dashboard/modules/configuration/locales/en";
import { voucher } from "@dashboard/modules/voucher/translations/en";
import { mobile } from "@dashboard/modules/mobile/translations/mobile.en";
import sharedOnboarding from "@dashboard/modules/shared/components/onboarding-shared/locales/en";
import productComponent from "@dashboard/modules/products/components/product-component/locales/en";

export const en = {
  translation: {
    continue: 'Continue',
		cancel: 'No, Cancel',
    next: 'Next',
    back: 'Back',
    skip: 'Skip',
    optional: 'Optional',
    apply: 'Apply',
    edit: "Edit",
    save: "Save",
    regenerateButton: "Regenerate with AI",
    accept: "Accept",
    downloadApp: "Download our App",
    start: "Start your online store",
    access: "Get full access to all tools",
    addon: {
      abandoned_cart: "Abandoned Cart",
      floating_button: "WhatsApp Button",
      voucher_addon: "Voucher payment",
      custom_domain: "Domain",
      ai_product: "AI Sumer",
      gg_shopping: "Google Shopping",
      options: {
        try: "Try",
      },
    },
    status: {
      loaderText: "Validating your information!",
    },
    addonHook: {
      get: "Get",
      getFor: "Get it for",
      orChangeTo: "or switch to the plan ",
      description: "Enjoy all the features of Sumer without limits.",
      getAbandonedCart: "Get abandoned cart",
      getUltraPlan: "Get Ultra plan",
    },
    header: {
      explorePlans: "Explore Plans",
      seeMyStore: "See my store",
      shareStore: "Share store",
      popups: {
        sharePopup: {
          title: "Share my store",
          description:
            "Welcome to {{storeName}}! Discover the treasures we have for you 🌟✨",
          linkCopy: "Copy link",
          linkWsp: "Whatsapp",
          linkFacebook: "Facebook",
          linkEmail: "Email",
          message:
            "Welcome to {{storeName}}! Discover the treasures we have for" +
            " you✨⭐: Follow these simple steps to place your order:%0A %0A" +
            "Enter our catalog at the following link: {{storeUrl}}%0A %0A" +
            "Explore and select your favorite products%0A %0A" +
            "Ready! Complete your order and let the magic begin. ✨🛒 Thank you for choosing us!",
        },
        actions: {
          successCopy: "Copied link",
        },
      },
    },
    login2: {
      loginWithQr: "Login with QR Code",
      signUpNow: "Sign Up Now on Sumer",
      login_qr: {
        title: "Login to Sumer Web with QR Code",
        step_1: "Go to the More Tools section.",
        step_2:
          "Within this options list, go to the Business Management section and click on Sumer Web.",
        step_3: "Click on the Link a Device button.",
        step_4: "When the camera is activated, scan the QR code.",
        help: "Need help?",
      },
    },
    loginQr: {
      title: "Enter Sumer from your desktop",
      step1: "1. Go to the Sumer Web section in your App.",
      step2: "2. Click the button <strong>Pair a device.</strong>",
      step3: "3. Scan the QR code.",
    },
    logout: {
      title: "Logout",
      message: "Are you sure you want to log out?",
      confirm: "Yes",
      cancel: "No",
    },
    onboarding: {
      login: {
        selectOption: {
          description:
            "Create your online store with <strong>Artificial Intelligence</strong> in seconds",
          title: "Log in or create your account",
          withGoogle: "Continue with Google",
          withFacebook: "Continue with Facebook",
          withPhone: "Continue with your phone number",
          withEmail: "Continue with email",
          withQr: "Pair with my cell phone",
          terms: "By continuing, you agree to Sumer's",
          and: "and",
          termsLink: "Terms and Conditions",
          data_policy: "Data Policy",
          otherOptions: "Other options",
          seeOtherOptions: "See other options",
          sumer: ".",
          yupSchema: {
            character_number: "Must be minimum of 6 characters",
            require: "Cell phone number is required.",
            require_prefix: "The prefix is required.",
          },
        },
        sendPhone: {
          title: "Enter your phone number",
          label: "Phone",
          disclaimer: "We will send you a code to confirm your phone number.",
          sms: "Receive code by SMS",
          wa: "Receive code by Whatsapp",
        },
        sendEmail: {
          title: "Enter your email",
          disclaimer: "We will send you a code to confirm your email.",
        },
        verifyCode: {
          title: "Verification code",
          phone: "We have sent a verification code to this phone number:",
          email: "We have sent a verification code via email to:",
          error: "The code does not match, please try again.",
          wrongCode: "The code is not working.",
          wrongCodeAgain: "Request another code",
          canResend: "Request another code within:",
          minutes: "minutes",
          seconds: "seconds",
          yupSchema: {
            email_validation: "Must be a valid email.",
            require: "The email is required.",
          },
        },
      },
      info: {
        title: "1. Let's create your store!",
        description:
          "This information helps us provide you with a better experience; you can modify it later.",
        languageLabel: "Catalogue language",
        languageLabelOptional: "Optional",
        country: "Country",
        countryPlaceholder: "Select a country",
        city: "City",
        cityPlaceholder: "Select your city's name",
        phonePlaceholder: "Enter your cell phone number",
        errorPhone: "The entered number is already in use.",
        yupSchema: {
          min_require: "Must be minimum of 10 characters",
          max_require: "Must be maximum of 11 characters",
          require: "Cell phone number is required.",
          require_country: "The country is required.",
          require_prefix: "The prefix is required.",
          require_sector_min: "The text must include more than two characters.",
          require_sector: "The sector is required.",
        },
      },
      sector: {
        title: "2. Information about your business",
        description:
          "Choose the economic sector for your store. Be guided by the category to which your products belong. You can modify it later.",
        type: "What type of store do you want to have online?",
        ownType: "Don't see what you're looking for?",
        placeholder: "Write your store type her",
        hint: "Ej: Clothing and accessories, food, home, women's clothing...",
        button: "Next",
      },
      business: {
        title: "3. Information about your business",
        description:
          "Choose the name by which you want your clients to identify you. You can modify it later.",
        economicLabel: "What will you sell in your store?",
        economicPlaceholder: "-Select an economic sector-",
        storeNameLabel: "What is your store's name?",
        storeLinkLabel: "Choose your online store link",
        storeTo: "{{name}}'s Store",
        button: "Generate your store",
      },
      store: {
        title: "4. Let's design your store",
        description: "How do you want to design your website?",
        generateAi: {
          title: "Generate store with AI",
          description: "Let our artificial intelligence do the magic for you",
          button: "Generate",
        },
        createNew: {
          title: "Create from scratch",
          description: "Select and customize a template for your store",
          button: "Select",
        },
      },
      templates: {
        navbar: {
          skipButton: "Skip",
        },
        title: "Select and customize your template with ease",
        description:
          "Discover the ideal template for your website <strong class='site-name'>{{siteName}}</strong>. With a wide variety of options for all styles.",
        message:
          "Explore ideal templates to stand out in your economic sector <strong>{{sector}}</strong>.",
        generateAi: {
          text: {
            title: "Generate store with AI",
            description: "Let our artificial intelligence do the magic for you",
          },
          button: "Generate",
        },
        template: {
          text: "Template",
          buttonControl: "Choose template",
        },
        banner: {
          title: "Generate store with AI",
          description:
            "Use our customization to create your own template and give your store a unique style.",
          button: "Continue",
        },
      },
      coupons: {
        text: "I have a coupon code or referral code",
        couponLabel: "Coupon code",
        couponPlaceholder: "Enter coupon code",
        referredLabel: "Referral code",
        referredPlaceholder: "Enter referral code",
        invalidCode: "Invalid code.",
      },
      personalize: {
        title: "3. Personalize your store",
        description:
          "Design your online store with professional templates, unique cover, your brand colors, and your preferred font.",
        ia: {
          one: "We are creating your AI store...",
          two: "Generating a beautiful banner...",
          three: "Choosing the best design for your business...",
          four: "Creating a gallery...",
          five: "Creating images...",
        },
      },
      premium: {
        title: "4. Select a plan",
        description:
          "To save your customization, select the ideal plan for your business",
        continueFree: "Continue with my free store",
        monthly: "Monthly",
        annual: "Annual",
        acquire: "Acquire",
        popular: "Most popular",
        month: "Month",
        period__Mensual: "Month",
        period__Anual: "Year",
        firstPeriod__Mensual: "days",
        firstPeriod__Anual: "Year",
        loading: "Looking for the best prices for you!",
      },
      popups: {
        customizationValue: {
          title: "It is great! Don't lose your customization",
          description: "Retain customization for {{value}} for one month",
          confirmLabel: "Keep personalization",
          linkLabel: "Continue",
        },
        customization: {
          title: "It is great! Don't lose your customization",
          description:
            "If you continue with the Limited Plan you will lose the changes you made to your store",
          confirmLabel: "Keep personalization",
          linkLabel: "Continue with Limited Plan",
        },
      },
    },
    personalization: {
      changesPopup: {
        title: "Unsaved changes",
        description:
          "You made changes that you have not saved. What do you want to do?",
        buttonLabel: "Save and exit",
        linkLabel: "Exit without saving",
      },
    },
    navbar: {
      home: "Home",
      statistics: "Statistics",
      meli_integration: "Mercado Libre",
      gg_shopping: "Google Shopping",
      orders: "Orders",
      "abandoned-cart": "Abandoned carts",
      "all-products": "Products",
      voucher: "Voucher",
      configuration: "Configuration",
      personalization: "Customize store",
      contact_button: "Whatsapp button",
      mobile: "Mobile",
      myAccount: "My account",
      planTag: "Plan {{plan}}",
      plan_free: "Limited",
      plan_legacy: "Expert",
      plan_basic: "Basic",
      plan_pro: "Pro",
      plan_ultra: "Ultra",
      sections: {
        management: "Manage",
        custom: "Set up your store",
        integrations: "Integrations",
      },
    },
    stats: {
      title: "My Statistics",
      visits: "Visits to your catalog",
      sells: "Total sales",
      orders: "Received orders",
      conversion: "Conversion rate",
      conversionTooltip:
        "Percentage of visitors to your store who become customers.",
      seeGraph: "See graph of:",
      selectRange: "Select range:",
      thisMonth: "This month:",
      today: "Today:",
      yesterday: "Yesterday:",
      thisWeek: "This week:",
      lastMonth: "Last month:",
      lastWeek: "Last week:",
    },
    fileSelector: {
      title: "Product Photos",
      description:
        "Select the folder with photos of your products. Note that the photo name must match the name in the Excel template.",
      folder: "Upload photo folder",
      eachPhoto: "Manually upload photos of each of your products.",
      normal: "Normal photo upload",
    },
    uploader: {
      title: "Successful Product Upload",
      success: "Products uploaded",
      photos: "Uploaded product photos",
      disclaimer:
        "You can view or edit your products directly from the app or the web",
      my: "My products",
    },
    admin: {
      breadcrumb: {
        products: "Products",
        admin: "Manage My Products",
      },
      columns: {
        photos: "Photos",
        productName: "Product Name",
        unitPrice: "Unit Price",
        category: "Category",
        inventory: "Inventory",
        quantity: "Units",
        available: "Available",
        description: "Description",
      },
      inventory: {
        ALWAYS: "Always Available",
        LIMITED: "Limited Quantity",
        BUILD_TO_ORDER: "Catalog Sales",
        NOT_AVAILABLE: "Not available",
      },
      images: {
        delete: "Delete Image",
        delete_message: "Are you sure you want to delete this image?",
      },
      products: {
        emptyPopup: {
          title: "Improve your plan",
          description:
            "Create promotions for specific products, categories or subcategories. Tool available in Pro Plan and Ultra Plan. Upgrade to have unlimited features.",
          buttonLeft: "Maybe later",
          buttonRight: "Know the plans",
        },
        side: {
					title: 'Edit product',
					photos: {
						title: 'Photos',
						edit: 'Edit photos',
						itemImage: 'Add an image',
						itemImageText1: 'The first photo will be the main one.',
						itemImageText2: 'How to take a good photo for my product?',
						textInEditImage: 'Drag and drop photos to change the order',
            maxPhotos: 'Sorry, you have reached the limit of photos allowed.',
            maxPhotosHookTitle: "Stand out with unlimited photos",
            maxPhotosHookDescription: 'Capture the attention of your customers by showing every detail of your products.',
            validateErrorSize: 'Maximum supported weight: {{size}} MB',
            validateErrorType: 'Allowed formats: png, jpg, jpeg, web.',
            emptyStateImage: "Add photo"
					},
					form: {
						productNameLabel: 'Product name',
            productNamePlaceholder: 'Write a name...',
            productNameErrorMsg: 'The name is empty. Enter the name of the product.',
						productValueLabel: 'Price',
            productValuePlaceholder: 'Write a price...',
            productPriceErrorMsg: 'The price is empty or zero. Add a valid value.',
						variationsLabel: {
              label: "Configure the price and stock in your variations",
              link: "Set price",
            },
            infoTitle: "Wholesale",
            infoSubTitle: "Set the wholesale price",
            infoButton: "Find it within the app",
          },
          productInfo: {
            title: "Product information",
            labelCategory: "Category",
            labelSubCategory: "Subcategory",
            selectPlaceholderCategory: "Select a category",
            selectPlaceholderSubCategory: "Enter/select a subcategory",
            inputDescriptionTitle: "Description",
            inputDescription:
              "Write a description, taking into account the materials of your product, care instructions",
            inputDescriptionLimit: "characters",
          },
          wholeSale: {
            title: "Wholesale price",
            subTitle: "Set the wholesale price",
            counterText: "Minimum amount",
            description: "Define wholesale discount",
            errorInput: "The value is greater than unitary",
            finalPrice: "Final price",
            alertMsg: "The discount will be applied to the price of your variations.",
            alertMsgWholesale: "The discount will be applied to the price of your features.",
            wholesaleHookTitle: 'Expand your sales opportunities',
            wholesaleHookDescription: 'Set exclusive prices for wholesale sales and attract business customers with offers.',
            priceDetail: "Product price",
            discountDetail: "Discount"
          },
          inventory: {
            title: "Inventory",
            info: {
              title: "Custom sale",
              subTitle: "Your product is sold to order",
              days: "Average days to deliver",
            },
            stock: {
              title: "Stock",
              subTitle: "What quantity do you have available",
              subTitleWithVariations: "Set the stock in your features",
              availableUnits: "Units available",
              alert:
                "If you do not activate stock, the product will always be available",
            },
            variations: {
              title: "Features",
              editTitle: "Edit Variations",
              subTitle: "Set up product variations",
              createdItems: "created items",
              addFeatures: "Add features",
              addVariationsDescriptionNoEmpty: "Set up your product features",
              addVariationsDescription: "Add Features such as colors, sizes, dimensions...",
              addVariationsButton: "Add variations",
              addSide: {
                description: "Configure variations of your product such as colors or sizes, remember that all of them will be mandatory to select to enable the purchase.",
                newVariationTitle: "Feature name",
                alert: "Make sure that the variation information is correct; you will not be able to edit or modify it from the website.",
                variationName: "Feature name",
                variants: "Variants",
                addButton: "Add",
                addAction: "Add variation",
                applyButton: "Save",
                suggestion: {
                  color: "Color",
                  size: "Size",
                  taste: "Taste",
                  size2: "Height",
                  weight: "Weight",
                  dimension: "Dimension",
                },
              },
              edit: {
                title: "Variations",
                button: "Edit",
              },
              filter: {
                showItems: "Showing {{num}} items",
                modal: {
                  title: "Filter",
                  buttonClean: "Clean filters",
                  buttonApply: "Apply filters",
                },
                dropdown: {
                  unlimited: "Unlimited",
                  limited: "limited",
                  spend: "Spend",
                },
              },
              hooks: {
                upgrade: {
                  title: "Get this functionality!",
                  description:
                    "Upgrade your plan now to have this and more features.",
                  confirmLabel: "Obtener ahora",
                  cancelLabel: "Quizá más tarde",
                },
                upgradeWithLimits: {
                  title: "Grow without limits",
                  description:
                    "You've reached the {{items}} limit on your current plan. Get unlimited features by upgrading your plan.",
                  confirmLabel: "Get now",
                  cancelLabel: "Maybe later",
                },
                variations: {
                  title: "Create custom products",
                  description:
                    "Specify the attributes of each of your products and give your customers the possibility to choose.",
                  confirmLabel: "Get now",
                  cancelLabel: "Maybe later",
                },
              },
              alertPopup: {
                title: "Variation limit",
                titleVariant: "Variant limit",
                description:
                  "Sorry, you have reached the limit of allowed variations.",
                descriptionVariant:
                  "Sorry, you have reached the limit of allowed variants.",
                confirmLabel: "Continue",
              },
              saveChangesPopup: {
                title: "Changes not applied",
                description:
                  "You made changes that you haven't saved yet. Apply the changes or come back later to make them.",
                alternativeLabel: "Leave without applying",
                confirmLabel: "Apply changes",
              },
              alertPopupCombinations: {
                title: "Limit reached",
                description:
                  "We are sorry if you create this variant and exceed the limit of allowed combinations.",
                confirmLabel: "Accept",
              },
            },
          },
          supplementaryContent: {
            section: {
              title: "Supplementary content",
              description: "Set up complementary content for your product",
            },
          },
					addButtonText: 'Create product',
					updateButtonText: 'Save',
          removeButtonText: 'Delete',
          duplicateButtonText: 'Duplicate',
          formAlert: {
            success: "Product entered successfully",
            error: "An error occurred adding the product",
          },
          loadingTextDuplicateProduct: "Duplicating product...",
        },
        createTitle: "Edit New Product",
        createDescription: "Test Description",
        delete: "Delete Product",
        deleteConfirm: "Are you sure you want to delete the product?",
        yes: "Yes, delete",
        no: "Cancel",
        deleteMessage: "Successfully deleted.",
        duplicate: "Duplicate Product",
        add: "Add a Product",
        massiveCharge: "Massive Charge",
        search: "Search",
      },
    },
    abandonedCarts: {
      title: "Abandoned Cart",
      description:
        "Recover lost sales through WhatsApp and email. From indecisive to loyal customers!",
      acquire: "Acquire",
      columns: {
        date: "Date",
        client: "Client",
        email: "Email",
        products: "Products",
        total: "Total",
        state: "Status",
        contact: "Contact",
      },
      empty: {
        title: "Empty Abandoned Cart",
        description: "There are no products in the abandoned cart.",
      },
      sendEmailConfirm: {
        title: "We will send an email to your buyer",
        description:
          "Your buyer will receive an email reminding them to complete their order.",
        send: "Send Email",
      },
      recover: "Recovered",
      notRecover: "Not Recovered",
      confirm: "Confirm Purchase",
      total: "Total",
      contactMessage:
        "What stopped you? Our high-quality products in your cart are just a click away from brightening your day. Complete your purchase now!",
    },
    orderDetail: {
      breadcrumbs: {
        orders: "Orders",
        detail: "Order detail",
      },
      states: {
        payed: "Paid",
        packaged: "Packaged",
        sended: "Sent",
        delivered: "Delivered",
        cancelled: "Cancelled",
        created: "Created",
        paymentError: "Payment error",
        unknown: "Unknown",
      },
      sendTo: "Send to",
      orderState: "Order state",
      products: "Products",
      payment: {
        label: "Payment detail",
        total: "Total",
        cancel: "Cancel order",
      },
    },
    downloadModal: {
      title: "Open the App",
      subTitle: "Open the Sumer app on your phone.",
      description: "Scan the QR code to download it if you don’t have it yet.",
    },
    support: {
      help: "Help and support",
      message: `I have just seen SUMER's website and I have some doubts`,
    },
    productsLoading: {
      products: "Products",
      loading: "Loading products",
      success: "Template loaded successfully",
    },
    ImagesUploader: {
      select: "Select or drag photos (.png, .jpg)",
      success: "Photos uploaded successfully",
      maxFilesError: "Only 10 photos can be uploaded per product",
    },
    handleFileChange: {
      title: "Upload the folder with your product photos",
      sub_title:
        "Select the folder containing your product photos. Photo names must match the names in the Excel template.",
      type_error: "The selected file is not a .png or .jpg file",
    },
    integrations: {
      message: "Hello Sumer, I would like you to help me with:",
      store_connected: "Your Sumer store is connected to Facebook Shopping",
      store_not_connected:
        "Your Sumer store is not yet connected to Facebook Shopping",
      store_connected_instagram:
        "Your Sumer store is connected to Instagram Shopping",
      store_not_connected_instagram:
        "Your Sumer store is not yet connected to Instagram Shopping",
      how_link: "How to link your catalog with Facebook?",
      how_link_instagram: "How to link your catalog with Instagram?",
      copy_catalogue: "Copy the URL of your catalog, access Facebook ",
      copy_catalogue_instagram:
        "Copy the URL of your catalog, access Instagram ",
      business: "Enter Business in the required field.",
      step: "Step by step guide",
      sumer: "Sumer",
      facebook: "Facebook",
      instagram: "Instagram",
      options:
        "Take advantage of the options that Facebook gives you to empower and continue growing your business!",
      options_instagram:
        "Link your catalog to Instagram, gain followers, and boost your sales.",
      publish: "Make your URL public",
      copy_url: "Copy the URL of your catalog",
      help: "Need help?",
      view_facebook: "View on Facebook",
      conect_facebook: "Connect with Facebook",
      copied: "URL copied to clipboard",
      connect_instagram: "Connect with Instagram",
      verification_code: "Verification code",
      add_code_copy:
        "Add and paste the Facebook Business code to link your catalog to Instagram.",
      add_code_button: "Add",
      edit_code_button: "Edit",
      addMetadata: {
        copy_1: "1. Copy the URL of your store",
        copy_2:
          "in the settings of your Facebook business, click Add and paste the <strong>URL.</strong>",
        copy_3:
          "3. Choose the option <strong>“Add a meta tag to your HTML source code”</strong> copy the <strong>Meta-tag.</strong> When you have it, come back here and paste the code.",
      },
      meli: {
        banner: {
          title: "Synchronize Sumer with Mercado Libre",
          errorSyncTitle: "Something went wrong in the synchronization",
          synchronizedTitle: "Account synchronized with Mercado Libre",
          description:
            "Import your Mercado Libre products to Sumer. Publish the products from your store on Mercado Libre.",
          synchronizedDescription:
            "Every time you do a synchronization, 100% of your Mercado Libre products will be imported, with their respective prices and inventory.",
          lastSync: "Last sync",
          syncButton: "Start synchronization",
          errorSyncButton: "Retry sync",
          synchronizedButton: "Synchronize new products",
          unlinkButton: "Unlink",
          syncModal: {
            title: "Do you want to unlink Sumer from Mercado Libre?",
            description:
              "Your catalog in Sumer will retain current products. To sync again you will have to make the connection again.",
            declineButton: "No, keep link",
            acceptButton: "Yes, unlink",
          },
        },
        cards: {
          title: "Recommendations for successful synchronization",
          card1: {
            title: "Name the products the same",
            description:
              "Mercado Libre products will be linked to Sumer products according to the name.",
          },
          card2: {
            title: "Update stock and prices",
            description:
              "Update the price and stock of products on Mercado Libre before synchronization.",
          },
          card3: {
            title: "Update variants",
            description:
              "Your products will maintain the variations that you have configured in Mercado Libre.",
          },
          card4: {
            title: "Eliminate duplicate products",
            description:
              "If you have products in Sumer that you also have in Mercado Libre, eliminate those from Sumer.",
          },
        },
        productsCounter: {
          title:
            "We have successfully imported {{counter}} products from Mercado Libre.",
          description:
            "Make sure there are no duplicate products and verify that both inventory and pricing are correct.",
          showButton: "See imported products",
        },
        popups: {
          plan: {
            title: "Simplify the management of your business",
            description:
              "Save time and maximize efficiency. With this tool, synchronization is unique and hassle-free.",
            buttonAccept: "Get now",
            buttonLink: "Maybe later",
          },
        },
        loader: {
          copy1: "Importing products from Mercadolibre...",
          copy2: "Creating products in Sumer...",
          copy3: "Creating variants...",
          copy4: "Synchronizing...",
        },
      },
      google_shopping: {
        banner: {
          title: "Sell more with Google Shopping",
          subtitle:
            "A free marketplace to advertise, promote, and sell your Sumer store products directly on Google.",
          text_button: "Connect Google Shopping",
        },
        how_works: {
          title: "How Google Shopping works",
          step1: {
            title: "Consumer Information",
            description:
              "Your buyers will find your products more easily, based on search relevance and activity on Google. Sponsored offers can influence.",
          },
          step2: {
            title: "Product Information",
            description:
              "Sellers and advertisers who display their products on Google Shopping engage in more commercial activities.",
          },
          step3: {
            title: "Reviews and Ratings",
            description:
              "To help more people find your product, we collect reviews from the Google Reviews program.",
          },
          step4: {
            title: "How to make a sale",
            description:
              "When a buyer finds your product, they will see its price and can purchase it by clicking on it.",
          },
        },
        alert: {
          title: "We have successfully connected your Google Shopping.",
          description:
            "If they do not meet the quality requirements, Google may reject your products, correct their data and upload them again.",
          button: "View imported products",
        },
        configuration:{
          title: "Connect Sumer with Google Shopping"
        },
        cards_configuration: {
          card1: {
            title: "1. Create your Google account",
            description:
              "Provide basic information about your store to register.",
            button: "Create account",
          },
          card2: {
            title: "2. Verify your store on Google",
            description:
              "Copy this link and paste it into the Confirm your online business store option of Google Merchant.",
            button: "yourstore.com",
          },
          card3: {
            title: "3. Add the HTML tag",
            description:
              "Copy the HTML tag provided by Google and paste it into the following box.",
            button: "Submit",
            placeholder: "Paste the HTML tag",
          },
          card4: {
            title: "4. Add your products",
            description:
              "Copy and paste this XML link into Google Merchant Center (remember that you must have previously set up the shipping and product details in Sumer).",
            button: "Copy",
          },
        },
      },
    },

    login: {
      account: "Log in or create your account on Sumer",
      phone: "Your business's mobile phone",
      agree: "I agree to the",
      terms: "terms and conditions",
      sms: "Receive code via SMS",
      whatsapp: "Receive code via WhatsApp",
      name: "Name",
      "last-name": "Last Name",
      ready: "Ready",
      code: "Enter the code we sent you",
      send: "The code has been sent to",
      "send-again": "Resend",
      next: "Next",
      incorrect: "The code does not match, please try again.",
      resend: "Code sent successfully",
      logout: "Log out",
      helpMessage: "Hello! Today, Sumer would like to ask you about:",
      support: "Need help?",
      codeQuestion: "Didn't receive your code?",
      loginQuestion: "Already have an account?",
      loginAction: "Log in",
      invalidPhoneNumber: "The phone number is invalid",
      invalidEmail: "The email is invalid",
      logoutTitle: "Log out",
      logoutMessage: "Are you sure you want to log out?",
      logoutConfirm: "Yes",
      logoutCancel: "No",
      error: "An error occurred, please try again",
      facebook_login: "Continue with Facebook",
      google_login: "Continue with Google",
      apple_login: "Continue with Apple",
      phone_login: "Continue with phone",
      email: "Continue with email",
      title: "Log in",
      number_phone:
        "Enter your mobile number that you used to create your account on Sumer",
      continue: "Continue",
      do_not_have_account: "Don't have an account?",
      register_now: "Register now",
      register: "Register on Sumer",
      code_verification: "Verification code",
      code_verification_send: "We have sent a verification code via SMS to:",
      change_phone: "Change",
      login_sumer: "Log in to Sumer",
      welcome: "Log in to Sumer",
    },
    register: {
      not_account: "Don't have an account on Sumer yet.",
      download_app: "Download the app and create your account",
    },
    download_app: "Download the app",
    formCheckout: {
      phone_placeholder: "Mobile number",
      email_placeholder: "Email",
    },

    upgrade: {
      title: "Improve your plan",
      copy: "Unlock 100% of our experience, inventory, variants, products, photos, accessories and more.",
      link: "Acquire",
    },
    featureSoon: {
      title: "Tool in development!",
      copy: "You will soon be able to have this tool, available for now in the app.",
      accept: "Accept",
    },
    maxImagesModalAlert: {
      copy: "You cannot upload more photos to your product due to the capacity of your plan, please review the number of photos allowed in your current plan.",
    },
    confirm: {
      title: "Confirm",
      copy: "Are you sure to continue?",
    },
    toggle: {
      yearly: "Yearly",
      monthly: "Monthly",
    },
    hookAgileConversion: {
      title: "Get this feature!",
      copy: "Upgrade your plan now to have this and more features.",
      confirm: "Subscribe to the plan",
      plans: "View plan details",
      plan: "{{plan}} plan",
      month: "month",
      year: "year",
    },

    products,
    onboardingAi,
    home,
    checkout,
    voucher,
    plans,
    addons,
    profile,
    orders,
    aiAssistant,
    configuration,
    sharedOnboarding,
    mobile,
    productComponent
  }
};

export interface PersonalizationState {
    pendingChanges: boolean;
    requestExit: ExitItems;
}

export interface ExitItems {
    exit: boolean;
    onAction?: () => void
}

export const initialState: PersonalizationState = {
    pendingChanges: false,
    requestExit: {
        exit: false,
    }
}
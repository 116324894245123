import { StorageItem } from "@dashboard/constants/storage-items";
import StepCustomization from "@dashboard/modules/shared/components/onboarding-shared/pages/step-customization/step-customization";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const OnboardingGuestPersonalize: FC = () => {
  const { t } = useTranslation();
  
  return (
    <StepCustomization
      nextButtonText={t(
        "sharedOnboarding.stepCustomization.split2.nextButtonText"
      )}
      planPath="/onboarding-guest/info"
      onNext={() => localStorage.removeItem(StorageItem.Token)}
      modalLogin
      noSave
    />
  );
};

export default OnboardingGuestPersonalize;

import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { PRODUCTS_FORMS_RULES } from "@dashboard/modules/products/utils/rules-forms.utils";
import FieldText from "@dashboard/components/field-text/field-text";
import { useTranslation } from "react-i18next";
import {
  SkuInputForm,
  SkuInputProps,
} from "@dashboard/modules/products/types/product-sku.types";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import { ProductSkuWrapper } from "./product-sku.styled";
import Package from '@sumerkit/icons/package';

const ProductSku: FC<SkuInputProps> = ({
  data,
  alreadyExist,
  showHeader,
  disabled,
  limit,
  onChange,
  onChangeValid,
  onClick,
  onFocus,
  onError,
}) => {
  const { t } = useTranslation();
  const [errorAlreadyExist, setErrorAlreadyExist] = useState(alreadyExist);

  const defaultValues: SkuInputForm = data || {
    sku: "",
  };

  useEffect(() => {
    setErrorAlreadyExist(alreadyExist);
  }, [alreadyExist]);

  return (
    <ProductSkuWrapper>
      {showHeader && (
        <CollapseContainerWrapper>
          <div className="info">
            <figure className="icons">
              <Package width={27} height={27}/>
            </figure>
            <div className="description">
              <strong className="title">{t("products.form.sku.title")}</strong>
              <small className="subtitle">{t("products.form.sku.copy")}</small>
            </div>
          </div>
        </CollapseContainerWrapper>
      )}
      <Formik
        initialValues={defaultValues}
        validateOnMount
        validationSchema={PRODUCTS_FORMS_RULES.SKU}
        onSubmit={() => {}}
      >
        {({
          values,
          isValid,
          touched,
          errors,
          setFieldTouched,
          handleChange,
          handleBlur,
        }) => {
          useEffect(() => {
            onChange &&
              onChange(
                !errorAlreadyExist &&
                  values.sku &&
                  values.sku?.length <= (limit || 12)
                  ? values
                  : { sku: "" }
              );
            setErrorAlreadyExist(false);
          }, [values]);

          useEffect(() => {
            onChangeValid && onChangeValid(isValid && !errorAlreadyExist);
            !isValid && onError && onError();
          }, [isValid, errorAlreadyExist]);

          return (
            <div className={`sku-input-field`} onClick={onClick}>
              <FieldText
                id="profile-sku"
                name="sku"
                className="sku-input-field"
                value={values.sku}
                label={!showHeader ? t("products.form.sku.label") : undefined}
                labelOutside
                autocomplete="off"
                placeholder={t("products.form.sku.placeholder")}
                readonly={disabled}
                maxLength={15}
                touched={touched}
                error={errorAlreadyExist}
                errors={errors}
                errorText={
                  errorAlreadyExist
                    ? t("products.form.sku.errors.repeat")
                    : t("products.form.sku.error")
                }
                errorHelpText={t("products.form.sku.error")}
                onChange={(e: React.ChangeEvent) => {
                  setFieldTouched("sku");
                  handleChange(e);
                }}
                onBlur={handleBlur}
                onFocus={onFocus}
              />
            </div>
          );
        }}
      </Formik>
    </ProductSkuWrapper>
  );
};

export default ProductSku;

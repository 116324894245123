import { FC, useEffect } from "react";
import { HomeWrapper } from "./home.styled";
import { useTranslation } from "react-i18next";
import Banners from "../../components/banners";
import { UiDropdown } from "@sumerlabs/component-library";
import Stats from "../../components/stats";
import { useHome } from "../../hooks/home.hooks";
import { StatisticsFilter } from "../../constants/home.constants";
import InConstruction from "../../components/in-construction";
import LearningItems from "../../components/learning-items";
import { useAnalytics } from "@dashboard/common/analytics";
import { HomeEvent, HomeEventSubtype } from "../../constants/home.events";
import FakeDoors from "../../components/fake-doors";
import NewOrders from "../../components/new-orders";

interface HomeProps {
  
}
 
const Home: FC<HomeProps> = () => {

  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const {
    statisticsFilter,
    fetchHomeData,
    handleStatisticsFilterChange,
  } = useHome();

  useEffect(() => {
    logEvent(HomeEvent.ViewScreen, {
      event_subtype: HomeEventSubtype.ViewHome,
    });
    fetchHomeData();
  }, []);

  return (
    <HomeWrapper>
      <div className="banners">
        <Banners />
        <NewOrders />
      </div>
      <div className="stats-tools">
        <div className="stats">
          <div className="head">
            <div className="title">{t('home.informsTitle')}</div>
            <UiDropdown
              className="dropdown"
              value={statisticsFilter}
              options={[
                { label: t('home.yesterday'), value: StatisticsFilter.LastDay },
                { label: t('home.lastSevenDays'), value: StatisticsFilter.LastSevenDays },
                { label: t('home.lastFifteenDays'), value: StatisticsFilter.LastFifteenDays },
                { label: t('home.lastThirtyDays'), value: StatisticsFilter.LastMonth },
              ]}
              onChange={(v) => handleStatisticsFilterChange(v as StatisticsFilter)}
            />
          </div>
          <Stats />
        </div>
        <div className="tools">
          <div className="head">
            <div className="title">{t('home.toolsTitle')}</div>
          </div>
          <FakeDoors />
        </div>
      </div>
      <div className="learning">
        <div className="head">
          <div className="title">{t('home.learningTitle')}</div>
        </div>
        <LearningItems />
      </div>
      <InConstruction />
    </HomeWrapper>
  );
}
 
export default Home;
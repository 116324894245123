import { useAnalytics } from "@dashboard/common/analytics";
import { partnerSelector, planParamsSelector } from "@dashboard/config/redux";
import { iframeEvents } from "@dashboard/constants/iframe-events";
import { StorageItem } from "@dashboard/constants/storage-items";
import { useSession } from "@dashboard/hooks/use-session.hook";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  SourceOnboarding,
  OptionOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { QUERY_PARAMS_TEMPLATES } from "@dashboard/modules/shared/components/onboarding-shared/constants/params.constants";
import { Plan } from "@dashboard/modules/shared/components/onboarding-shared/entities/premium.entities";
import { sourcePathSelector } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.selectors";
import { IframeEvent } from "@dashboard/modules/personalization/constants/events.constants";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useOnboardingStepPremium from "./step-plan.hook";

export const useStepCustomization = (planPath: string, noSave?: boolean) => {
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const [hasChanges, setHasChanges] = useState(false);
  const { plans } = useOnboardingStepPremium();
  const [planUltra, setPlanUltra] = useState<Plan>();
  const [showLoader, setShowLoader] = useState(false);
  const { setWebStatus, isTokenGuest } = useSession();
  const partner = useSelector(partnerSelector);
  const planParamsData = useSelector(planParamsSelector);
  const sourcePath = useSelector(sourcePathSelector);

  useMemo(() => {
    const group = plans.find((group) => group.name === "Mensual");
    const ultra = group?.plans.find((plan) => plan.type === "Ultra");
    setPlanUltra(ultra);
  }, [plans]);

  const handleBack = () => {
    navigate(-2);
  };

  const finishOnb = () => {
    setWebStatus(
      `/${partner?.userId}`,
      partner?.userId || '',
      true,
      false,
      true
    );
    handleNext();
  };

  const handleNext = async () => {
    logEvent(EventsOnboarding.SELECT_BUTTON, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      source:
        planParamsData?.plan && planParamsData.period
          ? SourceOnboarding.LANDING_PRICING
          : "onboarding",
      option: OptionOnboarding.PERSONALIZATION,
    });
    const url = sessionStorage.getItem(StorageItem.OnboardingFreeUrl);
    if (!!url) {
      window.open(url);
    } else {
      if (hasChanges && !noSave && !isTokenGuest()) {
        const iframe = document.querySelector("iframe");
        iframe?.contentWindow?.postMessage("handle_web_onsubmit", "*");
      }
      await sleep(1000);
      navigate(planPath);
    }
  };
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    logEvent(EventsOnboarding.VIEW_SCREEN, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      source:
        planParamsData?.plan && planParamsData.period
          ? SourceOnboarding.LANDING_PRICING
          : sourcePath || "onboarding",
      option: OptionOnboarding.PERSONALIZATION,
    });
  }, []);

  useEffect(() => {
    (window as any).messageHandler = {
      postMessage: (eventName: string) => {
        if (eventName === IframeEvent.PendingChanges) {
          setHasChanges(true);
        }
      },
    };
    return () => {
      (window as any).messageHandler = undefined;
    };
  }, []);

  const reloadFrame = () => {
    sessionStorage.removeItem(QUERY_PARAMS_TEMPLATES.KEY_NAME);
    logEvent(EventsOnboarding.SELECT_BUTTON, {
      event_subtype: SubtypeOnboarding.REGENERATE_PERSONALIZATION,
      source:
        planParamsData?.plan && planParamsData.period
          ? SourceOnboarding.LANDING_PRICING
          : "",
      option: OptionOnboarding.PERSONALIZATION,
    });
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    const iframe = document.querySelector("iframe");
    iframe?.contentWindow?.postMessage(iframeEvents.HANDLE_RELOAD, "*");
  };

  const getDemoTemplate = useMemo(
    () =>
      sessionStorage.getItem(QUERY_PARAMS_TEMPLATES.KEY_NAME)
        ? `&demotemplate=${sessionStorage.getItem(QUERY_PARAMS_TEMPLATES.KEY_NAME)}`
        : "",
    [sessionStorage.getItem(QUERY_PARAMS_TEMPLATES.KEY_NAME)]
  );

  return {
    getDemoTemplate,
    showLoader,
    planUltra,
    handleNext,
    handleBack,
    reloadFrame,
    finishOnb,
  };
};

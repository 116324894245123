import styled from "styled-components";

export const AiChatMessagesStyled = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  .chat-messages-group {
    width: 100%;
    .messages-group-title {
      color: #24272e;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: center;
    }
    .messages-list {
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
    }
  }
`;

import { FC } from "react";
import { ButtonProps } from "./button.types";
import { ButtonStyled } from "./button.styled";

const Button: FC<ButtonProps> = ({
  children,
  appearance,
  size,
  outline,
  link,
  disabled,
  className,
  onClick,
}) => {
  return (
    <ButtonStyled
      className={`${appearance ? `button__${appearance}` : ""} ${
        outline ? `button__outline` : ""
      } ${size ? `button__${size}` : ""} ${link ? `button__link` : ""} ${
        className || ""
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;

import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useSearchParams, Navigate } from "react-router-dom";
import Health from "@dashboard/components/health/Health";
import Layout from "@dashboard/components/main-layout/main-layout";
import "@sumerlabs/component-library/lib/styles/icomoon-font.prod.css";
import "@sumerlabs/component-library/lib/styles/icons.css";
import CartsList from "./modules/abandoned-cart/pages/carts-list/carts-list";
import { useGlobalData } from "./hooks/use-global-data.hook";
import Home from "./modules/home/pages/home";
import Personalization from "./modules/personalization/pages/personalization";
import ContactButtonAddon from "./modules/contact-button/pages/contact-button-addon";
import { PROFILE_ROUTES } from "./modules/profile/profile.routes";
import LoginQrPage from "@dashboard/components/login-qr-page/login-qr-page";
import { ORDERS_ROUTES } from "./modules/orders/orders.routes";
import Login from "./modules/login/login";
import LoaderStatus from "./components/loader-status/loader-status";
import MeliValidator from "./modules/meli/components/meli-validator/meli-validator";
import Guest from "./components/guest/guest";
import ConfigurationLayout from "./modules/configuration/layout";
import PaymentGatewayLayout from "./modules/configuration/pages/payment-gateway/layout/payment-gateway.layout";
import { useDeeplinks } from "./hooks/deeplinks.hook";
import Mobile from "./modules/mobile/pages/mobile/mobile";
import OnboardingInfo from "./modules/onboarding-general/pages/onboarding-info/onboarding-info";
import OnboardingStore from "./modules/onboarding-general/pages/onboarding-store/onboarding-store";
import OnboardingDemo from "./modules/onboarding-general/pages/onboarding-demo/onboarding-demo";
import OnboardingGenerating from "./modules/onboarding-general/pages/onboarding-generating/onboarding-generating";
import OnboardingCustomization from "./modules/onboarding-general/pages/onboarding-customization/onboarding-customization";
import OnboardingPlan from "./modules/onboarding-general/pages/onboarding-plan/onboarding-plan";
import OnboardingGuestGenerating from "./modules/onboarding-guest/pages/onboarding-guest-generating/onboarding-guest-generating";
import OnboardingGuestPersonalize from "./modules/onboarding-guest/pages/onboarding-guest-personalize/onboarding-guest-personalize";
import OnboardingGuestInfo from "./modules/onboarding-guest/pages/onboarding-guest-info/onboarding-guest-info";
import OnboardingGuestPlan from "./modules/onboarding-guest/pages/onboarding-guest-plan/onboarding-guest-plan";
import OnboardingGuest from "./modules/onboarding-guest/onboarding-guest";
import OnboardingGeneral from "./modules/onboarding-general/onboarding-general";
import Product from "./modules/products/components/product-component/views/product/product";
import ProductForm from "@dashboard/modules/products/components/product-component/layout/product-form";
import { GOOGLE_SHOPPING_ROUTES } from "./modules/google-shopping/google-shopping.routes";
import { useClevertap } from "./hooks/clevertap.hook";

function App() {
  const ProductListView = lazy(
    () => import("@dashboard/modules/products/views/list-view")
  );
  const BulkUploadProducts = lazy(
    () => import("@dashboard/modules/products/views/bulk-upload-products")
  );
  const BulkUploadFile = lazy(
    () => import("@dashboard/modules/products/views/bulk-upload-file")
  );
  const BulkUploadImages = lazy(
    () => import("@dashboard/modules/products/views/bulk-upload-images")
  );
  const BulkProductsUploadImages = lazy(
    () =>
      import("@dashboard/modules/products/views/bulk-products-upload-images")
  );
  const BulkProductsCreate = lazy(
    () => import("@dashboard/modules/products/views/bulk-products-create")
  );
  const BulkProductsRetry = lazy(
    () => import("@dashboard/modules/products/views/bulk-products-retry")
  );
  const BulkUploadCustomFile = lazy(
    () => import("@dashboard/modules/products/views/bulk-upload-custom-file")
  );
  const Plans = lazy(() => import("@dashboard/modules/plans/plans"));
  const Profile = lazy(() => import("@dashboard/modules/profile/profile"));
  const ProfileHome = lazy(
    () => import("@dashboard/modules/profile/views/home")
  );
  const BusinessSectors = lazy(
    () => import("@dashboard/modules/profile/views/business-sectors")
  );

  const Orders = lazy(() => import("@dashboard/modules/orders/orders"));
  const OrderDetail = lazy(
    () => import("@dashboard/modules/orders/views/deatil")
  );
  const Checkout = lazy(
    () => import("@dashboard/modules/checkout/pages/checkout")
  );
  const Status = lazy(() => import("@dashboard/modules/checkout/pages/status"));
  const VoucherList = lazy(
    () => import("@dashboard/modules/voucher/pages/voucher-list")
  );
  const VoucherCreation = lazy(
    () => import("@dashboard/modules/voucher/pages/voucher-creation")
  );
  const MeliModule = lazy(
    () => import("@dashboard/modules/meli/views/meli/meli")
  );
  const GoogleShoppingModule = lazy(
    () => import("@dashboard/modules/google-shopping/google-shopping")
  );

  const GoogleShoppingInfoModule = lazy(
    () =>
      import("@dashboard/modules/google-shopping/views/google-shopping-info")
  );
  const GoogleShoppingConfigurationModule = lazy(
    () =>
      import(
        "@dashboard/modules/google-shopping/views/google-shopping-configuration/google-shopping-configuration"
      )
  );
  const Configuration = lazy(
    () => import("@dashboard/modules/configuration/pages/configuration")
  );
  const Domain = lazy(
    () => import("@dashboard/modules/configuration/pages/domain")
  );
  const SearchDomain = lazy(
    () => import("@dashboard/modules/configuration/pages/search-domain")
  );
  const PaymentGatewayModule = lazy(
    () =>
      import(
        "@dashboard/modules/configuration/pages/payment-gateway/payment-gateway"
      )
  );
  const MercadoPagoModule = lazy(
    () =>
      import(
        "@dashboard/modules/configuration/pages/payment-gateway/mercado-pago/mercado-pago"
      )
  );

  const ProductImageModule = lazy(
    () =>
      import(
        "@dashboard/modules/products/components/product-component/views/product-image/product-image"
      )
  );

  const ProductFeatureListModule = lazy(
    () =>
      import(
        "@dashboard/modules/products/components/product-component/views/product-feature-list/product-feature-list"
      )
  );

  const ProductFeatureFormModule = lazy(
    () =>
      import(
        "@dashboard/modules/products/components/product-component/views/product-feature-form/product-feature-form"
      )
  );

  const OnboardingAiModule = lazy(
    () =>
      import(
        "@dashboard/modules/onboarding-ai/index"
      )
  );

  const [searchParams] = useSearchParams();
  const { valideDeeplink } = useDeeplinks();
  const { init: initClevertap } = useClevertap();
  const token = searchParams.get("t");

  useGlobalData();

  useEffect(() => {
    if (token) {
      localStorage.setItem("accessToken", `"${token}"`);
    }
  }, [token]);

  useEffect(() => {
    initClevertap();
    valideDeeplink();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/status" element={<LoaderStatus />} />
      <Route
        path="/onboarding-ai"
        element={
          <Suspense>
            <OnboardingAiModule />
          </Suspense>
        }
      />
      <Route path="/guest" element={<Guest />} />
      <Route path="/activate" element={<LoginQrPage />} />
      <Route path="/onboarding" element={<OnboardingGeneral />}>
        <Route path="info" element={<OnboardingInfo />} />
        <Route path="store" element={<OnboardingStore />} />
        <Route path="templates" element={<OnboardingDemo />} />
        <Route path="generating" element={<OnboardingGenerating />} />
        <Route path="personalize" element={<OnboardingCustomization />} />
        <Route path="premium" element={<OnboardingPlan />} />
      </Route>
      <Route path="/onboarding-split-two" element={<OnboardingGuest />}>
        {" "}
        {/* TODO: remover fallback ruta antigua cuando se migren las deps */}
        <Route path="generating" element={<OnboardingGuestGenerating />} />
      </Route>
      <Route path="/onboarding-guest" element={<OnboardingGuest />}>
        <Route path="personalize" element={<OnboardingGuestPersonalize />} />
        <Route path="info" element={<OnboardingGuestInfo />} />
        <Route path="premium" element={<OnboardingGuestPlan />} />
        <Route path="generating" element={<OnboardingGuestGenerating />} />
      </Route>
      <Route path="/integrations">
        <Route path="meli-validator" element={<MeliValidator />} />
      </Route>
      <Route path="/:id" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="products">
          <Route
            index
            element={
              <Suspense>
                <ProductListView />
              </Suspense>
            }
          />
          <Route path="edit" element={<Navigate to="new" replace />} />
          <Route path="edit/:productId" element={<ProductForm />}>
            <Route
              index
              element={
                <Suspense>
                  <Product />
                </Suspense>
              }
            />
            <Route
              path="images"
              element={
                <Suspense>
                  <ProductImageModule />
                </Suspense>
              }
            />
            <Route
              path="stock"
              element={
                <Suspense>
                  <ProductFeatureListModule />
                </Suspense>
              }
            />
            <Route
              path="features"
              element={
                <Suspense>
                  <ProductFeatureFormModule />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="bulk-upload"
            element={
              <Suspense>
                <BulkUploadProducts />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense>
                  <BulkUploadFile />
                </Suspense>
              }
            />
            <Route
              path="images"
              element={
                <Suspense>
                  <BulkUploadImages />
                </Suspense>
              }
            />
            <Route
              path="images/bulk-upload"
              element={
                <Suspense>
                  <BulkProductsUploadImages />
                </Suspense>
              }
            />
            <Route
              path="create"
              element={
                <Suspense>
                  <BulkProductsCreate />
                </Suspense>
              }
            />
            <Route
              path="summary"
              element={
                <Suspense>
                  <BulkProductsRetry />
                </Suspense>
              }
            />
            <Route
              path="custom-file"
              element={
                <Suspense>
                  <BulkUploadCustomFile />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path={PROFILE_ROUTES.ROOT}
          element={
            <Suspense>
              <Profile />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense>
                <ProfileHome />
              </Suspense>
            }
          />
          <Route
            path={PROFILE_ROUTES.BUSINESS_SECTORS}
            element={
              <Suspense>
                <BusinessSectors />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={ORDERS_ROUTES.ROOT}
          element={
            <Suspense>
              <Orders />
            </Suspense>
          }
        >
          <Route
            path={`${ORDERS_ROUTES.DETAIL}`}
            element={
              <Suspense>
                <OrderDetail />
              </Suspense>
            }
          />
        </Route>
        <Route path="abandoned-carts" element={<CartsList />} />
        <Route path="personalization" element={<Personalization />} />
        <Route
          path="checkout"
          element={
            <Suspense>
              <Checkout />
            </Suspense>
          }
        />
        <Route
          path="checkout/status/:orderId"
          element={
            <Suspense>
              <Status />
            </Suspense>
          }
        />
        <Route
          path="plans"
          element={
            <Suspense>
              <Plans />
            </Suspense>
          }
        />
        <Route
          path="contact-button"
          element={
            <Suspense>
              <ContactButtonAddon />
            </Suspense>
          }
        />
        <Route path="voucher">
          <Route
            index
            element={
              <Suspense>
                <VoucherList />
              </Suspense>
            }
          />
          <Route
            path="create"
            element={
              <Suspense>
                <VoucherCreation />
              </Suspense>
            }
          />
        </Route>
        <Route path="meli">
          <Route
            index
            element={
              <Suspense>
                <MeliModule />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={GOOGLE_SHOPPING_ROUTES.ROOT_GENERAL}
          element={
            <Suspense>
              <GoogleShoppingModule />
            </Suspense>
          }
        >
          <Route
            path={GOOGLE_SHOPPING_ROUTES.ROOT_GSH_CONNECTED}
            element={
              <Suspense>
                <GoogleShoppingConfigurationModule />
              </Suspense>
            }
          />
          <Route
            path={GOOGLE_SHOPPING_ROUTES.ROOT_INFO}
            element={
              <Suspense>
                <GoogleShoppingInfoModule />
              </Suspense>
            }
          />
        </Route>
        <Route path="configuration" element={<ConfigurationLayout />}>
          <Route
            index
            element={
              <Suspense>
                <Configuration />
              </Suspense>
            }
          />
          <Route path="domain">
            <Route
              index
              element={
                <Suspense>
                  <Domain />
                </Suspense>
              }
            />
            <Route
              path="search"
              element={
                <Suspense>
                  <SearchDomain />
                </Suspense>
              }
            />
          </Route>
          <Route path="pasarelas" element={<PaymentGatewayLayout />}>
            <Route
              index
              element={
                <Suspense>
                  <PaymentGatewayModule />
                </Suspense>
              }
            />
            <Route
              path="mercado-pago"
              element={
                <Suspense>
                  <MercadoPagoModule />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path="mobile" element={<Mobile />} />
      </Route>
      <Route path="/health" element={<Health />} />
    </Routes>
  );
}

export default App;

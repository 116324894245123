import styled from "styled-components";

export const CustomTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 16px;
  label {
    color: var(--grey-b, #24272e);
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    justify-content: space-between;
    line-height: 22px;
    width: 100%;
  }
  textarea {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--grey-border, #e9e9ed);
    background: var(--grey-w, #fff);
    font-family: 'Lato',sans-serif;
  }
  .characters-container {
    width: 100%;
    text-align: right;
    color: var(--grey-4, #5d606f);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

import {css} from "styled-components";
export const paddingY = css`
    padding-top: 1em;
    padding-bottom: 1em;
`;

export const paddingX = css`
    padding-right: 1em;
    padding-left: 1em;
`;

export const marginY = css`
    margin-top: 1em;
    margin-bottom: 1em;
`;

export const marginX = css`
    margin-right: 1em;
    margin-left: 1em;
`;


import { http } from "@dashboard/common/http";
import { FilesUploaderPayload } from "@dashboard/common/types";
import { camelizeKeys } from "humps";
import {
  BulkUploadCustomFileResponse,
  BulkUploadProductsResponse,
} from "../types/bulk-upload-products.types";
import { UpdateMassiveProductsPayload } from "../types/products-massive.types";

export const handleActionProductsFetch = (
  token: string,
  payload: UpdateMassiveProductsPayload
) => {
  return http
    .put(
      `/api/ms/catalogue/product/massive`,
      { ...payload },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        } as { [key: string]: string },
      }
    )
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as { update: number });
};

export const bulkUploadProductsFetch = (
  token: string,
  files: FilesUploaderPayload[],
  options: {
    onlyValidate?: boolean;
    abort?: AbortController;
    onUploadProgress?: (event: ProgressEvent) => void;
  } = {}
) => {
  const { onlyValidate, abort, onUploadProgress } = options;
  let formData = new FormData();
  files.forEach((item) => {
    formData.append(item.name, item.file);
  });
  return http
    .post("/api/ms/catalogue/product/massive/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "X-API-VERSION": 2,
      },
      params: { "only-validate": !!onlyValidate },
      onUploadProgress,
      ...(abort ? { signal: abort.signal } : {}),
    })
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as BulkUploadProductsResponse);
};

export const uploadCustomFileFetch = (
  token: string,
  file: File,
  options: {
    abort?: AbortController;
    onUploadProgress?: (event: ProgressEvent) => void;
  } = {}
) => {
  const { abort, onUploadProgress } = options;
  let formData = new FormData();
  formData.append("file", file);
  return http
    .post(
      "/api/ms/s3-database/file-uploader/upload/catalogue/massive-file",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress,
        ...(abort ? { signal: abort.signal } : {}),
      }
    )
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as BulkUploadCustomFileResponse);
};

import { css } from "styled-components";

export const primeGradient = css`
  background: linear-gradient(
    90deg,
    #a1bbfb,
    #a2bffb,
    #d8cfe8,
    #c7c1eb,
    #9fa7eb,
    #a5b5f1 57.48%
  );
`;

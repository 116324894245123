import styled from 'styled-components';

const ContactButtonIconWrapper = styled.div`
	gap: 12px;
	width: 100%;
	display: flex;

	.option {
		flex: 1;

		&.active {
			.box {
				border: none;
				background: #2c68f6;

				.icon {
					color: #ffffff;
				}
			}
		}

		.box {
			width: 100%;
			height: 48px;
			display: flex;
			border-radius: 8px;
			align-items: center;
			background: #ffffff;
			justify-content: center;
			border: 2px solid #e6e8f9;

			.icon {
				width: 24px;
				height: 24px;
				display: flex;
				font-size: 22px;
				align-items: center;
				justify-content: center;
			}
		}

		.text {
			font-size: 12px;
			font-weight: 400;
			margin: 4px 0 0 0;
			line-height: 18px;
			text-align: center;
		}
	}
`;

export default ContactButtonIconWrapper;

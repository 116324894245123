import styled from "styled-components";

export const AiChatInputStyled = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e9e9ed;
  border-radius: 0.5rem;
  display: flex;
  padding-right: 1rem;
  input {
    background: transparent;
    border: 0;
    color: #24272e;
    font-size: 0.875rem;
    height: 46px;
    padding: 0.625rem 1rem;
    width: 100%;
    &::placeholder {
      color: #9b9eab;
    }
  }
  .chat-input-option {
    background-color: #e9e9ed;
    border-radius: 50%;
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    color: #b0b2b6;
    font-size: 1.25rem;
    height: 32px;
    min-width: 32px;
    width: 32px;
  }
  &:focus-within {
    border-color: #24272e;
  }
  &.ai-chat-input__active {
    .chat-input-option {
      background-color: #24272e;
      color: #ffffff;
    }
  }
  &:has(input:disabled) {
    border: #e9e9ed;
    background: #e9e9ed;
  }
`;

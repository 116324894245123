import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@sumerlabs/component-library";
import { SharePopupHook } from "./share-popup.hook";
import { NotificationSuccess, PopupWrapper } from "./share-popup.styled";

export type SharePopupType = {
  show: boolean;
  imgStore: string;
  storeName: string;
  onClose: () => void;
};

const SharePopup: FC<SharePopupType> = ({
  show,
  imgStore,
  storeName,
  onClose,
}) => {
  const { t } = useTranslation();
  const { copyToClipboard, goToWspLink, openMail, showNotification } =
    SharePopupHook();

  return (
    <Modal
      show={show}
      showHeader={false}
      onClose={onClose}
      content={{
        styles: {
          width: "600px",
        },
      }}
      body={{
        styles: {
          padding: "0px",
        },
      }}
    >
      <PopupWrapper>
        <div className="icon">
          <figure className="icon-close" onClick={onClose} />
        </div>
        <div className="title">{t("header.popups.sharePopup.title")}</div>
        <div className="description">
          <div className="img-store">
            <img src={imgStore} alt="Popup logo tienda" />
          </div>
          <div className="text">
            {t("header.popups.sharePopup.description", { storeName })}
          </div>
        </div>
        <div className="actions">
          <div className="button" onClick={goToWspLink}>
            <div className="icon">
              <figure className="icon-whatsapp-outline"></figure>
            </div>
            <div className="text">{t("header.popups.sharePopup.linkWsp")}</div>
          </div>
          <div className="button" onClick={copyToClipboard}>
            <div className="icon">
              <figure className="icon-light-link-simple"></figure>
            </div>
            <div className="text">{t("header.popups.sharePopup.linkCopy")}</div>
          </div>
          <div className="button" onClick={openMail}>
            <div className="icon">
              <figure className="icon-email-outline"></figure>
            </div>
            <div className="text">
              {t("header.popups.sharePopup.linkEmail")}
            </div>
          </div>
        </div>
      </PopupWrapper>
      {showNotification && (
        <NotificationSuccess>
          <div className="text">{t("header.popups.actions.successCopy")}</div>
        </NotificationSuccess>
      )}
    </Modal>
  );
};

export default SharePopup;

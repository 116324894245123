import styled from "styled-components";

export const LoginSendPhoneWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 447px;
  .back {
    top: -10px;
    left: -20px;
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    .icon-arrow {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .logo-back {
    width: 94px;
    margin: 0 auto 60px auto;
  }
  .send-phone-title {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 30px 0;
  }
  .disclaimer {
    color: #111111;
    font-size: 12px;
    line-height: 18px;
    margin: 20px 0 14px 0;
  }
  .button {
    gap: 8px;
    height: 48px;
    display: flex;
    padding: 12px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    margin: 0 0 10px 0;
    align-items: center;
    background: #24272E;
    justify-content: center;
    &:disabled {
      background: #B0B2B6;
    }
    &.whatsapp {
      color: #25D366;
      background-color: #FFFFFF;
      border: 1px solid #25D366;
      &:disabled {
        color: #B0B2B6;
        background-color: #FFFFFF;
        border: 1px solid #B0B2B6;
      }
    }
    .icon-comments-outline, .icon-whatsapp-color {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

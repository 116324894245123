export enum StatisticsFilter {
  LastDay = 'last_day',
  LastSevenDays = 'last_seven_days',
  LastFifteenDays = 'last_fifteen_days',
  LastMonth = 'last_month',
};

export interface HomeFakeDoor {
  name: string;
  description: string;
  image: string;
  isExternalRoute: boolean;
  route: string;
};

export interface HomeBanner {
  title: string;
  description: string;
  cta: string;
  ctaColorHex: string;
  ctaBackgroundHex: string;
  textsColorHex: string;
  route: string;
  isExternalRoute: boolean;
  img: string;
  bg: string;
};

export interface HomeLearning {
  img: string;
  title: string;
  link: string;
  views: number;
};

export interface HomeStatistics {
  id?: string;
  owner_id?: string;

  count_visit_last_day?: number;
  count_visit_last_seven_days?: number;
  count_visit_last_fifteen_days?: number;
  count_visit_last_month?: number;
  visit_last_day_percentage?: number;
  visit_last_seven_days_percentage?: number;
  visit_last_fifteen_days_percentage?: number;
  visit_last_month_percentage?: number;

  count_orders_last_day?: number;
  count_orders_last_seven_days?: number;
  count_orders_last_fifteen_days?: number;
  count_orders_last_month?: number;
  orders_last_day_percentage?: number;
  orders_last_seven_days_percentage?: number;
  orders_last_fifteen_days_percentage?: number;
  orders_last_month_percentage?: number;

  count_orders_units_last_day?: number;
  count_orders_units_last_seven_days?: number;
  count_orders_units_last_fifteen_days?: number;
  count_orders_units_last_month?: number;
  orders_units_last_day_percentage?: number;
  orders_units_last_seven_days_percentage?: number;
  orders_units_last_fifteen_days_percentage?: number;
  orders_units_last_month_percentage?: number;

  orders_total_last_day?: number;
  orders_total_last_seven_days?: number;
  orders_total_last_fifteen_days?: number;
  orders_total_last_month?: number;
  orders_total_last_day_percentage?: number;
  orders_total_last_seven_days_percentage?: number;
  orders_total_last_fifteen_days_percentage?: number;
  orders_total_last_month_percentage?: number;

  count_abandoned_cart_last_day?: number;
  count_abandoned_cart_last_seven_days?: number;
  count_abandoned_cart_last_fifteen_days?: number;
  count_abandoned_cart_month?: number;
  count_abandoned_cart_last_month?: number;
  abandoned_cart_last_day_percentage?: number;
  abandoned_cart_last_seven_days_percentage?: number;
  abandoned_cart_last_fifteen_days_percentage?: number;
  abandoned_cart_last_month_percentage?: number;
};

export interface HomeNewOrders {
  total: number;
  data: {
    products: {
      images: string[];
    }[];
  }[];
};

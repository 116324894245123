import styled from "styled-components";

export const NewOrdersWrapper = styled.div`
  top: 50%;
  right: 16px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  min-width: 276px;
  border-radius: 8px;
  position: absolute;
  transform: translateY(-50%);
  justify-content: space-between;
  background: var(--grey-3, #3E434E);
  border: 1px solid var(--grey-w, #FFF);
  .texts {
    .title {
      display: flex;
      font-size: 14px;
      column-gap: 4px;
      font-weight: 600;
      line-height: 22px;
      margin: 0 0 4px 0;
      align-items: center;
      color: var(--grey-w, #FFF);
      .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #F93C00;
      }
    }
    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: var(--grey-w, #FFF);
    }
  }
  .arrow {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    .icon-arrow {
      font-size: 20px;
      transform: rotate(-90deg);
    }
  }
`;

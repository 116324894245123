export const getCatalogueUrlShare = async (token: string) => {
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue/product/url-share`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as any,
    }
  );
};

export interface ValidateCodeResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

export enum RequestCodeChannel {
  Sms = 'sms',
  Whatsapp = 'whatsapp',
  Email = 'email',
};

export type RequestCodePayload = {
  send_to: string;
  channel: RequestCodeChannel;
  prefix_send_to?: string;
  email?: string;
};

export type VerifyCodePayload = {
  send_to: string;
  channel: RequestCodeChannel;
  prefix_send_to?: string;
  code?: string;
  parameter_check_code_user?: {[key: string]: string};
};

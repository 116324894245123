import { getApiUrl, getEnvVar } from "@dashboard/common/utils";
import { RequestCodePayload, ValidateCodeResponse, VerifyCodePayload } from "../entities/auth.entities";

export const googleLogin = async (token: string): Promise<ValidateCodeResponse | null> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/user/auth/login-google-v2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "api-key-sumer": getEnvVar('REACT_APP_KEY'),
      } as any,
      body: JSON.stringify({ token: token }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else return null;
};

export const facebookLogin = async (token: string): Promise<ValidateCodeResponse | null> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/user/auth/login-facebook`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "api-key-sumer": getEnvVar('REACT_APP_KEY'),
      } as any,
      body: JSON.stringify({ token: token }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else return null;
};

export const sendPhoneCode = async (payload: RequestCodePayload): Promise<boolean> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/user/twilio/login/verification-code`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "api-key-sumer": getEnvVar('REACT_APP_KEY'),
      } as any,
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    const r = await response.json();
    return r.status === 'pending';
  } else return false;
};

export const verifyCode = async (payload: VerifyCodePayload): Promise<{ access_token: string }> => {
  const settings = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "accept-language": "es_CO",
      "api-key-sumer": getEnvVar('REACT_APP_KEY'),
    } as any,
    body: JSON.stringify(payload),
  };
  return fetch(`${getApiUrl()}/api/ms/user/twilio/login/check-code`, settings)
    .then(response => {
      if (response.status === 200) return response.json();
      else throw new Error('Error in fetching, status not 200');
    });
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BusinessSector, IpInfo, OnboardingData } from "../entities/onboarding.entities";
import { OnboardingUiState, initialState } from "./onboarding.state";
import { PersonalizationViewMode } from "../constants/personalization.constants";
import { UserRedirectResponse } from "../entities/split.entities";

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setUi: (state, { payload }: PayloadAction<Partial<OnboardingUiState>>) => {
      state.ui = { ...state.ui, ...payload };
    },
    setEconomicSectors: (state, { payload }: PayloadAction<BusinessSector[]>) => {
      state.data.businessSectors = payload;
    },
    setOnboardingData: (state, { payload }: PayloadAction<OnboardingData>) => {
      state.data.onboardingData = payload;
    },
    setCouponCode: (state, { payload }: PayloadAction<string>) => {
      state.data.couponCode = payload;
    },
    setPersonalizationViewMode: (state, { payload }: PayloadAction<PersonalizationViewMode>) => {
      state.ui.personalizationViewMode = payload;
    },
    setParams: (state, { payload }: PayloadAction<{[key: string]: string}>) => {
      state.data.params = payload;
    },
    setStoreDesignSplit: (state, { payload }: PayloadAction<UserRedirectResponse>) => {
      state.data.storeDesignSplit = payload;
    },
    setSourcePath: (state, { payload }: PayloadAction<string>) => {
      state.data.sourcePath = payload;
    },
    setOnboardingType: (state, { payload }: PayloadAction<string>) => {
      state.data.onboardingType = payload;
    },
    setIpInfo: (state, { payload }: PayloadAction<IpInfo>) => {
      state.data.ipInfo = payload;
    },
  }
});

export const actions = onboardingSlice.actions;

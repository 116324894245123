import styled from "styled-components";

export const HookInformationModalWrapper = styled.div`
  .modal {
    width: 580px;
    overflow: hidden;
    border-radius: 16px;
    background: var(--grey-w, #fff);
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    text-align: center;
   
    .img {
      width: 60%;
      padding: 24px;
    }
    .copys {
      border-top: 1px solid #f1f1f1;
      padding: 0px 24px 24px;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        margin: 24px 0px 8px 0px;
        color: var(--grey-b, #24272e);
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-bottom: 24px;
        color: var(--grey-b, #24272e);
      }
      .button {
        height: 50px;
        display: flex;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: var(--grey-8, #fcfcfc);
        background: var(--grey-b, #24272e);
      }
    }
  }
`;

import styled from "styled-components";

export const ProductDetailWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  > .title {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--Grey-Border, #e9e9ed);
    background: var(--Grey-W, #fff);
    .text {
      color: var(--Grey-B, #24272e);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 20px;
  }
`;

export const PhotosTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  .text {
    color: var(--Grey-B, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    flex: 1 0 0;
  }
  .editContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    .editText {
      color: var(--Grey-B, #24272e);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
    .editIcon {
      font-size: 20px;
      color: var(--Grey-B, #24272e);
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const InventoryTitle = styled.div`
  margin-top: 14px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  .text {
    color: var(--grey-b, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
`;

export const ImagesTagsStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
  .product-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    button {
      background: transparent;
      font-size: 1rem;
      padding: 0;
      svg {
        display: block;
      }
    }
    .tag {
      align-items: center;
      border: 1px solid var(--Grey-B);
      border-radius: 0.25rem;
      box-shadow: 0px 6px 8px 0px rgba(95, 99, 108, 0.12);
      color: var(--Grey-B);
      display: inline-flex;
      gap: 0.25rem;
      font-size: 0.75rem;
      line-height: 1em;
      min-height: 26px;
      padding: 0.125rem 0.5rem;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const SaleInfoContainer = styled.div`
  display: flex;
  padding: 16px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: var(--grey-7, #e9e9ed);
  border-radius: 8px;
  margin-top: 12px;
  .info {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .iconSale {
      display: flex;
      width: 32px;
      height: 32px;
      font-size: 20px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      background: var(--grey-8, #fcfcfc);
      &.minus-size {
        font-size: 15px;
      }
    }
    .title {
      color: var(--grey-4, #5d606f);

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      display: block;
    }
    .subtitle {
      color: var(--grey-4, #5d606f);

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      white-space: nowrap;
    }
    .buttonApp {
      display: flex;
      width: 100%;
      padding: 2px 4px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid var(--grey-b, #24272e);
      .text {
        color: var(--grey-b, #24272e);

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin: auto;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .label {
        color: var(--Grey-B, #24272e);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  label {
    color: var(--grey-b, #24272e);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .input {
    width: -webkit-fill-available;
  }
  .variation-label {
    .label {
      color: var(--Grey-B, #24272e);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: fit-content;
      .text {
        color: var(--Grey-B, #24272e);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-decoration-line: underline;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

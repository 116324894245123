import { camelizeKeys } from "humps";
import { getApiUrl } from "@dashboard/common/utils";
import { KustomerResponse } from "@dashboard/common/types";

export const fetchKustomerToken = async (
  token: string
): Promise<KustomerResponse> => {
  return fetch(`${getApiUrl()}/api/ms/kustomer/generate/token`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    } as any,
  })
    .then(async (response) => {
      if (response.ok) {
        return camelizeKeys(await response.json()) as KustomerResponse;
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      return {};
    });
};

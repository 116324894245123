import { FC, useEffect, useState } from "react";
import { SwitchProps } from "./models/switch.type";
import { CustomSwitchContainer } from "./custom-switch.styled";

const CustomSwitch: FC<SwitchProps> = ({ isActive, onChange, disabled }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (isActive !== undefined) setState(isActive);
  }, [isActive]);

  return (
    <CustomSwitchContainer
      className={`toggle-switch ${state && "active"} ${disabled && "disabled"}`}
      onClick={(e: any) => {
        setState((a) => {
          e.stopPropagation();
          return !a;
        });
        onChange(!state);
      }}
    >
      <span className={`${state && "icon-check check"}`}></span>
    </CustomSwitchContainer>  
  );
};

export default CustomSwitch;

import styled from "styled-components";

export const SendEmailConfirmationWrapper = styled.div`
  z-index: 2;
  display: flex;
  max-width: 375px;
  position: relative;
  border-radius: 24px;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 90px 24px 60px 24px;
  .close {
    top: 18px;
    right: 18px;
    width: 28px;
    height: 28px;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .main-icon {
    width: 102px;
    height: 102px;
    display: flex;
    color: #2C68F6;
    font-size: 60px;
    margin: 0 0 24px 0;
    border-radius: 50%;
    align-items: center;
    background: #E5EDFF;
    justify-content: center;
  }
  .title {
    color: #262930;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    margin: 0 0 12px 0;
  }
  .description {
    color: #262930;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0 0 56px 0;
  }
  .button {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: fit-content;
    background-color: #2C68F6;
    .icon-arrow {
      transform: rotate(-90deg);
    }
  }
`;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAiAssistantServices } from "./hooks/ai-assistant-services.hook";
import { AiMessage, AiMessageCreateResponse } from "./types/messages.types";
import { promiseTimeout } from "@dashboard/utils/debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  messageAiAssistantSelector,
  partnerSelector,
  showAiAssistantSelector,
} from "@dashboard/config/redux";
import { getMessageWaiting } from "./utils/messages.utils";
import { Feedback } from "./types/feedbacks.types";
import { QuickAnswer } from "./types/quick-answers.types";
import { useTranslation } from "react-i18next";
import { QUICK_ANSWERS_MESSAGE_IDS } from "./constants/quick-answers.constants";
import { MESSAGE_WAITING_IDS } from "./constants/messages.constants";
import { checkoutActions } from "../checkout/store/checkout.slice";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents } from "@dashboard/constants/events";
import { AI_ASSISTANT_EVENTS } from "./constants/events.constants";

export const useAiAssistant = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const {
    showSplash,
    getRecentMessages,
    createMessage,
    waitMessage,
    getFeedbacks,
    createFeedback,
    getQuickAnswers,
    regenerateMessage,
    getGetInteractions,
  } = useAiAssistantServices();
  const partner = useSelector(partnerSelector);
  const showAiAssistant = useSelector(showAiAssistantSelector);
  const directMessage = useSelector(messageAiAssistantSelector);
  const [currentMessages, setMessages] = useState<AiMessage[] | undefined>();
  const [waitingMessage, setWaitingMessage] = useState<
    AiMessage[] | undefined
  >();
  const [messageRegenerating, setMessageRegenerating] = useState<string>();
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [quickAnswers, setQuickAnswers] = useState<QuickAnswer[]>([]);
  const [chatTitle, setChatTitle] = useState<string>();
  const [maximized, setMaximized] = useState(false);
  const [interactionsLeft, setInteractionsLeft] = useState(Number.MAX_VALUE);

  const messages = useMemo(
    () => [...(currentMessages || []), ...(waitingMessage || [])],
    [currentMessages, waitingMessage]
  );

  const showDisabledChat = useMemo(
    () => interactionsLeft < 0,
    [interactionsLeft]
  );

  const handleCloseAiAssistant = () => {
    dispatch(actions.closeAiAssistant());
    setMaximized(false);
    logEvent(CoreEvents.SelectButton, {
      event_subtype: AI_ASSISTANT_EVENTS.AI_OPTIONS,
      option: "close",
    });
  };

  const handleToggleMaximized = () => {
    setMaximized((maximized) => !maximized);
    logEvent(CoreEvents.SelectButton, {
      event_subtype: AI_ASSISTANT_EVENTS.AI_OPTIONS,
      option: "resize",
    });
  };

  const loadMessages = () => {
    getRecentMessages()?.then((data) => {
      const recentMessages = data || [];
      setMessages(recentMessages);
      if (recentMessages.length === 0) {
        loadQuickAnswers();
      }
    });
  };

  const loadFeedbacks = () => {
    getFeedbacks()?.then((data) => {
      setFeedbacks(data);
    });
  };

  const loadInteractions = () => {
    getGetInteractions()?.then((data) => {
      setInteractionsLeft(data.monthlyInteractionsLeft);
    });
  };

  const loadQuickAnswers = () => {
    getQuickAnswers().then((answers) => {
      setQuickAnswers(answers);
    });
  };

  const handleCreateFeedback = (message: AiMessage, approved: boolean) => {
    createFeedback(message, approved)?.then(() => {
      setFeedbacks((feedbacks) => {
        return [
          {
            approvedResponse: approved,
            messageId: message.messageId,
            conversationId: message.conversationId || "",
            createdAt: new Date(),
          },
          ...feedbacks,
        ];
      });
    });
    logEvent(CoreEvents.SelectButton, {
      event_subtype: AI_ASSISTANT_EVENTS.AI_OPTIONS,
      option: approved ? "good_rate" : "bad_rate",
    });
  };

  const handleQuickAnswer = (answer: QuickAnswer) => {
    setChatTitle(answer.questionTitle);
    setMessages(() => {
      return [
        {
          messageId: QUICK_ANSWERS_MESSAGE_IDS.user,
          message: `<h3>${answer.questionTitle}</h3>${answer.questionSubtitle}`,
          role: "user",
          createdAt: new Date(),
        },
        {
          messageId: QUICK_ANSWERS_MESSAGE_IDS.assistant,
          message: answer.message,
          role: "assistant",
          createdAt: new Date(),
        },
      ];
    });
  };

  const waitMessages = async (
    create: AiMessageCreateResponse,
    options: { message: string; history?: AiMessage[] }
  ) => {
    const { message, history } = options;
    setInteractionsLeft(create.monthlyInteractionsLeft);
    try {
      const getMessageFirebase = () =>
        promiseTimeout(() => waitMessage(create.path), history ? 3000 : 12000);
      let tryRead = 1;
      let successGenerate = false;
      do {
        const response = await getMessageFirebase();
        if (
          !history ||
          (message &&
            !history.find(
              (item) => item.messageId === response.inputMessageData.messageId
            ))
        ) {
          setWaitingMessage(undefined);
          setMessages((currentMessages) => {
            return [
              ...(currentMessages || []),
              ...(history ? [response.inputMessageData] : []),
              response.botMessageData,
            ];
          });
          successGenerate = true;
          break;
        }
        tryRead++;
      } while (tryRead <= 10);
      if (!successGenerate) {
        throw new Error();
      }
    } catch (error) {
      setWaitingMessage((messages) =>
        messages?.map((item, index) =>
          index === 0 ? item : { ...item, message: undefined }
        )
      );
    }
  };

  const handleSendMessage = useCallback(
    (message: string) => {
      if (message) {
        if (interactionsLeft > 0) {
          let history = [...(currentMessages || [])].slice(
            (currentMessages || []).length - 2
          );
          if (
            history.length === 2 &&
            history[1].messageId === QUICK_ANSWERS_MESSAGE_IDS.assistant
          ) {
            history = [];
          }
          setWaitingMessage(getMessageWaiting(message));
          createMessage({
            message,
            history,
          })?.then(async (responseCreate) => {
            waitMessages(responseCreate, { message, history });
          });
          logEvent(CoreEvents.SelectButton, {
            event_subtype: AI_ASSISTANT_EVENTS.AI_SEND,
          });
        } else {
          setInteractionsLeft(-1);
        }
      }
    },
    [currentMessages]
  );

  const handleRegenerateQuestion = useCallback(() => {
    const messageExpired = waitingMessage?.find(
      (item) => item.messageId === MESSAGE_WAITING_IDS.user
    );
    if (messageExpired) {
      handleSendMessage(messageExpired.message || "");
    }
  }, [waitingMessage]);

  const handleRegenerateResponse = useCallback(
    (message: AiMessage, inputMessageId?: string) => {
      if (interactionsLeft > 0) {
        if (message.messageId === MESSAGE_WAITING_IDS.assistant) {
          handleRegenerateQuestion();
        } else {
          regenerateMessage({ message, inputMessageId })?.then(
            (responseRegenerate) => {
              setMessageRegenerating(message.messageId);
              waitMessages(responseRegenerate, {
                message: message.message || "",
              }).then(() => {
                setMessageRegenerating(undefined);
              });
            }
          );
          logEvent(CoreEvents.SelectButton, {
            event_subtype: AI_ASSISTANT_EVENTS.AI_OPTIONS,
            option: "regenerate",
          });
        }
      } else {
        setInteractionsLeft(-1);
      }
    },
    [interactionsLeft]
  );

  const handleAcquirePlan = useCallback(() => {
    handleCloseAiAssistant();
    dispatch(
      checkoutActions.setToBuyItem({
        type: "plan",
        plan_name: "Ultra",
        plan_duration: "Anual",
      })
    );
    navigate(`/${partner?.userId}/checkout`);
  }, [partner]);

  useEffect(() => {
    if (showAiAssistant && currentMessages === undefined) {
      loadMessages();
      loadFeedbacks();
      loadInteractions();
    }
  }, [showAiAssistant]);

  useEffect(() => {
    if (directMessage) {
      handleSendMessage(directMessage);
      dispatch(actions.setMessageAiAssistant());
    }
  }, [directMessage]);

  return {
    t,
    logEvent,
    messages,
    partner,
    currentMessages,
    showSplash,
    chatTitle,
    maximized,
    showAiAssistant,
    feedbacks,
    quickAnswers,
    messageRegenerating,
    showDisabledChat,
    handleToggleMaximized,
    handleSendMessage,
    handleCloseAiAssistant,
    handleCreateFeedback,
    handleQuickAnswer,
    handleRegenerateResponse,
    handleAcquirePlan,
  };
};

import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  GptGenerateButtonStyled,
  GptGenerateErrorStyled,
  GptGenerateLoadingStyled,
  GptGenerateModalStyled,
  GptGenerateStyled,
} from "./gpt-generate.styled";
import Gpt from "@sumerkit/icons/gpt";
import X from "@sumerkit/icons/x";
import Button from "@sumerkit/ui/button";
import { useTranslation } from "react-i18next";
import {
  PredictProduct,
  PredictProductError,
} from "@dashboard/modules/products/types/products-gpt.types";
import { AxiosError } from "axios";
import ProductContext from "../../context/product.context";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";

type GptGenerateProps = {
  fieldLabel: string;
  buttonLabel?: string;
  descriptionCopy?: string;
  helpCopy?: string;
  position?: "bottom" | "top";
  loading?: boolean;
  onClickGenerate?: () => void;
  onClickPredict?: () => Promise<PredictProduct> | undefined;
};

const GptGenerate: FC<GptGenerateProps> = ({
  fieldLabel,
  buttonLabel,
  descriptionCopy,
  helpCopy,
  position = "bottom",
  loading,
  onClickGenerate,
  onClickPredict,
}) => {
  const { t } = useTranslation();
  const { setShowProductsAiHook } = useContext(ProductContext);
  const { isAvailable } = useFeatureAvailability(
    FeatureProducts.ProductsAi,
    true
  );
  const gptGenerateRef = useRef<HTMLDivElement>(null);
  const gptGenerateModalRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<boolean | "retry">(false);

  const handleShowModal = () => {
    if (isAvailable) {
      onClickGenerate && onClickGenerate();
      setShowModal(true);
      document.addEventListener("mousedown", handleCloseModal, false);
    } else {
      setShowProductsAiHook(true);
    }
  };

  const handleCloseModal = (event?: Event) => {
    if (
      !event ||
      !gptGenerateModalRef.current?.contains(event.target as Node)
    ) {
      setShowModal(false);
      setError(false);
      document.removeEventListener("mousedown", handleCloseModal, false);
    }
  };

  const handleClickPredict = () => {
    setError(false);
    onClickPredict &&
      onClickPredict()
        ?.then(() => {
          handleCloseModal();
        })
        .catch((error: AxiosError) => {
          const response = error.response?.data as PredictProductError;
          if (
            response &&
            [
              "visual-gpt-products.clip_vision_model_bad_request",
              "visual-gpt-products.openai_bad_request",
            ].indexOf(response.code) >= 0
          ) {
            setError("retry");
            return;
          }
          setError(true);
        });
  };

  useEffect(() => {
    if (gptGenerateRef.current) {
      (gptGenerateRef.current.parentElement as HTMLDivElement).style.position =
        "relative";
    }
  }, [gptGenerateRef]);

  const GptGenerateError = () => {
    const handleAction = () => {
      if (error === "retry") {
        handleClickPredict();
      } else {
        handleCloseModal();
      }
    };

    return (
      <GptGenerateErrorStyled>
        <div className="gpt-error-icon">
          <Gpt />
        </div>
        <h3 className="gpt-error-title">
          {error === "retry"
            ? t("products.gptGenerate.retry.title")
            : t("products.gptGenerate.error.title")}
        </h3>
        <p className="gpt-error-description">
          {error === "retry"
            ? t("products.gptGenerate.retry.description")
            : t("products.gptGenerate.error.description")}
        </p>
        <Button
          appearance="secondary"
          darkMode
          fullWidth
          onClick={handleAction}
        >
          {error === "retry"
            ? t("products.gptGenerate.retry.action")
            : t("products.gptGenerate.error.action")}
        </Button>
      </GptGenerateErrorStyled>
    );
  };

  return (
    <GptGenerateStyled ref={gptGenerateRef}>
      <GptGenerateButtonStyled onClick={handleShowModal}>
        <Gpt className="gpt-icon" />
        <span>{buttonLabel || t("products.gptGenerate.generate")}</span>
      </GptGenerateButtonStyled>
      {showModal && (
        <GptGenerateModalStyled
          ref={gptGenerateModalRef}
          className={`${
            !!position && `gpt-generate-modal__${position}`
          } fadeInUp`}
        >
          <button
            className="gpt-generate-close"
            onClick={() => handleCloseModal()}
          >
            <X />
          </button>
          {!error && (
            <>
              <div className="gpt-generate-modal-header">
                <div className="modal-header-icon">
                  <Gpt />
                </div>
                <h3
                  className="modal-header-title"
                  dangerouslySetInnerHTML={{
                    __html: t("products.gptGenerate.title"),
                  }}
                />
              </div>
              <div className="gpt-generate-modal-info">
                <div className={!!loading ? "gpt-generate-modal__loading" : ""}>
                  {!!descriptionCopy && <p>{descriptionCopy}</p>}
                  <Button
                    appearance="secondary"
                    darkMode
                    fullWidth
                    onClick={handleClickPredict}
                  >
                    {t("products.gptGenerate.generateField", { fieldLabel })}
                  </Button>
                  {!!helpCopy && (
                    <p>
                      <small>{helpCopy}</small>
                    </p>
                  )}
                </div>
                {loading && (
                  <GptGenerateLoadingStyled>
                    <div className="gpt-loader" />
                    {t("products.gptGenerate.loading", { fieldLabel })}
                  </GptGenerateLoadingStyled>
                )}
              </div>
              {false && (
                <div className="gpt-generate-modal-footer">
                  <Button appearance="secondary" darkMode link ghost>
                    {t("products.gptGenerate.goToAddons")}
                  </Button>
                </div>
              )}
            </>
          )}
          {error && <GptGenerateError />}
        </GptGenerateModalStyled>
      )}
    </GptGenerateStyled>
  );
};

export default GptGenerate;

const WarningCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M12 2.38477C10.0716 2.38477 8.18657 2.95659 6.58319 4.02794C4.97982 5.09928 3.73013 6.62202 2.99218 8.4036C2.25422 10.1852 2.06114 12.1456 2.43735 14.0369C2.81355 15.9282 3.74215 17.6655 5.10571 19.0291C6.46928 20.3926 8.20656 21.3212 10.0979 21.6974C11.9892 22.0736 13.9496 21.8805 15.7312 21.1426C17.5127 20.4046 19.0355 19.155 20.1068 17.5516C21.1782 15.9482 21.75 14.0631 21.75 12.1348C21.745 9.55043 20.7162 7.07335 18.8888 5.24595C17.0614 3.41854 14.5843 2.38972 12 2.38477ZM11.25 7.63477C11.25 7.43585 11.329 7.24509 11.4697 7.10444C11.6103 6.96378 11.8011 6.88477 12 6.88477C12.1989 6.88477 12.3897 6.96378 12.5303 7.10444C12.671 7.24509 12.75 7.43585 12.75 7.63477V12.8848C12.75 13.0837 12.671 13.2744 12.5303 13.4151C12.3897 13.5557 12.1989 13.6348 12 13.6348C11.8011 13.6348 11.6103 13.5557 11.4697 13.4151C11.329 13.2744 11.25 13.0837 11.25 12.8848V7.63477ZM12 17.3848C11.7775 17.3848 11.56 17.3188 11.375 17.1952C11.19 17.0716 11.0458 16.8959 10.9606 16.6903C10.8755 16.4847 10.8532 16.2585 10.8966 16.0403C10.94 15.8221 11.0472 15.6216 11.2045 15.4643C11.3618 15.3069 11.5623 15.1998 11.7805 15.1564C11.9988 15.113 12.225 15.1353 12.4305 15.2204C12.6361 15.3056 12.8118 15.4497 12.9354 15.6347C13.059 15.8198 13.125 16.0373 13.125 16.2598C13.125 16.5581 13.0065 16.8443 12.7955 17.0553C12.5845 17.2662 12.2984 17.3848 12 17.3848Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningCircle;

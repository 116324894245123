const locale = {
  title: "Planes",
  compare: {
    title: "Compara nuestros planes",
    description:"MEMBRESIA CON SUMER",
    continue: "Continuar",
    monthly: "Mensual",
    annual: "Anual",
    acquire: "Adquirir",
    popular: "Más popular",
    current: "Mi plan actual",
    period__Mensual: "Mes",
    period__Anual: "Año",
    firstPeriod__Mensual: "Mes",
    firstPeriod__Anual: "Anual",
    discount: {
      monthly: "<strong>{{pricing}}</strong> por tu primer mes",
      yearly: "<strong>{{pricing}}</strong> por tu primer año",
    },
  },
};

export default locale;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalLoader, initialState } from "./global.state";
import { camelizeKeys } from "humps";
import { getPartnerByToken } from "@dashboard/services/partner.service";
import {
  getConfiguration,
  updateStoreConfiguration,
} from "@dashboard/services/configuration.service";
import {
  AddonInfo,
  Configuration,
  Partner,
  PartnerNetwork,
  PlatformCountry,
  StoreUrlShare,
} from "../../common/types";
import { PlanBenefit } from "@dashboard/entities/plan-benefit";
import { Menu } from "@dashboard/entities/menu";
import { HookInfo } from "@dashboard/entities/hook-info";
import { Plan } from "@dashboard/entities/plan-funnel";
import { getCatalogueUrlShare } from "@dashboard/services/catalogue.service";
import { HookUpgradePlanState } from "@dashboard/components/hook-upgrade-plan/hook-upgrade-plan.types";
import { UserAddon } from "@dashboard/entities/user-addon";
import { t } from "i18next";
import { CustomPlanHook } from "@dashboard/constants/custom-plan-hook";
import { Feature } from '@dashboard/constants/features';
import { Plan as PlanI, PlanParams, ReviewsPlansTypes } from "@dashboard/modules/plans/plans.types";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";
import { MeliFeatures } from "@dashboard/modules/meli/constants/features.constants";
import { PersonalizationFeatures } from "@dashboard/modules/personalization/constants/features.constants";

export const fetchPartnerByToken = createAsyncThunk(
  "partner/fetchByToken",
  async (token: string, { rejectWithValue }) => {
    const response = await getPartnerByToken(token);
    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }
    return camelizeKeys(await response.json());
  }
);

export const fetchConfiguration = createAsyncThunk(
  "configuration/fetchByToken",
  async (token: string, { rejectWithValue }) => {
    const response = await getConfiguration(token);
    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }
    return camelizeKeys(await response.json());
  }
);

export const fetchUrlShare = createAsyncThunk(
  "configuration/fetchUrlShare",
  async (token: string, { rejectWithValue }) => {
    const response = await getCatalogueUrlShare(token);
    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }
    return camelizeKeys(await response.json());
  }
);

export const updateConfiguration = createAsyncThunk(
  "configuration/updateByToken",
  async (
    obj: { token: string; payload: Configuration },
    { rejectWithValue }
  ) => {
    const response = await updateStoreConfiguration(obj.token, obj.payload);
    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }
    return camelizeKeys(await response.json());
  }
);

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setShowGeneralLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showGeneralLoader = payload;
    },
    setCountries: (state, { payload }: PayloadAction<PlatformCountry[]>) => {
      state.data.countries = payload;
    },
    setShowAddonHook: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showAddonHook = payload;
    },
    setAvailableBenefits: (
      state,
      { payload }: PayloadAction<PlanBenefit[]>
    ) => {
      state.data.availableFeatures = payload;
    },
    setAvailableAddons: (state, { payload }: PayloadAction<UserAddon[]>) => {
      state.data.availableAddons = payload;
    },
    setPlan: (state, { payload }: PayloadAction<Plan>) => {
      state.data.plan = payload;
    },
    setPartnerData: (state, { payload }: PayloadAction<any>) => {
      state.data.partner = payload.userInformation;
      state.data.countryInformation = payload.countryInformation;
    },
    setToBuyAddonInfo: (state, { payload }: PayloadAction<AddonInfo>) => {
      state.data.toBuyAddonInfo = payload;
    },
    setMenu: (state, { payload }: PayloadAction<Menu[]>) => {
      state.data.menu = payload;
    },
    setReviewsPlans: (state, { payload }: PayloadAction<ReviewsPlansTypes[]>) => {
      state.data.reviewsPlans = payload;
    },
    setHookInfo: (state, { payload }: PayloadAction<HookInfo | undefined>) => {
      state.data.hookInfo = payload;
    },
    setUserDetailData: (
      state,
      {
        payload,
      }: PayloadAction<{ userDetail?: Partner; userNetwork?: PartnerNetwork }>
    ) => {
      state.data.partner = !!state.data.partner
        ? {
            ...state.data.partner,
            userDetail: payload.userDetail || state.data.partner.userDetail,
            userNetwork: payload.userNetwork || state.data.partner.userNetwork,
          }
        : undefined;
    },
    showUpgradePlanHook: (
      state,
      action: PayloadAction<HookUpgradePlanState | undefined>
    ) => {
      state.ui.upgradePlanHook = action.payload || {};
    },
    showFeatureSoonHook: (state) => {
      state.ui.upgradePlanHook = {
        title: t("featureSoon.title"),
        description: t("featureSoon.copy"),
        image:
          "https://sumerlabs.com/prod/assets/sumerweb/hook-feature-soon.webp",
        confirmLabel: t("featureSoon.accept"),
        closeOnConfirm: true,
        cancelLabel: "",
      };
    },
    hideUpgradePlanHook: (state) => {
      state.ui.upgradePlanHook = undefined;
    },
    openAiAssistant: (state, { payload }: PayloadAction<string | undefined>) => {
      state.ui.showAiAssistant = true;
      if (payload) {
        state.ui.messageAiAssistant = payload;
      }
    },
    closeAiAssistant: (state) => {
      state.ui.showAiAssistant = false;
    },
    setMessageAiAssistant: (state, { payload }: PayloadAction<string | undefined>) => {
      state.ui.messageAiAssistant = payload;
    },
    setShowGlobalLoader: (state, { payload }: PayloadAction<GlobalLoader>) => {
      state.ui.globalLoader = payload;
    },
    setShowShareModal: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showShareModal = payload;
    },
    setCustomPlanHooks: (
      state,
      { payload }: PayloadAction<CustomPlanHook[]>
    ) => {
      state.data.customPlanHooks = payload;
    },
    showCustomPlanHook: (
      state,
      {
        payload,
      }: PayloadAction<
        Feature | FeatureProducts | MeliFeatures | PersonalizationFeatures
      >
    ) => {
      state.ui.showCustomPlanHook = true;
      state.ui.customPlanHookFeature = payload;
    },
    hideCustomPlanHook: (state) => {
      state.ui.showCustomPlanHook = false;
      state.ui.customPlanHookFeature = "";
    },
    setPlans: (state, { payload }: PayloadAction<PlanI[]>) => {
      state.data.plans = payload;
    },
    setPlanParams: (state, { payload }: PayloadAction<PlanParams>) => {
      state.data.planParams = payload;
    },
    resetPlanParams: (state) => {
      state.data.planParams = undefined;
    },
    setIsNavigationBarCollapsed: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isNavigationBarCollapsed = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartnerByToken.pending, (state, action) => {
      state.data.isLoginError = false;
    });
    builder.addCase(fetchPartnerByToken.fulfilled, (state, action: any) => {
      state.data.partner = action.payload.userInformation;
      state.data.countryInformation = action.payload.countryInformation;
    });
    builder.addCase(fetchPartnerByToken.rejected, (state, action: any) => {
      state.data.partner = undefined;
      state.data.isLoginError = true;
    });
    builder.addCase(fetchConfiguration.fulfilled, (state, action) => {
      state.data.configuration = action.payload as unknown as Configuration;
    });
    builder.addCase(fetchUrlShare.fulfilled, (state, action) => {
      state.data.urlShare = action.payload as unknown as StoreUrlShare;
    });
    builder.addCase(updateConfiguration.fulfilled, (state, action) => {
      state.data.configuration = action.payload as unknown as Configuration;
      state.ui.isLoadingConfiguration = false;
    });
    builder.addCase(updateConfiguration.pending, (state, action) => {
      state.ui.isLoadingConfiguration = true;
    });
    builder.addCase(updateConfiguration.rejected, (state, action) => {
      state.ui.isLoadingConfiguration = false;
    });
  },
});

export const actions = globalSlice.actions;
export default globalSlice.reducer;

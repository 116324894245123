const locale = {
  product: {
    name: "Producto",
    breadcrumb: {
      createPath: "Crear producto",
      editPath: "Editar producto",
      editPhotos: "Editar fotos",
      editFeatures: "Editar características",
      editStock: "Editar stock"
    },
    mapContent: {
      detailLabel: "Detalle",
      inventoryLabel: "Inventario",
      productInfoLabel: "Información del producto",
    },
    productDetail: {
      title: "Detalle"
    },
    uploadImage: {
      principalTag: "Principal",
    },
    editImage: {
      title: "Editar fotos",
      description: "Arrastra y suelta las fotos para cambiar el orden"
    },
    productInventory: {
      title: "Inventario"
    },
    productInformation: {
      title: "Información del producto"
    },
    productFeatures: {
      stock: {
        title: "Stock"
      },
      featureForm: {
        title: "Características",
        featureNameLabel: "Característica",
        optionsLabel: "Opciones",
        addLink: "Agregar otra característica"
      }
    }
  },
};

export default locale;

export const EVENTS = {
  SUCCESS_LOGIN: "system_qr_success_login",
  VIEW_SUCCESSFUL_PRODUCT: "web_view_successful_product_sheet",
  SELECT_SKIP_IMAGE_LOAD: "web_select_skip_images_load",
  SELECT_ADD_IMAGES_FOLDER: "web_select_add_images_folder",
  VIEW_LOADING_IMAGES_FOLDER: "web_view_loading_images_folder",
  VIEW_SUCCESSFUL_IMAGES_FOLDER: "web_view_successful_images_folder",
  VIEW_SUCCESSFUL_MASSIVE_LOAD: "web_view_successful_massive_load",
  SELECT_PREVIEW_PRODUCTS: "web_select_preview_products",
  VIEW_IMAGE_FOLDER_ERROR: "web_view_images_folder_error",
  SYSTEM_QR: "system_qr_success_login",
  WEB_SUMER_WEB_SELECT_ADD_PRODUCT: "select_add_product_complete",
  WEB_SUMER_WEB_SELECT_DELETE_PRODUCT: "web_sumer_web_select_delete_product",
  WEB_SUMER_WEB_SELECT_DUPLICATE_PRODUCT: "web_sumer_web_select_duplicate_product",
  WEB_SUMER_WEB_SELECT_EDIT_PRODUCT: "web_sumer_web_select_edit_product",
  WEB_SUMER_WEB_SELECT_ADD_PHOTO: "web_sumer_web_select_add_photo",
  WEB_SCREEN: "view_screen",
  SELECT_BUTTON: "select_button",
  SELECT_SHARE_CATALOGUE: "select_button",
  SELECT_SHARE_CATALOGUE_SUBTYPE: "share_catalogue",
  SELECT_INPUT: "select_input",
  VIEW_SCREEN: "view_screen",
  SELECT_DROPDOWN: "select_dropdown",
  SELECT_SAVE_CONFIGURATION: "select_save_configuration",
  USER_LOGIN: "user_login",
  SELECT_CONFIRM_DELETE: "select_confirm_delete",
  SYSTEM_CONNECTION_COMPLETE: "system_connection_complete",
  SYSTEM_EXPERT_WARNING: "system_expert_warning",
};

export enum CoreEvents {
  ViewScreen = "view_screen",
  SelectButton = "select_button",
  SelectInput = "select_input",
  SelectDropdown = "select_dropdown",
  SelectLink = "select_link",
  SelectToggle = "select_toggle",
  StateErrorWarning = "state_error_warning",
  SystemExpertWarning = "system_expert_warning",
  SelectSaveConfiguration = "select_save_configuration",
  OpenChat = "openChat",
  KustomerChat = "kustomer_chat",
  SelectConfirmDelete = "select_confirm_delete",
  SelectShare = "select_share",
};

export const WS_BUTTON_EVENTS = {
  EVENT_TYPE: "system_addon_installation",
  EVENT_SUBTYPE: "floating_button",
};
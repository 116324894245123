import styled from "styled-components";

export const LoginModalWrapper = styled.div`
  .icon {
    display: flex;
    height: 32px;
    justify-content: right;
    align-items: center;
    align-self: stretch;
    figure {
      font-size: 20px;
      :hover {
        cursor: pointer;
      }
    }
  }
`;

const XCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      {...props}
    >
      <path
        d="M21.2569 19.8783L21.2566 19.878L18.0011 16.6348L21.2566 13.3915L21.2568 13.3918L21.2608 13.3869C21.4301 13.1806 21.5166 12.9187 21.5036 12.6522C21.4905 12.3856 21.3787 12.1335 21.19 11.9448C21.0013 11.7561 20.7491 11.6443 20.4826 11.6312C20.216 11.6181 19.9541 11.7046 19.7479 11.874L19.7477 11.8737L19.7432 11.8782L16.5 15.1337L13.2568 11.8782L13.257 11.8779L13.2522 11.874C13.0459 11.7046 12.784 11.6181 12.5174 11.6312C12.2509 11.6443 11.9987 11.7561 11.81 11.9448C11.6213 12.1335 11.5095 12.3856 11.4965 12.6522C11.4834 12.9187 11.5699 13.1806 11.7392 13.3869L11.739 13.3871L11.7434 13.3915L14.9989 16.6348L11.7434 19.878L11.7431 19.8783C11.5437 20.0796 11.4319 20.3514 11.4319 20.6348C11.4319 20.9181 11.5437 21.1899 11.7431 21.3913L11.7441 21.3922C11.9469 21.5883 12.2179 21.6979 12.5 21.6979C12.7821 21.6979 13.0532 21.5883 13.256 21.3922L13.2568 21.3914L16.5 18.1358L19.7432 21.3914L19.7441 21.3922C19.9469 21.5883 20.2179 21.6979 20.5 21.6979C20.7821 21.6979 21.0532 21.5883 21.256 21.3922L21.2569 21.3912C21.4563 21.1899 21.5681 20.9181 21.5681 20.6348C21.5681 20.3514 21.4563 20.0796 21.2569 19.8783ZM9.31232 5.87763C11.4399 4.45605 13.9412 3.69728 16.4999 3.69727C19.9292 3.70386 23.2161 5.06903 25.6409 7.49387C28.0658 9.91872 29.4309 13.2056 29.4375 16.6349C29.4375 19.1936 28.6787 21.6949 27.2571 23.8225C25.8356 25.95 23.815 27.6082 21.451 28.5875C19.087 29.5667 16.4857 29.8229 13.976 29.3237C11.4664 28.8245 9.16115 27.5923 7.35181 25.783C5.54247 23.9736 4.31029 21.6684 3.8111 19.1587C3.3119 16.6491 3.56811 14.0478 4.54731 11.6838C5.52652 9.31978 7.18475 7.29922 9.31232 5.87763Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.125"
      />
    </svg>
  );
};

export default XCircle;

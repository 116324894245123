import { FC, ReactNode } from "react";
import { AlertCardStyled } from "./alert-card.styled";

type AlertCardProps = {
  title?: ReactNode;
  copy?: ReactNode;
  type?: "default" | "info" | "success" | "warning" | "danger";
  icon?: ReactNode;
  className?: string;
  children?: ReactNode;
};

const AlertCard: FC<AlertCardProps> = ({
  title,
  copy,
  type = "default",
  icon,
  className,
  children
}) => {
  return (
    <AlertCardStyled className={`alert-card__${type} ${className}`}>
      <span className="alert-card-icon">{icon || "!"}</span>
      <div className="alert-card-info">
        {children}
        {title && <strong>{title}</strong>}
        {copy && <p className="alert-card-description">{copy}</p>}
      </div>
    </AlertCardStyled>
  );
};

export default AlertCard;

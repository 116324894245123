import { Breakpoints } from "@sumerlabs/component-library";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  .container {
    display: none;
    .left {
      width: 50%;
      overflow: hidden;
      .bg-image {
        position: absolute;
        height: 100%;
        z-index: 0;
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .phone {
        bottom: 0;
        left: 0;
        position: absolute;
        max-width: 1000px;
        img {
          width: 60%;
          height: 60%;
          object-fit: cover;
          @media screen and (min-width: ${Breakpoints.LaptopMedium}) {
            width: 80%;
            height: 80%;
          }
          @media screen and (min-width: ${Breakpoints.LaptopLarge}) {
            width: 100%;
            height: 100%;
          }
        }
        @media screen and (min-width: ${Breakpoints.LaptopMedium}) {
          left: -30px;
        }
        @media screen and (min-width: ${Breakpoints.LaptopMedium}) {
          left: 150px;
        }
        @media screen and (min-width: ${Breakpoints.LaptopLarge}) {
          left: 500px;
        }
      }
    }
    .right {
      width: 50%;
      display: grid;
      place-content: center;
      .container-right {
        padding: 1rem;
        position: relative;
        width: 447px;
        z-index: 99;
      }
    }
    @media screen and (min-width: ${Breakpoints.Tablet}) {
      background-color: #fff;
      display: flex;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
  }
`;

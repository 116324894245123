import { camelizeKeys } from "humps";

export const getQRdata = async () => {
    const getFetch = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/ms/user/qr/generate`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            } as any
        }
    );

    const json = await getFetch.json();
    return json;
};

export const getTokenBycode = async (code: string) => {
    const getFetch = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/ms/user/qr/login`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            } as any,
            body: JSON.stringify({code})
        }
    );

    const json = await getFetch.json();
    return camelizeKeys(json);
};


import styled from "styled-components";

export const ContactButtonAddonWrapper = styled.div`
  padding: 24px;
  display: flex;
  min-height: calc(100% - var(--header-height));
  .contact-button-onboarding {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    .overlay {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      backdrop-filter: blur(10px);
      background: rgba(12, 18, 32, 0.80);
    }
    .left-side {
      flex: 1;
      display: flex;
      margin: 160px 0 0 0;
      align-items: center;
      justify-content: center;
      height: calc(100% - 160px);
      .frame {
        z-index: 1;
        height: 80%;
        width: 768px;
        border-radius: 8px;
        border: 2px solid var(--grey-w, #FFF);
      }
    }
    .right-side {
      z-index: 2;
      height: 100%;
      width: 378px;
      display: flex;
      flex-direction: column;
      padding: 92px 16px 0 16px;
      background: var(--grey-b, #24272E);
      .title {
        font-size: 26px;
        font-weight: 600;
        line-height: 33px;
        margin: 0 0 8px 0;
        color: var(--grey-w, #FFF);
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0 0 40px 0;
        color: var(--grey-w, #FFF);
      }
      .button {
        height: 40px;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: var(--grey-b, #24272E);
        background: var(--grey-w, #FFF);
      }
    }
  }
  .preview-side {
    flex: 1;
    height: 100%;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background: var(--grey-w, #FFF);
    box-shadow: 0px 12px 18px -4px rgba(69, 68, 74, 0.18);
    .header {
      height: 68px;
      display: flex;
      flex: 0 0 auto;
      padding: 0 16px;
      align-items: center;
      border-radius: 8px 8px 0px 0px;
      background-color: var(--grey-w, #FFF);
      border: 1px solid var(--grey-7, #E9E9ED);
      .toggle-buttons {
        height: 40px;
        display: flex;
        background: #FFF;
        margin: 0 0 0 auto;
        border-radius: 8px;
        align-items: center;
        border: 1px solid var(--grey-7, #E9E9ED);
        button {
          width: 38px;
          height: 38px;
          cursor: pointer;
          border-radius: 6px;
          background: #FFFFFF;
          &.active {
            background: var(--grey-b, #24272E);
            .icon {
              color: #FFFFFF;
            }
          }
          .icon {
            font-size: 24px;
          }
        }
      }
    }
    .catalogue {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .frame {
        overflow: hidden;
        position: relative;
        box-shadow: 0px 6px 8px 0px rgba(95, 99, 108, 0.12);
        &.desktop-frame {
          height: calc(100vh - 280px);
          width: 768px;
        }
        &.mobile-frame {
          height: calc(100vh - 280px);
          width: 375px;
          .iframe {
            width: 375px;
            height: 100%;
            transform: scale(1);
          }
        }
        .iframe {
          border: none;
          width: 1440px;
          height: 1200px;
          position: absolute;
          transform-origin: 0 0;
          transform: scale(0.53);
        }
      }
    }
  }
  .configuration-side {
    width: 378px; 
    padding: 0 0 0 24px;
  }
`;

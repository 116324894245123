import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MainHeaderWrapper } from "./main-header.styled";
import {
  actions,
  partnerSelector,
  planSelector,
  showShareModalSelector,
  urlShareSelector,
} from "@dashboard/config/redux";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "@dashboard/common/analytics";
import {
  HomeEvent,
  HomeEventSubtype,
} from "@dashboard/modules/home/constants/home.events";
import { getEnvVar } from "@dashboard/common/utils";
import { usePersonalization } from "@dashboard/modules/personalization/hooks/personalization.hooks";
import { useStoreImages } from "@dashboard/hooks/store-images.hook";
import SharePopup from "../share-popup/share-popup";
import { EVENTS } from "@dashboard/constants/events";
import { useSession } from "@dashboard/hooks/use-session.hook";

interface MainHeaderProps {}

const MainHeader: FC<MainHeaderProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logEvent } = useAnalytics();
  const plan = useSelector(planSelector);
  const partner = useSelector(partnerSelector);
  const storeUrlShare = useSelector(urlShareSelector);
  const showShareModal = useSelector(showShareModalSelector);
  const { partnerProfilePicture } = useStoreImages(partner?.userDetail);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const { checkForPendingChanges } = usePersonalization();
  const newRef = useRef<HTMLDivElement>(null);
  const { logOut } = useSession();

  const handleLogout = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    logOut();
  };

  const handleOutsideClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setShowProfilePopup(false);
    }
  };

  const goToPlansPage = () => {
    logEvent(HomeEvent.SelectButton, {
      event_subtype: HomeEventSubtype.ViewPlans,
      option: plan?.benefit_plan_name || "",
    });
    navigate(`/${partner?.userId}/plans`);
  };

  const goToStore = useCallback(() => {
    if (!!partner) {
      const catalogueIdentifier =
        partner?.userNetwork.urlSummer || partner?.userId;
      const urlStore =
        storeUrlShare?.url ||
        `${getEnvVar("REACT_APP_HOST")}/catalogo/${catalogueIdentifier}`;
      window.open(urlStore, "_blank");
      logEvent(HomeEvent.SelectButton, {
        event_subtype: HomeEventSubtype.ViewStore,
      });
    }
  }, [partner, storeUrlShare]);

  const handleShareStore = () => {
    dispatch(actions.setShowShareModal(true));
    logEvent(EVENTS.SELECT_SHARE_CATALOGUE, {
      event_subtype: EVENTS.SELECT_SHARE_CATALOGUE_SUBTYPE,
    });
  };

  const goToProfile = () => {
    navigate("profile");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <MainHeaderWrapper>
      <SharePopup
        show={showShareModal}
        imgStore={partnerProfilePicture}
        storeName={partner?.userDetail?.enterpriseName || ""}
        onClose={() => dispatch(actions.setShowShareModal(false))}
      />
      {plan?.benefit_plan_name?.toLowerCase() !== "ultra" && (
        <div
          className={`plan-badge`}
          onClick={() => checkForPendingChanges(goToPlansPage)}
        >
          <div className="icon">
            <span className="icon-crown" />
          </div>
          <div className="text">{t("header.explorePlans")}</div>
        </div>
      )}
      <div className="see-my-store" onClick={goToStore}>
        <div className="icon">
          <span className="icon-eye" />
        </div>
        <div className="text">{t("header.seeMyStore")}</div>
      </div>
      <div className="share-store" onClick={handleShareStore}>
        <div className="icon">
          <span className="icon-share-outline" />
        </div>
        <div className="text">{t("header.shareStore")}</div>
      </div>
      <div
        ref={newRef}
        className="profile"
        onClick={() => setShowProfilePopup((s) => !s)}
      >
        <img src={partnerProfilePicture} alt="Logo tienda" />
        <div className={`popup ${showProfilePopup ? "show" : "none"}`}>
          <div
            className="store-name"
            onClick={() => checkForPendingChanges(goToProfile)}
          >
            {partner?.userDetail?.enterpriseName}
          </div>
          <div className="divider" />
          <div
            className="logout"
            onClick={(e) => {
              checkForPendingChanges(() => handleLogout(e));
            }}
          >
            <span className="icon icon-arrow-line" />
            <div className="text">{t("logout.title")}</div>
          </div>
        </div>
      </div>
    </MainHeaderWrapper>
  );
};

export default MainHeader;

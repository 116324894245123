import { useAppDispatch } from "@dashboard/config/redux";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { meliActions } from "../../store/meli.slice";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { useMeli } from "../../views/meli/meli.hook";

const MeliValidator = () => {
    const [params] = useSearchParams();
    const dispatch = useAppDispatch();
    const { decodedToken } = useSession();
    const navigate = useNavigate();
    const { startLoading } = useMeli();

    useEffect(() => {
        const auxToken = params.get('code');
        const auxUserId = decodedToken?.user_id;
        if (auxToken && auxUserId) {
            startLoading();
            dispatch(meliActions.setMeliToken(auxToken));
            navigate(`/${auxUserId}/meli`);
        }
    }, [params, decodedToken]);
    
    return <></>
}

export default MeliValidator;
import styled from "styled-components";

export const BannersWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  .item {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    scroll-snap-align: center;
    color: var(--grey-b, #24272E);
    justify-content: space-between;
    .left {
      width: 50%;
      padding: 0 0 0 46px;
      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        margin: 0 0 16px 0;
        color: currentColor
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin: 0 0 16px 0;
        color: currentColor
      }
      .button {
        height: 48px;
        display: flex;
        column-gap: 8px;
        padding: 0 14px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        border-radius: 8px;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: var(--grey-8, #FCFCFC);
        background: var(--grey-b, #24272E);
        .icon-arrow {
          font-size: 18px;
          transform: rotate(-90deg);
        }
      }
      .second-link {
        display: flex;
        font-size: 14px;
        cursor: pointer;
        column-gap: 4px;
        font-weight: 600;
        line-height: 22px;
        width: fit-content;
        margin: 24px 0 0 0;
        color: currentColor;
        align-items: center;
        .icon {
          font-weight: 600;
        }
      }
    }
    .right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .img {
        max-height: 70%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
`;

import { Breakpoints } from "@sumerlabs/component-library";
import styled from "styled-components";

export const MobileViewStyles = styled.div`
  display: none;
  @media screen and (max-width: ${Breakpoints.Tablet}) {
    display: flex;
    flex-direction: column;
    height:100vh;
    height:100svh;
    width: 100vw;
    overflow: hidden;
    .mobile-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      padding: 32px 32px 0 32px;
      height: 100%;
      background: #FAFAFA;
      z-index: 3;
      .logo-mobile {
        display: flex;
        width: 142.609px;
        height: 48px;
        .img {
          width: 100%;
          object-fit: contain;
        }
      }
      .body-mobile {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        .header-texts {
          align-items: center;
          color: var(--Grey-B, #24272e);
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          h1 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 31px;
          }
          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .icons {
          align-items: center;
          color: var(--Grey-B, #24272e);
          display: flex;
          font-size: 1rem;
          gap: 8px;
          justify-content: center;
        }
      }
      .button-mobile-view {
        align-items: center;
        border-radius: 8px;
        background: var(--Grey-B, #24272e);
        display: flex;
        gap: 4px;
        height: 48px;
        justify-content: center;
        padding: 0px 14px;
        .button-text {
          color: var(--Grey-800, #fcfcfc);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
        }
      }
    }
    .mobile-image-container {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
`;

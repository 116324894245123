import styled from "styled-components";

export const FeaturesOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

export const FeatureOption = styled.div`
  display: flex;
 
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Grey-800, #fcfcfc);
  .option-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    .title {
      color: var(--Grey-400, #5d606f);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .options {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;
      .option {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Grey-700, #e9e9ed);
        background: var(--Grey-800, #fcfcfc);
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
  .trash-simple-icon:hover {
    cursor: pointer;
    color: red;
  }
  .arrow-right-icon {
    transform: rotate(90deg);
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

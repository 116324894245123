import { StorageItem } from "@dashboard/constants/storage-items";
import { Token } from "@dashboard/entities/token";
import { useEffect } from "react";
import { useJwt } from "react-jwt";
import { useNavigate, useSearchParams } from "react-router-dom";

const Guest = () => {
  const [params] = useSearchParams();
  const guestToken = params.get("token") || "";
  const goTo = params.get("go") || "";
  const { decodedToken }: { decodedToken: Token | null } = useJwt(guestToken);
  const { isExpired } = useJwt(guestToken);
  const navigate = useNavigate();

  const checkGuestToken = (isExpired: boolean) => {
    if (isExpired) {
      navigate("/");
    } else {
      localStorage.setItem(StorageItem.Token, `"${guestToken}"`);
      if (goTo && goTo === "onboarding") {
        navigate(`/onboarding/info`);
      } else {
        navigate(`/${decodedToken?.user_id}`);
      }
    }
  };

  useEffect(() => {
    if (decodedToken) {
      checkGuestToken(isExpired);
    }
  }, [decodedToken]);

  return <></>;
};

export default Guest;

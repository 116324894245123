import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CONTACT_BUTTON_ICONS } from "../../constants/contact-button.constants";
import { useContactButton } from "../../hooks/contact-button.hook";
import ContactButtonIconWrapper from "./contact-button-icon.styled";

interface ContactButtonIconProps {}

const ContactButtonIcon: FC<ContactButtonIconProps> = () => {

  const { t } = useTranslation();
  const {
    buttonConfig,
    handleIconChange,
  } = useContactButton();

  return (
    <ContactButtonIconWrapper>
      {CONTACT_BUTTON_ICONS.map((option) => (
        <div
          key={`contact-button-icon-${option.text}`}
          className={`option ${
            buttonConfig.wp_contact?.icon === option.icon && "active"
          }`}
          onClick={() => handleIconChange(option.icon)}
        >
          <div className="box">
            <span className={`icon-${option.icon} icon`} />
          </div>
          <div className="text">{`${t("addons.contact_button.option")} ${
            option.text
          }`}</div>
        </div>
      ))}
    </ContactButtonIconWrapper>
  );
};

export default ContactButtonIcon;

import Fuse from "fuse.js";
import { Product } from "../types/products.types";

const productOptions = {
  includeScore: true,
  threshold: 0.25,
  keys: [
    {
      name: "name",
      weight: 1,
    },
  ],
};

const categoryOptions = {
  includeScore: true,
  threshold: 0.5,
  keys: ["category"],
};

export const searchProductByName = (val: string, arr: Product[]): Product[] => {
  const fuse = new Fuse(arr, productOptions);
  return fuse.search(val).map((item) => item.item);
};

export const searchProductByCategory = (
  val: string,
  arr: Product[]
): Product[] => {
  const fuse = new Fuse(arr, categoryOptions);
  return fuse.search(val).map((item) => item.item);
};

import styled from "styled-components";

export const CustomCounterContainer = styled.div`
  display: flex;
  height: 48px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: var(--grey-w, #fff);
  .icon {
    color: var(--Grey-B,  #24272E);
    &:hover {
      cursor: pointer;
    }
    &.plus {
      font-weight: bolder;
      font-size: 20px;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Grey-B, #24272e);
      color: white;
    }
    &.disabled {
      color: #9b9eab;
    }
  }
  .counter {
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    > input {
      border: none;
      width: 40px;
      text-align: center;
    }
  }
`;

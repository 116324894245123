import styled from "styled-components";

export const PlanToggleStyled = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Grey-Border, #E9E9ED);
  display: flex;
  justify-content: space-around;
  padding: 0.25rem;
  height: 58px;
  width: 250px;
  .monthly, .yearly{
    color: var(--Grey-B, #24272E);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .toggle-button,
  .toggle-active {
    background: transparent;
    border-radius: 38px;
    display: block;
    min-height: 38px;
    padding: 0.5rem 0.75rem;
    position: relative;
    width: 168px;
    z-index: 2;
    &__active {
      font-weight: 600;
    }
  }
  .toggle-active {
    background-color: #ececed;
    position: absolute;
    transition: all 0.4s;
    z-index: 1;
    &__0 {
      left: 0.25rem;
    }
    &__1 {
      left: calc(0.25rem + (168px * 1));
    }
    &__2 {
      left: calc(0.25rem + (168px * 2));
    }
    &__3 {
      left: calc(0.25rem + (168px * 3));
    }
  }
  .percentage {
      border-radius: 4px;
      background: var(--Grey-7, #e9e9ed);
      color: var(--Grey-B, #24272e);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 4px 8px;
      &__active {
        background: var(--Grey-B, #24272e);
        color: var(--Grey-W, #FFF);
      }
      &__disabled {
        background: var(--Grey-7, #e9e9ed);
      }
    }
`;

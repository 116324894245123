import { FC } from "react";
import { ToastCustomStyled } from "./toast-custom.styled";
import { ToastCustomProps } from "./toast-custom.types";

const ToastCustom: FC<ToastCustomProps> = ({
  type = "info",
  text,
  leftIcon,
  className,
}) => {
  return (
    <ToastCustomStyled className={`${className} toast-custom__${type}`}>
      {leftIcon && <>{leftIcon}</>}
      {text && <>{text}</>}
    </ToastCustomStyled>
  );
};

export default ToastCustom;

export const checkout = {
  plan: {
    free: 'Plan Limited',
    legacy: 'Plan Limited',
    basic: 'Plan Basic',
    pro: 'Plan Pro',
    ultra: 'Plan Ultra',
  },
  cartItem: {
    yearly: 'yearly',
    domainDescription: 'Look more professional with your own domain.',
  },
  checkout: {
    subtotal: 'Subtotal',
    total: 'Total',
    couponText: 'Coupon',
    addOtherPaymentCard: 'Add another payment method',
    terms: 'By making this payment you agree to the {{link}} of the platform',
    pay: 'Pay',
    card: {
      nextPayment: 'Next payment',
      copyTitle: 'Some benefits of the plan',
      editButton: 'Edit'
    },
    termsAndConditions: "Terms and Conditions",
    message: "This is an auto-renewing subscription that you can cancel at any time.",
    coupon: {
      inputText: "Enter coupon code",
      errorText: "Enter a valid coupon code",
      buttonText: "Apply"
    },
    plan: {
      nextPayment: "Next payment will be for",
      month: "month",
      year: "year",
      days: "days"
    }
  },
  status: {
    title_checkout: 'We are processing!',
    title_created: 'We are processing!',
    title_success: 'Successful Payment!',
    title_error: 'Problem processing your payment!',
    title_failed: 'Problem processing your payment!',
    description_checkout: 'We are processing the payment to complete the transaction.',
    description_created: 'We are processing the payment to complete the transaction.',
    description_success: 'We have received your payment and confirm your purchase.',
    description_error: 'We recommend that you check the details of your payment method and try again.',
    description_failed: 'We recommend that you check the details of your payment method and try again.'
  }
};

import { RootState } from "@dashboard/config/redux";
import { createSelector } from "@reduxjs/toolkit";
import { MeliState } from "./meli.state";

export const meliTokenSelector = createSelector(
  (state: RootState) => state.meli,
  (state: MeliState) => state.meliToken
);

export const productsCountSelector = createSelector(
  (state: RootState) => state.meli,
  (state: MeliState) => state.productsCount
);

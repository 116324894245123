import { getApiUrl, getEnvVar } from "@dashboard/common/utils";
import { QuickAnswer, QuickAnswerResponse } from "../types/quick-answers.types";
import { camelizeKeys } from "humps";

export const fetchGetQuickAnswers = async (): Promise<QuickAnswer[]> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/remote-config/country-data/assistant_ai_quick_answers`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "api-key-sumer": getEnvVar("REACT_APP_KEY_SP"),
      },
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseData = camelizeKeys(
      await response.json()
    ) as QuickAnswerResponse;
    return responseData.value.questions;
  }
};

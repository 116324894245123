import { FC, useEffect, useMemo, useState } from "react";
import {
  OnboardingStepPlans,
  StepPlanSkin2Wrapper,
} from "./step-plan-skin2.styled";
import useOnboardingStepPremium from "../../../../hooks/step-plan.hook";
import logoSumer from "@dashboard/assets/img/logo-sumer-iso.png";
import Button from "@dashboard/modules/products/components/button/button";
import KeepCustomizationPopup from "@dashboard/modules/shared/components/onboarding-shared/components/keep-customization-popup/keep-customization-popup";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents } from "@dashboard/constants/events";
import PlansToggle from "@dashboard/components/plans-toggle";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  SourceOnboarding,
  MethodOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { useSelector } from "react-redux";
import { planParamsSelector } from "@dashboard/config/redux";
import PlansGridShared from "@dashboard/modules/shared/components/plan-shared/components/plans-grid-shared/plans-grid-shared";
import { Plan } from "@dashboard/modules/plans/plans.types";
import { EPlanPricing } from "@dashboard/modules/shared/components/plan-shared/constants/plan-pricing";
import { Loader } from "@dashboard/assets/icons";

export type StepPlanSkin2Types = {
  onBack?: () => void;
};

const StepPlanSkin2: FC<StepPlanSkin2Types> = ({ onBack }) => {
  const {
    t,
    plans: planGroups,
    country,
    plansBillings,
    billing,
    setBilling,
    handleContinueFree,
    handlePlanClick,
    imageBannerPersonalization,
  } = useOnboardingStepPremium();
  const [showPopup, setShowPopup] = useState(false);
  const { logEvent } = useAnalytics();
  const planParamsData = useSelector(planParamsSelector);

  const plans = useMemo(() => {
    const group = planGroups.find((group) => group.name === billing);
    return group ? group.plans : [];
  }, [planGroups, billing]);

  useEffect(() => {
    logEvent(EventsOnboarding.VIEW_SCREEN, {
      event_subtype: SubtypeOnboarding.EXPERT_PLANS,
      source:
        planParamsData?.plan && planParamsData.period
          ? SourceOnboarding.LANDING_PRICING
          : "onboarding",
    });
  }, []);

  return (
    <StepPlanSkin2Wrapper>
      <KeepCustomizationPopup
        image={imageBannerPersonalization}
        title={t("onboarding.popups.customization.title")}
        description={t("onboarding.popups.customization.description")}
        confirmLabel={t("onboarding.popups.customization.confirmLabel")}
        linkLabel={t("onboarding.popups.customization.linkLabel")}
        show={showPopup}
        onClose={() => setShowPopup(false)}
        onConfirmLabel={() => {
          setShowPopup(false);
          logEvent(EventsOnboarding.SELECT_BUTTON, {
            event_subtype:
              SubtypeOnboarding.EXPERT_PLAN_ONBOARDING_CONFIRMATION,
            source:
              planParamsData?.plan && planParamsData.period
                ? SourceOnboarding.LANDING_PRICING
                : "",
            method: MethodOnboarding.POP_UP,
          });
        }}
        onLinkLabel={() => {
          handleContinueFree();
          logEvent(CoreEvents.SelectButton, {
            event_subtype: "continue_free",
            option: "onboarding_v2",
            source:"onboarding",
            method: "pop_up"
          });
        }}
      />
      <div className="onboarding-container fadeInUp">
        <main className="onboarding-step-container">
          <div className="onboarding-step-info">
            <img src={logoSumer} alt="Sumer" />
            <h1 className="onboarding-step-title">
              {t("onboarding.premium.title")}
            </h1>
            <p className="onboarding-step-copy">
              {t("onboarding.premium.description")}
            </p>
          </div>
          {!plansBillings.length && (
            <div className="container fadeInUp">
              <Loader />
              <div className="spinnerText">
                {t("onboarding.premium.loading")}
              </div>
            </div>
          )}
          {plansBillings.length > 1 && (
            <PlansToggle
              plansBillings={plansBillings}
              billing={billing}
              onChange={setBilling}
            />
          )}
          <OnboardingStepPlans>
            <PlansGridShared
              plans={plans as Plan[]}
              view={EPlanPricing.ONBOARDING}
              country={country}
              onClick={handlePlanClick}
            />
          </OnboardingStepPlans>
          <Button
            className="onboarding-step-continue"
            outline
            appearance="dark"
            onClick={() => {
              logEvent(EventsOnboarding.SELECT_BUTTON, {
                event_subtype: SubtypeOnboarding.CONTINUE_FREE,
                source:
                  planParamsData?.plan && planParamsData.period
                    ? SourceOnboarding.LANDING_PRICING
                    : "onboarding",
                method: MethodOnboarding.POP_UP,
              });
              setShowPopup(true);
            }}
          >
            {t("onboarding.premium.continueFree")}
            <span className="icon-arrow" />
          </Button>
        </main>
        <div className="onboarding-step-header" />
      </div>
    </StepPlanSkin2Wrapper>
  );
};

export default StepPlanSkin2;

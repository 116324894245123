import { fetchPartnerByToken } from "@dashboard/config/redux";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Outlet } from "react-router-dom";
import Header from "../shared/components/onboarding-shared/components/header/header";
import LogoutButton from "../shared/components/onboarding-shared/components/logout-button/logout-button";
import { AbOnboarding } from "../shared/components/onboarding-shared/constants/split.constants";
import { useOnboarding } from "../shared/components/onboarding-shared/hooks/onboarding.hook";
import { fetchUserDetail } from "../shared/components/onboarding-shared/services/onboarding.services";
import {
  actions,
  actions as onboardingActions,
} from "../shared/components/onboarding-shared/store/onboarding.slice";

const OnboardingGeneral: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { token, validSession, webStatus, decodedToken } = useSession();
  const { getFetchSplitUsers } = useOnboarding();

  useEffect(() => {
    validSession();
  }, [token]);

  useEffect(() => {
    if (decodedToken?.user_id) {
      webStatus(decodedToken?.user_id);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (!!token) {
      dispatch(fetchPartnerByToken(token));
      fetchUserDetail(token)
        .then((response) => {
          dispatch(onboardingActions.setOnboardingData(response));
        })
        .catch();
    }
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(actions.setOnboardingType(AbOnboarding.AB_ONBOARDING_STORE_INFO));
    getFetchSplitUsers();
  }, []);

  return (
    <>
      {!pathname.includes("info") && <Header />}
      <Outlet />
      <LogoutButton />
    </>
  );
};

export default OnboardingGeneral;

import styled from "styled-components";

export const ContactButtonConfigurationWrapper = styled.div`
  width: 100%;
	.phone-field {
		margin: 0 0 24px 0;
	}
	.hide-button {
		height: 56px;
		display: flex;
		padding: 16px;
		align-items: center;
		border-radius: 12px;
		border: 1px solid var(--grey-7, #E9E9ED);
		.icon {
			width: 20px;
			height: 20px;
			display: flex;
			flex: 0 0 auto;
			font-size: 20px;
			margin: 0 8px 0 0;
			align-items: center;
			justify-content: center;
		}
		.text {
			flex: 1;
			font-size: 16px;
			font-weight: 400;
			line-height: 140%;
		}
	}
`;

import CustomSwitch from "@dashboard/components/custom-switch/custom-switch";
import {
  CounterWrapper,
  DiscountDetail,
  DropdownWrapper,
  PriceDetail,
  QuantityWrapper,
} from "./product-wholesale.styled";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import CustomCounter from "@dashboard/components/custom-counter/custom-counter";
import { UiDropdown, UiDropdownOption } from "@sumerlabs/component-library";
import CustomInputCurrency from "@dashboard/components/custom-input-currency/custom-input-currency";
import { Formatter } from "@dashboard/utils/formatter";
import { useEffect, useState } from "react";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import CustomAlert from "@dashboard/components/custom-alert/custom-alert";
import { countryInformationSelector } from "@dashboard/config/redux";
import { useSelector } from "react-redux";
import { Sign } from "@dashboard/components/custom-input-currency/constants/input-currency.constants";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import { useLogicWholesale } from "./product-wholesale.hook";
import Package from "@sumerkit/icons/package"
import { EDiscoutType } from "./constants/discount-type.constants";

const ProductWholesale = () => {
  const { t } = useTranslation();
  const { handleBlur, setFieldValue } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const [enableWholeSale, setEnableWholeSale] = useState(
    values.wholesale ? true : false
  );
  const currency = useSelector(countryInformationSelector)?.currency;
  const [data, setData] = useState<UiDropdownOption[]>([
    {
      label: currency || Sign.PESO,
      value: "value",
    },
  ]);
  const { logEvent } = useAnalytics();
  const {
    discount,
    disabledDiscount,
    setDiscount,
    checkFinalPrice,
    getHasError,
    calculateDiscount,
    handleWholesaleClickWhenNotAvailable,
    viewDiscount,
    viewFinalPrice,
    isWholesaleAvailable,
  } = useLogicWholesale();

  useEffect(() => {
    if (values.toppings && values.toppings.length > 0) {
      setData([ 
        {
          label: "%",
          value: EDiscoutType.PERCENTAGE,
        },
      ]);
      setFieldValue("wholesale.configurations[0].discountType", "percentage");
    } else {
      setData([
        {
          label: currency || Sign.PESO,
          value: EDiscoutType.VALUE,
        },
        {
          label: "%",
          value: EDiscoutType.PERCENTAGE,
        },
      ]);
    }
    calculateDiscount();
  }, [values.toppings]);

  return (
    <CollapseContainerWrapper>
      <div className="info">
        <figure className="icons">
          <Package width={27} height={27}/>
        </figure>
        <div className="description">
          <strong className="title">
            {t("admin.products.side.wholeSale.title")}
          </strong>
          <small className="subtitle">
            {t("admin.products.side.wholeSale.subTitle")}
          </small>
        </div>
        <div
          className="switchContainer"
          onClick={() => {
            if (!isWholesaleAvailable) {
              handleWholesaleClickWhenNotAvailable();
            }
          }}
        >
          <CustomSwitch
            isActive={enableWholeSale}
            disabled={!isWholesaleAvailable}
            onChange={(state) => {
              logEvent(PRODUCTS_EVENTS.SELECT_TOGGLE, {
                event_subtype: "wholesale",
                source: `${values.id ? "edit" : "create"}`,
                option: `${state ? "on" : "off"}`,
              });
              if (state) {
                setFieldValue(
                  "wholesale.configurations[0].minUnits",
                  values.wholesale?.configurations[0].minUnits || 2
                );
              } else {
                setFieldValue("wholesale.configurations[0].minUnits", 0);
              }
              setEnableWholeSale(state);
              setDiscount("0");
            }}
          />
        </div>
      </div>
      {enableWholeSale && (
        <QuantityWrapper>
          <CounterWrapper>
            <p className="text">
              {t("admin.products.side.wholeSale.counterText")}
            </p>
            <CustomCounter
              minimum={2}
              value={values.wholesale?.configurations[0].minUnits}
              onChange={(count) =>
                setFieldValue("wholesale", {
                  ...values.wholesale,
                  configurations: [
                    {
                      ...values.wholesale?.configurations[0],
                      minUnits: count,
                    },
                  ],
                })
              }
              onClick={(source) => {
                logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
                  event_subtype: PRODUCTS_EVENTS.WHOLESALE_INVENTORY_QUANTITY,
                  option: `${source}`,
                });
              }}
            />
          </CounterWrapper>
          <p className="description">
            {t("admin.products.side.wholeSale.description")}
          </p>
          <DropdownWrapper>
            <UiDropdown
              value={values.wholesale?.configurations[0].discountType}
              onChange={(type) => {
                logEvent(PRODUCTS_EVENTS.SELECT_OPTION, {
                  event_subtype: "wholesale_discount",
                  source: `${values.id ? "edit" : "create"}`,
                  method: `${type}`,
                });
                setFieldValue("wholesale", {
                  ...values.wholesale,
                  configurations: [
                    {
                      ...values.wholesale?.configurations[0],
                      discountType: type,
                    },
                  ],
                });
              }}
              options={data}
              placeholder={currency || Sign.PESO}
              className="customDropdown"
            />
            <CustomInputCurrency
              name="wholesale"
              id="wholesale"
              value={discount}
              disabled={disabledDiscount}
              onChange={(value) => {
                setDiscount(value.toString());
                checkFinalPrice(+value);
              }}
              onFocus={() => {
                logEvent(PRODUCTS_EVENTS.SELECT_INPUT, {
                  event_subtype: "wholesale_discount",
                  source: `${values.id ? "edit" : "create"}`,
                });
              }}
              hasError={getHasError()}
              errorMessage={t("admin.products.side.wholeSale.errorInput")}
              onBlur={handleBlur}
            />
          </DropdownWrapper>
          {values.productToppingVariations && (
            <CustomAlert
              description={t("admin.products.side.wholeSale.alertMsgWholesale")}
            ></CustomAlert>
          )}
          {values.price !== undefined && values.price !== 0 && (
            <>
              <PriceDetail>
                <div className="text">
                  {t("admin.products.side.wholeSale.priceDetail")}
                </div>
                <div className="price">
                  {currency || Sign.PESO} {Formatter.format(viewFinalPrice)}
                </div>
              </PriceDetail>
              <DiscountDetail>
                <div className="text">
                  {t("admin.products.side.wholeSale.discountDetail")}
                </div>
                <div className="price">
                  {currency || Sign.PESO} {Formatter.format(viewDiscount)}
                </div>
              </DiscountDetail>
            </>
          )}
        </QuantityWrapper>
      )}
    </CollapseContainerWrapper>
  );
};

export default ProductWholesale;

const WarningCircleLight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 9.23926C18.3502 9.23926 8.90625 18.6833 8.90625 30.333C8.90625 41.9828 18.3502 51.4268 30 51.4268C41.6498 51.4268 51.0938 41.9828 51.0938 30.333C51.0938 18.6833 41.6498 9.23926 30 9.23926ZM6.09375 30.333C6.09375 17.13 16.7969 6.42676 30 6.42676C43.2031 6.42676 53.9062 17.13 53.9062 30.333C53.9062 43.5361 43.2031 54.2393 30 54.2393C16.7969 54.2393 6.09375 43.5361 6.09375 30.333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 17.6768C30.7767 17.6768 31.4062 18.3064 31.4062 19.083V32.208C31.4062 32.9847 30.7767 33.6143 30 33.6143C29.2233 33.6143 28.5938 32.9847 28.5938 32.208V19.083C28.5938 18.3064 29.2233 17.6768 30 17.6768Z"
        fill="currentColor"
      />
      <path
        d="M30 42.9893C31.2944 42.9893 32.3438 41.9399 32.3438 40.6455C32.3438 39.3511 31.2944 38.3018 30 38.3018C28.7056 38.3018 27.6562 39.3511 27.6562 40.6455C27.6562 41.9399 28.7056 42.9893 30 42.9893Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningCircleLight;

import {
  fetchPartnerByToken,
  isLoginErrorSelector,
} from "@dashboard/config/redux";
import { StatusOnboarding } from "@dashboard/modules/shared/components/onboarding-shared/entities/onboarding.entities";
import {
  fetchUserDetail,
  getOnboardingStatus,
  setOnboardingStatus,
} from "@dashboard/modules/shared/components/onboarding-shared/services/onboarding.services";
import { useLocalStorage } from "@sumerlabs/component-library";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { actions as onboardingActions } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.slice";
import { useJwt } from "react-jwt";
import { Token } from "@dashboard/entities/token";
import { useDeeplinks } from "./deeplinks.hook";
import { OnboardingSplit } from "@dashboard/constants/onboarding";

export const useSession = () => {
  const [token] = useLocalStorage("accessToken", "");
  const isLoginError = useSelector(isLoginErrorSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { decodedToken, isExpired } = useJwt<Token | null>(token);
  const { pathname } = useLocation();
  const { navigateByDeeplink } = useDeeplinks();
  const [searchParams] = useSearchParams();
  const onboardingSplit = searchParams.get("onboarding");

  const isTokenGuest = (): boolean => {
    return (
      token.includes("nogvXf8XsIcRWZ4JXB8mTDlTAVeC-VONeNuA6-eIrk") ||
      token.includes("R1Lq82qPSDV9jGlzXAzkrTM7ipe1RpLMLgJEuUw") ||
      token.includes("l63-pWbWbzjE2Z53A8Cn5J2Q9khYf0mbWI") ||
      token.includes("RcNPtD3hseEub9T2ZH9O4UvCep0jeZFuserAGR004") // TODO: Se deja mientras back nos devuelve para saber si un token es guest
    );
  };

  const validSession = () => {
    if ((!token || isExpired) && !isTokenGuest()) {
      navigate("/");
    }
  };

  const validSessionOnLogin = () => {
    if (token && !isExpired) {
      if (isTokenGuest() && pathname === '/') {
        logOut();
      } else {
        navigate("/status");
      }
    }
  };

  const getOnboardingPath = (split: string) => {
    if (split === OnboardingSplit.SPLIT2 || sessionStorage.getItem("guestToken")) {
      return "/onboarding-guest/info";
    }
    return "/onboarding/info";
  };

  const webStatus = (ownerId: string, isNewUser?: boolean) => {
    if (ownerId) {
      if (!isGuestUser()) {
        getOnboardingStatus({
          schema_partition: `/web/owner_id=${ownerId}/onboarding`,
        }).then((res: StatusOnboarding[] | []) => {
          validStatusOnboardingComplete(res[0])
          if (
            !isNewUser &&
            pathname === "/status" &&
            !isTokenGuest()
          ) {
            if(!res[0]){
              navigateByDeeplink(`/${ownerId}`);
            } else {
              navigateByDeeplink(res[0]?.data?.pathname || `/${ownerId}`);
            }
          }
          if (!res[0] && isNewUser) {
            setWebStatus(
              `${getOnboardingPath(onboardingSplit || "")}`,
              decodedToken?.user_id || "",
              false,
              true
            );
          }
        });
      }
    } else {
      if (!isTokenGuest()) {
        navigate("/");
      }
    }
  };

  const validStatusOnboardingComplete = (data: StatusOnboarding) => {
    if (
      (data &&
        (pathname.includes("onboarding") ||
          pathname.includes("status"))) ||
      (data && !data.data.is_complete)
    ) {
      if (!isTokenGuest()) {
        navigate(data?.data?.pathname || "/");
      } else {
        navigate("/onboarding-guest/generating");
      }
    }
  }

  const setWebStatus = (
    path: string,
    ownerId: string,
    complete: boolean,
    redirect: boolean,
    setStatus?: boolean
  ) => {
    if (!isGuestUser() && setStatus) {
      setOnboardingStatus({
        id: new Date().getTime(),
        replace: true,
        schema_partition: `/web/owner_id=${ownerId}/onboarding`,
        data: {
          is_complete: complete,
          pathname: `${path}`,
        },
      }).then(() => {
        redirect && navigate(path);
      });
    } else {
      navigate(path);
    }
  };

  const checkUserInfo = () => {
    if (!!token) {
      dispatch(fetchPartnerByToken(token));
      fetchUserDetail(token)
        .then((response) => {
          dispatch(onboardingActions.setOnboardingData(response));
          webStatus(
            decodedToken?.user_id || "",
            !Boolean(response?.country && response.country !== "undefined")
          );
        })
        .catch();
    }
  };

  const isGuestUser = (): boolean => {
    if (
      pathname.includes("onboarding-guest/personalize") ||
      pathname.includes("onboarding-guest/generating")
    ) {
      return true;
    }
    return false;
  };

  const logOut = (queryParam?: string) => {
    if (typeof Kustomer !== "undefined") Kustomer.close();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("refreshToken");
    navigate(`/${queryParam || ""}`);
  };

  return {
    token,
    isLoginError,
    validSession,
    webStatus,
    setWebStatus,
    checkUserInfo,
    decodedToken,
    validSessionOnLogin,
    logOut,
    isTokenGuest,
  };
};

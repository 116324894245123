import { http } from "@dashboard/common/http";
import { camelizeKeys, decamelizeKeys } from "humps";
import {
  CreateProductPayload,
  DeleteProductPayload,
  Product,
  ProductVariation,
  Topping,
  UpdateProductPayload,
} from "../types/products.types";
import { ProductsExportResponse } from "../types/bulk-upload-products.types";

export const updateProduct = (token: string, payload: UpdateProductPayload) => {
  const data: any = decamelizeKeys(payload);
  const product = {
    ...data,
    ownerId: data.owner_id,
  };
  return http.put(
    `/api/ms/catalogue/product/${payload.id}`,
    {
      ...product,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
    }
  );
};

export const deleteProducts = (
  token: string,
  payload: DeleteProductPayload
) => {
  return http.put(
    `/api/ms/catalogue/product/massive`,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
    }
  );
};

export const createProducts = (
  token: string,
  payload: CreateProductPayload[]
) => {
  return http.post(
    `/api/ms/catalogue/product/massive`,
    decamelizeKeys(payload),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
    }
  );
};

export const createUniqueProduct = (
  token: string,
  payload: CreateProductPayload
) => {
  return http.post(`/api/ms/catalogue/product`, decamelizeKeys(payload), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    } as { [key: string]: string },
  });
};

export const updateProductFetch = (token: string, payload: Product) => {
  const data: any = decamelizeKeys(payload);
  const product = {
    ...data,
    ownerId: data.owner_id,
  };
  return http
    .put(
      `/api/ms/catalogue/product/${payload.id}`,
      {
        ...product,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        } as { [key: string]: string },
      }
    )
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as Product);
};

export const getProductDetailFetch = (
  token: string,
  payload: { id: string }
) => {
  return http
    .get(`/api/ms/catalogue/product/${payload.id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
    })
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as Product);
};

export const calculateVariationsProductFetch = (
  token: string,
  payload: { toppings: Topping[] } | Product
) => {
  const data: any = decamelizeKeys(payload);
  return http
    .post(
      `/api/ms/catalogue/product-topping-variations/calculate`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        } as { [key: string]: string },
      }
    )
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as ProductVariation);
};

export const productsExportFetch = (token: string) => {
  return http
    .get("/api/ms/catalogue/files/xlsx", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-API-VERSION": 1,
      },
    })
    .then((res) => res.data)
    .then((res) => camelizeKeys(res) as ProductsExportResponse);
};

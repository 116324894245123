export const useLoginHook = () => {
    const IMAGES_BACKGROUND = [
        "https://sumerlabs.com/prod/web/dashboard/bg1.webp",
        "https://sumerlabs.com/prod/web/dashboard/bg2.webp",
        "https://sumerlabs.com/prod/web/dashboard/bg3.webp",
        "https://sumerlabs.com/prod/web/dashboard/bg4.webp",
      ]
      
      const IMAGE_MOBILE = [
        "https://sumerlabs.com/prod/web/dashboard/1-sumer-app-template.webp",
        "https://sumerlabs.com/prod/web/dashboard/2-sumer-app-template.webp",
        "https://sumerlabs.com/prod/web/dashboard/3-sumer-app-template.webp",
        "https://sumerlabs.com/prod/web/dashboard/4-sumer-app-template.webp",
      ]

    return {
        IMAGES_BACKGROUND,
        IMAGE_MOBILE
    };    
};
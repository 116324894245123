import { FC } from "react";
import { ProductNamePriceWrapper } from "./product-name-price.styled";
import { useProductDetail } from "../product-detail/product-detail.hook";
import GptGenerate from "../gpt-generate";
import CustomInputCurrency from "@dashboard/components/custom-input-currency/custom-input-currency";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { UiInput } from "@sumerlabs/component-library";
import { FormikErrors, FormikTouched } from "formik";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { Sign } from "@dashboard/components/custom-input-currency/constants/input-currency.constants";

const ProductNamePrice: FC = () => {
  const {
    values,
    showAiGenerator,
    loadingPredictProduct,
    errors,
    touched,
    currency,
    handleClickGenerate,
    predictNameProduct,
    handleBlur,
    handleChange,
    logEvent,
    hasVariations,
    setFieldValue,
    t,
  } = useProductDetail();

  return (
    <ProductNamePriceWrapper>
      <div className="input-container">
        <div className="title-wrapper">
          <div className="label">
            {`${t("admin.products.side.form.productNameLabel")} *`}
          </div>
          {showAiGenerator && values.imagesForm?.length !== 0 && (
            <GptGenerate
              fieldLabel={t("products.gptGenerate.fields.name.label")}
              descriptionCopy={t(
                "products.gptGenerate.fields.name.description"
              )}
              loading={loadingPredictProduct}
              onClickGenerate={() => handleClickGenerate("title")}
              onClickPredict={predictNameProduct}
            />
          )}
        </div>
        <UiInput
          label=""
          name="name"
          className="input"
          placeholder={t("admin.products.side.form.productNamePlaceholder")}
          value={values.name}
          hasError={
            Boolean((errors as FormikErrors<FormProps>).name) &&
            (touched as FormikTouched<FormProps>).name
          }
          errorMessage={(errors as FormProps).name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={() => {
            logEvent(PRODUCTS_EVENTS.SELECT_INPUT, {
              event_subtype: "product_name",
              source: `${values.id ? "edit" : "create"}`,
            });
          }}
        />
      </div>
      <div className="input-container">
        <div className="title-wrapper">
          <div className="label">
            {t("admin.products.side.form.productValueLabel")}
          </div>
        </div>
        <CustomInputCurrency
          name="price"
          id="price"
          value={values.price && !hasVariations ? `${values.price}` : ""}
          placeholder={t("admin.products.side.form.productValuePlaceholder")}
          onChange={(val) => setFieldValue("price", +val)}
          disabled={hasVariations}
          hasError={
            Boolean((errors as FormikErrors<FormProps>).price) &&
            (touched as FormikTouched<FormProps>).price
          }
          errorMessage={(errors as any).price}
          onBlur={handleBlur}
          onFocus={() => {
            logEvent(PRODUCTS_EVENTS.SELECT_INPUT, {
              event_subtype: PRODUCTS_EVENTS.PRODUCT_VALUE,
            });
          }}
          sign={currency || Sign.PESO}
        />
      </div>
    </ProductNamePriceWrapper>
  );
};

export default ProductNamePrice;

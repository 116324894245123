import styled from "styled-components";

export const ContactButtonPersonalizationOptionsWrapper = styled.div`
	gap: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	.option-item {
		width: 100%;
		height: 42px;
		display: flex;
		padding: 0 8px;
		cursor: pointer;
		align-items: center;
		&:hover {
			background: var(--grey-7, #E9E9ED);
		}
		.icon {
			width: 24px;
			height: 24px;
			display: flex;
			font-size: 22px;
			margin: 0 8px 0 0;
			align-items: center;
			justify-content: center;
		}
		.text {
			color: #262930;
			font-size: 18px;
			font-weight: 600;
			line-height: 26px;
		}
	}
`;

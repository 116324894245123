import { FC, useEffect, useState } from "react";
import { GlobalLoaderWrapper } from "./global-loader.styled";
import { Loader } from "@dashboard/assets/icons";

export type GlobalLoaderTypes = {
  copys: Array<string>;
  onFinish?: () => void;
};

const GlobalLoader: FC<GlobalLoaderTypes> = ({ copys, onFinish }) => {
  const [currentCopy, setCurrentCopy] = useState(0);
  const [loaderCopys, setLoaderCopys] = useState(copys);

  useEffect(() => {
    setLoaderCopys(copys);
  }, [copys]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCopy((t) => t + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentCopy >= loaderCopys.length) {
      setCurrentCopy(loaderCopys.length - 1);
    }
  }, [currentCopy]);

  return (
    <GlobalLoaderWrapper>
      <div className="loader">
        <Loader />
        <div className="text">{loaderCopys[currentCopy]}</div>
      </div>
    </GlobalLoaderWrapper>
  );
};
export default GlobalLoader;

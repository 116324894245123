import React from 'react';

function Health() {
    return (
        <div>
            Health check
        </div>
    );
}

export default Health;

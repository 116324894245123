import styled from "styled-components";

export const ContactButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    &.withoutBack {
      .title {
        margin: 84px 0 8px 0;
      }
    }
    .back {
      height: 64px;
      display: flex;
      cursor: pointer;
      margin: 0 0 20px 0;
      align-items: center;
      &:hover {
        background: var(--grey-7, #E9E9ED);
      }
      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-arrow {
          font-size: 22px;
          transform: rotate(90deg);
        }
      }
      .text {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: var(--grey-b, #24272E);
      }
    }
    .title {
      font-size: 26px;
      font-weight: 600;
      line-height: 33px;
      margin: 0 0 8px 0;
      color: var(--grey-b, #24272E);
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 0 0 40px 0;
      color: var(--grey-4, #5D606F);
    }
  }
  .button {
    width: 100%;
    height: 40px;
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: auto 0 0 0;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--grey-w, #FFF);
    background: var(--grey-b, #24272E);
  }
`;

import styled from "styled-components";

export const PlanHookWrapper = styled.div`
  .modal {
    width: 520px;
    .img {
      width: 100%;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin: 24px 24px 8px 24px;
      color: var(--grey-b, #24272E);
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin: 0 24px 24px 24px;
      color: var(--grey-b, #24272E);
    }
    .button {
      height: 50px;
      display: flex;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      margin: 0 24px 24px 24px;
      color: var(--grey-8, #FCFCFC);
      background: var(--grey-b, #24272E);
    }
    .later {
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin: 0 0 30px 0;
      color: var(--grey-b, #24272E);
      text-decoration-line: underline;
    }
  }
`;

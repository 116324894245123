import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { PlansCtaBanner } from "@dashboard/entities/plans-cta-banner";
import { fetchPlansCtaBanner } from "@dashboard/services/plans-cta-banner.services";
import { LockKeyOpen, SealPercent } from "@phosphor-icons/react";
import { partnerSelector, planSelector } from "@dashboard/config/redux";
import { useAnalytics } from "@dashboard/common/analytics";
import { CoreEvents } from "@dashboard/constants/events";

export const usePlansCtaBanner = () => {

  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const partner = useSelector(partnerSelector);
  const plan = useSelector(planSelector);
  const [banner, setBanner] = useState<PlansCtaBanner | undefined>(undefined);

  const initData = async () => {
    const x = await fetchPlansCtaBanner();
    setBanner(x);
  };
  
  const MainIcon =  useMemo(() => {
    switch (banner?.mainTextIcon) {
      case 'lock-key-open':
        return LockKeyOpen;
      case 'seal-percent':
        return SealPercent;
      default:
        return undefined;
    }
  }, [banner]);

  const handleCtaClick = () => {
    if (!!banner) {
      logEvent(CoreEvents.SelectButton, {
        event_subtype: "view_plans",
        source: "banner",
        option: 'free',
      });
      if (banner.isExternalLink) {
        window.open(banner.ctaLink, '_blank');
      } else {
        navigate(`/${partner?.userId}${banner.ctaLink}`);
      }
    }
  };
  
  useEffect(() => {
    if (plan?.benefit_plan_name.toLowerCase() === 'free') {
      initData();
    }
  }, [plan]);

  return {
    banner,
    MainIcon,
    handleCtaClick,
  };
};

import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  availableAddonsSelector,
  availableFeaturesSelector,
  planSelector,
} from "@dashboard/config/redux";
import { Feature } from "@dashboard/constants/features";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";
import { FeatureOrders } from "@dashboard/modules/orders/constants/features.constants";

export const useFeatureAvailability = (
  featureName: Feature | FeatureProducts | FeatureOrders,
  isAddon?: boolean
) => {
  const plan = useSelector(planSelector);
  const features = useSelector(availableFeaturesSelector);
  const addons = useSelector(availableAddonsSelector);

  const isAvailable = useMemo(() => {
    if (isAddon) {
      return !!addons.find((a) => a.addon_type_id === featureName);
    } else {
      const allAvailable = !!features.find(
        (f) => f.feature_name === Feature.All
      );
      return !allAvailable
        ? !!features.find((f) => f.feature_name === featureName)
        : false;
    }
  }, [features, addons]);

  const feature = useMemo(() => {
    if (isAddon) {
      return addons.find((a) => a.addon_type_id === featureName);
    } else {
      const allAvailable = !!features.find(
        (f) => f.feature_name === Feature.All
      );
      return !allAvailable
        ? features.find((f) => f.feature_name === featureName)
        : allAvailable;
    }
  }, [features, addons]);

  return {
    isAvailable,
    feature,
    plan,
  };
};

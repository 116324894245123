import styled from "styled-components";

export const LoginOptionsWrapper = styled.div`
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  .option {
    height: 54px;
    display: flex;
    color: #262930;
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;
    position: relative;
    border-radius: 8px;
    align-items: center;
    background: #ffffff;
    justify-content: center;
    border: 1px solid var(--Grey-Border, #E9E9ED);
    .option-logo,
    .option-icon {
      left: 14px;
      position: absolute;
    }
    .option-icon {
      font-size: 16px;
    }
    .option-others {
      align-items: center;
      display: flex;
      justify-content: center;
        svg {
          left: 14px;
           position: absolute;
        }
    }
  }
  .close-other-options {
    display: none;
  }
  .option-texts-decoration {
    background-color: transparent;
    .texts-decoration-container {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      .text-decoration {
        width: 30%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      .line-decoration {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        width: 35%;
      }
    }
  }
  .open-other-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    animation-duration: 0.4s;
    animation-name: fadeInUp;
    transition: all 0.2s;
  }
  .terms {
    color: var(--grey-b, #24272e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    .link-terms {
      text-decoration: underline;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, -5%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

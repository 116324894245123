import styled from "styled-components";

export const MainHeaderWrapper = styled.div`
  position: fixed;
  background: #ffffff;
  box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
    2px 4px 10px 0px rgba(53, 53, 53, 0.04);
  width: 100%;
  display: flex;
  padding: 0 16px;
  column-gap: 20px;
  align-items: center;
  justify-content: flex-end;
  height: var(--header-height);
  z-index: 99;
  .plan-badge {
    height: 38px;
    display: flex;
    cursor: pointer;
    column-gap: 8px;
    padding: 0 14px;
    border-radius: 8px;
    align-items: center;
    background: var(--grey-w, #fff);
    border: 1px solid var(--grey-b, #24272e);
    .icon {
      display: flex;
      color: #24272e;
      font-size: 16px;
      align-items: center;
      justify-content: center;
    }
    .text {
      color: #24272e;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  .see-my-store {
    height: 32px;
    display: flex;
    padding: 0 8px;
    cursor: pointer;
    column-gap: 8px;
    align-items: center;
    border-radius: 28px;
    justify-content: center;
    background: var(--grey-w, #fff);
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
    .icon {
      display: flex;
      color: #24272e;
      font-size: 22px;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      color: var(--grey-b, #24272e);
    }
  }
  .share-store {
    height: 32px;
    display: flex;
    padding: 0 12px;
    cursor: pointer;
    column-gap: 8px;
    align-items: center;
    border-radius: 28px;
    justify-content: center;
    background: var(--grey-7, #e9e9ed);
    .icon {
      display: flex;
      color: #24272e;
      font-size: 22px;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      color: var(--grey-b, #24272e);
    }
  }
  .profile {
    width: 46px;
    height: 46px;
    display: flex;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    font-weight: bold;
    position: relative;
    align-items: center;
    justify-content: center;
    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }
    .popup {
      right: 0;
      top: 100%;
      z-index: 10;
      width: 184px;
      padding: 16px;
      cursor: default;
      position: absolute;
      border-radius: 8px;
      transition: display 0.2s;
      background: var(--grey-w, #fff);
      box-shadow: 0px 6px 8px 0px rgba(95, 99, 108, 0.12);
      &.show {
        display: block;
      }
      &.none {
        display: none;
      }
      .store-name {
        color: var(--grey-b, #24272e);
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
      .divider {
        height: 1px;
        width: 100%;
        margin: 16px 0;
        background: #e9e9ed;
      }
      .logout {
        display: flex;
        cursor: pointer;
        column-gap: 10px;
        align-items: center;
        .icon {
          font-size: 16px;
          color: var(--red-main, #f1450f);
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: var(--red-main, #f1450f);
        }
      }
    }
  }
`;

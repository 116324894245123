import { FC, useEffect, useState } from "react";
import { AlertPopupWrapper } from "./alert-popup.styled";
import { Modal, UiButton } from "@sumerlabs/component-library";
import { AlertPopupType } from "./types/alert-popup.type";

const AlertPopup: FC<AlertPopupType> = ({
  confirmLabel,
  alternativeLabel,
  description,
  title,
  onClose,
  onConfirm,
  onAlternative,
  show,
  hiddeIcon,
}) => {
  const [showModal, setShowModal] = useState(show ?? false);

  useEffect(() => {
    setShowModal(show ?? false);
  }, [show]);

  const handleClose = () => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleClose}
      showHeader={false}
      show={showModal}
      content={{
        styles: {
          width: "520px",
        },
      }}
      body={{
        styles: {
          padding: "0px",
        },
      }}
    >
      <AlertPopupWrapper>
        <figure className="close icon-close" onClick={handleClose}></figure>
        {!hiddeIcon && (
          <div className="icon">
            <figure className="icon-circle-exclamation"></figure>
          </div>
        )}
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <UiButton onClick={(event) => onConfirm(event)}>
          {confirmLabel}
        </UiButton>
        {alternativeLabel && (
          <div className="alternative" onClick={onAlternative && onAlternative}>
            <small className="text">{alternativeLabel}</small>
          </div>
        )}
      </AlertPopupWrapper>
    </Modal>
  );
};

export default AlertPopup;

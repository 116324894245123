import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(169.31deg, #131313 43.13%, #494949 97.03%),
    linear-gradient(180deg, #0c1220 0%, rgba(12, 18, 32, 0) 100%);
  display: flex;
  .contentWrapper {
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: auto;
    .image-login{
      top: 0px;
      left: 0px;
      z-index: 1;
      width: 560px;
      img {
        width: 100%;
        
      }
    }
    
    .content {
      display: flex;
      height: 400px;
      z-index: 0;
      padding: 32px 80px;
      margin-top: -74px;
      justify-content: center;
      align-items: center;
      gap: 92px;
      flex-shrink: 0;
      border-radius: 20px;
      background: var(--Grey-W, #fff);
      box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
      .qrContent {
        display: flex;
        align-items: flex-start;
        gap: 80px;
        height: 230px;
        padding: 0px 50px;
        > img {
          width: 230px;
        }
        .loading {
          align-items: center;
          display: flex;
          justify-content: center;
          z-index: 99;
          height: -webkit-fill-available;
        }
        .description {
          width: -webkit-fill-available;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 30px 0px;
          height: -webkit-fill-available;
          justify-content: space-between;
          > hr {
            border: 1px solid #EAF0FE;
            width: 100%;
          }
          .title {
            color: var(--Grey-B, #24272e);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 31px;
          }
          .step {
            color: var(--Grey-B, #24272e);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }
    }
  }
`;
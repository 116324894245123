import styled from "styled-components";

export const GlobalLoaderWrapper = styled.div`
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23.894px;
    .text {
      color: var(--Grey-W, #fff);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
`;

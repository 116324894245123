import styled from 'styled-components';

const ContactButtonMessagesWrapper = styled.div`
	width: 100%;
	overflow: scroll;
	.title {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: var(--grey-3, #3E434E);
	}
	.description {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin: 0 0 4px 0;
		color: var(--grey-3, #3E434E);
	}

	.textarea {
		position: relative;
		margin: 0 0 16px 0;

		.input {
			width: 100%;
			resize: none;
			height: 106px;
			padding: 16px;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			border-radius: 10px;
			background-color: #ffffff;
			border: 1px solid #e6e8f9;
			font-family: var(--font-family);

			&::placeholder {
				font-size: 16px;
				color: #8f9298;
				font-weight: 400;
				line-height: 24px;
				font-family: var(--font-family);
			}
		}

		.characters {
			color: #b0b2b6;
			text-align: end;
			font-size: 10px;
			font-weight: 400;
			line-height: 140%;
		}

		.tags {
			gap: 8px;
			display: flex;
			align-items: center;

			.tag {
				display: flex;
				cursor: pointer;
				font-size: 12px;
				font-weight: 400;
				padding: 6px 12px;
				line-height: 18px;
				border-radius: 8px;
				align-items: center;
				background: #ffffff;
				justify-content: center;
				color: var(--grey-3, #3E434E);
				box-shadow: 2px 4px 10px rgba(53, 53, 53, 0.04), 3px 4px 13px -4px rgba(180, 180, 180, 0.18);
			}
		}
	}
`;

export default ContactButtonMessagesWrapper;

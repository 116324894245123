import { FC } from "react";
import { FrequentQuestionsStyled } from "./frequent-questions.styled";
import { QuickAnswer } from "../../types/quick-answers.types";

type FrequentQuestionsProps = {
  answers: QuickAnswer[];
  className?: string;
  onClickAnswer?: (answer: QuickAnswer, index?: number) => void;
};

const FrequentQuestions: FC<FrequentQuestionsProps> = ({
  answers,
  className,
  onClickAnswer,
}) => {
  const handleClickAnswer = (answer: QuickAnswer, index?: number) => {
    onClickAnswer && onClickAnswer(answer, index);
  };

  return (
    <FrequentQuestionsStyled className={className || ""}>
      {answers.map((answer, answersIndex) => (
        <article
          className="frequent-question"
          key={`frequent-question-${answersIndex}`}
          onClick={() => handleClickAnswer(answer, answersIndex)}
        >
          <h3 className="question-title">{answer.questionTitle}</h3>
          <p className="question-description">{answer.questionSubtitle}</p>
        </article>
      ))}
    </FrequentQuestionsStyled>
  );
};

export default FrequentQuestions;

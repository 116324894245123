import styled from "styled-components";

export const PersonalizationWrapper = styled.div`
  height: 100%;
  .frame {
    width: 100%;
    height: 100%;
    .iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;

import {css} from "styled-components";

export const flexVertical = css`
    display:flex;
    flex-direction:column;
`;

export const flexHorizontal = css`
    display:flex;
    flex-direction:row;
`;

import { FC, useMemo } from "react";
import { FieldTextType } from "./field-text.types";
import { FielTextHelpStyled, FieldTextStyled } from "./field-text.styles";

const FieldText: FC<FieldTextType> = ({
  type = "text",
  id,
  name,
  className,
  value,
  label,
  labelOutside,
  placeholder,
  maxLength,
  showMaxLength,
  isRequired,
  autocomplete,
  readonly,
  disabled,
  error,
  errorText,
  errorHelpText,
  helpText,
  leftIcon,

  onChange,
  onFocus,
  onBlur,
  onClick,

  errors,
  touched,
}) => {
  const propsInput = {
    type,
    id,
    name,
    value,
    className: "sw-field-input",
    required: isRequired || undefined,
    readOnly: readonly,
    disabled,
    autoComplete: autocomplete,
    placeholder: placeholder,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    onClick
  };

  const showError = useMemo(() => {
    return error || (errors && errors[name] && touched && touched[name]);
  }, [name, error, errors, touched]);

  return (
    <FieldTextStyled
      className={`${className || ""} ${showError ? "sw-field__error" : ""}`}
      labelOutside={labelOutside}
    >
      {label && (
        <label className="sw-field-label">
          {label} {isRequired ? "*" : ""}
        </label>
      )}
      <div className="sw-field-control">
        {leftIcon && <span className="sw-field-icon-left">{leftIcon}</span>}
        {type !== "textarea" ? (
          <input {...propsInput} />
        ) : (
          <textarea {...propsInput} />
        )}
        {showError && (
          <span className="sw-field-icon-error icon-circle-exclamation" />
        )}
      </div>
      {showMaxLength && maxLength && (
        <div className="sw-field-max-length">
          {(value || "").length}/{maxLength}
        </div>
      )}
      {showError && <div className="sw-field-error">{errorText}</div>}
      {!showError && !!errorHelpText && <div className="sw-field-error-help">{errorText}</div>}
      {helpText && (
        <FielTextHelpStyled>
          <button type="button" className="button">
            <span className="icon-info-alert-icon" />
          </button>
          <div className="tooltip">{helpText}</div>
        </FielTextHelpStyled>
      )}
    </FieldTextStyled>
  );
};

export default FieldText;

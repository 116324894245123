import { useAnalytics } from "@dashboard/common/analytics";
import {
  countriesSelector,
  partnerSelector,
  planParamsSelector,
  fetchPartnerByToken,
} from "@dashboard/config/redux";
import { CoreEvents, EVENTS } from "@dashboard/constants/events";
import { StorageItem } from "@dashboard/constants/storage-items";
import { useSession } from "@dashboard/hooks/use-session.hook";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  SourceOnboarding,
  OptionOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import {
  updateUserDetail,
  fetchUserDetail,
  fetchEconomicSectorIa,
  fetchEconomicSectorPredict,
} from "@dashboard/modules/shared/components/onboarding-shared/services/onboarding.services";
import {
  economicSectorsSelector,
  ipInfoSelector,
  onboardingDataSelector,
  onboardingUiSelector,
  storeDesignSplitSelector,
} from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.selectors";
import { actions } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.slice";
import { fetchGetCountryByIp } from "@dashboard/services/countries.services";
import { debounce } from "@dashboard/utils/debounce";
import {
  UiDropdownOption,
  UiPhoneOption,
  useLocalStorage,
} from "@sumerlabs/component-library";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { stepInfoSchema } from "../schemas/step-info.schema";
import { useNavigate } from "react-router-dom";

export const useStepInfo = (
  nextStepPath?: string,
  hiddeEconomicSector?: boolean,
  isGuest?: boolean
) => {
  const { t } = useTranslation();
  const { logEvent, appsFlyerEvent } = useAnalytics();
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelector);
  const partner = useSelector(partnerSelector);
  const { isLoading } = useSelector(onboardingUiSelector);
  const [token] = useLocalStorage("accessToken", "");
  const { setWebStatus, decodedToken } = useSession();
  const planParamsData = useSelector(planParamsSelector);
  const economicSectors = useSelector(economicSectorsSelector);
  const onboardingData = useSelector(onboardingDataSelector);
  const storeSplit = useSelector(storeDesignSplitSelector);
  const ipInfo = useSelector(ipInfoSelector);
  const navigate = useNavigate();
  const [storeSplitEnabled, setStoreSplitEnabled] = useState(false);

  const form = useFormik({
    initialValues: {
      country: partner?.userDetail?.country || "",
      prefixPhone: partner?.userDetail?.prefixPhone || "",
      phone: partner?.userDetail?.phone || "",
      templateId: "",
      economicSector: "",
    },
    validationSchema: stepInfoSchema(t, hiddeEconomicSector),
    onSubmit: async (values) => {
      let auxValues = { ...values };
      const findIndexSector = economicSectors.findIndex(
        (sector) => sector.id === auxValues.economicSector
      );
      if (findIndexSector === -1) {
        const response = await fetchEconomicSectorPredict(
          form.values.economicSector,
          token
        );
        auxValues.economicSector = response?.economic_sector_id || "clothes";
      }
      dispatch(actions.setUi({ isLoading: true }));
      updateUserDetail(token, {
        ...onboardingData,
        ...auxValues,
        prefixPhone: getPrefix(values.prefixPhone),
        economicSectors: [{ id: auxValues.economicSector }],
      }).then((r) => {
        if (!r) {
          form.setFieldError("phone", t("onboarding.info.errorPhone"));
          dispatch(actions.setUi({ isLoading: false }));
          return;
        }
        dispatch(fetchPartnerByToken(token));
        fetchUserDetail(token)
          .then((response) => {
            dispatch(actions.setOnboardingData(response));
          })
          .catch();
        logEvent(EventsOnboarding.SELECT_BUTTON, {
          event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
          source:
            planParamsData?.plan && planParamsData.period
              ? SourceOnboarding.LANDING_PRICING
              : "onboarding",
          option: OptionOnboarding.REGISTER,
        });
        nextStep();
        dispatch(actions.setUi({ isLoading: false }));
      });
    },
  });

  const getPrefix = (country: string) => {
    return countries.find((c) => c.name === country)?.prefixPhone || "";
  };

  const checkEconomicSector = (): boolean => {
    if (storeSplit?.config?.economicSectors) {
      const index = (storeSplit?.config?.economicSectors as string[]).findIndex(
        (sector) => sector === form.values.economicSector
      );
      return index !== -1;
    }
    return false;
  };

  const nextStep = () => {
    const path = `${
      storeSplitEnabled && checkEconomicSector() ? "store" : "generating"
    }`;
    if (isGuest) {
      setWebStatus(
        `/${decodedToken?.user_id}`,
        decodedToken?.user_id || "",
        true,
        false,
        true
      );
      navigate(nextStepPath || "");
    } else {
      setWebStatus(
        `/onboarding/${path}`,
        decodedToken?.user_id || "",
        false,
        true,
        true
      );
    }
  };

  const countriesOptions: UiDropdownOption[] = useMemo(
    () =>
      countries.map((c) => ({
        label: c.name,
        value: c.name,
        flagUrl: c.flagImage,
      })),
    [countries]
  );

  const prefixCodeOptions: UiPhoneOption[] = useMemo(
    () =>
      countries.map((c) => ({
        label: c.prefixPhone || "",
        value: c.name || "",
        flagUrl: c.flagImage,
      })),
    [countries]
  );

  const autocompleteOptions = useMemo(() => {
    return economicSectors.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [economicSectors]);

  const sectorList = useMemo(() => {
    return economicSectors.map((item) => ({
      label: item.name,
      value: item.id,
      isActive: false,
    }));
  }, [economicSectors]);

  const handleSectorAutocompleteClick = (sectorId: string) => {
    logEvent(CoreEvents.SelectDropdown, {
      event_subtype: "onboarding_steps",
      option: "economic_sector_other",
      step_number: 2,
    });
    form.setFieldValue("templateId", "", true);
    form.setFieldValue("economicSector", sectorId, true);
  };

  const handleAutocompleteInputChange = debounce(async (value: string) => {
    const response = await fetchEconomicSectorIa(value);
    if (!response) {
      form.setFieldValue("templateId", "", true);
      form.setFieldValue("economicSector", "", true);
      return;
    }
    form.setFieldValue("templateId", response.template_id, true);
  }, 1000);

  const activateSector = (sectorId: string) => {
    sectorList.forEach(
      (sector) => (sector.isActive = sector.value === sectorId && true)
    );
  };

  useEffect(() => {
    if (storeSplit) {
      setStoreSplitEnabled(storeSplit.config?.enabled as boolean);
    }
  }, [storeSplit]);

  useEffect(() => {
    activateSector(form.values.economicSector);
  }, [form.values.economicSector]);

  useEffect(() => {
    if (!partner || partner.userDetail.country === "undefined") {
      if (!ipInfo) {
        fetchGetCountryByIp().then((res) => {
          form.setFieldValue("country", res.country || "");
          form.setFieldValue("prefixPhone", res.country || "");
          dispatch(actions.setIpInfo(res));
        });
      } else {
        form.setFieldValue("country", ipInfo.country || "");
        form.setFieldValue("prefixPhone", ipInfo.country || "");
      }
    }
  }, [countriesOptions]);

  useEffect(() => {
    const loggedBy = localStorage.getItem(StorageItem.LoggedBy) || "";
    if (!!partner || decodedToken?.user_id) {
      if (partner?.userId && partner.userDetail.enterpriseName) {
        logEvent(EVENTS.USER_LOGIN, {
          event_subtype: "sumer_web",
          source: "login",
          method: loggedBy,
        });
        form.setFieldValue(
          "country",
          ipInfo ? ipInfo.country : partner.userDetail.country || ""
        );
        form.setFieldValue(
          "prefixPhone",
          ipInfo ? ipInfo.country : partner.userDetail.country || ""
        );
        form.setFieldValue("phone", partner.userDetail.phone || "");
        form.setFieldValue(
          "economicSector",
          partner.userDetail.economicSector || ""
        );
        setTimeout(() => {
          form.validateForm();
        }, 500);
      }
      logEvent(EVENTS.USER_LOGIN, {
        event_subtype: "sumer_web",
        source: "onboarding",
        method: loggedBy,
      });
      logEvent("view_screen", {
        event_subtype: "web_onboarding_register",
        source: "onboarding"
      });
      appsFlyerEvent({
        eventName: "view_screen",
        eventValue: {
          event_subtype: "view_screen_web_onboarding_business_info",
          user_type: "store",
          platform: "web",
        },
      });
      logEvent(EventsOnboarding.VIEW_SCREEN, {
        event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
        source:
          planParamsData?.plan && planParamsData.period
            ? SourceOnboarding.LANDING_PRICING
            : "onboarding",
        option: OptionOnboarding.REGISTER,
      });
    }
  }, [partner, decodedToken?.user_id]);

  return {
    form,
    countriesOptions,
    prefixCodeOptions,
    autocompleteOptions,
    isLoading,
    sectorList,
    logEvent,
    handleSectorAutocompleteClick,
    handleAutocompleteInputChange,
    activateSector,
  };
};

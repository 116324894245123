import styled from "styled-components";

export const HookUpgradePlanStyled = styled.aside`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: 100%;
  .image {
    margin: 0 -2rem;
    img {
      aspect-ratio: 520/295;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      object-fit: cover;
      width: 100%;
    }
  }
  .copies {
    color: #24272e;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    .title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  .options {
    width: 100%;
  }
`;

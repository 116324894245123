import { camelizeKeys } from "humps";
import { MonthlyInteractionsResponse } from "../types/interactions.types";
import { getApiUrl } from "@dashboard/common/utils";

export const fetchGetInteractions = async (
  token: string
): Promise<MonthlyInteractionsResponse> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/ai-assistant/conversation/get-monthly-interactions`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) throw new Error();
  else {
    const responseJson = await response.json();
    return camelizeKeys(responseJson) as MonthlyInteractionsResponse;
  }
};

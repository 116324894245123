import { Plan } from "@dashboard/modules/plans/plans.types";
import GridPricing from "@sumerkit/ui/grid-pricing";
import React, { FC } from "react";
import PlanCardShared from "../plan-card-shared/plan-card-shared";
import { PlatformCountry } from "@dashboard/common/types";
import { EPlanPricing } from "../../constants/plan-pricing";
import { usePlanGridShared } from "../../hooks/plan-grid-shared.hook";

export type PlansGridSharedTypes = {
  plans: Plan[];
  view: EPlanPricing;
  country?: PlatformCountry;
  horizontalCard?: boolean;
  onClick?: (plan: Plan) => void;
};

const PlansGridShared: FC<PlansGridSharedTypes> = ({
  plans,
  country,
  view,
  horizontalCard,
  onClick,
}) => {
  const { plansOrdered } = usePlanGridShared(plans, view);

  return (
      <GridPricing direction={horizontalCard ? "column" : 'row'}>
        {plansOrdered.map((plan, i) => (
          <PlanCardShared
            key={`plan-${i}`}
            plan={plan}
            country={country}
            view={view}
            onClick={onClick}
            horizontalCard={horizontalCard}
            hint={plan.customHint}
          />
        ))}
      </GridPricing>
  );
};

export default PlansGridShared;

import Tooltip from "rc-tooltip";
import { FC, ReactElement } from "react";
import 'rc-tooltip/assets/bootstrap.css';

interface UiTooltipProps {
  children: ReactElement;
  text: string;
  placement?: 'top' | 'left' | 'right' | 'bottom' | 
    'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 
    'leftTop' | 'leftBottom' | 'rightTop' |'rightBottom';
}
 
const UiTooltip: FC<UiTooltipProps> = ({
  children,
  text,
  placement = 'top',
}) => {
  return (
    <Tooltip
      placement={placement} 
      destroyTooltipOnHide={true}
      overlay={<span>{text}</span>}>
      {children}
    </Tooltip>
  );
}
 
export default UiTooltip;
import styled from "styled-components";

export const StepDemoWrapper = styled.div`
  .navbar {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--Grey-700, #e9e9ed);
    background: var(--Grey-W, #fff);
    box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
    position: fixed;
    width: 100%;
    z-index: 1;
    .back-arrow {
      transform: rotate(-90deg);
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  .body {
    display: flex;
    width: 100%;
    padding: 52px 0px;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    max-width: 900px;
    .header-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      .header {
        display: flex;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        margin-top: 60px;
        .title {
          color: var(--Grey-B, #24272e);
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 33px;
        }
      }
      .message {
        display: flex;
        height: 32px;
        padding: 24px;
        align-items: center;
        gap: 32px;
        border-radius: 16px;
        background: var(--Light-Complementary-Swamp-50, #e8ecdb);
        .text {
          color: var(--Grey-B, #24272e);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .container {
      width: 100%;
      display: grid;
      padding: 40px 0px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 48px 32px;
      flex-wrap: wrap;
      background: var(--Grey-800, #fcfcfc);
      grid-template-columns: repeat(3, 1fr);
      .generate-ai {
        align-items: center;
        border-radius: 16px;
        border: 1px solid var(--Grey-Border, #e9e9ed);
        background: var(--Grey-W, #fff);
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        height: 392px;
        padding: 24px;
        cursor: pointer;
        > img {
          max-width: 100%;
          height: auto;
        }
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          .title {
            color: var(--Grey-B, #24272e);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
          .description {
            color: var(--Grey-300, #3e434e);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .button {
          width: fit-content;
        }
      }
      .banner {
        grid-column: 1 / 4;
      }
      .template {
        position: relative;
        display: flex;
        padding-bottom: 9px;
        flex-direction: column;
        gap: 16.333px;
        flex-shrink: 0;
        &:hover {
          cursor: pointer;
        }
        > img {
          width: 100%;
          border-radius: 8px;
          border: 1px solid var(--Grey-600, #b0b2b6);
          height: 345px;
          object-fit: cover;
          object-position: top;
        }
        .controls {
          position: absolute;
          display: flex;
          padding: 16px;
          align-items: flex-start;
          gap: 10px;
          background: rgba(12, 18, 32, 0.4);
          bottom: 53px;
          width: 100%;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          .custom-button {
            border: none;
            color: var(--Grey-B, #24272e);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }
        .description {
          display: flex;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;
          .text-template {
            color: var(--Grey-400, #5d606f);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .text-name {
            color: var(--Grey-B, #24272e);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
        }
      }
    }
  }
`;

import { useSelector } from "react-redux";
import { meliTokenSelector } from "../../store/meli.selector";
import {
  fetchCheckUserMeli,
  fetchGetMeliProducts,
  fetchGetMeliRecord,
  fetchGetMeliUrl,
  fetchRegisterMeliToken,
  fetchUnlinkMeliAccount,
} from "../../services/meli.service";
import { useLocalStorage } from "@sumerlabs/component-library";
import { db } from "@dashboard/firebase";
import { onValue, ref } from "firebase/database";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { useMemo, useState } from "react";
import { RecordMeli } from "../../models/record-meli.model";
import { useTranslation } from "react-i18next";
import {
  actions,
  partnerSelector,
  useAppDispatch,
} from "@dashboard/config/redux";
import { useNavigate } from "react-router-dom";
import { planSelector } from "@dashboard/config/redux";
import { EMeliStatus } from "../../constants/meli-status.constant";
import { meliActions } from "../../store/meli.slice";
import { useAnalytics } from "@dashboard/common/analytics";
import { EVENTS } from "@dashboard/constants/events";
import { Plan } from "@dashboard/constants/plan";
import { MeliEvents } from "../../constants/meli-events.contant";
import { MeliFeatures } from "../../constants/features.constants";

export const useMeli = () => {
  const meliToken = useSelector(meliTokenSelector);
  const dispatch = useAppDispatch();
  const [token] = useLocalStorage("accessToken", null);
  const { decodedToken } = useSession();
  const [isSinchronized, setIsSinchronized] = useState(false);
  const [record, setRecord] = useState<RecordMeli[]>([]);
  const [syncModal, setSyncModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const partner = useSelector(partnerSelector);
  const plans = useSelector(planSelector);
  const [productsImport, setProductsImport] = useState(0);
  const [errorSync, setErrorSync] = useState(false);
  const { logEvent } = useAnalytics();

  const userIsPermitted = useMemo(() => {
    return (
      plans?.benefit_plan_name === Plan.ULTRA ||
      plans?.benefit_plan_name === Plan.PRO
    );
  }, [plans]);

  const registerMeliUser = () => {
    if (userIsPermitted && meliToken) {
      fetchRegisterMeliToken(token || "", meliToken)
        .then(() => {
          setErrorSync(false);
          logEvent(EVENTS.SYSTEM_CONNECTION_COMPLETE, {
            event_subtype: MeliEvents.MELI_INTEGRATION,
            option: "success",
          });
          syncProducts();
        })
        .catch(() => {
          setErrorSync(true);
          stopLoading();
          logEvent(EVENTS.SYSTEM_CONNECTION_COMPLETE, {
            event_subtype: MeliEvents.MELI_INTEGRATION,
            option: "failed",
          });
        });
    } else {
      logEvent(EVENTS.SYSTEM_EXPERT_WARNING, {
        event_subtype: MeliEvents.MELI_INTEGRATION,
      });
      stopLoading();
      handleShowHook();
    }
  };

  const syncMeliStatus = () => {
    const meliRef = ref(
      db,
      `${
        process.env.REACT_APP_ENV === "local"
          ? "dev"
          : process.env.REACT_APP_ENV
      }/users/${decodedToken?.user_id}/meli/bring_products`
    );
    onValue(meliRef, async (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        if (data.state === EMeliStatus.FINISHED) {
          setProductsImport(data?.detail?.size_products);
          dispatch(meliActions.setMeliToken(""));
          stopLoading();
          dispatch(meliActions.setProductsCount(data?.detail?.size_products));
        }
      }
    });
  };

  const checkMeliUser = () => {
    const auxToken = token || "";
    fetchCheckUserMeli(auxToken)
      .then(() => {
        setIsSinchronized(true);
        fetchGetMeliRecord(auxToken).then((res) => {
          if (res.length !== 0) {
            setRecord(res);
          }
        });
      })
      .catch(() => setIsSinchronized(false));
  };

  const startLoading = () => {
    dispatch(
      actions.setShowGlobalLoader({
        showLoader: true,
        copys: [
          t("integrations.meli.loader.copy1"),
          t("integrations.meli.loader.copy2"),
          t("integrations.meli.loader.copy3"),
          t("integrations.meli.loader.copy4"),
        ],
      })
    );
  };

  const stopLoading = () => {
    dispatch(
      actions.setShowGlobalLoader({
        showLoader: false,
        copys: [],
      })
    );
  };

  const handleSyncProducts = () => {
    startLoading();
    syncProducts();
  };

  const syncProducts = () => {
    if (userIsPermitted) {
      fetchGetMeliProducts(token || "")
        .then(() => {
          syncMeliStatus();
          checkMeliUser();
        })
        .catch((err) => {});
    } else {
      logEvent(EVENTS.SYSTEM_EXPERT_WARNING, {
        event_subtype: MeliEvents.MELI_INTEGRATION,
        source: "hook",
        method: "free",
      });
      handleShowHook();
    }
  };

  const unlinkAccount = () => {
    fetchUnlinkMeliAccount(token || "")
      .then(() => {
        setSyncModal(false);
        checkMeliUser();
        setIsSinchronized(false);
      })
      .catch(() => setSyncModal(false));
  };

  const handleGoToPlansPage = () => {
    navigate(`/${partner?.userId}/plans`);
  };

  const getMeliUrl = () => {
    fetchGetMeliUrl(token || "").then((data) => {
      if (data?.url && userIsPermitted) {
        window.location.href = data.url;
      } else {
        logEvent(EVENTS.SYSTEM_EXPERT_WARNING, {
          event_subtype: MeliEvents.MELI_INTEGRATION,
          source: "hook",
          method: "free",
        });
        handleShowHook();
      }
    });
  };

  const handleShowHook = () => {
    dispatch(actions.showCustomPlanHook(MeliFeatures.meliIntegration));
  };

  return {
    registerMeliUser,
    syncMeliStatus,
    checkMeliUser,
    syncProducts,
    unlinkAccount,
    setSyncModal,
    record,
    meliToken,
    isSinchronized,
    plans,
    productsImport,
    handleSyncProducts,
    startLoading,
    errorSync,
    handleShowHook,
    userIsPermitted,
    syncModal,
    logEvent,
    handleGoToPlansPage,
    getMeliUrl,
  };
};

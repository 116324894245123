import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiLoaderWrapper } from "./ai-loader.styled";
import loaderImg from "@dashboard/assets/img/loader.png";
import fakeDesktopImg from "@dashboard/assets/img/fake-desktop.webp";
import { QUERY_PARAMS_TEMPLATES } from "@dashboard/modules/shared/components/onboarding-shared/constants/params.constants";

export type AiLoaderProps = {
  redirectPath?: string;
};

const AiLoader: FC<AiLoaderProps> = ({ redirectPath }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentText, setCurrentText] = useState(0);
  const templateLandingQuery = searchParams.get(QUERY_PARAMS_TEMPLATES.KEY_NAME);
  
  const texts = [
    t("onboarding.personalize.ia.one"),
    t("onboarding.personalize.ia.two"),
    t("onboarding.personalize.ia.three"),
    t("onboarding.personalize.ia.four"),
    t("onboarding.personalize.ia.five"),
  ];

  useEffect(() => {
    if (templateLandingQuery !== null) {
      sessionStorage.setItem(QUERY_PARAMS_TEMPLATES.KEY_NAME, templateLandingQuery);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((t) => t + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentText >= texts.length) {
      navigate(`${redirectPath || "/onboarding/personalize"}`);
    }
  }, [currentText]);

  return (
    <AiLoaderWrapper>
      <div className="screen-loader">
        <div className="overlay" />
        <div className="progress">
          <img src={loaderImg} alt="Loader progress" className="img" />
          <div className="logo">
            <span className="icon-isotipo-sumer" />
          </div>
          <div className="text">{texts[currentText]}</div>
        </div>
      </div>
      <div className="fake-content">
        <img src={fakeDesktopImg} alt="Desktop" className="img" />
      </div>
    </AiLoaderWrapper>
  );
};

export default AiLoader;

import styled from "styled-components";

export const BannerIaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Grey-B, #24272e);
  height: 320px;
  .content {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    width: 50%;
    .title {
      color: var(--Grey-W, #fff);
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
    .description {
      color: var(--Grey-700, #e9e9ed);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .banner-img {
    width: 50%;
    object-fit: cover;
    height: 320px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

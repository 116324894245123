import dayjs from "dayjs";
import { capitalized } from "./strings.utils";

export const dateFormat = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const getDateByText = (dateText: string): string => {
  const formatDate = (dateText: string, format: string = "DD/MM/YYYY") =>
    !!dateText ? dayjs(dateText).format(format) : "";
  const month = formatDate(dateText, "MMM");
  const day = formatDate(dateText, "DD");
  const year = formatDate(dateText, "YYYY");
  return `${capitalized(month)} ${day}, ${year}`;
};

import { useFormikContext } from "formik";
import { useContext } from "react";
import ProductContext from "../../context/product.context";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { Topping } from "@dashboard/modules/products/types/products.types";

export const useLogicVariationsFilterModal = () => {
  const { setFieldValue } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const { setOverflow, setShowFilterModal, setFilterOn, setFilterCount } =
    useContext(ProductContext);

  const addItemToFilter = (parentindex: number, name: string, single: boolean) => {
    const aux = values.toppings;
    if (aux) {
      if (single) {
        aux.forEach((filter, i) => {
          if (i === parentindex) {
            delete aux[i].filters;
          }
        });
      }
      aux[parentindex].filters = aux[parentindex].filters || [];
      const index = aux[parentindex].filters?.findIndex(
        (filter) => filter === name
      );
      if (index === -1) {
        aux[parentindex].filters?.push(name);
        setFilterOn(true);
      }
      countFilters(aux);
      setFieldValue("toppings", aux);
    }
  };

  const countFilters = (toppings: Topping[]) => {
    let aux = 0;
    toppings.forEach((topping) => {
      if (topping.filters) {
        aux += topping.filters.length;
      }
    });
    setFilterCount(aux);
  };

  const cleanFilters = () => {
    const aux = values.toppings;
    aux?.forEach((topping) => {
      delete topping.filters;
    });
    countFilters(aux || []);
    setFieldValue("toppings", aux);
    setFilterOn(false);
    setOverflow("is-overflow");
    setShowFilterModal(false);
  };

  const isFiltered = (parentIndex: number, value: string): boolean => {
    if (
      values.toppings &&
      values.toppings[parentIndex].filters &&
      values.toppings[parentIndex].filters?.findIndex(
        (filter) => filter === value
      ) !== -1
    ) {
      return true;
    }
    return false;
  };

  return {
    addItemToFilter,
    cleanFilters,
    isFiltered,
  };
};

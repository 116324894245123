import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CartsListWrapper } from "./carts-list.styled";
import { Pagination, UiButton, useLocalStorage } from "@sumerlabs/component-library";
import { fetchAbandonedCarts, sendRecoveryEmail } from "../../services/abandoned-cart.services";
import { AbandonedCart } from "../../entities/abandoned-cart";
import { goToWhatApp } from "@dashboard/utils/go-to-whatsapp";
import { countryInformationSelector, partnerSelector } from "@dashboard/config/redux";
import { avatarProfile } from "@dashboard/assets/svg";
import SendEmailConfirmation from "../../components/send-email-confirmation/send-email-confirmation";
import 'dayjs/locale/es-mx';
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { Feature } from '@dashboard/constants/features';
import { useAnalytics } from "@dashboard/common/analytics";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { PlatformCountry } from "@dashboard/common/types";

dayjs.locale('es-mx');

interface CartsListProps {}
 
const CartsList: FC<CartsListProps> = () => {
  
  const perPage = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {logEvent} = useAnalytics();
  const [page, setPage] = useState(1);
  const [data, setData] = useState<AbandonedCart[]>([]);
  const partner = useSelector(partnerSelector);
  const country = useSelector(countryInformationSelector);
  const { isAvailable } = useFeatureAvailability(Feature.AbandonedCart, true);
  const [accessToken] = useLocalStorage('accessToken', null);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [selectedCart, setSelectedCart] = useState<AbandonedCart>();
  const paginatedData = data.slice(perPage * (page - 1), perPage * page);

  const profileImage = partner?.userDetail?.profilePic || avatarProfile; 

  const formatPrice = (cart: AbandonedCart) => {
    const c = ((!!cart.cart.locale && !!cart.cart.currency) ? {
      locale: cart.cart.locale, currency: cart.cart.currency
    } : country) as PlatformCountry || undefined;
    return formatCurrency(cart.cart.total_value, c);
  }
  const handleShowSendEmail = (cart?: AbandonedCart) => {
    setShowSendEmailModal(!!cart);
    setSelectedCart(cart);
  }
  const handleSendRecoveryEmailClick = () => {
    if (!!selectedCart && !!accessToken) sendRecoveryEmail({
      cartId: selectedCart.cart_id,
      token: accessToken
    }).then(r => {
      if (r) {
        handleShowSendEmail();
        setData(d => {
          const allCarts = d;
          const cartIndex = d.findIndex(i => i.cart_id === selectedCart.cart_id);
          allCarts.splice(cartIndex, 1, {...data[cartIndex], send_email: true });
          return allCarts;
        })
      }
    });
  }
  const handleOpenWhatsappMessage = (cart: AbandonedCart) => {
    const message = t('abandonedCarts.contactMessage');
    const phone = (!!cart?.cart.customer_prefix_phone && !!cart?.cart.customer_phone) ? 
    cart?.cart.customer_prefix_phone + cart?.cart.customer_phone : '';
    goToWhatApp(phone, message);
  }

  useEffect(() => {
    if (!!accessToken) {
      fetchAbandonedCarts(accessToken).then(r => setData(r));
      logEvent('view_screen', { event_subtype: 'abandoned_cart_main', show_orders: isAvailable });
    }
  }, [accessToken]);

  return (
    <CartsListWrapper>
      {!isAvailable && (
        <div className="buy-addon">
          <img className="img" src={profileImage} alt="Store" />
          <div className="texts">
            <div className="title">{t('abandonedCarts.title')}</div>
            <div className="description">{t('abandonedCarts.description')}</div>
          </div>
          <UiButton
            className="buy"
            onClick={() => {
              dispatch(checkoutActions.setToBuyItem({ type: 'addon', addon_type_id: 'abandoned_cart' }));
              setTimeout(() => {
                navigate(`/${partner?.userId}/checkout`);
              }, 100);
              logEvent('system_expert_warning', {
                event_subtype: 'sumer_web_abandoned_cart',
                legacy_event: 'Home - Sumer Web',
              });
            }}>
            {t('abandonedCarts.acquire')}
          </UiButton>
        </div>
      )}
      {!!paginatedData.length ? (
        <>
          <div className="list-header">
            <div className="title">{t('abandonedCarts.title')}</div>
          </div>
          <table className={`list-items ${!isAvailable && 'blur'}`}>
            <tbody>
              <tr>
                <th className="head-cell">#</th>
                <th className="head-cell">{t('abandonedCarts.columns.date')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.client')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.email')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.products')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.total')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.state')}</th>
                <th className="head-cell">{t('abandonedCarts.columns.contact')}</th>
              </tr>

              {paginatedData.map(i => (
                <tr>
                  <td className="cell">
                    <span className="ellipsis">{i.cart_id}</span>
                  </td>
                  <td className="cell">{dayjs(i.created_at).format('DD/MM/YYYY')}</td>
                  <td className="cell">{i.cart.customer_name}</td>
                  <td className="cell">
                    <div className="ellipsis">{i.cart.customer_email}</div>
                  </td>
                  <td className="cell scroll">
                    {i.cart.products?.map(p => (
                      <img className="img" src={p.images[0]} alt="Product" />
                    ))}
                  </td>
                  <td className="cell">{formatPrice(i)}</td>
                  <td className="cell">
                    <div className={`badge ${i.recovered && 'recovered'}`}>
                      {i.recovered ? t('abandonedCarts.recover') : t('abandonedCarts.notRecover')}
                    </div>
                  </td>
                  <td className="cell">
                    {!i.send_email && 
                      <span className="action blue icon-email-outline" onClick={() => handleShowSendEmail(i)} />
                    }
                    <span className="action green icon-talk" onClick={() => handleOpenWhatsappMessage(i)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={page}
            totalPageCount={Math.ceil(data.length / perPage)}
            onPageChange={setPage}
          />
        </>
      ) : (
        <div className={`empty-list ${!isAvailable && 'blur'}`}>
          <div className="img">
            <span className="icon-cart-outline" />
          </div>
          <div className="title">{t('abandonedCarts.empty.title')}</div>
          <div className="description">{t('abandonedCarts.empty.description')}</div>
        </div>
      )}
      {showSendEmailModal && (
        <div className="modal">
          <div className="overlay" onClick={() => handleShowSendEmail()} />
          <SendEmailConfirmation
            onClose={() => handleShowSendEmail()}
            onButtonClick={handleSendRecoveryEmailClick}
          />
        </div>
      )}
    </CartsListWrapper>
  );
}
 
export default CartsList;

import { RootState } from "@dashboard/config/redux";
import { createSelector } from "@reduxjs/toolkit";

export const homeUiSelector = createSelector(
  (state: RootState) => state.home.ui,
  (ui) => ui
);

export const homeBannersSelector = createSelector(
  (state: RootState) => state.home.data.banners,
  (data) => data
);

export const homeStatisticsSelector = createSelector(
  (state: RootState) => state.home.data.statistics,
  (data) => data
);

export const homeProductsQuantitySelector = createSelector(
  (state: RootState) => state.home.data.productsQuantity,
  (data) => data
);

export const homeNewOrdersSelector = createSelector(
  (state: RootState) => state.home.data.newOrders,
  (data) => data
);

export const homeLearningsSelector = createSelector(
  (state: RootState) => state.home.data.learnings,
  (data) => data
);

export const homeFakeDoorsSelector = createSelector(
  (state: RootState) => state.home.data.fakeDoors,
  (data) => data
);

import dayjs from "dayjs";
import isTodayDayJs from "dayjs/plugin/isToday";
import utcDayJs from "dayjs/plugin/utc";
import {
  AiMessage,
  AiMessageGroup,
  AiMessagesGroup,
  AiMessagesGroupId,
} from "../types/messages.types";
import { Feedback } from "../types/feedbacks.types";
import { MESSAGE_WAITING_IDS } from "../constants/messages.constants";

export const groupMessages = (
  messages: AiMessage[],
  feedbacks: Feedback[]
): AiMessagesGroup[] => {
  dayjs.extend(isTodayDayJs);
  dayjs.extend(utcDayJs);
  const groups: { [key: string]: { [key: string]: AiMessageGroup } } = {};
  const groupsKeys: { [key: string]: string[] } = {};
  messages.forEach((message) => {
    const diferenceDays = dayjs()
      .hour(0)
      .diff(dayjs(message.createdAt).utc(true).local().hour(0), "days");

    const key: AiMessagesGroupId =
      diferenceDays <= 0
        ? "today"
        : diferenceDays === 1
        ? "yesterday"
        : dayjs(message.createdAt).utc(true).local().format("DD-MM-YYYY");

    const feedback = feedbacks.find(
      (item) => item.messageId === message.messageId
    );
    if (feedback) {
      message.approved = feedback.approvedResponse;
    }

    if (groups[key]) {
      if (groups[key][message.messageId]) {
        groups[key][message.messageId] = {
          ...message,
          messages: [
            ...(groups[key][message.messageId].messages || []),
            message.message || "",
          ],
        };
      } else {
        groups[key][message.messageId] = {
          ...message,
          messages: [message.message || ""],
        };
        groupsKeys[key].push(message.messageId);
      }
    } else {
      groups[key] = { [message.messageId]: message };
      groupsKeys[key] = [message.messageId];
    }
  });

  return Object.keys(groupsKeys).map((idGroup) => ({
    idGroup,
    messages: groupsKeys[idGroup].map((item) => groups[idGroup][item]),
  }));
};

export const getMessageWaiting = (message: string): AiMessage[] => {
  return [
    {
      messageId: MESSAGE_WAITING_IDS.user,
      message,
      createdAt: new Date(),
      role: "user",
    },
    {
      messageId: MESSAGE_WAITING_IDS.assistant,
      message: ". . .",
      createdAt: new Date(),
      role: "assistant",
    },
  ];
};

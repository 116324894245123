import { useTranslation } from "react-i18next";
import CustomSearchSelect from "@dashboard/components/custom-search-select/custom-search-select";
import CustomTextArea from "@dashboard/components/custom-textarea/custom-textarea";
import { FC, useCallback, useContext, useEffect } from "react";
import { useLocalStorage } from "@sumerlabs/component-library";
import { useAppDispatch } from "@dashboard/config/redux";
import { useFormikContext } from "formik";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { fetchCategoryAction } from "@dashboard/modules/products/redux";
import { CoreEvents } from "@dashboard/constants/events";
import GptGenerate from "../gpt-generate";
import { useProductGpt } from "@dashboard/modules/products/hooks/products-gpt.hook";
import ProductContext from "../../context/product.context";
import { Category } from "@dashboard/modules/products/types/products.types";
import { ProductCategoryDescriptionWrapper } from "./product-category-description.styled";
import { useLogicInformation } from "../product-information/product-information.hook";

type ProductCategoryDescriptionTypes = {
  setSectionsValid?: (values: { [key: string]: boolean }) => void;
};

const ProductCategoryDescription: FC<ProductCategoryDescriptionTypes> = ({
  setSectionsValid,
}) => {
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage("accessToken", null);
  const dispatch = useAppDispatch();
  const { setFieldValue, isSubmitting } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const { logEvent } = useAnalytics();
  const LIMIT_DESCRIPTION = 2000;
  const {
    categories,
    setCategories,
    categoriesBackup,
    setCategoriesBackup,
    subCategories,
    setSubCategories,
    getCategoryNames,
    searchFilterCategories,
    searchFilterSubCategories,
  } = useLogicInformation({ setSectionsValid });
  const { loading: loadingPredictProduct, predictProduct } = useProductGpt();
  const { showAiGenerator } = useContext(ProductContext);

  const handleClickGenerate = (field: string) => {
    logEvent(CoreEvents.ViewScreen, {
      event_subtype: PRODUCTS_EVENTS.IA_GENERATE,
      source: "add_product",
      option: field,
    });
  };

  const predictProductData = useCallback(() => {
    if (values.imagesForm && values.imagesForm.length > 0) {
      return predictProduct({
        name: values.name,
        justDescription: true,
        image: values.imagesForm[0].image,
      });
    }
  }, [values.imagesForm]);

  const predictDescriptionProduct = useCallback(() => {
    logEvent(CoreEvents.SelectButton, {
      event_subtype: PRODUCTS_EVENTS.IA_GENERATE,
      source: "add_product",
      option: "description",
    });
    return predictProductData()?.then((data) => {
      setFieldValue("description", data.description);
      return data;
    });
  }, [predictProductData]);

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchCategoryAction(accessToken)).then((res) => {
        setCurrentCategory(res.payload, values.category || "");
        setCategories(res.payload);
        setCategoriesBackup(res.payload);
      });
    }
  }, []);

  const setCurrentCategory = (data: Category[], category: string) => {
    const auxCategory = data.find((cat) => cat.id === category);
    setFieldValue("category", auxCategory?.name || category);
  };

  useEffect(() => {
    if (categoriesBackup.length) {
      searchFilterCategories(values.category || "");
    }
  }, [categoriesBackup]);

  useEffect(() => {
    setCategories(categoriesBackup);
    setSubCategories([]);
  }, [isSubmitting]);

  return (
    <>
      <ProductCategoryDescriptionWrapper>
        <CustomSearchSelect
          key={"search-category"}
          id="search-select-category"
          label={t("admin.products.side.productInfo.labelCategory")}
          data={getCategoryNames(categories)}
          placeholder={t(
            "admin.products.side.productInfo.selectPlaceholderCategory"
          )}
          value={values.category || ""}
          onChange={(itemSelected) => {
            logEvent(PRODUCTS_EVENTS.SELECT_DROPDOWN, {
              event_subtype: "product_category",
              source: `${values.id ? "edit" : "create"}`,
            });
            searchFilterCategories(itemSelected);
            setFieldValue("category", itemSelected);
          }}
        />
        <CustomSearchSelect
          key={"search-subcategory"}
          id="search-select-subcategory"
          label={t("admin.products.side.productInfo.labelSubCategory")}
          data={subCategories}
          placeholder={t(
            "admin.products.side.productInfo.selectPlaceholderSubCategory"
          )}
          value={values.subCategory}
          onChange={(itemSelected) => {
            logEvent(PRODUCTS_EVENTS.SELECT_DROPDOWN, {
              event_subtype: "product_subcategory",
              source: `${values.id ? "edit" : "create"}`,
            });
            searchFilterSubCategories(itemSelected);
            setFieldValue("subCategory", itemSelected);
          }}
        />
      </ProductCategoryDescriptionWrapper>
      <CustomTextArea
        id="input-description"
        label={
          <>
            {t("admin.products.side.productInfo.inputDescriptionTitle")}
            {showAiGenerator && values.imagesForm?.length !== 0 && (
              <GptGenerate
                fieldLabel={t("products.gptGenerate.fields.description.label")}
                descriptionCopy={t(
                  "products.gptGenerate.fields.description.description"
                )}
                helpCopy={t("products.gptGenerate.fields.description.help")}
                position="top"
                loading={loadingPredictProduct}
                onClickGenerate={() => handleClickGenerate("description")}
                onClickPredict={predictDescriptionProduct}
              />
            )}
          </>
        }
        cols={1}
        rows={4}
        value={values.description || ""}
        placeholder={t("admin.products.side.productInfo.inputDescription")}
        onChange={(text) => setFieldValue("description", text.target.value)}
        limit={LIMIT_DESCRIPTION}
        limitText={t("admin.products.side.productInfo.inputDescriptionLimit")}
        onFocus={() => {
          logEvent(PRODUCTS_EVENTS.SELECT_INPUT, {
            event_subtype: "product_description",
            source: `${values.id ? "edit" : "create"}`,
          });
        }}
      />
    </>
  );
};

export default ProductCategoryDescription;

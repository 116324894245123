import { FC, ReactNode } from "react";
import { Wrapper } from "./ui-modal.styled";

interface UiModalProps {
  show: boolean;
  children: ReactNode;
  dataTestId?: string;
  className?: string;
  onClose: () => void;
}
 
const UiModal: FC<UiModalProps> = ({ 
  show,
  dataTestId,
  onClose,
  className,
  children,
}) => {

  const handleClose = () => {
    onClose();
  }

  return (
    <Wrapper data-testid={dataTestId} className={`${show ? 'show' : 'hide'}`}>
      <div className="overlay" onClick={handleClose}/>
      <div className={`${className} modal-content`}>
        <div data-testid={`${dataTestId}-close-button`} className="modal-close" onClick={handleClose}>
          <span className="icon icon-close" />
        </div>
        {children}
      </div>
    </Wrapper>
  );
}
 
export default UiModal;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "@dashboard/config/redux/global.slice";
import { fetchCountries } from "@dashboard/services/countries.services";
import { countryInformationSelector, partnerSelector } from "@dashboard/config/redux";
import { fetchLastPremiumPlan } from "@dashboard/services/get-user-plan";
import { StorageItem } from "@dashboard/constants/storage-items";
import { fetchMenu } from "@dashboard/services/menu.services";

import { useTranslation } from "react-i18next";
import { fetchCustomPlanHooks } from "@dashboard/services/custom-plan-hooks.service";
import { fetchAllPlans } from "@dashboard/modules/shared/components/onboarding-shared/services/premium.services";
import { mapPlan } from "@dashboard/modules/plans/mappers/plans.mappers";
import { useUserAddons } from "./addons.hook";
import { fetchReviewsPlans } from "@dashboard/modules/plans/fetchers/review-plans.fectcher";


export const useGlobalData = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(partnerSelector);
  const country = useSelector(countryInformationSelector);
  const { getUserAddons } = useUserAddons();

  const getPlans = () => {
    const token = localStorage.getItem(StorageItem.Token)?.replaceAll("\"", '') || '';
    fetchAllPlans(country?.name || 'Colombia', '', token)
      .then(response => {
        const plans = response.map(mapPlan);
        dispatch(actions.setPlans(plans));
      })
      .catch();
  }

  const getMenu = () => {
    fetchMenu()
      .then(response => {
        dispatch(actions.setMenu(response));
      })
      .catch();
  };

  const getReviewsPlans = () => {
    fetchReviewsPlans()
      .then(response => {
        dispatch(actions.setReviewsPlans(response));
      })
      .catch();
  }

  const getCustomPlanHooks = () => {
    fetchCustomPlanHooks()
      .then(response => {
        dispatch(actions.setCustomPlanHooks(response));
      })
      .catch();
  }

  const getCountries = () => {
    fetchCountries(i18n.language)
      .then(response => {
        dispatch(actions.setCountries(response));
      })
      .catch();
  };

  const getUserPlan = () => {
    if (!!user) {
      const token = localStorage.getItem(StorageItem.Token)?.replaceAll("\"", '') || '';
      fetchLastPremiumPlan({ token })
        .then((plan) => {
          if (!!plan) {
            dispatch(actions.setPlan(plan));
            dispatch(actions.setAvailableBenefits(plan?.features || [{ feature_name: 'all' }]));
          }
        })
        .catch(console.error);
    }
  };

  const setCustomerId = () => {
    if (!!user) {
      (window as any).AF('pba', 'setCustomerUserId' , user?.userId);
    }
  };

  useEffect(() => {
    if (!!country && !!user) getPlans();
  }, [country, user]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getMenu();
    getCustomPlanHooks();
    getUserPlan();
    getUserAddons();
    setCustomerId();
    getReviewsPlans();
  }, [user]);
  
};
export enum UserRedirectSplitEnum {
  CUSTOMIZATION = "customization",
  PRODUCTS = "products",
  HOME = "home",
}

export enum AbOnboarding {
  AB_ONBOARDING_GUEST = "ab_onboarding_guest",
  AB_ONBOARDING_STORE_INFO = "ab_onboarding_store_info",
}

const locale = {
  name: "Mi cuenta",
  avatarSelector: {
    format: "Formato JPG o PNG.",
    size: "Tamaño 500x500 px",
  },
  planCard: {
    upgradePlan: "Mejora tu plan",
    viewPlans: "Explorar planes",
    viewBenefits: "Conoce tus beneficios",
    plan_free: "Plan Limitado",
    plan_legacy: "Plan Experto",
    plan_basic: "Plan Basic",
    plan_pro: "Plan Pro",
    plan_ultra: "Plan Ultra",
  },
  urlSumerFakeDoor: {
    title: "Personaliza el link de tu tienda",
    description: "Configura y adquiere el dominio para tu tienda",
  },
  businessSectors: {
    add: "Agregar sector",
  },
  networksSections: {
    title: "Redes sociales",
    form: {
      facebook: {
        label: "Facebook",
        placeholder: "facebook.com/",
        error: "",
      },
      instagram: {
        label: "Instagram",
        placeholder: "instagram.com/",
        error: "",
      },
      twitter: {
        label: "Twitter",
        placeholder: "twitter.com/",
        error: "",
      },
      tiktok: {
        label: "Tiktok",
        placeholder: "tiktok.com/",
        error: "",
      },
      customUrl: {
        label: "Otra",
        placeholder: "Agregar otra red social",
        error: "",
      },
      urlSummer: {
        label: "Link de tu tienda online",
        placeholder: "Link de tienda",
        error: "Debe agregar un link para su tienda",
      },
    },
  },
  addressesSections: {
    form: {
      address: {
        label: "Dirección",
        labelAlternative: "Dirección principal",
        placeholder: "Ingrese su dirección",
        error: "",
      },
      addressDetails: {
        label: "Detalle de la dirección",
        placeholder: "Apt 201, Oficina 305, Piso 2...",
        error: "",
      },
    },
    options: {
      new: "Agregar dirección",
      remove: "Eliminar",
    },
  },
  form: {
    economicSectors: {
      label: "Sector economico",
      placeholder:
        "Selecciona el sector económico de tu tienda. Puedes elegir hasta 3 sectores.",
      error: "Puedes tener máximo 3 sectores económicos seleccionados",
    },
    enterpriseName: {
      label: "Nombre del negocio",
      placeholder: "Agregue un nombre a su tienda",
      error: "Debe agregar un nombre de negocio",
    },
    description: {
      label: "Descripción de tu tienda (opcional)",
      placeholder:
        "Agregar una descripción a tu negocio incrementa la confianza de tus clientes al momento de comprar",
      error: "",
    },
    email: {
      label: "Correo de contacto de la tienda",
      placeholder: "correo@gmail.com",
      error: "Debe agregar un correo de contacto de la tienda",
    },
    phone: {
      label: "Número de contacto",
      placeholder: "3214567890",
      error: "Debe agregar un número de contacto de la tienda",
    },
    prefixPhone: {
      label: "País",
      placeholder: "País",
      error: "",
    },
    country: {
      label: "País",
      placeholder: "País",
      error: "",
    },
    city: {
      label: "Ubicación",
      placeholder: "Ubicación",
      error: "",
    },
  },
  options: {
    save: {
      label: "Guardar",
      placeholder: "Guardar",
      error: "",
    },
    next: {
      label: "Listo",
      placeholder: "Listo",
      error: "",
    },
  },
};

export default locale;

import { MobileViewStyles } from "./mobile-view.styled";
import { useTranslation } from "react-i18next";

export const MobileView = () => {
  const imageMobileView =
    "https://sumerlabs.com/prod/web/landing/sumer-app-mobile.webp";
  const { t } = useTranslation();
  return (
    <MobileViewStyles>
      <div className="mobile-container">
        <div className="logo-mobile">
          <img
            src="https://www.sumerlabs.com/prod/assets/web/images/sumer-logo-black.png"
            alt="Main logo"
            className="img"
          />
        </div>
        <div className="body-mobile">
          <div className="header-texts">
            <h1>{t("start")}</h1>
            <p>{t("access")}</p>
          </div>
          <div className="icons">
            <span className={"icon icon-android"} />
            <span className={"icon icon-apple"} />
          </div>
          <a
            href="https://link.sumerlabs.com/sDH5/download"
            target="_blank"
            rel="noreferrer"
          >
            <button className="button-mobile-view" onClick={() => {}}>
              <span className="button-text">{t("downloadApp")}</span>
            </button>
          </a>
        </div>
      </div>
      <div className="mobile-image-container">
        <img src={imageMobileView} alt="sumer-app-download" />
      </div>
    </MobileViewStyles>
  );
};

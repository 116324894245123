import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./home.state";

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.ui.isLoading = action.payload;
    },
    setShowInConstruction: (state, action) => {
      state.ui.showInConstruction = action.payload;
    },
    setShowUltraHook: (state, action) => {
      state.ui.showUltraHook = action.payload;
    },
    setBanners: (state, action) => {
      state.data.banners = action.payload;
    },
    setLearnings: (state, action) => {
      state.data.learnings = action.payload;
    },
    setFakeDoors: (state, action) => {
      state.data.fakeDoors = action.payload;
    },
    setStatisticsFilter: (state, action) => {
      state.ui.statisticsFilter = action.payload;
    },
    setStatistics: (state, action) => {
      state.data.statistics = action.payload;
    },
    setProductsQuantity: (state, action) => {
      state.data.productsQuantity = action.payload;
    },
    setNewOrders: (state, action) => {
      state.data.newOrders = action.payload;
    },
  },
});

export const homeActions = homeSlice.actions;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLogicSupplementaryContent } from "./supplementary-content.hook";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import BookOpen from "@sumerkit/icons/book-open";

const SupplementaryContent: FC = () => {
  const { t } = useTranslation();
  const { openHookTool } = useLogicSupplementaryContent();

  return (
    <CollapseContainerWrapper>
      <div className="info" onClick={openHookTool}>
        <figure className="icons">
          <BookOpen width={27} height={27}/>
        </figure>
        <div className="description">
          <strong className="title">
            {t("admin.products.side.supplementaryContent.section.title")}
          </strong>
          <small className="subtitle">
            {t("admin.products.side.supplementaryContent.section.description")}
          </small>
        </div>
        <div className="arrowContainer">
          <figure className="icon-arrow-right"></figure>
        </div>
      </div>
    </CollapseContainerWrapper>
  );
};

export default SupplementaryContent;

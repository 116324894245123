import { useAnalytics } from "@dashboard/common/analytics";
import { actions, useAppDispatch } from "@dashboard/config/redux";
import { useMemo, useRef } from "react";
import { useLocalStorage } from "@sumerlabs/component-library";
import { CoreEvents } from "@dashboard/constants/events";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import { EVENTS } from "@dashboard/constants/events";
import { useProducts } from "@dashboard/modules/products/hooks/products.hook";
import { PlanBenefit } from "@dashboard/entities/plan-benefit";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";
import {
  categorySelector,
  uploadImagesAction,
  createUniqueProductAction,
  productsActions,
} from "@dashboard/modules/products/redux";
import {
  ProductType,
  CreateProductPayload,
  UpdateProductPayload,
  Product,
} from "@dashboard/modules/products/types/products.types";
import {
  FormProps,
  ImageProductForm,
} from "@dashboard/modules/products/types/product-form.types";
import { useNavigate } from "react-router-dom";

export const useProduct = () => {
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage("accessToken", null);
  const { logEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const categoriesData = useSelector(categorySelector);
  const {
    isValid,
    setFieldTouched,
    submitForm,
    resetForm,
    setFieldValue,
    setSubmitting,
  } = useFormikContext() ?? {};
  const { values }: { values: FormProps } = useFormikContext() ?? {};
  const {
    products,
    createProductsFeature,
    handleUpdateProduct: handleUpdateProductFn,
  } = useProducts();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const DEFAULTIMAGE = "https://sumerlabs.com/prod/sumerweb/products/default-product-image.png";
  const DEFAULTIMAGES3 = "https://sumer-s3-database.s3.us-west-2.amazonaws.com/prod/sumerweb/products/default-product-image.png";

  const productTitleRef = useRef<HTMLDivElement>(null);
  const scrollToProductTitle = () => {
    productTitleRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const maxProductsCreate = useMemo(() => {
    return createProductsFeature
      ? (createProductsFeature as PlanBenefit).conditions?.max_catalogue_size ||
          0
      : 0;
  }, [createProductsFeature]);

  const handleSaveProduct = () => {
    if (!isValid) {
      setIsLoading(false);
      scrollToProductTitle();
    }
    if (values?.id) {
      handleUpdateProduct();
    } else {
      handleCreateProduct();
    }
  };

  const handleCreateProductDuplicate = (product: Product) => {
    if (product) {
      handleCreateProduct(product);
    }
  };

  const validateCountVariations = (): boolean => {
    const aux = values.productToppingVariations ?? [];
    let isModified = aux.length === 0;
    if (aux) {
      aux.forEach((topping) => {
        if (topping.inventory?.units !== 1) {
          isModified = true;
        }
      });
    }
    return isModified;
  };

  const handleCreateProduct = async (product?: Product) => {
    setFieldTouched("name");
    setFieldTouched("price");
    if (isValid) {
      if (products.length < maxProductsCreate) {
        setIsLoading(true);
        submitForm();
        setSubmitting(true);
        const productToCreate: FormProps = cleanToppings(
          product ? { ...product } : { ...values }
        );
        const categoryAux = setCategory(productToCreate.category);
        try {
          let images: string[] | undefined = [];
          if (product) {
            const imgForm = await createFileByUrl(
              productToCreate.imagesForm || []
            );
            images = await uploadImagesToS3({
              ...productToCreate,
              imagesForm: imgForm,
            });
          } else {
            images = await uploadImagesToS3(productToCreate);
          }
          createNewProduct({
            ...productToCreate,
            type: ProductType.Product,
            category: categoryAux || null,
            images: images || [],
            imagesDetail: images?.map((image) => ({ url: image })),
          });
        } catch (error) {
          setIsLoading(false);
          toast.error(t("admin.products.side.formAlert.error"));
        }
      } else {
        handleShowMaxProductsHook();
      }
    }
  };

  const uploadImagesToS3 = async (productToCreate: FormProps) => {
    return productToCreate.imagesForm?.length
      ? await uploadFile(
          (productToCreate.imagesForm || []).map((item) => item.image as File)
        )
      : [
          DEFAULTIMAGE,
        ];
  };
  const createFileByUrl = async (
    imagesForm: ImageProductForm[]
  ): Promise<ImageProductForm[]> => {
    let auxProductToCreate: ImageProductForm[] = [];
    let fetchingData = await Promise.all(
      imagesForm?.map(async (item) => {
        const imgUrl = (item.image as string).includes("default-product-image")
          ? DEFAULTIMAGES3
          : (item.image as string);
        return fetch(imgUrl)
          .then((response) => response.blob())
          .then((data) => {
            const imageName = (item.image as string).split("/").pop();
            const imageExtension = imageName?.split(".").pop();
            return new File([data], imageName || "duplicate-sumer-image.jpeg", {
              type: `image/${imageExtension ? imageExtension : "jpeg"}`,
            });
          });
      }) || []
    );
    fetchingData.forEach((file, index) => {
      auxProductToCreate.push({
        id: index,
        image: file,
        type: "file",
      });
    });
    return auxProductToCreate;
  };

  const handleShowMaxProductsHook = () => {
    dispatch(actions.showCustomPlanHook(FeatureProducts.CreateProducts));
    logEvent(CoreEvents.SystemExpertWarning, {
      event_subtype: EVENTS.WEB_SUMER_WEB_SELECT_ADD_PRODUCT,
      source: "hook",
    });
  };

  const handleUpdateProduct = async () => {
    if (isValid) {
      setIsLoading(true);
      submitForm();
      const productToEdit: FormProps = cleanToppings({ ...values });
      const categoryAux = setCategory(productToEdit.category);
      try {
        const imagesUpload = (productToEdit.imagesForm || []).filter(
          (item) => item.type === "file"
        );
        const newImages = imagesUpload.length
          ? (await uploadFile(
              imagesUpload.map((item) => item.image) as File[]
            )) || []
          : [];
        newImages.forEach((imageUrl, index) => {
          imagesUpload[index].image = imageUrl;
          imagesUpload[index].type = "url";
        });
        const images = (productToEdit.imagesForm || []).map((image) => {
          const isNewImage = imagesUpload.find(
            (newImage) => newImage.id === image.id
          );
          return (isNewImage ? isNewImage.image : image.image) as string;
        });
        updateProduct({
          ...productToEdit,
          type: ProductType.Product,
          category: categoryAux || null,
          images: images.length
            ? images
            : [
                DEFAULTIMAGE,
              ],
          imagesDetail: images.map((image) => ({ url: image })),
        });
      } catch (error) {
        setIsLoading(false);
        toast.error(t("admin.products.side.formAlert.error"));
      }
    }
  };

  const cleanToppings = (product: FormProps): FormProps => {
    if (product.toppings) {
      product.toppings = product.toppings.filter(
        (topping) => topping.id !== "new"
      );
    }
    return product;
  };

  const uploadFile = async (files: File[]) => {
    if (accessToken) {
      return await dispatch(
        uploadImagesAction({
          token: accessToken,
          files: files.map((file, index) => {
            return {
              name: `image_${index}`,
              file,
            };
          }),
        })
      )
        .unwrap()
        .then((response) => {
          return Object.values(response) as string[];
        });
    }
  };

  const createNewProduct = async (product: CreateProductPayload) => {
    if (accessToken) {
      logEvent(EVENTS.WEB_SUMER_WEB_SELECT_ADD_PRODUCT);
      dispatch(
        createUniqueProductAction({
          token: accessToken,
          payload: { product },
        })
      )
        .then((r) => {
          resetForm();
          setFieldValue("name", "");
          setSubmitting(false);
          if (r.payload.code === "validation.request") {
            toast.error(t("admin.products.side.formAlert.error"));
            return;
          }
          if (r.payload.code === "prime.membership_not_active") {
            dispatch(productsActions.setShowNotAvailableHook(true));
            handleShowMaxProductsHook();
            return;
          }
          navigate("../../products");
          toast.success(t("admin.products.side.formAlert.success"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateProduct = (product: UpdateProductPayload) => {
    if (accessToken) {
      handleUpdateProductFn(product)
        ?.then(() => {
          navigate("../../products");
          toast.success(t("admin.products.side.formAlert.success"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const setCategory = (category: string | null): string | null => {
    let categoryFound = categoriesData.find(
      (cat) => cat.name === category && cat.isUserCategory
    );
    return categoryFound?.id || category;
  };

  return {
    isLoading,
    setIsLoading,
    handleSaveProduct,
    validateCountVariations,
    productTitleRef,
    scrollToProductTitle,
    handleCreateProductDuplicate,
  };
};

import styled from "styled-components";

export const FilterModalContentWrapper = styled.div`
  .header {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    background: var(
      --Linear,
      linear-gradient(
        124deg,
        rgba(0, 0, 0, 0.9) 40.19%,
        rgba(12, 18, 32, 0.9) 53.5%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .body {
    .variation {
      display: grid;
      height: fit-content;
        margin-top: 16px;
      .name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        background: var(
          --Linear,
          linear-gradient(
            124deg,
            rgba(0, 0, 0, 0.9) 40.19%,
            rgba(12, 18, 32, 0.9) 53.5%
          )
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .values {
        display: block;
        margin-top: 12px;
        .item {
          display: flex;
          padding: 12px;
          margin: 5px;
          justify-content: center;
          align-items: flex-start;
          gap: 10px;
          border-radius: 8px;
          border: 2px solid var(--grey-border, #e9e9ed);
          background: var(--grey-w, #fff);
          float: left;
          &.isFiltered {
            background: var(--grey-b, #24272E);
            color: white;
          }
          :hover {
            cursor: pointer;
          }
        }
      }
    }
    .controls {
      display: grid;
      margin-top: 16px;
      padding: 16px 16px 24px 16px;
      gap: 16px;
      align-self: stretch;
      grid-template-columns: auto auto;
      .link {
        display: flex;
        width: 100%;
        padding: 0px 14px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        color: var(--Grey-B, #24272E);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const ModalWrapper = styled.div`
  .modal {
    display: flex;
    width: 520px;
    padding: 32px 24px;
    flex-direction: column;
    border-radius: 16px;
    background: var(--grey-w, #fff);
    box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
      2px 4px 10px 0px rgba(53, 53, 53, 0.04);
  }
`;

import { http } from "@dashboard/common/http";
import { getEnvVar } from "@dashboard/common/utils";
import { PlansCtaBanner } from "@dashboard/entities/plans-cta-banner";

export const fetchPlansCtaBanner = async (): Promise<PlansCtaBanner | undefined> => {
  const response = await http.get("/api/ms/remote-config/country-data/sumerweb_bottom_plans_banner", {
    headers: {
      'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
    },
  });
  return response.data.value;
};
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { useFormikContext } from "formik";
import { FC } from "react";
import {
  FeatureOption,
  FeaturesOptionsWrapper,
} from "./features-options.styled";
import TrashSimple from "@sumerkit/icons/trash-simple";
import ArrowUp from "@sumerkit/icons/arrow-up";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";

const FeaturesOptions: FC = () => {
  const { values }: { values: FormProps } = useFormikContext();
  const { setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();

  const removeTooping = (index: number) => {
    let aux = [...(values.toppings || [])];
    aux.splice(index, 1);
    setFieldValue("toppings", aux);
  };

  return (
    <FeaturesOptionsWrapper>
      {values.toppings?.map((tooping, i) => (
        <FeatureOption key={`tooping-option-${i}`}>
          <div className="option-content">
            <div className="title">{tooping.name}</div>
            <div className="options">
              {tooping.values.map((option, j) => (
                <div
                  key={`option-${j}`}
                  className="option"
                  onClick={() => {
                    logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
                      event_subtype: PRODUCTS_EVENTS.PRODUCT_VARIATION_DETAIL,
                    });
                    navigate("stock");
                  }}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
          <TrashSimple
            width={20}
            height={20}
            className="trash-simple-icon"
            onClick={() => removeTooping(i)}
          />
          <ArrowUp
            width={20}
            height={20}
            className="arrow-right-icon"
            onClick={() => navigate("stock")}
          />
        </FeatureOption>
      ))}
    </FeaturesOptionsWrapper>
  );
};

export default FeaturesOptions;

import { getApiUrl, getEnvVar } from "@dashboard/common/utils";
import { camelizeKeys, decamelizeKeys } from "humps";
import { PartnerNetwork } from "@dashboard/common/types";
import {
  UserRedirectResponse,
  UserRedirectSplit,
} from "../entities/split.entities";
import { UserRedirectSplitEnum } from "../constants/split.constants";
import {
  BusinessSector,
  OnboardingData,
  SectorIaResponse,
  SectorPredict,
  StatusOnboarding,
  StatusOnboardingReq,
} from "../entities/onboarding.entities";
import { http } from "@dashboard/common/http";
import { Configuration } from "../entities/configuration.entities";

export const fetchEconomicSectors = async (
  locales: string
): Promise<BusinessSector[]> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/remote-config/country-data/business_sectors`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "api-key-sumer": getEnvVar("REACT_APP_KEY_SP"),
        "Accept-Language": locales || "es",
      },
    }
  );

  if (response.status !== 200) return [];

  const data = await response.json();
  const sectors = data.value.business_sectors
    .sort((a: BusinessSector, b: BusinessSector) => a.order - b.order)
    .slice(0, 9);

  return sectors.map((b: BusinessSector) => {
    const translatedName = data.translations?.[b.id] || b.name;
    return { ...b, name: translatedName };
  });
};

export const fetchUserDetail = async (
  token: string
): Promise<OnboardingData> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/user/user-detail`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as any,
    }
  );
  if (response.status === 200) {
    const responseJson = await response.json();
    return camelizeKeys(responseJson.user_detail);
  } else return {};
};

export const updateUserDetail = async (
  token: string,
  data: OnboardingData
): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/user/user-detail`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
      body: JSON.stringify(decamelizeKeys(data)),
    }
  );
  if (response.status === 200) {
    const responseJson = await response.json();
    return responseJson;
  } else return null;
};

export const updateUserNetwork = async (
  token: string,
  data: PartnerNetwork
): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/ms/user/user-network`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      } as { [key: string]: string },
      body: JSON.stringify(decamelizeKeys(data)),
    }
  );
  if (response.status === 200) {
    const responseJson = await response.json();
    return responseJson;
  } else return null;
};

export const fetchSplitUsers = async (
  data: UserRedirectSplit
): Promise<UserRedirectResponse> => {
  const response = await fetch(`${getApiUrl()}/api/ms/ab-proxy/treatment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "api-key-sumer": getEnvVar("REACT_APP_KEY_SP"),
    },
    body: JSON.stringify(decamelizeKeys(data)),
  });
  if (response.status !== 200)
    return {
      treatment: "off",
      config: {
        productPath: UserRedirectSplitEnum.HOME,
      },
    };

  const dataResponse = await response.json();
  return camelizeKeys(dataResponse) as UserRedirectResponse;
};

export const fetchEconomicSectorIa = async (
  userPrompt: string
): Promise<SectorIaResponse | undefined> => {
  try {
    const response = await http.post(
      "/api/ms/product-categorizer/onboarding-ai/predict",
      {
        merchant_prompt: userPrompt,
      },
      {
        headers: {
          "api-key-sumer": getEnvVar("REACT_APP_KEY"),
        },
      }
    );
    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    return undefined;
  }
};

export const fetchEconomicSectorPredict = async (
  userPrompt: string,
  token: string
): Promise<SectorPredict | undefined> => {
  try {
    const response = await http.post(
      "/api/ms/product-categorizer/economic-sector/predict-onboarding",
      {
        merchant_prompt: userPrompt,
      },
      {
        headers: {
          "api-key-sumer": getEnvVar("REACT_APP_KEY"),
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.status === 200 ? response.data : undefined;
  } catch (error) {
    return undefined;
  }
};

export const setOnboardingStatus = async (
  data: StatusOnboardingReq
): Promise<{}> => {
  const response = await fetch(
    `${
      getEnvVar("REACT_APP_ENV") === "local"
        ? getEnvVar("REACT_APP_LAMBDA_CHECK_ONBOARDING_STEPS")
        : ""
    }/default/s3-partition-prod`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": getEnvVar("REACT_APP_KEY_ONBOARDING_STEPS"),
      },
      body: JSON.stringify(decamelizeKeys(data)),
    }
  );
  const dataResponse = await response.json();
  return dataResponse;
};

export const getOnboardingStatus = async (data: {
  schema_partition: string;
}): Promise<Array<StatusOnboarding>> => {
  const response = await fetch(
    `${
      getEnvVar("REACT_APP_ENV") === "local"
        ? getEnvVar("REACT_APP_LAMBDA_CHECK_ONBOARDING_STEPS")
        : ""
    }/default/s3-partition-prod`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": getEnvVar("REACT_APP_KEY_ONBOARDING_STEPS"),
      },
      body: JSON.stringify(decamelizeKeys(data)),
    }
  );
  const dataResponse = await response.json();
  return dataResponse;
};

export const fetchConfigurationsPersonalization = async (token: string): Promise<Array<Configuration>> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/personalization/configurations`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "api-key-sumer": getEnvVar("REACT_APP_KEY_SP")
      },
    }
  );
  if (response.status !== 200) return [];
  const dataResponse = await response.json();
  return dataResponse && camelizeKeys(dataResponse);
};

export enum Feature {
  All = "all",
  Statistics = "web_statistics_home",
  Orders = "web_orders",
  AbandonedCart = "abandoned_cart",
  Products = "web_products",
  ProductImages = "image_product_creation",
  ProductWholesale = "product_wholesale",
  ToppingCreation = "topping_creation",
  StockConfiguration = "stock_configuration",
  ContactButton = "floating_button",
  Voucher = "voucher_addon",
  Domain = "custom_domain",
  Customization = "customization",
  GatewayActivate = "gateway_activate",
  GoogleShopping = "gg_shopping"
};

const locale = {
  name: "My account",
  avatarSelector: {
    format: "JPG or PNG format.",
    size: "Size 500x500 px",
  },
  planCard: {
    upgradePlan: "Upgrade your plan",
    viewPlans: "Explore plans",
    viewBenefits: "Learn about your benefits",
    plan_free: "Limited Plan",
    plan_legacy: "Expert Plan",
    plan_basic: "Basic Plan",
    plan_pro: "Pro Plan",
    plan_ultra: "Ultra Plan",
  },
  urlSumerFakeDoor: {
    title: "Customize your store link",
    description: "Set up and purchase the domain for your store",
  },
  businessSectors: {
    add: "Add sector",
  },
  networksSections: {
    title: "Social networks",
    form: {
      Facebook: {
        label: "Facebook",
        placeholder: "facebook.com/",
        error: "",
      },
      instagram: {
        label: "Instagram",
        placeholder: "instagram.com/",
        error: "",
      },
      twitter: {
        label: "Twitter",
        placeholder: "twitter.com/",
        error: "",
      },
      tiktok: {
        label: "Tiktok",
        placeholder: "tiktok.com/",
        error: "",
      },
      customUrl: {
        label: "Other",
        placeholder: "Add another social network",
        error: "",
      },
      urlSummer: {
        label: "Link to your online store",
        placeholder: "Store link",
        error: "You must add a link for your store",
      },
    },
  },
  addressesSections: {
    form: {
      address: {
        label: "Address",
        labelAlternative: "Principal address",
        placeholder: "Enter your address",
        error: "",
      },
      addressDetails: {
        label: "Address detail",
        placeholder: "Apt 201, Office 305, Floor 2...",
        error: "",
      },
    },
    options: {
      new: "Add address",
      remove: "Remove",
    }
  },
  form: {
    economicSectors: {
      label: "Economic sector",
      placeholder:
        "Select the economic sector of your store. You can choose up to 3 sectors.",
      error: "You can have a maximum of 3 economic sectors selected",
    },
    enterpriseName: {
      label: "Business name",
      placeholder: "Add a name to your store",
      error: "You must add a business name",
    },
    description: {
      label: "Description of your store (optional)",
      placeholder:
        "Adding a description to your business increases your customers' confidence when purchasing",
      error: "",
    },
    email: {
      label: "Store contact email",
      placeholder: "email@gmail.com",
      error: "You must add a store contact email",
    },
    phone: {
      label: "Contact number",
      placeholder: "3214567890",
      error: "You must add a store contact number",
    },
    prefixPhone: {
      label: "Country",
      placeholder: "Country",
      error: "",
    },
    country: {
      label: "Country",
      placeholder: "Country",
      error: "",
    },
    city: {
      label: "Location",
      placeholder: "Location",
      error: "",
    },
  },
  options: {
    save: {
      label: "Save",
      placeholder: "Save",
      error: "",
    },
    next: {
      label: "Next",
      placeholder: "Next",
      error: "",
    },
  },
};

export default locale;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  gap: 4;
  width: 100%;
  small {
    color: #646464;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
  }
  .hasError {
    color: #f93c00;
  }
`;

export const InputWrapper = styled.div`
  border: none;
  background: #fff;
  border: 1px solid #E9E9ED;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  height: 56px;
  position: relative;
  transition: border-color 0.3s;

  width: 100%;
  > input {
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    caret-color: #24272E;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    outline: none;
    padding: 0 16px;
    width: 100%;
    :focus {
      border: 1px solid #24272E;
    }
    &.hasError::placeholder {
      color: #f93c00;
    }
    &.disabled {
      background: #E9E9ED;
    }
  }
  .hasError {
    border: 1px solid #f93c00;
  }
`;

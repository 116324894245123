import { useTranslation } from "react-i18next";
import CustomSwitch from "@dashboard/components/custom-switch/custom-switch";
import CustomCounter from "@dashboard/components/custom-counter/custom-counter";
import { FC, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { Feature } from "@dashboard/constants/features";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { useAnalytics } from "@dashboard/common/analytics";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import { actions, planSelector, useAppDispatch } from "@dashboard/config/redux";
import { useSelector } from "react-redux";
import { FormProps } from "@dashboard/modules/products/types/product-form.types";
import { CoreEvents } from "@dashboard/constants/events";
import { Plan } from "@dashboard/constants/plan";
import { InventoryType } from "@dashboard/modules/products/types/products.types";
import { CollapseContainerWrapper } from "../../shared/styled/collapse-container.styled";
import NumberSquareFive from "@sumerkit/icons/number-square-five";
import { StockAlert } from "./product-stock.styled";
import CaretRight from "@sumerkit/icons/caret-right";
import { useNavigate } from "react-router-dom";
import CustomAlert from "@dashboard/components/custom-alert/custom-alert";

const ProductStock: FC = () => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { values }: { values: FormProps } = useFormikContext();
  const [showStock, setShowStock] = useState(
    values.inventory?.units ? true : false
  );
  const { isAvailable } = useFeatureAvailability(Feature.StockConfiguration);
  const { logEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const plan = useSelector(planSelector);
  const hasVariations = useMemo(() => {
    return values.toppings && values.toppings.length ? true : false;
  }, [values.toppings]);
  const navigate = useNavigate();

  const checkIsAvailable = () => {
    if (!isAvailable) {
      dispatch(actions.showCustomPlanHook(Feature.StockConfiguration));
      logEvent(CoreEvents.SystemExpertWarning, {
        event_subtype: "stock_configuration",
        source: "hook",
        method: plan?.benefit_plan_name === Plan.FREE ? "free" : "upgrade",
      });
      logEvent(PRODUCTS_EVENTS.SYSTEM_EXPERT_WARNING, {
        event_subtype: "product_inventory",
        source: `${values.id ? "edit" : "create"}`,
        option: "sale_on_stock",
        membership_type: plan?.benefit_plan_name ?? "",
      });
    }
  };

  useEffect(() => {
    if (values.toppings && values.toppings.length !== 0) {
      setFieldValue("inventory.units", 0);
      setFieldValue("inventory.type", InventoryType.ALWAYS_AVAILABLE);
    }
  }, [values.productToppingVariations, values.toppings]);

  return (
    <>
      <CollapseContainerWrapper>
        <div className="info">
          <figure className="icons">
            <NumberSquareFive width={27} height={27} />
          </figure>
          <div className="description">
            <strong className="title">
              {t("admin.products.side.inventory.stock.title")}
            </strong>
            <small className="subtitle">
              {hasVariations
                ? t(
                    "admin.products.side.inventory.stock.subTitleWithVariations"
                  )
                : t("admin.products.side.inventory.stock.subTitle")}
            </small>
          </div>
          <div className="switchContainer" onClick={() => checkIsAvailable()}>
            {hasVariations ? (
              <CaretRight
                className="caretRight"
                width={20}
                height={20}
                onClick={() => navigate("stock")}
              />
            ) : (
              <CustomSwitch
                disabled={!isAvailable}
                isActive={showStock}
                onChange={(state) => {
                  logEvent(PRODUCTS_EVENTS.SELECT_TOGGLE, {
                    event_subtype: "sale_on_stock",
                    source: `${values.id ? "edit" : "create"}`,
                    option: `${state ? "on" : "off"}`,
                  });
                  if (state) {
                    setFieldValue("inventory.units", 1);
                    setFieldValue("inventory.type", InventoryType.LIMITED);
                  } else {
                    setFieldValue(
                      "inventory.type",
                      InventoryType.ALWAYS_AVAILABLE
                    );
                  }
                  setShowStock(state);
                }}
              />
            )}
          </div>
        </div>
        {showStock && !hasVariations && (
          <div className="available">
            <small>
              {t("admin.products.side.inventory.stock.availableUnits")}
            </small>
            <CustomCounter
              value={values.inventory?.units}
              onChange={(count) => {
                setFieldValue("inventory.units", count);
              }}
              onClick={(source) => {
                logEvent(PRODUCTS_EVENTS.SELECT_BUTTON, {
                  event_subtype: PRODUCTS_EVENTS.PRODUCT_INVENTORY_QUANTITY,
                  option: `${source}`,
                });
              }}
            />
          </div>
        )}
      </CollapseContainerWrapper>
      {!showStock && !hasVariations && (
        <StockAlert>
          <CustomAlert
            className="custom-alert"
            description={t("admin.products.side.inventory.stock.alert")}
          ></CustomAlert>
        </StockAlert>
      )}
    </>
  );
};

export default ProductStock;

import styled from "styled-components";

export const AddonHookWrapper = styled.div`
  .modal {
    width: 520px;
    display: flex;
    flex-direction: column;
    background-color: #24272e !important;
    .img {
      width: 100%;
    }
		.forPrice {
      font-size: 24px;
      font-weight: 400;
      line-height: 31px;
      text-align: center;
			margin: 24px 24px 0 24px;
      color: var(--grey-w, #FFF);
		}
		.ultra {
			margin: 4px 24px 12px 24px;
		}
    .ultra {
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			text-align: center;
			color: var(--grey-w, #fff);
			strong {
				color: #a5b5f1;
				font-size: 26px;
				font-weight: 600;
				line-height: 33px;
			}
		}
		.or {
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			text-align: center;
			color: var(--grey-5, #9b9eab);
		}
		.description {
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			margin: 0 24px 40px 24px;
			color: var(--grey-w, #fff);
		}
		.actionAcquire {
			font-size: 16px;
			cursor: pointer;
			line-height: 24px;
			text-align: center;
			margin: 0 16px 16px 16px;
			color: var(--grey-w, #fff);
			text-decoration-line: underline;
		}
		.actionUltra {
			display: flex;
			cursor: pointer;
			height: 50px;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			border-radius: 8px;
			align-items: center;
			justify-content: center;
			margin: 0 40px 40px 40px;
			background-color: #ffffff;
			color: var(--grey-b, #24272e);
		}
  }
`;

export const voucher = {
    list_title: 'Payment voucher',
    list_cta: 'Create new voucher',
    table: {
      detail: 'Voucher detail',
      amount: 'Amount',
      date: 'Creation date',
      voucher: 'Voucher',
    },
    list_empty_title: 'Create your first payment voucher',
    list_empty_description: 'Send professional payment vouchers to easily collect payments from your clients.',
    list_empty_cta: 'Create new voucher',
    right_modal_title: 'Invoice preview',
    preview: {
      store_name_not_fill: 'Store name',
      voucher_number: 'Voucher number:',
      voucher_date: 'Date:',
      client_info: 'Client information:',
      client_name: 'Client name',
      client_email: 'Client email',
      client_country: 'Country',
      client_city: 'City',
      client_phone: 'Phone number',
      product_units: 'Units:',
      payment_details: 'Payment details:',
      payment_subtotal: 'Subtotal',
      payment_discount: 'Discount',
      payment_total: 'Total',
      created_with: 'Created with',
      not_fill: 'Not filled',
    },
    configuration: {
      back: 'Back',
      save: 'Save',
      titles: {
        main: 'Create voucher',
        date: 'Payment date',
        client: 'Client information',
        details: 'Order details',
        discounts: 'Discounts',
      },
      descriptions: {
        main: 'Configure your payment voucher to suit your needs',
        date: 'Select the payment date you want to record on the voucher',
        client: 'Fill in the client information that will appear on the voucher',
        details: 'Add the products related to this payment voucher',
        discounts: 'Activate this option and specify the promotion applied to this purchase. Verify the final price on the invoice.',
      },
      options: {
        date: 'Payment date',
        client: 'Client information',
        details: 'Order details',
        discounts: 'Discounts',
      },
      client_info: {
        name_placeholder: 'Client name',
        email_placeholder: 'Client email',
        country_placeholder: 'Country',
        city_placeholder: 'City',
        phone_placeholder: 'Phone number',
        not_show: 'Do not show client information',
      },
      products_detail: {
        title: 'Add products',
        search_placeholder: 'Search products',
        empty: 'No products found',
        not_show_images: 'Do not show product images',
      },
      discounts: {
        shouldApply: 'Apply a discount',
        input_placeholder: 'Enter the discount value',
      }
    },
    delete_modal: {
      title: 'Delete voucher?',
      description: 'The voucher and the configured details will disappear and cannot be recovered.',
      cancel: 'No, go back',
      delete: 'Delete',
    }
  };

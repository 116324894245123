import { FC, useEffect, useRef, useState } from "react";
import { AiChatMessagesStyled } from "./chat-messages.styled";
import AiChatMessage from "../chat-message/chat-message";
import { AiMessage, AiMessagesGroup } from "../../types/messages.types";
import { groupMessages } from "../../utils/messages.utils";
import { useTranslation } from "react-i18next";
import { Feedback } from "../../types/feedbacks.types";
import { PartnerData } from "@dashboard/common/types";

type AiChatMessagesProps = {
  messages?: AiMessage[];
  feedbacks?: Feedback[];
  partner?: PartnerData | null;
  messageRegenerating?: string;
  className?: string;
  onMessageApproved?: (message: AiMessage, approved: boolean) => void;
  onMessageRegenerate?: (message: AiMessage, inputMessageId?: string) => void;
};

const AiChatMessages: FC<AiChatMessagesProps> = ({
  messages,
  feedbacks = [],
  messageRegenerating,
  partner,
  className,
  onMessageApproved,
  onMessageRegenerate,
}) => {
  const { t } = useTranslation();
  const lastElementRef = useRef<HTMLLIElement>(null);
  const [localMessages, setLocalMessages] = useState<AiMessagesGroup[]>([]);

  const handleMessageApproved = (message: AiMessage, approved: boolean) => {
    onMessageApproved && onMessageApproved(message, approved);
  };

  const handleMessageRegenerate = (
    message: AiMessage,
    inputMessageId?: string
  ) => {
    onMessageRegenerate && onMessageRegenerate(message, inputMessageId);
  };

  useEffect(() => {
    setLocalMessages(groupMessages(messages || [], feedbacks));
  }, [messages, feedbacks]);

  useEffect(() => {
    setTimeout(() => {
      if (lastElementRef.current) {
        lastElementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, [messages, lastElementRef]);

  return (
    <AiChatMessagesStyled className={className || ""}>
      {localMessages.map((group, groupIndex) => (
        <div
          key={`${group.idGroup}-${groupIndex}`}
          className="chat-messages-group"
        >
          <div className="messages-group-title">
            {group.idGroup === "today" || group.idGroup === "yesterday"
              ? t(`aiAssistant.dates.${group.idGroup}`)
              : group.idGroup}
          </div>
          <ul className="messages-list">
            {group.messages.map((message, messageIndex) => (
              <AiChatMessage
                ref={
                  groupIndex === localMessages.length - 1 &&
                  messageIndex === group.messages.length - 1
                    ? lastElementRef
                    : null
                }
                key={`${message.messageId}-${messageIndex}`}
                message={message}
                partner={partner}
                enabledRegenerate={
                  groupIndex === localMessages.length - 1 &&
                  messageIndex === group.messages.length - 1
                }
                inputMessageId={
                  group.messages[messageIndex - 1]?.messageId || undefined
                }
                regenerating={messageRegenerating === message.messageId}
                onApproved={(approved) =>
                  handleMessageApproved(message, approved)
                }
                onRegenerate={(inputMessageId) =>
                  handleMessageRegenerate(message, inputMessageId)
                }
              />
            ))}
          </ul>
        </div>
      ))}
    </AiChatMessagesStyled>
  );
};

export default AiChatMessages;

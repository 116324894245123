import StepPlan from "@dashboard/modules/shared/components/onboarding-shared/pages/step-plan/step-plan";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingPlan: FC = () => {
  const navigate = useNavigate();
  return (
    <StepPlan onBack={() => navigate("/onboarding/personalize")} />
  );
};

export default OnboardingPlan;
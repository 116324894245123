export enum ActionUpdateProductsPayload {
  "DELETE" = "DELETE",
  "ENABLE" = "ENABLE",
  "DISABLE" = "DISABLE",
  "ENABLE_HIGHLIGHT" = "ENABLE_HIGHLIGHT",
  "DISABLE_HIGHLIGHT" = "DISABLE_HIGHLIGHT",
}

export type UpdateMassiveProductsPayload = {
  ids: string[];
  action: ActionUpdateProductsPayload;
};

import { CONTACT_BUTTON_CONFIGURATION_OPTIONS, ContactButtonPersonalizationOptionId } from "../constants/contact-button.constants";
import { ContactButtonEditPayload, ContactButtonPersonalizationOption, PreviewMode } from "../entities/contact-button.entities";

export interface ContactButtonState {
  ui: ContactButtonUiState;
  data: ContactButtonDataState;
};

export interface ContactButtonUiState {
  isDirty: boolean;
  viewMode: PreviewMode;
  showOnboarding: boolean;
  selectedOptionId: ContactButtonPersonalizationOptionId;
};
export interface ContactButtonDataState {
  buttonConfig: ContactButtonEditPayload;
  oldButtonConfig: ContactButtonEditPayload;
  contactButtonConfigurationOptions: ContactButtonPersonalizationOption[];
};

export const initialState: ContactButtonState = {
  ui: {
    showOnboarding: false,
    isDirty: false,
    viewMode: PreviewMode.Desktop,
    selectedOptionId: ContactButtonPersonalizationOptionId.None,
  },
  data: {
    oldButtonConfig: {} as ContactButtonEditPayload,
    buttonConfig: {} as ContactButtonEditPayload,
    contactButtonConfigurationOptions: CONTACT_BUTTON_CONFIGURATION_OPTIONS,
  },
};

import { Plan } from "../plans.types";
import { formatCurrency } from "@dashboard/utils/format-currency";
import { PlanPeriod } from "@dashboard/modules/checkout/constants/plan-period";
import { PlatformCountry } from "@dashboard/common/types";
import { CartItemPlanValues } from "@dashboard/modules/checkout/components/cart-item-plan/cart-item-plan";
import { t } from "i18next";
import { getDateByText } from "@dashboard/utils/format-date";

export const mapPlan = (planJson: any): Plan => {
  let plan: any = {
    id: planJson.id,
    type: planJson.additional_data.plan_type,
    name: planJson.name,
    price:
      planJson.cart?.total_value === 0
        ? 0
        : planJson.cart?.total_value || planJson.price,
    priceWithoutDiscount: planJson.cart?.total_value_without_discount,
    description:
      planJson.benefit_plan.features_grid.main_description ||
      planJson.benefit_plan.description,
    keyFeatures: [],
    otherFeatures: [],
    features: [],
    country: planJson.country,
    price_id: planJson?.price_id || null,
    activation_months: planJson.activation_months,
    additional_data: planJson.additional_data,
    enabled: planJson.enabled,
    palette: planJson.benefit_plan.features_grid.palette,
    cart: planJson.cart,
    benefitPlan: planJson?.benefit_plan,
    planTypeComponent: planJson?.plan_type_component,
    customHint: planJson.customHint,
  };

  planJson.benefit_plan.features_grid.principal_benefits.forEach(
    (benefit: any) => {
      plan.keyFeatures.push({
        name: benefit.name || null,
        value: true,
        icon: benefit.icon || null,
      });
    }
  );

  planJson.benefit_plan.features_grid.grid_benefits.forEach((benefit: any) => {
    let value = benefit.value || null;
    if (value === "∞" || value === "✓") {
      value = true; // if the value is infinity symbol or checkmark, consider it as true
    } else if (value === "30% OFF") {
      value = "30% OFF"; // if the value is 30% OFF, keep as is
    }
    plan.features.push({
      name: benefit.name || null,
      value: value || null,
    });
  });

  const keyFeatures = plan.keyFeatures.map((feature: any) => feature.name);

  plan.otherFeatures = plan.features
    .filter((feature: any) => !keyFeatures.includes(feature.name))
    .sort(() => Math.random() - 0.5)
    .slice(0, 3);

  return plan;
};

export const mapPlanHorizontalCard = (
  plan: Plan,
  options: { country?: PlatformCountry } = {}
): Plan => {
  const { country } = options;
  const newPlan = {
    ...mapPlan(plan),
    customHint: mapCustomHint(plan, { country }),
  };
  return newPlan;
};

const mapCustomHint = (
  plan: Plan,
  options: { valid?: "internal" | "external"; country?: PlatformCountry } = {
    valid: "internal",
  }
) => {
  const { valid, country } = options;
  const generatePriceDays = (
    text: string,
    price: string,
    days?: number
  ): string => {
    return text
      .replace(":price", price.toString())
      .replace(":days", days?.toString() || "");
  };
  const valuePeriodDefault = `${formatCurrency(plan.price, country)} / ${
    plan.name || PlanPeriod.MENSUAL
  }`;
  const upcoming = plan.cart?.additional_information.upcoming_invoice_v2;
  let aux: CartItemPlanValues = {
    price: plan.price,
    priceWithoutDiscount: plan.priceWithoutDiscount || 0,
    daysText: valuePeriodDefault,
    nextPaymentValue: `${formatCurrency(
      upcoming?.normal_value || 0,
      country
    )} / ${
      plan.name === PlanPeriod.MENSUAL
        ? t("checkout.checkout.plan.month")
        : t("checkout.checkout.plan.year")
    }`,
    nextPaymentDate: getDateByText(upcoming?.period_end || ""),
    textNoFormat: "",
  };
  if (upcoming && upcoming.use_upcoming_invoice_values) {
    aux.price = formatCurrency(upcoming.total_value, country);
    aux.priceWithoutDiscount = upcoming.normal_value;
    if (valid === "internal" && upcoming.show_internal_days) {
      aux.daysText = generatePriceDays(
        upcoming.internal_text,
        formatCurrency(upcoming.total_value, country).toString(),
        upcoming.enjoyed_days
      );
      aux.textNoFormat = upcoming.internal_text;
    } else if (valid === "external" && upcoming.show_external_days) {
      aux.daysText = generatePriceDays(
        upcoming.external_text,
        formatCurrency(upcoming.total_value, country).toString(),
        upcoming.enjoyed_days
      );
      aux.textNoFormat = upcoming.external_text;
    }
  }
  return `<strong>${
    plan.cart?.additional_information?.upcoming_invoice_v2?.onboarding_text
      ? plan.cart?.additional_information?.upcoming_invoice_v2?.onboarding_text
      : ""
  }</strong> <br />${
    aux?.nextPaymentDate
      ? `${t("checkout.checkout.card.nextPayment")}: <strong>${
          aux?.nextPaymentDate
        }</strong> `
      : ""
  }`;
};

import { useSession } from "@dashboard/hooks/use-session.hook";
import { FC, useEffect } from "react";
import { Loader } from "@dashboard/assets/icons";
import { LoaderStatusWrapper } from "./loader-status.styled";
import { useTranslation } from "react-i18next";

const LoaderStatus: FC = () => {
  const { t } = useTranslation();
  const { checkUserInfo, decodedToken } = useSession();

  useEffect(() => {
    if (decodedToken) {
      checkUserInfo();
    }
  }, [decodedToken]);

  return (
    <LoaderStatusWrapper>
      <div className="container">
        <Loader />
        <div className="spinnerText">{t("status.loaderText")}</div>
      </div>
    </LoaderStatusWrapper>
  );
};

export default LoaderStatus;

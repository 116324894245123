import styled from "styled-components";

export const ProductInformationWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  > .title {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--Grey-Border, #e9e9ed);
    background: var(--Grey-W, #fff);
    .text {
      color: var(--Grey-B, #24272e);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }
  .content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .category-container {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      > div {
        width: 100%;
      }
    }
  }
`;

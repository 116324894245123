import { FC, useEffect } from "react";
import { StepStoreWrapper } from "./step-store.styled";
import StoreDescription from "../../components/store-description/store-description";
import { useTranslation } from "react-i18next";
import { useStepStore } from "../../hooks/step-store.hook";
import {
  EventsOnboarding,
  OptionOnboarding,
  SubtypeOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { UiButton } from "@sumerlabs/component-library";
import { StoreDesignPath } from "../../constants/store-design-path.constants";

export type StepStoreTypes = {
  ctaGenerateWithAi?: string;
  ctaSelectTemplate?: string;
};

const StepStore: FC<StepStoreTypes> = ({
  ctaGenerateWithAi,
  ctaSelectTemplate,
}) => {
  const { t } = useTranslation();
  const { nextStep, logEvent } = useStepStore();

  useEffect(() => {
    logEvent(EventsOnboarding.VIEW_SCREEN, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      option: OptionOnboarding.STORE_DESING,
    });
  }, []);

  return (
    <StepStoreWrapper className="fadeInRight">
      <div className="description">
        <StoreDescription
          title={t("sharedOnboarding.stepStore.title")}
          description={t("sharedOnboarding.stepStore.description")}
        />
      </div>
      <div className="options">
        <div className="option">
          <img
            src="https://sumerlabs.com/prod/sumerweb/step-store/generate-ia.png"
            alt="Generate store with ai"
            width="200px"
          />
          <div className="text">
            <div className="title">
              {t("sharedOnboarding.stepStore.options.generateAi.text.title")}
            </div>
            <div className="description">
              {t(
                "sharedOnboarding.stepStore.options.generateAi.text.description"
              )}
            </div>
          </div>
          <UiButton
            className="button"
            appereance="outline"
            onClick={() =>
              nextStep(ctaGenerateWithAi || StoreDesignPath.GENERATING)
            }
          >
            {t("sharedOnboarding.stepStore.options.generateAi.button")}
          </UiButton>
        </div>
        <div className="option">
          <img
            src="https://sumerlabs.com/prod/sumerweb/step-store/template.png"
            alt="Select template"
          />
          <div className="text">
            <div className="title">
              {t("sharedOnboarding.stepStore.options.template.text.title")}
            </div>
            <div className="description">
              {t(
                "sharedOnboarding.stepStore.options.template.text.description"
              )}
            </div>
          </div>
          <UiButton
            className="button"
            appereance="outline"
            onClick={() =>
              nextStep(ctaSelectTemplate || StoreDesignPath.TEMPLATES)
            }
          >
            {t("sharedOnboarding.stepStore.options.template.button")}
          </UiButton>
        </div>
      </div>
    </StepStoreWrapper>
  );
};

export default StepStore;

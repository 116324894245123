import { Icon } from "@phosphor-icons/react";

export enum ConfigurationOptionId {
  None = "none",
  Date = "date",
  Client = "client",
  Details = "details",
  Discounts = "discounts",
};

export interface ConfigurationOption {
  id: ConfigurationOptionId;
  icon: Icon;
  text: string;
};

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "@dashboard/assets/global-styles";
import { ThemeProvider } from "styled-components";
import { theme } from "@dashboard/assets/theme";
import { language } from "./i18n";
import { store } from "@dashboard/config/redux";
import { Provider } from "react-redux";
import { Toaster } from "sonner";
import { SumerKitIconsProvider } from "@sumerkit/icons/providers";
import { SumerKitAppProvider } from "@sumerkit/themes";
import "./config/plugins/sentry.plugin";
import { MobileView } from "./components/mobile-view/mobile-view";

document.documentElement.lang = language;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter basename="/web">
    <SumerKitAppProvider>
      <SumerKitIconsProvider>
        <Toaster position="top-center" richColors closeButton />
        <Provider store={store}>
          <MobileView />
          <ThemeProvider theme={theme}>
            <link
              href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"
              rel="stylesheet"
            ></link>
            <GlobalStyles />
            <App />
          </ThemeProvider>
        </Provider>
      </SumerKitIconsProvider>
    </SumerKitAppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export enum DomainState {
  Idle = 'idle',
  None = 'none',
  Loading = 'loading',
  Owned = 'owned',
};

export enum DomainOwnerState {
  Error = 'ERROR',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
};

import { Breakpoints } from "@sumerlabs/component-library";
import styled from "styled-components";

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  &.show {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s 0s, opacity .3s;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s .3s, opacity .3s;
  }
  .overlay {
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #111111;
  }
  .modal-content {
    z-index: 10;
    margin: auto;
    position: relative;
    border-radius: 10px;
    background: #FFFFFF;
    background-color: #FFFFFF;
    overflow: hidden;
    .modal-close {
      top: 20px;
      right: 16px;
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      position: absolute;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 26px;
      }
    }
    
  }

  @media screen and (max-width: ${Breakpoints.Tablet}) {
    
  }
`;
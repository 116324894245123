import styled from "styled-components";

export const AiAssistantStyled = styled.main`
  background: #ffffff;
  box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  height: 668px;
  margin-left: 1rem;
  transition: all 0.2s;
  position: fixed;
  width: 375px;
  z-index: 9999;
  .ai-assistant-profile {
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 6px 8px 0px rgba(95, 99, 108, 0.12);
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    .ai-assistant-avatar {
      align-items: center;
      background-color: #24272e;
      border-radius: 50%;
      color: #ffffff;
      display: flex;
      font-size: 1.375rem;
      justify-content: center;
      height: 32px;
      width: 32px;
    }
    .ai-assistant-data {
      color: #24272e;
      line-height: 1.125em;
      .ai-assistant-title {
        font-size: 0.875rem;
        font-weight: 600;
      }
      .ai-assistant-help  {
        font-size: 0.625rem;
      }
    }
  }
  .ai-assistant-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto;
    max-width: 816px;
    overflow: auto;
    width: 100%;
  }
  .ai-assistant-body {
    flex: 1;
    padding: 0.75rem 1.5rem;
  }
  .ai-assistant-footer {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
    .ai-assistant-fq {
      margin: 0 -1rem;
    }
    .ai-assistant-interactions {
      align-items: center;
      display: flex;
      color: #24272e;
      gap: 0.25rem;
      font-size: 0.625rem;
      margin-top: -0.75rem;
      span {
        font-size: 1.5em;
      }
    }
  }
  &.ai-assistant-maximized {
    height: calc(100vh - 68px - 2rem);
    width: calc(
      100vw - var(--navigation-bar-collapse, calc(250px - 2rem)) - 2rem
    );
  }
`;

import styled from "styled-components";

export const PopupWrapper = styled.div`
  background-color: #000;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  .icon {
    position: absolute;
    font-size: 20px;
    right: 20px;
    top: 38px;
    color: var(--grey-w, #fff);
    :hover {
      cursor: pointer;
    }
  }
  img {
    width: 100%;
  }
  .body {
    display: flex;
    padding: 35px 24px 24px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
    background: #24272e;
    .copys {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      .title {
        color: var(--grey-w, #fff);
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        margin: auto 47px;
      }
      .description {
        color: var(--grey-w, #fff);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .controls {
      display: flex;
      padding: 20px 16px 16px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: -webkit-fill-available;
      .confirmLabel {
        display: flex;
        height: 48px;
        padding: 0px 14px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 8px;
        background: var(--grey-w, #fff);
        :hover {
          cursor: pointer;
        }
      }
      .linkLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        .text {
          color: var(--grey-w, #fff);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration-line: underline;
        }
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

import { http } from "@dashboard/common/http";
import { getEnvVar } from "@dashboard/common/utils";
import { CustomPlanHook } from "@dashboard/constants/custom-plan-hook";

export const fetchCustomPlanHooks = async (): Promise<CustomPlanHook[]> => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.get("/api/ms/remote-config/country-data/custom_plans_hook", {
    headers: {
      'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
      "Accept-Language": language,
    },
  });
  return response.data.value.hooks;
};
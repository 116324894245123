import { ChangeEvent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { useAnalytics } from "@dashboard/common/analytics";
import { useFeatureAvailability } from "@dashboard/hooks/feature-availability.hook";
import { FeatureProducts } from "@dashboard/modules/products/constants/features.constants";
import { PlanBenefit } from "@dashboard/entities/plan-benefit";
import {
  FormProps,
  ImageProductForm,
} from "@dashboard/modules/products/types/product-form.types";
import { Feature } from "@dashboard/constants/features";
import { actions } from "@dashboard/config/redux";
import { CoreEvents } from "@dashboard/constants/events";
import { Plan } from "@dashboard/constants/plan";
import { useToast } from "@dashboard/hooks/use-toast.hook";

export const useProductUploadImage = () => {
  const IMAGE_MAX_MB = 20;
  const IMAGE_MAX_SIZE = 1024 * 1024 * IMAGE_MAX_MB;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInput = useRef<HTMLInputElement>(null);
  const { setFieldValue } = useFormikContext();
  const { plan, feature: imagesProductsFeature } = useFeatureAvailability(
    FeatureProducts.ProductImages
  );
  const { values }: { values: FormProps } = useFormikContext();
  const { toast } = useToast();
  const [showErrors, setShowErrors] = useState(false);

  const MAX_PHOTOS = useMemo(() => {
    return (
      (imagesProductsFeature as PlanBenefit)?.conditions
        ?.max_image_by_product || 1
    );
  }, [imagesProductsFeature]);

  const selectFile = () => {
    fileInput?.current?.click();
  };
  const { logEvent } = useAnalytics();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files;
    if (!fileObj) {
      return;
    }
    let auxArray: ImageProductForm[] = [];
    auxArray = [...(values.imagesForm || [])];
    Array.from(fileObj).forEach((item) => {
      const isValid = validateImageSize(item);
      if (!validateIfExist(item.name) && isValid) {
        auxArray.push({
          id: auxArray.length,
          type: "file",
          image: item,
        });
      }
      if (!isValid) {
        setShowErrors(true);
        toast.danger({
          text: t("admin.products.side.photos.validateErrorSize", {
            size: IMAGE_MAX_MB,
          }),
          options: { position: "top-center" },
        });
      }
    });
    if (auxArray.length > MAX_PHOTOS) {
      auxArray.splice(MAX_PHOTOS, auxArray.length);
      if (plan?.benefit_plan_name.toLowerCase() !== Plan.ULTRA.toLowerCase()) {
        logEvent(CoreEvents.SystemExpertWarning, {
          event_subtype: "image_product_creation",
          source: "hook",
          method: plan?.benefit_plan_name === Plan.FREE ? "free" : "upgrade",
        });
        dispatch(actions.showCustomPlanHook(Feature.ProductImages));
      }
    }
    setFieldValue("imagesForm", [...auxArray]);
    event.target.value = "";
  };

  const validateIfExist = (file: string): boolean => {
    const index =
      values.imagesForm &&
      Array.from(values.imagesForm).findIndex(
        (item) => item.type === "file" && (item.image as File).name === file
      );
    return index !== -1;
  };

  const validateImageSize = (file: File): boolean => {
    return file.size <= IMAGE_MAX_SIZE;
  };

  const removeImage = (index: number) => {
    (values.imagesForm || []).splice(index, 1);
    setFieldValue("imagesForm", [...(values.imagesForm || [])]);
  };

  return {
    values,
    fileInput,
    showErrors,
    IMAGE_MAX_MB,
    selectFile,
    handleFileChange,
    setShowErrors,
    logEvent,
    t,
    removeImage
  };
};

import styled from "styled-components";

export const HeaderWrapper = styled.div`
  padding: 24px;
  position: fixed;
  .icon {
    transform: rotate(90deg);
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
  }
`;

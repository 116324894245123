import ToastCustom from "@dashboard/components/toast-custom";
import { ToastCustomProps } from "@dashboard/components/toast-custom/toast-custom.types";
import { ReactNode } from "react";
import { ExternalToast, toast as toastSonner } from "sonner";

type ToastProps = {
  text: ReactNode;
  options?: ExternalToast;
};

export const useToast = () => {
  const show = ({
    type,
    text,
    leftIcon,
    className,
    options = {},
  }: { options?: ExternalToast } & ToastCustomProps) => {
    toastSonner.custom(
      () => (
        <ToastCustom
          text={text}
          type={type}
          leftIcon={leftIcon}
          className={className}
        />
      ),
      {
        position: "top-right",
        ...options,
      }
    );
  };

  const toast = {
    info: ({ text, options = {} }: ToastProps) =>
      show({
        text,
        options,
        type: "info",
        leftIcon: <span className="icon-info-alert-icon" />,
      }),
    danger: ({ text, options = {} }: ToastProps) =>
      show({
        text,
        options,
        type: "danger",
        leftIcon: <span className="icon-close-circle" />,
      }),
    success: ({ text, options = {} }: ToastProps) =>
      show({
        text,
        options,
        type: "success",
        leftIcon: <span className="icon-circle-check" />,
      }),
    warning: ({ text, options = {} }: ToastProps) =>
      show({
        text,
        options,
        type: "warning",
        leftIcon: <span className="icon-circle-exclamation" />,
      }),
  };

  return { toast };
};

import styled from "styled-components";

export const ProductVariationsWrapper = styled.div`
  .features-content {
    border-bottom: 1px solid #d9d9d9;
    .add-features-link {
      display: flex;
      gap: 4px;
      width: fit-content;
      .text {
        color: var(--Grey-B, #24272e);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
`;

import { FC, useState } from "react";
import { ChromePicker } from "react-color";
import { useTranslation } from "react-i18next";
import { CONTACT_BUTTON_COLOR_OPTIONS } from "../../constants/contact-button.constants";
import { useContactButton } from "../../hooks/contact-button.hook";
import ContactButtonColorWrapper from "./contact-button-color.styled";

interface ContactButtonColorProps {

};

const ContactButtonColor: FC<ContactButtonColorProps> = () => {

  const { t } = useTranslation();
  const {
    buttonConfig,
    handleColorChange,
  } = useContactButton();
  const [isPickingCustomColor, setIsPickingCustomColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorClick = (color: string) => {
    if (color === "custom") setIsPickingCustomColor(true);
    else handleColorChange(color);
  };

  return (
    <ContactButtonColorWrapper>
      {isPickingCustomColor ? (
        <div
          className="selectingCustomColor"
          onClick={(_) => {
            setShowColorPicker((s) => !s);
          }}
        >
          <div className="icon">
            <span className='icon-pencil-line' />
          </div>
          <div className="selectedColor">
            {showColorPicker && (
              <div
                className="picker"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ChromePicker
                  color={buttonConfig.wp_contact?.colors?.background}
                  onChangeComplete={(r) => handleColorChange(r.hex)}
                  disableAlpha
                />
              </div>
            )}
            <div
              className="preview"
              style={{
                backgroundColor: buttonConfig.wp_contact?.colors?.background,
              }}
            />
            <div className="value">
              {buttonConfig.wp_contact?.colors?.background}
            </div>
            <div className="name">
              {t("addons.contact_button.background")}
            </div>
          </div>
        </div>
      ) : (
        CONTACT_BUTTON_COLOR_OPTIONS.map((color) => (
          <div
            key={`contact-button-color-option-${color}`}
            className={`colorItem ${
              (color === CONTACT_BUTTON_COLOR_OPTIONS[0]
                ? !CONTACT_BUTTON_COLOR_OPTIONS.includes(
                    buttonConfig.wp_contact?.colors?.background || "",
                  )
                : buttonConfig.wp_contact?.colors?.background === color) &&
              "active"
            }`}
            onClick={() => handleColorClick(color)}
          >
            {color !== "custom" ? (
              <div
                className="color"
                style={{ backgroundColor: color }}
              />
            ) : (
              <span className={`icon-plus customIcon`} />
            )}
          </div>
        ))
      )}
    </ContactButtonColorWrapper>
  );
};

export default ContactButtonColor;

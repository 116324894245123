import { useMemo } from "react";
import { Partner } from "@dashboard/common/types";
import { WEB_ASSETS } from "@sumerlabs/component-library";
import { resizeImage as resizeImageFn } from "@dashboard/utils/images";

export const useStoreImages = (partner?: Partner) => {
  const avatarProfileDefault = `${WEB_ASSETS}/cute_logo.webp`;

  const resizeImage = (link: string, width: number, height: number) => {
    return link.indexOf("cute_logo") >= 0
      ? avatarProfileDefault
      : resizeImageFn(link, width, height, 80);
  };
  const storeSquareImage = useMemo(() => {
    return partner?.profilePicConfiguration?.enableSquare
      ? resizeImage(
          partner?.profilePicConfiguration?.squareImage!!,
          64,
          64,
        )
      : null;
  }, [partner]);
  const storeRectangleImage = useMemo(() => {
    return partner?.profilePicConfiguration?.enableRectangle
      ? resizeImage(
          partner?.profilePicConfiguration?.rectangleImage!!,
          120,
          64,
        )
      : null;
  }, [partner]);

  const partnerProfilePicture = useMemo(() => {
    return (
      storeSquareImage ||
      storeRectangleImage ||
      partner?.profilePic ||
      avatarProfileDefault
    );
  }, [storeSquareImage, storeRectangleImage]);

  return {
    partnerProfilePicture,
  };
};

import { Configuration } from '@dashboard/common/types';
import { decamelizeKeys } from 'humps';

export const getConfiguration = (token: string):Promise<Response> => {
    return fetch(
        `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue/configuration`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${token}`,
            } 
        }
    );
};

export const updateStoreConfiguration = (token: string, payload: Configuration):Promise<Response> => {
    return fetch(
        `${process.env.REACT_APP_BASE_URL}/api/ms/catalogue/configuration`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(decamelizeKeys(payload))
        }
    );
};
import { http } from '@dashboard/common/http';
import { getEnvVar } from '@dashboard/common/utils';
import { ReviewsPlansTypes } from '../plans.types';

export const fetchReviewsPlans = async (): Promise<ReviewsPlansTypes[]> => {
  const response = await http.get("/api/ms/remote-config/country-data/reviews_checkout", {
    headers: {
      'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
    },
  });
  return response.data.value.reviews;
};

import styled from "styled-components";

export const LearningItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 22px;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: 0 0 20px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
  .first {
    width: 202px;
    height: 416px;
    display: flex;
    flex: 0 0 auto;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(108deg, rgba(82, 86, 255, 0.30) 22.11%, rgba(142, 144, 227, 0.00) 104.93%), #AAA7E9;
    .texts {
      margin: auto 14px 60px 14px;
      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        margin: 0 0 14px 0;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.40);
        .icon-arrow-2 {
          font-size: 26px;
          transform: rotate(-90deg);
        }
      }
      .title {
        font-size: 26px;
        font-weight: 600;
        line-height: 33px;
        color: var(--grey-w, #FFF);
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--grey-w, #FFF);
      }
    }
  }
  .item {
    width: 202px;
    height: 416px;
    display: flex;
    flex: 0 0 auto;
    cursor: pointer;
    flex-direction: column;
    .img {
      width: 100%;
      height: 332px;
      object-fit: cover;
      border-radius: 8px;
      margin: 0 0 16px 0;
    }
    .name {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
    .watch-quantity {
      color: #5B5B5B;
      font-size: 12px;
      font-weight: 400;
      margin: auto 0 0 0;
    }
  }
`;

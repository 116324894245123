import styled from "styled-components";

export const FrequentQuestionsStyled = styled.section`
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 1rem;
  .frequent-question {
    background-color: #fcfcfc;
    border: 1px solid #e9e9ed;
    border-radius: 0.5rem;
    color: #24272e;
    cursor: pointer;
    line-height: 1.125em;
    min-width: 190px;
    padding: 0.75rem;
    width: 190px;
    .question-title {
      font-size: 0.75rem;
      font-weight: 600;
    }
    .question-description {
      font-size: 0.625rem;
      margin-top: 0.25rem;
    }
  }
`;

import { FC, useEffect, useState } from "react";
import {
  CustomSearchSelectContainer,
  CustomerSearchSelectData,
} from "./custom-search-select.styled";
import { SelectProps } from "./models/select.type";

const CustomSearchSelect: FC<SelectProps> = ({
  id,
  onChange,
  label,
  data,
  placeholder,
  value,
}) => {
  const [showData, setShowData] = useState(false);
  const [dataState, setDataState] = useState(data);
  const [inputValue, setInputValue] = useState(value || "");
  const TIMEOUT = 200;

  useEffect(() => {
    setDataState(data);
  }, [data]);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <CustomSearchSelectContainer key={`select-${id}`}>
      <label htmlFor={id}>{label}</label>
      <div className="input-container">
        <span className="icon icon-search left-icon"></span>
        <span
          className="icon icon-arrow right-icon"
          onClick={() => setShowData((showData) => !showData)}
        ></span>
        <input
          type="text"
          id={id}
          key={`input-${id}`}
          onFocus={() => setShowData(true)}
          onBlur={() =>
            setTimeout(() => {
              setShowData(false);
            }, TIMEOUT)
          }
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e.target.value);
          }}
          value={inputValue}
          placeholder={placeholder}
        />
      </div>
      {showData && (
        <CustomerSearchSelectData key={`data-${id}`}>
          {dataState.map((item, i) => (
            <div
              className="item"
              key={`${item}${i}`}
              onClick={() => {
                onChange(item);
                setInputValue(item);
              }}
            >
              {item}
            </div>
          ))}
        </CustomerSearchSelectData>
      )}
    </CustomSearchSelectContainer>
  );
};

export default CustomSearchSelect;

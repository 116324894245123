import { RootState } from "@dashboard/config/redux";
import { createSelector } from "@reduxjs/toolkit";
import { ExitItems } from "./personalization.state";

export const personalizationChangesSelector = createSelector(
    (state: RootState) => state.personalization.pendingChanges,
    (state: boolean) => state
);

export const personalizationExitSelector = createSelector(
    (state: RootState) => state.personalization.requestExit,
    (state: ExitItems) => state
);
import { FC } from "react";
import { SplashStyled } from "./splash.styled";

type SplashProps = {};

const Splash: FC<SplashProps> = () => {
  return (
    <SplashStyled>
      <img
        src="https://sumerlabs.com/prod/assets/sumerweb/ai/evo-animated.webp"
        alt="Evo"
      />
    </SplashStyled>
  );
};

export default Splash;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CheckoutUiState, initialState } from "./checkout.state";
import { ToBuyItem } from "../entities/to-buy-item.entity";

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setUi: (state, { payload }: PayloadAction<Partial<CheckoutUiState>>) => {
      state.ui = { ...state.ui, ...payload };
    },
    setToBuyItem: (state, action: PayloadAction<ToBuyItem>) => {
      state.data.toBuyItem = action.payload;
    },
    setCheckoutSessionSecret: (state, action) => {
      state.data.checkoutSessionSecret = action.payload;
    },
    setCardSessionSecret: (state, action) => {
      state.data.cardSessionSecret = action.payload;
    },
    setOrderId: (state, action) => {
      state.data.setOrderId = action.payload;
    },
    setStatus: (state, action) => {
      state.data.status = action.payload;
    },
    setAllAddons: (state, action) => {
      state.data.allAddons = action.payload;
    },
    setAllPlans: (state, action) => {
      state.data.allPlans = action.payload;
    },
    setUserCards: (state, action) => {
      state.data.userCards = action.payload;
    },
    resetCheckoutData: (state) => {
      state.data = initialState.data;
    }
  },
});

export const checkoutActions = checkoutSlice.actions;
export const checkoutReducer = checkoutSlice.reducer;
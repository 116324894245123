import { http } from "@dashboard/common/http";
import { HomeFakeDoor, HomeBanner, HomeLearning } from "../constants/home.constants";
import { getEnvVar } from "@dashboard/common/utils";

export const fetchHomeConfig = async (token: string): Promise<{
  banners: HomeBanner[], learnings: HomeLearning[], fake_doors: HomeFakeDoor[]
}> => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.get("/api/ms/remote-config/country-data/dashboard_home", {
    headers: {
      'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
      "Accept-Language": language,
    },
  });
  return response.data.value;
};

export const fetchHomeStatistics = async (token: string) => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.get("/api/ms/orchestrator/users/web/home", {
    headers: {
      'Authorization': `Bearer ${token}`,
      "Accept-Language": language,
    },
  });
  return response.data;
};
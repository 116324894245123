import { FC } from "react";
import StepPlanSkin2 from "./skins/skin-2/step-plan-skin2";

export type StepPlanTypes = {
  onBack?: () => void
}

const StepPlan: FC<StepPlanTypes> = ({ onBack }) => {
  return <StepPlanSkin2 onBack={onBack}/>;
};

export default StepPlan;

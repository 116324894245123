import styled from 'styled-components';

const ContactButtonConfiguredWrapper = styled.div`
	z-index: 9;
	width: 60px;
	height: 60px;
	display: flex;
	cursor: pointer;
	font-size: 48px;
	border-radius: 50%;
	position: absolute;
	align-items: center;
	justify-content: center;

	&.hide {
		display: none;
	}

	&.left-x {
		left: 16px;
	}

	&.center-x {
		left: 0;
		right: 0;
		margin: auto;
	}

	&.right-x {
		right: 16px;
	}

	&.center-y {
		top: 0;
		bottom: 0;
		margin: auto;
	}

	&.bottom-y {
		bottom: 80px;
	}
`;

export default ContactButtonConfiguredWrapper;
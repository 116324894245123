import { toast } from "sonner";

export const useNotification = () => {

  const notify = ({ message, type }: {
    message: string,
    type: "success" | "error" | "info",
  }) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.message(message);
        break;
      default:
        toast(message);
        break;
    }
  };

  return { notify };
};

const locale = {
  name: "Productos",
  options: {
    addProduct: "Agregar producto",
    massiveUpload: "Carga masiva",
    exportInventory: "Exportar inventario",
  },
  loadingProducts: "Cargando productos",
  product: {
    options: {
      highlight: "Destacar",
      highlighted: "Destacado",
      available: "Activar",
      unavailable: "Desactivar",
      seeAvailability: "Ver disponibilidad",
    },
    moreActions: "Más acciones",
    actions: {
      activate: "Activar",
      deactivate: "Desactivar",
      highlight: "Destacar",
      duplicate: "Duplicar",
      delete: "Eliminar",
    },
    inventory: {
      soldOut: "Agotado",
      available: "{{units}} disponible",
      availablePlural: "{{units}} disponibles",
      alwaysAvailable: "Siempre disponible",
      variations: {
        limited: "{{count}} items agotados",
        limitedAvailable: "{{count}} en stock",
        alwaysAvailable: "{{count}} items con stock sin límite",
      },
    },
    share: {
      whatsapp: {
        button: "Whatsapp",
        message:
          "Hola! Tenemos ya disponible {{name}}, cómpralo ahora en mi tienda online: {{link}}",
      },
      copylink: {
        button: "Copiar link",
        confirm: "El enlace a tu producto se ha copiado correctamente.",
      },
    },
    fake: "Producto de muestra",
  },
  empty: {
    title: "Aún no tienes ningún producto / servicio",
    description:
      "Crea tus primeros productos / servicios y empieza a vender en tu tienda.",
    addProduct: "Crear un producto",
    bulkUploadProducts: "Carga masiva con plantilla",
    bulkUploadProductsXls: "Carga masiva con tu propio documento",
    help: "¿Necesitas ayuda con tu carga masiva?",
  },
  list: {
    name: "lista de productos",
    options: {
      selectAll: "Seleccionar todo",
      deselectAll: "Deseleccionar todo",
    },
    search: {
      placeholder: "Buscar producto...",
    },
    meliAlert: {
      title:
        "Se han importado exitosamente {{count}} productos desde Mercado Libre",
      description:
        "Puedes configurar el precio o el inventario en Sumer, sin embargo, no se actualizarán en Mercado Libre.",
    },
  },
  bulkUploadFile: {
    name: "Carga masiva",
    bulkFile: {
      title: "Carga masiva",
      copy: "Agrega hasta 1000 productos masivamente a través de un archivo de Excel. Ten en cuenta los siguientes pasos:",
      template: "archivo de Excel",
      customTemplate: "propio archivo aqui",
      step_one: "1. Descarga el",
      step_two: "2. Edita el archivo con tus productos",
      step_three: "3. Adjunta el archivo actualizado",
      step_four: "4. Tambien puedes adjuntar tu propio archivo aqui",
      select: "Selecciona o arrastra un archivo de Excel aquí",
      type_error: "El archivo seleccionado no es un archivo .xls o .xlsx",
      max_file_error: "Solo se puede subir un archivo",
      loadingFile: "Cargando archivo",
      statusTitle: "Estado del archivo",
      statusAlert:
        "Al “omitir y continuar” descargaremos un archivo con los errores que podrás corregir al finalizar el proceso",
      statusLoadedProductsCount: "Productos cargados",
      statusNotLoadProductsCount: "Productos sin cargar",
      statusReloadFile: "Volver a cargar archivo",
      statusConitnue: "Continuar",
      statusConitnueWithErrors: "Omitir y continuar",
      statusViewErrors: "Ver errores",
      statusErrorCopy: "Carga de archivo errónea",
      statusErrorDescription:
        "Hubo un problema cargando el archivo, revisa si:",
      statusErrorCauses: `<li>Estás usando el archivo de Sumer.</li>
        <li>El archivo no ha sido modificado.</li>
        <li>El archivo tiene contenido.</li>
        <li>El archivo tiene el numero de productos permitidos.</li>`,
      errorsTitle: "Errores en el archivo",
      errorsCopy:
        "Hemos generado un archivo con los errores resaltados, revisa si:",
      errorsCauses: `<li>Hay campos sin diligenciar.</li>
      <li>Hay campos que superen el limite de carácteres. </li>`,
      errorsDownloadFile: "Descargar archivo con errores",
      errorsItem: {
        row: "Fila",
        column: "Columnas",
        emptyName: "Sin nombre",
      },
    },
    images: {
      name: "Carga de fotos",
      bulkUploadProducts: {
        title: "Estado carga masiva",
        copy: "¡Felicitaciones, llegaste al último paso para terminar tu carga masiva!",
        upload: "Productos cargados correctamente",
      },
      bulkUpload: {
        title: "Carga masiva de fotos",
        copy: `Selecciona o arrastra una carpeta con las fotos (.png, jpg,
          webp) de tus productos. 
          <strong>
            Asegúrate de que el nombre de la foto coincida con el de la
            plantilla de Excel.
          </strong>`,
        action: "Cargar carpeta de fotos",
      },
      manualUpload: {
        title: "Carga manual de fotos",
        copy: `Sube las fotos de cada uno uno de tus productos de forma manual. 
          <strong>
            Asegúrate de que cada artículo quede con una foto que lo
            represente.
          </strong>`,
        action: "Cargar fotos manualmente",
      },
    },
    bulkImages: {
      name: "Carga masiva de fotos",
      title: "Sube la carpeta con las fotos",
      copy: `Selecciona o arrastra una carpeta con las fotos (.png, jpg, webp) de tus productos. <strong>Ten en cuenta que el nombre de la foto debe coincidir con el nombre en el archivo de Excel.</strong>`,
      step_one: "1. Crea una carpeta con tus fotos",
      step_two:
        "2. Asegúrate de que el nombre de las fotos coincide con el nombre que pusiste en el archivo de Excel.",
      step_three: "3. Sube la carpeta",
      select: "Selecciona o arrastra una carpeta aquí",
      directoryNone: "Carpeta fotos",
      loadingFolder: "Cargando carpeta",
      statusTitle: "Estado carga de fotos",
      statusAlert:
        "Al “omitir y continuar” descargaremos un archivo con los errores que podrás corregir al finalizar el proceso",
      statusLoadedImagesCount: "Fotos subidas correctamente",
      statusWithGenericCount: "Productos con foto de prueba",
      statusWithErrorsCount: "Fotos con errores",
      statusReloadFile: "Volver a cargar fotos",
      errorsTitle: "Errores en las fotos",
      errorsCopy: "Ten en cuenta que:",
      errorsCauses: `<li>El nombre de la imagen debe ser igual que en el excel.</li>
        <li>El tamaño recomendado 800x800px.</li>`,
      errorsDownloadFile: "Descargar archivo con errores",
      errorsItem: {
        row: "Fila",
        photos: "Fotos",
        emptyName: "Sin nombre",
        copy: "Fotos no encontradas",
      },
    },
    bulkCustomFile: {
      title: "Carga de tu propio archivo",
      success: "Archivo cargado",
      successCopy:
        "Gracias por cargar tu archivo. En un plazo de 48 horas hábiles, recibirás la confirmación de la carga de tus productos en el sistema.",
      contactSupport: "Contactar soporte",
      supportCopy: `Hola Sumer, subí mi propio archivo de productos desde Sumer Web y quisiera preguntarles acerca de:
`,
    },
    discardConfirm: {
      title: "Fotos de prueba",
      copy: "Si decides no cargar fotos, todos tus productos que no tengan una foto usarán una imagen de prueba, mientras cargas una foto del mismo.",
      buttonDiscard: "Omitir y usar fotos de prueba",
      continue: "Usar fotos de prueba",
    },
    cancelConfirm: {
      title: "Advertencia",
      copy: "No has completado el proceso de carga masiva, al regresar podrías perder todo tu progreso.",
    },
    create: {
      name: "Creando...",
      copy: "Creando productos",
      errors: {
        unknown:
          "Ocurrio un error desconocido en el servidor. No se pueden crear los productos masivamente.",
      },
    },
    summary: {
      name: "Resumen",
      title: "Resumen carga masiva",
      copy: "¡Felicitaciones terminaste el flujo de carga masiva!",
      success: "Productos creados correctamente",
      warning: "Productos creados con foto de prueba",
    },
    retry: {
      title: "Resumen",
      copy: "No se pudieron crear tus productos debido a la capacidad de tu plan, revisa la cantidad de productos, fotos permitidas y variaciones en tu plan actual.",
      copyRetry: "Reintenta la carga masiva después de mejorar tu plan.",
      copyRetryError:
        "Si ya adquiriste un nuevo plan, es posible que haya ocurrido un error, por favor vuelve a intentar tu carga masiva.",
      button: "Reintentar",
      finish: "Finalizar carga masiva",
    },
    educationButton: {
      title: "¿Cómo editar la plantilla de Excel?",
      copy: "¡Te mostramos lo fácil que es!",
    },
    downloadProducts: {
      error: "Error exportando el inventario",
    },
  },
  newProduct: {
    title: "Editar nuevo producto",
    description: "Descripción de prueba",
  },
  deleteProduct: {
    title: "Eliminar producto",
    copy: "¿Seguro que deseas eliminar este producto?",
    multiple: {
      title: "Eliminar productos",
      copy: "¿Seguro que deseas eliminar estos {{count}} productos?",
    },
    confirm: "Sí, eliminar",
  },
  duplicateProductRename: {
    field: "Nombre del producto a duplicar",
    confirm: "Si, duplicar",
    cancel: "Cancelar",
  },
  improvePlan: {
    title: "Mejora tu plan",
    titleGrow: "Crece sin límites",
    copy: "Funcionalidad disponible en plan Pro y en Ultra. Haz upgrade para tener funcionalidades ilimitadas.",
    copyMaxProducts:
      "Alcanzaste el límite de productos en tu plan actual. Obtén funciones ilimitadas mejorando tu plan.",
    cancel: "Quizá más tarde",
    confirm: "Conoce los planes",
    confirmUnlock: "Desbloquear ahora",
    image:
      "https://sumerlabs.com/prod/assets/sumerweb/products/hook-upgrade-plan.webp",
    imageMaxProducts:
      "https://sumerlabs.com/prod/assets/sumerweb/products/hook-max-products.webp",
  },
  getFunctionality: {
    title: "¡Obtén ésta funcionalidad!",
    copy: "Mejora tu plan ahora para tener ésta y más funcionalidades.",
    confirm: "Quiero saber más",
    image:
      "https://sumerlabs.com/prod/assets/sumerweb/products/hook-get-benefits.webp",
  },
  stockHook: {
    title: "Controla tu inventario",
    description:
      "Nunca pierdas una venta por falta de productos con el control de stock en tiempo real.",
  },
  alertPopup: {
    title: "Stock",
    description:
      "No has modificado la cantidad de stock de tus variaciones, ¿estas seguro que deseas continuar?",
    confirmLabel: "Continuar",
  },
  form: {
    sku: {
      title: "SKU general",
      copy: "Código de identificación único del producto",
      label: "Código SKU",
      placeholder: "Escribe un código SKU",
      error: "Máximo 12 caracteres",
      errors: {
        repeat: "Este SKU ya esta asignado a otro item",
      },
    },
  },
  gptGenerate: {
    title: "Complemento <br /> Inteligencia Artificial",
    generate: "Generar",
    generateField: "Generar {{fieldLabel}}",
    goToAddons: "Ir a complementos",
    loading: "Generando {{fieldLabel}}",
    fields: {
      name: {
        label: "Nombre",
        description:
          "Deja que la Inteligencia Artificial te ayude agregando el mejor nombre para tu producto",
      },
      description: {
        label: "Descripción",
        description:
          "Deja que la Inteligencia Artificial te ayude con la descripción de tu producto/servicio.",
        help: "Recuerda tener escrito el nombre de tu producto/servicio para que la IA funcione.",
      },
      tags: {
        label: "Etiqueta",
        generate: "Agregar etiquetas a la imagen",
        description:
          "Deja que la Inteligencia Artificial te ayude agregando etiquetas a la imagen del producto.",
        help: "Con etiquetas en tus productos tendrás mayor posicionamiento en Google. Recuerda que el resultado dependera de calidad de la imagen.",
      },
    },
    error: {
      title: "Vuelve a intentarlo más tarde",
      description:
        "El servicio no está disponible en el momento vuelve intentarlo más tarde",
      action: "Intentar más tarde",
    },
    retry: {
      title: "Se presento un error",
      description:
        "!Lo sentimos se presento un error al generar Sumer IA. Por favor intenta de nuevo.",
      action: "Volver a intentar",
    },
    hook: {
      title: "Disponible en el plan <span>Ultra</span>",
      copy: "Disfruta de todas las funcionalidades de Sumer sin límites o <strong>adquiere IA Sumer por sólo {{price}}.</strong>",
      subscribe: "Suscribirme al plan Ultra",
      buy: "Adquirir IA Sumer",
      subscriptionHelp: "Pago recurrente automático, cancela cuando quieras",
    },
  },
};

export default locale;

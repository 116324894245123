import styled from "styled-components";

export const StockAlert = styled.div`
  margin-top: 10px;
  .custom-alert {
    border-left: 8px solid #1458F5;
    .icon {
      color: #1458F5;
    }
  }
`;
import { FC } from "react";
import {
  ImagesTagsStyled,
  ProductDetailWrapper,
} from "./product-detail.styled";
import GptGenerate from "../gpt-generate";
import X from "@sumerkit/icons/x";
import ProductUploadImages from "../product-upload-image/product-upload-image";
import ProductWholesale from "../product-wholesale/product-wholesale";
import { useProductDetail } from "./product-detail.hook";
import ProductNamePrice from "../product-name-price/product-name-price";

export type ProductDetailTypes = {
  setSectionsValid?: (values: { [key: string]: boolean }) => void;
};

const ProductDetail: FC<ProductDetailTypes> = ({ setSectionsValid }) => {
  const {
    values,
    showAiGenerator,
    loadingPredictProduct,
    predictTagsImages,
    handleClickGenerate,
    deleteImageTag,
    t,
  } = useProductDetail();

  return (
    <ProductDetailWrapper>
      <div className="title">
        <div className="text">
          {t("productComponent.product.productDetail.title")}
        </div>
      </div>
      <div className="content">
        <ProductUploadImages />
        <ImagesTagsStyled>
          {values.calculatedTags && values.calculatedTags.length > 0 && (
            <div className="product-tags">
              {values.calculatedTags.map((tag, index) => (
                <span className="tag" key={`image-tag-${index}`}>
                  {tag}
                  <button onClick={() => deleteImageTag(tag)}>
                    <X />
                  </button>
                </span>
              ))}
            </div>
          )}
          {showAiGenerator && values.imagesForm?.length !== 0 && (
            <GptGenerate
              buttonLabel={t("products.gptGenerate.fields.tags.generate")}
              fieldLabel={t("products.gptGenerate.fields.tags.label")}
              descriptionCopy={t(
                "products.gptGenerate.fields.tags.description"
              )}
              helpCopy={t("products.gptGenerate.fields.tags.help")}
              loading={loadingPredictProduct}
              onClickGenerate={() => handleClickGenerate("tags")}
              onClickPredict={predictTagsImages}
            />
          )}
        </ImagesTagsStyled>
        <ProductNamePrice />
        <ProductWholesale />
      </div>
    </ProductDetailWrapper>
  );
};

export default ProductDetail;

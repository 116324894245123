import { FC } from "react";
import { PRODUCTS_EVENTS } from "@dashboard/modules/products/constants/events.products";
import {
  PhotosTitle,
  ProductUploadImagesWrapper,
  UploadImageError,
} from "./product-upload-image.styled";
import Plus from "@sumerkit/icons/plus";
import { useProductUploadImage } from "./product-upload-image.hook";
import { useProductDetail } from "../product-detail/product-detail.hook";

export const ProductUploadImages: FC = () => {
  const {
    values,
    fileInput,
    showErrors,
    IMAGE_MAX_MB,
    selectFile,
    handleFileChange,
    setShowErrors,
    logEvent,
    t,
  } = useProductUploadImage();
  const { openOrganizeImages } = useProductDetail();

  return (
    <>
      <PhotosTitle>
        <small className="text">{t("admin.products.side.photos.title")}</small>
        {values.imagesForm?.length !== 0 && (
          <div className="editContainer" onClick={() => openOrganizeImages()}>
            <span className="icon icon-pencil-line editIcon"></span>
            <small className="editText">
              {t("admin.products.side.photos.edit")}
            </small>
          </div>
        )}
      </PhotosTitle>
      <ProductUploadImagesWrapper>
        {values.imagesForm && values.imagesForm?.length >= 1 && (
          <div className="principal-image">
            <div className="principal-tag">
              <div className="text">
                {t("productComponent.product.uploadImage.principalTag")}
              </div>
            </div>
            {values.imagesForm[0].type === "file" && (
              <img
                src={URL.createObjectURL(values.imagesForm[0].image as File)}
                alt="Principal"
              />
            )}
            {values.imagesForm[0].type === "url" && (
              <img src={values.imagesForm[0].image as string} alt="Principal" />
            )}
          </div>
        )}
        {values.imagesForm && values.imagesForm?.length <= 1 && (
          <div className="empty-state" onClick={selectFile}>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.webp"
              multiple={true}
              ref={fileInput}
              onChange={handleFileChange}
              onClick={() => {
                setShowErrors(false);
                logEvent(PRODUCTS_EVENTS.SELECT_UPLOAD, {
                  event_subtype: "product_image",
                  source: `${values.id ? "edit" : "create"}`,
                });
              }}
            />
            <Plus width="32" height="32" />
            <div className="text">
              {t("admin.products.side.photos.emptyStateImage")}
            </div>
          </div>
        )}
        {values.imagesForm && values.imagesForm?.length > 1 && (
          <div className="images-container">
            {values.imagesForm?.map(
              (item, i) =>
                i > 0 &&
                i < 4 && (
                  <div
                    className="item-container"
                    key={`image-current-${item.type}-${item.id}`}
                  >
                    <div className="item">
                      {item.type === "file" && (
                        <img
                          src={URL.createObjectURL(item.image as File)}
                          alt=""
                        />
                      )}
                      {item.type === "url" && (
                        <img src={item.image as string} alt="" />
                      )}
                    </div>
                    {values.imagesForm &&
                      i === 3 &&
                      values.imagesForm?.length > 4 && (
                        <div className="overlay">
                          <div className="count-image">
                            +{values.imagesForm.length - 3}
                          </div>
                        </div>
                      )}
                  </div>
                )
            )}
            <div className="item-container">
              <div className="item">
                <div className="upload-image" onClick={selectFile}>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.webp"
                    multiple={true}
                    ref={fileInput}
                    onChange={handleFileChange}
                    onClick={() => {
                      setShowErrors(false);
                      logEvent(PRODUCTS_EVENTS.SELECT_UPLOAD, {
                        event_subtype: "product_image",
                        source: `${values.id ? "edit" : "create"}`,
                      });
                    }}
                  />
                  <Plus width="32" height="32" />
                </div>
              </div>
            </div>
          </div>
        )}
      </ProductUploadImagesWrapper>
      {showErrors && (
        <UploadImageError>
          <p>{t("admin.products.side.photos.validateErrorType")}</p>
          <p>
            {t("admin.products.side.photos.validateErrorSize", {
              size: IMAGE_MAX_MB,
            })}
          </p>
        </UploadImageError>
      )}
    </>
  );
};

export default ProductUploadImages;

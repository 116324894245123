import { actions } from "@dashboard/config/redux";
import {
  DEEPLINKS_MODULES,
  DEEPLINKS_MODULES_DATA,
  DEEPLINKS_QUERYPARAM,
  DEEPLINKS_SESSIONS,
} from "@dashboard/constants/deeplinks.constants";
import {
  Deeplinks,
  DeeplinksModuleData,
  DeeplinksModules,
} from "@dashboard/entities/deeplinks.types";
import { ToBuyItemPlan } from "@dashboard/modules/checkout/entities/to-buy-item.entity";
import { checkoutActions } from "@dashboard/modules/checkout/store/checkout.slice";
import { capitalized } from "@dashboard/utils/strings.utils";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useDeeplinks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const deeplinkQuery = searchParams.get(DEEPLINKS_QUERYPARAM);

  const deeplink = sessionStorage.getItem(DEEPLINKS_SESSIONS.DEEPLINK);
  const deeplinkDataString = sessionStorage.getItem(
    DEEPLINKS_SESSIONS.DEEPLINK_DATA
  );
  const deeplinkData = deeplinkDataString
    ? JSON.parse(deeplinkDataString)
    : undefined;

  const valideDeeplink = () => {
    if (deeplinkQuery) {
      const deeplinkModule =
        DEEPLINKS_MODULES[deeplinkQuery as keyof DeeplinksModules];
      if (deeplinkModule !== undefined) {
        const dataKeys =
          DEEPLINKS_MODULES_DATA[deeplinkQuery as keyof DeeplinksModuleData];
        sessionStorage.setItem(DEEPLINKS_SESSIONS.DEEPLINK, deeplinkQuery);
        if (dataKeys) {
          const data = dataKeys.reduce((previews, current) => {
            return {
              ...previews,
              [current]: searchParams.get(current) || undefined,
            };
          }, {});
          sessionStorage.setItem(
            DEEPLINKS_SESSIONS.DEEPLINK_DATA,
            JSON.stringify(data)
          );
        }
      } else {
        searchParams.delete(DEEPLINKS_QUERYPARAM);
        setSearchParams(searchParams);
      }
    }
  };

  const navigateByDeeplink = (pathOwner: string) => {
    const deeplink = sessionStorage.getItem(DEEPLINKS_SESSIONS.DEEPLINK);
    if (deeplink) {
      const deeplinkModule =
        DEEPLINKS_MODULES[deeplink as keyof DeeplinksModules];
      loadDeeplinkData();
      navigate(`${pathOwner}${deeplinkModule}`);
      clearDeeplinkSession();
    } else {
      navigate(pathOwner);
    }
  };

  const loadDeeplinkData = () => {
    if (Deeplinks.primePayPreview === deeplink && deeplinkData) {
      dispatch(
        checkoutActions.setToBuyItem({
          type: "plan",
          plan_name: capitalized(deeplinkData["benefitName"]) as ToBuyItemPlan,
          plan_duration: capitalized(deeplinkData["durationTag"]),
        })
      );
    } else if (Deeplinks.evoMessage === deeplink && deeplinkData) {
      dispatch(actions.openAiAssistant(deeplinkData["message"]));
    }
  };

  const clearDeeplinkSession = () => {
    sessionStorage.removeItem(DEEPLINKS_SESSIONS.DEEPLINK);
    sessionStorage.removeItem(DEEPLINKS_SESSIONS.DEEPLINK_DATA);
  };

  return { deeplink, deeplinkData, valideDeeplink, navigateByDeeplink };
};

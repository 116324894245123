import { FC } from "react";
import { useTranslation } from "react-i18next";
import { InConstructionWrapper } from "./in-construction.styled";
import { useHome } from "../../hooks/home.hooks";
import UiModal from "@dashboard/components/ui-modal";
import inConstructionToolImg from '@dashboard/assets/img/in-construction-tool.webp';
import { HookInfo } from "@dashboard/entities/hook-info";

interface InConstructionProps {
  hookInfo?: HookInfo;
}
 
const InConstruction: FC<InConstructionProps> = () => {

  const { t } = useTranslation();
  const { showInConstruction, handleToggleShowInConstruction } = useHome();

  return (
    <InConstructionWrapper>
      <UiModal show={showInConstruction} onClose={handleToggleShowInConstruction} className="modal">
        <img src={inConstructionToolImg} alt="En construcción" className="img" />
        <div className="title">{t('home.inConstruction.title')}</div>
        <div className="description">{t('home.inConstruction.description')}</div>
        <div className="button" onClick={handleToggleShowInConstruction}>
          {t('home.inConstruction.button')}
        </div>
      </UiModal>
    </InConstructionWrapper>
  );
}
 
export default InConstruction;
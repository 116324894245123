import { FC } from "react";
import { BannersWrapper } from "./banners.styled";
import { useHome } from "../../hooks/home.hooks";
import { HomeBanner } from "../../constants/home.constants";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "@dashboard/common/analytics";
import { HomeEvent, HomeEventSubtype } from "../../constants/home.events";

interface BannersProps {
  
}
 
const Banners: FC<BannersProps> = () => {

  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const { banners } = useHome();

  const handleCtaClick = (banner: HomeBanner) => {
    logEvent(HomeEvent.SelectBanner, {
      event_subtype: HomeEventSubtype.HomeWeb,
      option: banner.route,
    });
    if (banner.isExternalRoute) {
      window.open(banner.route, '_blank');
    } else {
      navigate(banner.route);
    }
  };

  return (
    <BannersWrapper>
      {banners.slice(0,1).map((banner, i) => (
        <div key={`banner-${i}`} className="item" style={{
          backgroundImage: `url(${banner.bg})`,
          color: banner.textsColorHex,
        }}>
          <div className="left">
            <div className="title">{banner.title}</div>
            <div className="description">{banner.description}</div>
            <button
              className="button"
              style={{
                backgroundColor: banner.ctaBackgroundHex,
                color: banner.ctaColorHex,
              }}
              onClick={() => handleCtaClick(banner)}>
              {banner.cta}
              <span className="icon-arrow" />
            </button>
          </div>
          <div className="right">
            <img src={banner.img} alt="Banner" className="img" />
          </div>
        </div>
      ))}
    </BannersWrapper>
  );
}
 
export default Banners;
import styled from "styled-components";

export const StatsInfoBoxWrapper = styled.div`
  flex: 1;
  row-gap: 4px;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  .value {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--grey-b, #24272E);
    &.free, &.basic {
      filter: blur(10px);
    }
    &.abandoned-carts.pro, &.abandoned-carts.legacy {
      filter: blur(10px);
    }
    &.all-products {
      filter: blur(0px);
    }
  }
  .label {
    display: flex;
    column-gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-items: center;
    color: var(--grey-3, #3E434E);
  }
  .percentage {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-items: center;
    &.green {
      color: var(--green-main, #3ABD7C);
      .icon-arrow-3 {
        transform: rotate(225deg);
      }
    }
    &.red {
      color: var(--red-main, #FF6E6E);
      .icon-arrow-3 {
        transform: rotate(45deg);
      }
    }
  }
  .add-products {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-main, #1458F5);
  }
`;

import { useSelector } from "react-redux";
import { partnerSelector, useAppDispatch } from "@dashboard/config/redux";
import {
  fetchConfigurationsPersonalization,
  fetchEconomicSectors,
  fetchSplitUsers,
} from "../services/onboarding.services";
import { storeDesignSplitSelector } from "../store/onboarding.selectors";
import { actions } from "../store/onboarding.slice";
import { useState } from "react";
import { TemplateTypeEnum } from "../constants/template.constants";
import { DataTemplate } from "../entities/configuration.entities";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@sumerlabs/component-library";

export const useOnboarding = () => {
  const partner = useSelector(partnerSelector);
  const storeSplit = useSelector(storeDesignSplitSelector);
  const dispatch = useAppDispatch();
  const [templates, setTemplates] = useState<Array<DataTemplate>>([]);
  const { i18n } = useTranslation();
  const [token] = useLocalStorage("accessToken", null);

  const getFetchSplitUsers = () => {
    if (storeSplit) {
      return storeSplit;
    } else {
      fetchSplitUsers({
        attributes: {},
        feature: "onboarding_templates_web",
        userId: partner?.userId || "",
        includeProfile: true,
      }).then((res) => {
        dispatch(actions.setStoreDesignSplit(res));
      });
    }
  };

  const getFetchConfigurations = () => {
    fetchConfigurationsPersonalization(token || '')
      .then((res) => {
        const templ = res.filter(
          (temp) => temp.type === TemplateTypeEnum.CATALOGUE_TEMPLATES
        );
        if (templ.length) {
          const auxTempl = templ[0].data?.templates[0].template?.map(
            (template) => {
              return {
                id: template.id,
                onboardingImage: template.onboardingImage,
                title: template.title,
                showControls: false,
              };
            }
          );
          setTemplates(auxTempl);
        } else {
          setTemplates([]);
        }
      })
      .catch(() => {
        setTemplates([]);
      });
  };

  const getEconomicSectors = () => {
    fetchEconomicSectors(i18n.language)
      .then((response) => {
        dispatch(actions.setEconomicSectors(response));
      })
      .catch();
  };

  return {
    getFetchSplitUsers,
    getFetchConfigurations,
    templates,
    setTemplates,
    getEconomicSectors,
  };
};

import { BulkUploadProductsResponse } from "../types/bulk-upload-products.types";
import { Category, Product } from "../types/products.types";
import { ProductsUploaderResponse } from "../views/bulk-upload-products/bulk-upload-products.types";

export interface ProductsState {
  ui: ProductsUiState;
  data: ProductsDataState;
}

export interface ProductsUiState {
  isFetchingOrchestrator?: boolean;
  isLoadingImagesFile: boolean;
  isLoadingProductsFile: boolean;
  loadingProductsProgress: number;
  loadingImagesProgress: number;
  isProductsFileLoaded: boolean;
  isImagesFilesLoaded: boolean;
  showNotAvailableHook: boolean;
  initProcessMasiveUpload?: boolean;
  actionDiscardProcessUpload?: () => void;
}

export interface ProductsDataState {
  products: Product[];
  categories: Category[];
  productsUploaderResponse: ProductsUploaderResponse | null;
  imagesUploaderResponse: Record<string, string> | null;
  bulkProductsResult?: BulkUploadProductsResponse;
}

export const initialState: ProductsState = {
  ui: {
    isLoadingProductsFile: false,
    isProductsFileLoaded: false,
    isImagesFilesLoaded: false,
    isLoadingImagesFile: false,
    loadingProductsProgress: 0,
    loadingImagesProgress: 0,
    showNotAvailableHook: false,
  },
  data: {
    products: [],
    categories: [],
    productsUploaderResponse: null,
    imagesUploaderResponse: null,
  },
};

import styled from "styled-components";

export const StepCustomizationWrapper = styled.div`
  display: flex;
  column-gap: 100px;
  min-height: 100vh;
  flex-direction: column;
  .header {
    z-index: 1;
    height: 74px;
    display: flex;
    padding: 0 24px;
    align-items: center;
    background: var(--grey-w, #fff);
    box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
    .back {
      width: 48px;
      height: 48px;
      display: flex;
      cursor: pointer;
      border-radius: 8px;
      align-items: center;
      border: 1px solid #000;
      justify-content: center;
      background: var(--grey-w, #fff);
      box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
        2px 4px 10px 0px rgba(53, 53, 53, 0.04);
      .arrow {
        font-size: 24px;
        transform: rotate(-90deg);
      }
    }
    .toggle-buttons {
      height: 48px;
      padding: 4px;
      display: flex;
      background: #fff;
      column-gap: 4px;
      margin: 0 0 0 22px;
      align-items: center;
      border-radius: 50px;
      border: 1px solid var(--grey-5, #9b9eab);
      button {
        width: 56px;
        height: 40px;
        cursor: pointer;
        border-radius: 47px;
        background: #ffffff;
        &.active {
          background: ${(props) => `${props.theme.colors.grayScale.main}`};
          .icon {
            color: #ffffff;
          }
        }
        .icon {
          font-size: 24px;
        }
      }
    }
    .controlsIA {
      width: -webkit-fill-available;
      display: flex;
      justify-content: center;
      .buttonIA {
        width: fit-content;
        display: flex;
        height: 48px;
        padding: 8px;
        align-items: center;
        border-radius: 28px;
        border: 1px solid var(--Grey-B, #24272e);
        background: var(--Grey-8, #fcfcfc);
        box-shadow: 3px 4px 13px -4px rgba(180, 180, 180, 0.18),
          2px 4px 10px 0px rgba(53, 53, 53, 0.04);
        > figure {
          font-size: 30px;
        }
        > span {
          color: var(--Grey-B, #24272e);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-left: 4px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .next {
      margin: 0 0 0 auto;
      .button {
        border-radius: 8px;
        background: ${(props) => `${props.theme.colors.grayScale.main}`};
        .arrow {
          transform: rotate(-90deg);
        }
      }
    }
  }
  .container {
    flex: 1;
    height: 100%;
    display: flex;
    position: relative;
    .preview {
      flex: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      .iframe {
        padding: 0;
        width: 100%;
        border: none;
        height: 100%;
      }
    }
    .preview-mobile {
      flex: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      .frame {
        height: 100%;
        margin: auto;
        display: flex;
        position: relative;
        .bg {
          top: 50%;
          z-index: 1;
          position: absolute;
          right: calc(50% - 0.3px);
          height: calc(600px + 26px);
          transform: translate(50%, -50%);
          min-width: calc(320px + 34px);
          @media screen and (max-height: 40rem) {
            height: calc(465px);
            min-width: calc(214px);
            z-index: 3;
          }
        }
        .iframe {
          z-index: 4;
          padding: 0;
          border: none;
          margin: auto;
          height: 600px;
          width: 320px;
          border-radius: 46px;
          @media screen and (max-height: 40rem) {
            height: 445px;
            width: 207px;
            border-radius: 24px;
          }
        }
      }
    }
  }
`;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NewOrdersWrapper } from "./new-orders.styled";
import { useHome } from "../../hooks/home.hooks";

interface NewOrdersProps {
  
}
 
const NewOrders: FC<NewOrdersProps> = () => {

  const { t } = useTranslation();
  const { newOrders, handleOnOrdersClick } = useHome();
  
  return !!newOrders?.total ? (
    <NewOrdersWrapper onClick={handleOnOrdersClick}>
      <div className="texts">
        <div className="title">
          {t('home.orders.title')} <span className="point" />
        </div>
        <div className="description">
          {t('home.orders.youHas')}{' '}
          <strong className="number">{newOrders?.total}</strong>{' '}
          {t(`home.orders.${newOrders?.total > 1 ? 'newOrdersPlural' : 'newOrdersSingular'}`)}
        </div>
      </div>
      <div className="arrow">
        <span className="icon-arrow" />
      </div>
    </NewOrdersWrapper>
  ) : null;
}
 
export default NewOrders;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./meli.state";

export const meliSlice = createSlice({
  name: "meli",
  initialState,
  reducers: {
    setMeliToken: (state, { payload }: PayloadAction<string>) => {
      state.meliToken = payload;
    },
    setProductsCount: (state, { payload }: PayloadAction<number>) => {
      state.productsCount = payload;
    },
  },
});

export const meliActions = meliSlice.actions;
export const meliReducer = meliSlice.reducer;

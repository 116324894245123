import { FC } from "react";
import ArrowDown from "@sumerkit/icons/arrow-down";
import { HeaderWrapper } from "./header.styled";
import { useNavigate } from "react-router-dom";

const Header: FC = () => {
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <ArrowDown
        className="icon"
        width={20}
        height={20}
        onClick={() => navigate(-1)}
      />
    </HeaderWrapper>
  );
};

export default Header;

import { primeGradient } from "@dashboard/assets/variables/prime";
import styled from "styled-components";

export const ProductSkuWrapper = styled.div`
  width: 100%;
  .sku-input-header {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.75rem;
    .sku-input-icons {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: center;
      position: relative;
      width: 32px;
      .sku-input-icon {
        font-size: 1.25rem;
      }
      .sku-input-prime {
        ${primeGradient}
        align-items: center;
        bottom: -4px;
        border-radius: 50%;
        display: flex;
        font-size: 11px;
        justify-content: center;
        position: absolute;
        height: 20px;
        right: -4px;
        width: 20px;
      }
    }
    .sku-input-title {
      font-size: 0.875rem;
      font-weight: 600;
    }
    .sku-input-description {
      color: #5d606f;
      font-size: 0.75rem;
    }
  }
  .sku-input-field {
    padding: 12px;
    .sw-field-label {
      font-size: 0.75rem;
    }
  }
`;

import { wrapperContainer } from "./variables/container";
import { flexHorizontal, flexVertical } from "./variables/flex";
import { paddingX, paddingY } from "./variables/space";

export const theme = {
    container:{
        wrapperContainer:`${wrapperContainer}`,
      },
    colors: {
        white: "#ffffff",
        black: "#000",
        neutral: "#0E1729",
        baseColor: "#f4f4f4",
        primaryColorScale: {
            p1: "#efeeff",
            p2: "#4c42f6",
            p3: "#5337ff",
            p4: "#0c02c1",
            p5: "#A59BFD",
        },
        secondary:{
            main: "#8A43F5",
            s2:"#9e64f8",
            s3:"#b588f9",
        },
        greenScale: {
            g1: "#dcffea",
            g2: "#00ea96",
            g3: "#0dc382",
            g4: "#02b47f",
            g5: "#129556",
            g6: "#25D366",
            g7:"#01825C",
        },
        redScale: {
            r1: "#ffe4e4",
            r2: "#ff5b24",
            r3: "#e34c1a",
            r4: "#ffb237",
            r5: "#f93c00"
        },
        grayScale: {
            main: "#24272E",
            gr: "#878788",
            gr1: "#fafafa",
            gr2: "#666666",
            gr3: "#888888",
            gr4: "#adadad",
            gr5: "#d2d2d2",
            gr6: "#e4e9f2",
            gr7: "#dddddd",
            gr8: "#E5E5E5",
            gr9: "#BABABA",
            gr10: "#DDE0F5",
            gr11: "#2E5679",
            gr12: "#646464",
            gr13: "#878788",
            gr14: "#0C1220",
            gr15: "#e0e0de",
            gr16: "#ACADAF",
            gr17: "#f0f0f0",
            gr18: "#F3F3F3"
        },
        blueScale: {
            b1: "#0c7df2",
            b2: "#DBD9FD",
            b6: "#8942f6",
            b7: "#0052F1",
            b8: "#4B44F2",
            b9: "#8942F6",
            b10: "#DDE0F5",
            b11: "#16233D",
            b12: "#362FAF"
        },
        blackScale: {
            b1: "#111111",
        },
        purpleScale: {
            p1: "#7879F1",
            p2: "#6F1DF4",
            p3: "#8173fc",
            p4: "#edecfe",
            p5: "#ECDFFF"
    },
    yellowScale: {
        y1: "#FFC300"
    },
        whatsScale: {
            w1: "#2adb26",
        },
        boxShadow: {
            bs1: "#b5b5b5",
        },
    },
    flex: {
        row:`${flexHorizontal}`,
        column: `${flexVertical}`,
    },
    padding: {
        left:{
          small: ".5em",
          normal: "1rem",
          large: "1.5em",
        },
        right:{
          small: ".5em",
          normal: "1rem",
          large: "1.5em",
        },
        bottom:{
          small: ".5em",
          normal: "1rem",
          large: "1.5em",
        },
        top:{
          small: ".5em",
          normal: "1rem",
          large: "1.5em",
        },
        pY:`${paddingY}`,
        pX: `${paddingX}`,
      },
}
import { getEnvVar } from "@dashboard/common/utils";
import * as Sentry from "@sentry/react";

Sentry.init({
  environment:
    getEnvVar("REACT_APP_ENV") === "prod"
      ? "production"
      : getEnvVar("REACT_APP_ENV") === "dev"
      ? "development"
      : "local",
  dsn: getEnvVar("REACT_APP_SENTRY_DSN"),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /https:\/\/(services(\.dev)?|dev)?\.sumerlabs\.com\/.*/,
      ]
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^http:\/\/dashboard.sumerlabs\.com\/api/,
    /^http:\/\/dashboard.dev.sumerlabs\.com\/api/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

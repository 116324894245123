const IconUploadImagesLight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 10.6348C15.25 10.2206 15.5858 9.88477 16 9.88477H27C27.4641 9.88477 27.9092 10.0691 28.2374 10.3973C28.5656 10.7255 28.75 11.1706 28.75 11.6348V25.6348C28.75 26.0989 28.5656 26.544 28.2374 26.8722C27.9092 27.2004 27.4641 27.3848 27 27.3848H5C4.53587 27.3848 4.09075 27.2004 3.76256 26.8722C3.43437 26.544 3.25 26.0989 3.25 25.6348V13.6348C3.25 13.2206 3.58579 12.8848 4 12.8848C4.41421 12.8848 4.75 13.2206 4.75 13.6348V25.6348C4.75 25.7011 4.77634 25.7647 4.82322 25.8115C4.87011 25.8584 4.93369 25.8848 5 25.8848H27C27.0663 25.8848 27.1299 25.8584 27.1768 25.8115C27.2237 25.7647 27.25 25.7011 27.25 25.6348V11.6348C27.25 11.5685 27.2237 11.5049 27.1768 11.458C27.1299 11.4111 27.0663 11.3848 27 11.3848H16C15.5858 11.3848 15.25 11.049 15.25 10.6348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.38477C4.9337 8.38477 4.87011 8.4111 4.82322 8.45799C4.77634 8.50487 4.75 8.56846 4.75 8.63477V12.8848H11.6604C11.7158 12.8843 11.7697 12.8664 11.8143 12.8334C11.814 12.8336 11.8145 12.8333 11.8143 12.8334L14.7491 10.6348L11.815 8.43661C11.8147 8.43643 11.8152 8.43679 11.815 8.43661C11.7704 8.40371 11.7158 8.38521 11.6605 8.38477H5ZM11.6625 13.6348L11.6656 14.3848C12.042 14.3832 12.408 14.2612 12.71 14.0366L12.7122 14.035L16.4497 11.235C16.6387 11.0934 16.75 10.871 16.75 10.6348C16.75 10.3986 16.6387 10.1761 16.4497 10.0345L12.7122 7.23452L12.71 7.23292C12.408 7.00832 12.042 6.88632 11.6656 6.88477L5 6.88477C4.53587 6.88477 4.09075 7.06914 3.76256 7.39733C3.43438 7.72552 3.25 8.17064 3.25 8.63477V13.6348C3.25 14.049 3.58579 14.3848 4 14.3848H11.6625V13.6348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 19.1348C12.25 18.7206 12.5858 18.3848 13 18.3848H19C19.4142 18.3848 19.75 18.7206 19.75 19.1348C19.75 19.549 19.4142 19.8848 19 19.8848H13C12.5858 19.8848 12.25 19.549 12.25 19.1348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15.3848C16.4142 15.3848 16.75 15.7206 16.75 16.1348V22.1348C16.75 22.549 16.4142 22.8848 16 22.8848C15.5858 22.8848 15.25 22.549 15.25 22.1348V16.1348C15.25 15.7206 15.5858 15.3848 16 15.3848Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconUploadImagesLight;

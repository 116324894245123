import { RootState } from "@dashboard/config/redux";
import { createSelector } from "@reduxjs/toolkit";
import { OnboardingAiState } from "./onboarding-ai.state";

export const onboardingAiUrlSelector = createSelector(
  (state: RootState) => state.onboardingAi,
  (state: OnboardingAiState) => state.data.url
);

export const onboardingAiTextSelector = createSelector(
  (state: RootState) => state.onboardingAi,
  (state: OnboardingAiState) => state.data.aiText
);

export const onboardingAiEmptyStateSelector = createSelector(
  (state: RootState) => state.onboardingAi,
  (state: OnboardingAiState) => state.ui.showEmptyState
);

export const onboardingAiLoadIframeSelector = createSelector(
    (state: RootState) => state.onboardingAi,
    (state: OnboardingAiState) => state.ui.loadIframe
  );
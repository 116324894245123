import { FC, useEffect } from "react";
import { useContactButton } from "../../hooks/contact-button.hook";
import {
  ContactButtonPersonalizationOptionId,
} from "../../constants/contact-button.constants";
import { ContactButtonWrapper } from "./contact-button.styled";
import ContactButtonPersonalizationOptions from "../../components/contact-button-personalization-options";
import ContactButtonConfiguration from "../../components/contact-button-configuration";
import ContactButtonMessages from "../../components/contact-button-messages";
import ContactButtonIcon from "../../components/contact-button-icon";
import ContactButtonPosition from "../../components/contact-button-position";
import ContactButtonColor from "../../components/contact-button-color";

interface ContactButtonProps {}

const ContactButton: FC<ContactButtonProps> = () => {
  
  const {
    t,
    showBackButton,
    showApplyButton,
    title,
    description,
    selectedOptionId,
    partner,
    verifyUserHasAddon,
    getAddonInfo,
    onBackClick,
    handleApplyClick,
  } = useContactButton();

  useEffect(() => {
    verifyUserHasAddon();
    getAddonInfo();
  }, [partner]);

  return (
    <ContactButtonWrapper>
      <div className={`header ${!showBackButton && 'withoutBack'}`}>
        {showBackButton && (
          <div className="back" onClick={onBackClick}>
            <div className="icon">
              <span className="icon-arrow" />
            </div>
            <span className="text">
              {t("addons.contact_button.titles.main")}
            </span>
          </div>
        )}
        <div className="title">{t(title)}</div>
        <div className="description">{t(description)}</div>
      </div>
      {selectedOptionId === ContactButtonPersonalizationOptionId.None && (
        <ContactButtonPersonalizationOptions />
      )}
      {selectedOptionId ===
        ContactButtonPersonalizationOptionId.Configuration && (
        <ContactButtonConfiguration />
      )}
      {selectedOptionId === ContactButtonPersonalizationOptionId.Messages && (
        <ContactButtonMessages />
      )}
      {selectedOptionId === ContactButtonPersonalizationOptionId.Position && (
        <ContactButtonPosition />
      )}
      {selectedOptionId === ContactButtonPersonalizationOptionId.Color && (
        <ContactButtonColor />
      )}
      {selectedOptionId === ContactButtonPersonalizationOptionId.Icon && (
        <ContactButtonIcon />
      )}
      {showApplyButton && (
        <button className="button" onClick={handleApplyClick}>
          {t("addons.contact_button.apply")}
        </button>
      )}
    </ContactButtonWrapper>
  );
};

export default ContactButton;

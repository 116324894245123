import { KustomerResponse } from "@dashboard/common/types";
import { fetchKustomerToken } from "@dashboard/services/kustomer.service";
import { useLocalStorage } from "@sumerlabs/component-library";
import { useCallback } from "react";

export const useKustomer = () => {
  const [token] = useLocalStorage("accessToken", "");
  const KUSTOMER_BRAND = "63ec69e99d9d759e7ac6553e";

  const init = (callback?: () => void) => {
    if (typeof Kustomer !== "undefined") {
      Kustomer.start(
        {
          brandId: KUSTOMER_BRAND,
          hideChatIcon: true,
        },
        callback || login
      );
    }
  };

  const login = useCallback(() => {
    fetchKustomerToken(token).then((data: KustomerResponse) => {
      if (data.token) {
        if (typeof Kustomer !== "undefined") {
          Kustomer.login({
            jwtToken: data.token,
          });
        }
      }
    });
  }, [token]);

  return {
    init,
    login,
  };
};

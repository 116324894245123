import { useAnalytics } from "@dashboard/common/analytics";
import { useAppDispatch } from "@dashboard/config/redux";
import { useSession } from "@dashboard/hooks/use-session.hook";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  OptionOnboarding,
  MethodOnboarding,
  SourceOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { actions } from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.slice";
import { useLocation } from "react-router-dom";
import { StoreDesignPath } from "../constants/store-design-path.constants";

export const useStepStore = () => {
  const { setWebStatus, decodedToken } = useSession();
  const { logEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const nextStep = (path: string) => {
    logEvent(EventsOnboarding.SELECT_BUTTON, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      option: OptionOnboarding.STORE_DESING,
      method:
        path === StoreDesignPath.GENERATING
          ? MethodOnboarding.AI_GENERATE
          : MethodOnboarding.STANDARD,
    });
    if (path === StoreDesignPath.GENERATING) {
      dispatch(actions.setSourcePath(SourceOnboarding.AI_GENERATE));
    } else {
      dispatch(actions.setSourcePath(SourceOnboarding.TEMPLATES));
    }
    setWebStatus(
      `/${pathname.split("/")[1]}/${path}`,
      decodedToken?.user_id || '',
      false,
      true,
      true
    );
  };

  return {
    nextStep,
    logEvent,
  };
};

import styled from "styled-components";

export const AlertCardStyled = styled.div`
  align-items: flex-start;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px -2px rgba(135, 135, 136, 0.5);
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  overflow: hidden;
  padding: 1.25rem 1rem;
  position: relative;
  &:before {
    background-color: #24272e;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
  }
  .alert-card-icon {
    align-items: center;
    background-color: #24272e;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    font-weight: 600;
    height: 24px;
    justify-content: center;
    min-width: 24px;
    width: 24px;
    * {
      display: block;
    }
  }
  .alert-card-info {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    width: 100%;
  }
  .alert-card-description {
    line-height: 1.5em;
    width: 100%;
  }
  &.alert-card__info {
    &::before {
      background-color: #1458F5;
    }
    .alert-card-icon {
      background-color: #1458F5;
    }
  }
  &.alert-card__success {
    &::before {
      background-color: #298457;
    }
    .alert-card-icon {
      background-color: #298457;
    }
  }
  &.alert-card__warning {
    &::before {
      background-color: #ffb237;
    }
    .alert-card-icon {
      background-color: #ffb237;
    }
  }
  &.alert-card__danger {
    &::before {
      background-color: #f93c00;
    }
    .alert-card-icon {
      background-color: #f93c00;
    }
  }
`;

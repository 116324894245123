import { Menu } from '@dashboard/entities/menu';
import { http } from '@dashboard/common/http';
import { getEnvVar } from '@dashboard/common/utils';

export const fetchMenu = async (): Promise<Menu[]> => {
  const response = await http.get("/api/ms/remote-config/country-data/dashboard_menu", {
    headers: {
      'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
    },
  });
  return response.data.value.menu;
};

const locale = {
  product: {
    name: "Product",
    breadcrumb: {
      createPath: "Create product",
      editProduct: "Edit product",
      editPhotos: "Edit photos",
      editFeatures: "Edit features",
      editStock: "Edit stock"
    },
    mapContent: {
      detailLabel: "Detail",
      inventoryLabel: "Inventory",
      productInfoLabel: "Product information",
    },
    productDetail: {
      title: "Detail"
    },
    uploadImage: {
      principalTag: "Principal",
    },
    editImage: {
      title: "Edit photos",
      description: "Drag and drop photos to change the order"
    },
    productInventory: {
      title: "Inventory"
    },
    productInformation: {
      title: "Product information"
    },
    productFeatures: {
      stock: {
        title: "Stock"
      },
      featureForm: {
        title: "Features",
        featureNameLabel: "Feature",
        optionsLabel: "Options",
        addLink: "Add other feature"
      }
    }
  },
};

export default locale;

import { http } from "@dashboard/common/http";
import { AddonInfo } from "@dashboard/common/types";

export const fetchAddonInfo = async (
  token: string, countryName: string, addonTypeId: string,
): Promise<AddonInfo | undefined> => {
  const response = await http.get<any>(
    `/api/ms/addon/calculator/by-country/${countryName}`,
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    }
  );
  if (response.status === 200) return response.data.find((d: any) => d.addon_type_id === addonTypeId);
  else return;
};

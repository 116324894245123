import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #24272e;
    &:hover {
      cursor: pointer;
    }
  }
  .icon {
    margin-bottom: 16px;
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 200px;
    background: var(--yellow-5, #fff7eb);
    > figure {
      font-size: 40px;
      color: #b37d27;
    }
  }
  .title {
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .description {
    margin-top: 8px;
    color: var(--grey-b, #24272e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .link {
    display: flex;
    height: 48px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    > span {
      color: var(--primary-main, #1458f5);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration-line: underline;
      margin-top: 16px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .confirmButton {
    width: 150px;
  }
`;

import { useLocalStorage } from "@sumerlabs/component-library";
import { useCallback, useState } from "react";
import { PredictProductPayload } from "../types/products-gpt.types";
import { getPredictProductFetch } from "../fetchers/product-gpt.fetchers";
import { getEnvVar } from "@dashboard/common/utils";

export const useProductGpt = () => {
  const [accessToken] = useLocalStorage("accessToken", null);
  const [loading, setLoading] = useState(false);

  const predictProduct = useCallback(
    async (
      payload: Omit<PredictProductPayload, "image"> & { image: string | File }
    ) => {
      if (accessToken) {
        setLoading(true);
        if (typeof payload.image !== "string") {
          return getPredictProductFetch(accessToken, {
            ...payload,
            image: payload.image as File,
          })
            .then((data) => data.visualGptResponse)
            .finally(() => {
              setLoading(false);
            });
        } else {
          try {
            const imageUrl = new URL(payload.image);
            let imageHref = imageUrl.href;
            if (
              ["prod", "dev"].includes(getEnvVar("REACT_APP_ENV")) &&
              imageHref.indexOf("sumerlabs.com") >= 0
            ) {
              imageHref = imageUrl.pathname;
            }
            const image = await fetch(imageHref as string);
            const imageBlob = await image.blob();

            return getPredictProductFetch(accessToken, {
              ...payload,
              image: imageBlob as File,
            })
              .then((data) => data.visualGptResponse)
              .finally(() => {
                setLoading(false);
              });
          } catch (error) {
            setLoading(false);
            return Promise.reject(false);
          }
        }
      }
      return Promise.reject(false);
    },
    [accessToken]
  );

  return { loading, predictProduct };
};

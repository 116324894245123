import { http } from "@dashboard/common/http";
import { AbandonedCart } from "../entities/abandoned-cart";

export const fetchAbandonedCarts = async (token: string) => {
  const response = await http.get<AbandonedCart[]>(
    `/api/ms/calculator/shopping-cart/abandoned-view`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const sendRecoveryEmail = async ({ cartId, token }: { cartId: string, token: string }) => {
  const response = await http.put<AbandonedCart>(
    `/api/ms/calculator/shopping-cart/abandoned-view/${cartId}/send-email-recovery`, null, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
  );
  return !!response.data;
};

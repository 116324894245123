import { useLocalStorage } from "@sumerlabs/component-library";
import { useCallback, useMemo, useState } from "react";
import {
  fetchCreateFeedback,
  fetchCreateMessage,
  fetchGetFeedbacks,
  fetchGetRecentMessages,
  fetchRegenerateMessage,
} from "../fetchers/ai-assistant.fetchers";
import { partnerSelector } from "@dashboard/config/redux";
import { useSelector } from "react-redux";
import { Unsubscribe, onValue, ref as refFire } from "firebase/database";
import { db } from "@dashboard/firebase";
import { camelizeKeys } from "humps";
import { AiMessage, AiMessageFirebaseResponse } from "../types/messages.types";
import { fetchGetQuickAnswers } from "../fetchers/quick-answers.fetchers";
import { fetchGetInteractions } from "../fetchers/interactions.fetchers";
import { useTranslation } from "react-i18next";

export const useAiAssistantServices = () => {
  const { i18n } = useTranslation();
  const [accessToken] = useLocalStorage("accessToken", null);
  const partnerData = useSelector(partnerSelector);
  const [showSplash, setShowSplash] = useState(true);
  const [firebaseSuscription, setFirebaseSuscription] = useState<Unsubscribe>();

  const waitingMessage = useMemo(() => !!firebaseSuscription, []);

  const waitMessage = useCallback(
    (pathFirebase: string) => {
      const path = `${process.env.REACT_APP_ENV}/${pathFirebase}`;
      const ref = refFire(db, path);
      setFirebaseSuscription((firebaseSuscription) => {
        firebaseSuscription && firebaseSuscription();
        return undefined;
      });
      return new Promise<AiMessageFirebaseResponse>((resolve, reject) => {
        const suscription = onValue(
          ref,
          async (snapshot) => {
            if (snapshot.exists()) {
              const data = camelizeKeys(snapshot.val());
              setFirebaseSuscription(() => undefined);
              if (data.state === "SUCCESS") {
                resolve({
                  botMessageData: {
                    ...data.message.botMessageData,
                    ownerId: partnerData?.userId,
                  },
                  inputMessageData: {
                    ...data.message.inputMessageData,
                    ownerId: partnerData?.userId,
                  },
                } as AiMessageFirebaseResponse);
              } else {
                reject(data.message.message);
              }
            }
          },
          (error) => {
            setFirebaseSuscription(() => undefined);
            reject(error);
          }
        );
        setFirebaseSuscription(() => suscription);
      });
    },
    [partnerData]
  );

  const getRecentMessages = useCallback(() => {
    if (accessToken) {
      setShowSplash(true);
      return Promise.all([
        fetchGetRecentMessages(accessToken).then((messages) => {
          return messages.sort((item1, item2) => {
            const date1 = new Date(item1.createdAt);
            const date2 = new Date(item2.createdAt);
            return date1 > date2 ? 1 : -1;
          });
        }),
        new Promise((resolve) => setTimeout(() => resolve(undefined), 3050)),
      ])
        .then((data) => data[0])
        .finally(() => {
          setShowSplash(false);
        });
    }
  }, [accessToken]);

  const getFeedbacks = useCallback(() => {
    if (accessToken) {
      return fetchGetFeedbacks(accessToken);
    }
  }, [accessToken]);

  const createMessage = useCallback(
    ({ message, history }: { message: string; history?: AiMessage[] }) => {
      if (accessToken && partnerData) {
        return fetchCreateMessage({
          token: accessToken,
          payload: { message, history, ownerId: partnerData.userId },
        });
      }
    },
    [accessToken, partnerData]
  );

  const createFeedback = useCallback(
    (message: AiMessage, approved: boolean) => {
      if (accessToken) {
        return fetchCreateFeedback({
          token: accessToken,
          payload: {
            messageId: message.messageId,
            conversationId: message.conversationId || "",
            approvedResponse: approved,
          },
        });
      }
    },
    [accessToken]
  );

  const getQuickAnswers = useCallback(() => {
    return fetchGetQuickAnswers().then((questions) => {
      const { language } = i18n;
      const filterQuestions = questions.filter(
        (question) =>
          language.indexOf(String(question.language || "").toLowerCase()) >= 0
      );

      return filterQuestions.length > 0
        ? filterQuestions
        : questions.filter(
            (question) => String(question.language || "").toLowerCase() === "es"
          );
    });
  }, []);

  const regenerateMessage = useCallback(
    ({
      message,
      inputMessageId,
    }: {
      message?: AiMessage;
      inputMessageId?: string;
    }) => {
      if (accessToken && partnerData) {
        return fetchRegenerateMessage({
          token: accessToken,
          payload: {
            inputMessageId: inputMessageId || "",
            outputMessageId: message?.messageId || "",
            message: "",
            conversationId: message?.conversationId || "",
            ownerId: message?.ownerId || "",
          },
        });
      }
    },
    [accessToken, partnerData]
  );

  const getGetInteractions = useCallback(() => {
    if (accessToken) {
      return fetchGetInteractions(accessToken);
    }
  }, [accessToken]);

  return {
    showSplash,
    waitingMessage,
    getRecentMessages,
    createMessage,
    waitMessage,
    getFeedbacks,
    createFeedback,
    getQuickAnswers,
    regenerateMessage,
    getGetInteractions,
  };
};

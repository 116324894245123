const locale = {
  name: "Products",
  options: {
    addProduct: "Add product",
    massiveUpload: "Bulk upload",
    exportInventory: "Export inventory",
  },
  loadingProducts: "Loading products",
  product: {
    options: {
      highlight: "Highlight",
      highlighted: "Highlighted",
      available: "Available",
      unavailable: "Unavailable",
      seeAvailability: "See availability",
    },
    moreActions: "More actions",
    actions: {
      activate: "Activate",
      deactivate: "Deactivate",
      highlight: "Highlight",
      duplicate: "Duplicate",
      delete: "Delete",
    },
    inventory: {
      soldOut: "Not available",
      available: "{{units}} available",
      availablePlural: "{{units}} available",
      alwaysAvailable: "Always available",
      variations: {
        limited: "{{count}} items out of stock",
        limitedAvailable: "{{count}} in stock",
        alwaysAvailable: "{{count}} items with unlimited stock",
      },
    },
    share: {
      whatsapp: {
        button: "Whatsapp",
        message:
          "Hello! We now have {{name}} available, buy it now in my online store: {{link}}",
      },
      copylink: {
        button: "Copy link",
        confirm: "The link to your product has been successfully copied.",
      },
    },
    fake: "Sample product",
  },
  empty: {
    title: "You don't have any products / services yet",
    description:
      "Create your first products/services and start selling in your store.",
    addProduct: "Create a product",
    bulkUploadProducts: "Bulk upload with template",
    bulkUploadProductsXls: "Bulk upload with your own document",
    help: "Do you need help with your bulk upload?",
  },
  list: {
    name: "product list",
    options: {
      selectAll: "Select all",
      deselectAll: "Deselect all",
    },
    search: {
      placeholder: "Search product...",
    },
    meliAlert: {
      title: "{{count}} products have been successfully imported from Mercado Libre",
      description: "You can configure the price or inventory in Sumer, however, they will not be updated in Mercado Libre."
    }
  },
  bulkUploadFile: {
    name: "Bulk upload",
    bulkFile: {
      title: "Bulk upload",
      copy: "Add up to 1000 products in bulk via an Excel file. Please note the following steps:",
      template: "Excel file",
      customTemplate: "own file here",
      step_one: "1. Download the",
      step_two: "2. Edit the file with your products",
      step_three: "3. Attach the updated file",
      step_four: "4. You can also attach your own file here",
      select: "Select or drag an Excel file here",
      type_error: "The selected file is not a .xls or .xlsx file",
      max_file_error: "Only one file can be uploaded",
      loadingFile: "Loading file",
      statusTitle: "File status",
      statusAlert:
        "By “skip and continue” we will download a file with the errors that you can correct at the end of the process",
      statusLoadedProductsCount: "Products loaded",
      statusNotLoadProductsCount: "Unloaded products",
      statusReloadFile: "Reload file",
      statusConitnue: "Continue",
      statusConitnueWithErrors: "Skip and continue",
      statusViewErrors: "View errors",
      statusErrorCopy: "File upload failed",
      statusErrorDescription: "There was a problem loading the file, check if:",
      statusErrorCauses: `<li>You are using the Sumer file.</li>
        <li>The file has not been modified.</li>
        <li>The file has content.</li>
        <li>The file has the number of allowed products.</li>`,
      errorsTitle: "Errors in file",
      errorsCopy:
        "We have generated a file with the errors highlighted, check if:",
      errorsCauses: `<li>There are unfilled fields.</li>
      <li>There are fields that exceed the character limit. </li>`,
      errorsDownloadFile: "Download file with errors",
      errorsItem: {
        row: "Row",
        column: "Columns",
        emptyName: "No name",
      },
    },
    images: {
      name: "Photo upload",
      bulkUploadProducts: {
        title: "Bulk load status",
        copy: "Congratulations, you have reached the last step to finish your bulk upload!",
        upload: "Products uploaded successfully",
      },
      bulkUpload: {
        title: "Bulk photo upload",
        copy: `Select or drag a folder with the photos (.png, jpg,
          webp) of your products.
          <strong>
            Make sure the name of the photo matches the name of the
            Excel template.
          </strong>`,
        action: "Load photo folder",
      },
      manualUpload: {
        title: "Manual upload of photos",
        copy: `Upload photos of each of your products manually.
          <strong>
            Make sure each item has a photo that shows it.
            represent.
          </strong>`,
        action: "Upload photos manually",
      },
    },
    bulkImages: {
      name: "Bulk photo upload",
      title: "Upload the folder with the photos",
      copy: `Select or drag a folder with photos (.png, jpg, webp) of your products. <strong>Please note that the name of the photo must match the name in the Excel file.</strong>`,
      step_one: "1. Create a folder with your photos",
      step_two:
        "2. Make sure the name of the photos matches the name you put in the Excel file.",
      step_three: "3. Upload the folder",
      select: "Select or drag a folder here",
      directoryNone: "Photos folder",
      loadingFolder: "loading folder",
      statusTitle: "Photo upload status",
      statusAlert:
        "By “skip and continue” we will download a file with the errors that you can correct at the end of the process",
      statusLoadedImagesCount: "Photos uploaded successfully",
      statusWithGenericCount: "Products with test photo",
      statusWithErrorsCount: "Photos with errors",
      statusReloadFile: "Reload photos",
      errorsTitle: "Errors in photos",
      errorsCopy: "Please note:",
      errorsCauses: `<li>The name of the image must be the same as in excel.</li>
        <li>The recommended size 800x800px.</li>`,
      errorsDownloadFile: "Download file with errors",
      errorsItem: {
        row: "Row",
        photos: "Photos",
        emptyName: "No name",
        copy: "Photos not found",
      },
    },
    bulkCustomFile: {
      title: "Upload your own file",
      success: "File uploaded",
      successCopy:
        "Thank you for uploading your file. Within 48 business hours, you will receive confirmation that your products have been uploaded to the system.",
      contactSupport: "Contact support",
      supportCopy: `Hello Summer, I upload my own product file from Sumer Web and I would like to ask you about:
`,
    },
    discardConfirm: {
      title: "Test photos",
      copy: "If you decide not to upload photos, all your products that do not have a photo will use a test image, while you upload a photo of it.",
      buttonDiscard: "Skip and use test photos",
      continue: "Use test photos",
    },
    cancelConfirm: {
      title: "Warning",
      copy: "You have not completed the bulk upload process, returning could lose all your progress.",
    },
    create: {
      name: "Creating...",
      copy: "Creating products",
      errors: {
        unknown:
          "An unknown error occurred on the server. Products cannot be created in bulk.",
      },
    },
    summary: {
      name: "Summary",
      title: "Bulk upload summary",
      copy: "Congratulations, you finished the bulk upload flow!",
      success: "Products created successfully",
      warning: "Products created with test photo",
    },
    retry: {
      title: "Summary",
      copy: "Your products could not be created due to the capacity of your plan, review the number of products, allowed photos and variations in your current plan.",
      copyRetry: "Retry the bulk upload after upgrading your plan.",
      copyRetryError:
        "If you have already purchased a new plan, an error may have occurred, please retry your bulk upload.",
      button: "Retry",
      finish: "Finish bulk upload",
    },
    downloadProducts: {
      error: "Error exporting inventory",
    },
  },
  newProduct: {
    title: "Edit new product",
    description: "Test description",
  },
  deleteProduct: {
    title: "Delete product",
    copy: "Are you sure you want to delete this product?",
    multiple: {
      title: "Delete products",
      copy: "Are you sure you want to delete these {{count}} products?",
    },
    confirm: "Yes, delete",
  },
  duplicateProductRename: {
    field: "Product name to duplicate",
    confirm: "Yes, duplicate",
    cancel: "Cancel",
  },
  improvePlan: {
    title: "Improve your plan",
    titleGrow: "Grow without limits",
    copy: "Functionality available in Pro and Ultra plans. Upgrade to have unlimited features.",
    copyMaxProducts:
      "You've reached your product limit on your current plan. Get unlimited features by upgrading your plan.",
    cancel: "Maybe later",
    confirm: "Know the plans",
    confirmUnlock: "Unlock now",
  },
  alertPopup: {
    title: "Stock",
    description:
      "You have not modified the stock quantity of your variations, are you sure you want to continue?",
    confirmLabel: "Continue",
  },
  getFunctionality: {
    title: "Get this feature!",
    copy: "Upgrade your plan now to have this and more features.",
    confirmed: "I want to know more",
  },
  stockHook: {
    title: "Control your inventory",
    description: "Never lose a sale due to lack of products with real-time stock control.",
  },
  gptGenerate: {
    title: "Add-on <br /> Artificial Intelligence",
    generate: "Generate",
    generateField: "Generate {{fieldLabel}}",
    goToAddons: "Go to Add-ons",
    loading: "Generating {{fieldLabel}}",
    fields: {
      name: {
        label: "Name",
        description:
          "Let Artificial Intelligence help you by adding the best name for your product",
      },
      description: {
        label: "Description",
        description:
          "Let Artificial Intelligence help you with the description of your product/service.",
        help: "Remember to have the name of your product/service written so that the AI works.",
      },
      tags: {
        label: "Tags",
        generate: "Add tags to image",
        description:
          "Let Artificial Intelligence help you by adding tags to the product image.",
        help: "With labels on your products you will have greater positioning in Google. Remember that the result will depend on the quality of the image.",
      },
    },
    mistake: {
      title: "Try again later",
      description:
        "The service is not available at the moment, please try again later",
      action: "Try later",
    },
    retry: {
      title: "An error occurred",
      description:
        "We're sorry, there was an error generating Sumer AI. Please try again.",
      action: "Try again",
    },
    hook: {
      title: "Available in the <span>Ultra</span> plan",
      copy: "Enjoy all the features of Sumer without limits or <strong>purchase AI Sumer for only {{price}}.</strong>",
      subscribe: "Subscribe to the Ultra plan",
      buy: "Acquire AI Sumer",
      subscriptionHelp: "Automatic recurring payment, cancel anytime",
    },
  },
};

export default locale;

import { AddonInfo } from "@dashboard/common/types";
import { DomainState } from "../constants/domain.constants";
import { SearchDomainResult } from "../entities/search-domain.entity";
import { DomainInfo } from "../entities/domain-state.entity";
import { PaymentGatewayErrorAuth, PaymentGatewayOptions } from "../entities/payment-gateway.entity";

export interface ConfigurationState {
 ui: ConfigurationUiState;
 data: ConfigurationDataState;
};

export interface ConfigurationUiState {
  showEditDomainModal: boolean;
  isLoadingSearchDomain: boolean;
  lastSearchDomain: string;
};
export interface ConfigurationDataState {
  domainState: DomainInfo;
  addonInfo?: AddonInfo;
  searchDomainResults: SearchDomainResult[];
  paymentGateway: PaymentGatewayOptions[];
  paymentGatewayErrorAuth?: PaymentGatewayErrorAuth;
};

export const initialState: ConfigurationState = {
  ui: {
    lastSearchDomain: '',
    showEditDomainModal: false,
    isLoadingSearchDomain: false,
  },
  data: {
    domainState: { state: DomainState.Idle },
    searchDomainResults: [],
    paymentGateway: []
  },
};

import styled from "styled-components";

export const StoreDescriptionWrapper = styled.div`
  display: flex;
  width: 416px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  .title {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
  }
  .description {
    color: var(--Grey-400, #5d606f);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
`;

import styled from "styled-components";

export const ProductWholeSaleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--grey-border, #e9e9ed);
  margin-top: 16px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  padding: 16px 12px;
  align-items: center;
  justify-content: space-around;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grey-w, #fff);
  .icon {
    font-size: 20px;
  }
  .title {
    color: var(--grey-b, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .subTitle {
    color: var(--grey-4, #5d606f);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
  }
  .switchContainer {
    display: flex;
    width: 100%;
    text-align: right;
    align-items: baseline;
    justify-content: end;
  }
`;

export const QuantityWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 100%;
  .description {
    color: var(--Grey-B, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grey-8, #fcfcfc);
  justify-content: space-between;
  width: 100%;
  .text {
    color: var(--grey-b, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 10px;
  .inputPrice {
    width: 100%;
  }
`;

export const PriceDetail = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  .text {
    display: flex;
    height: 31px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: var(--Grey-B, #24272e);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    flex: 1 0 0;
  }
  .price {
    color: var(--Grey-B, #24272e);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const DiscountDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  .text {
    display: flex;
    width: 177.5px;
    align-items: center;
    gap: 4px;
    color: var(--Grey-B, #24272e);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    flex: 1 0 0;
  }
  .price {
    color: var(--Grey-B, #24272e);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 10px;
  .text {
    color: var(--grey-b, #24272e);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .discount {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 28px;
    border: 1px solid var(--error-2, #ab0d02);
    background: var(--error-7, #feece6);
  }
  .total {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 28px;
    border: 1px solid var(--success-main, #3abd77);
    background: var(--success-7, #dff7ed);
  }
`;

const GreenCheck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      {...props}
    >
      <path
        d="M14.6628 21.6973C14.9386 21.6988 15.2044 21.5943 15.4053 21.4053L15.4057 21.405L22.7307 14.405L22.7307 14.405L22.7324 14.4033C22.9233 14.2066 23.0308 13.9436 23.0324 13.6695C23.034 13.3953 22.9296 13.1311 22.7409 12.9322C22.5522 12.7333 22.294 12.6149 22.0201 12.602C21.7463 12.589 21.478 12.6824 21.2714 12.8627L21.2714 12.8626L21.2694 12.8645L14.6625 19.1609L11.7315 16.3654C11.6319 16.263 11.5127 16.1817 11.381 16.1265C11.2489 16.0711 11.107 16.0429 10.9638 16.0438C10.8205 16.0446 10.6789 16.0744 10.5475 16.1313C10.4161 16.1883 10.2975 16.2712 10.1989 16.3751C10.1004 16.4791 10.0239 16.6019 9.97397 16.7361C9.92407 16.8704 9.90184 17.0134 9.90861 17.1565C9.91538 17.2995 9.95101 17.4398 10.0134 17.5687C10.0755 17.6972 10.1629 17.8119 10.2703 17.9059L13.9313 21.4044C13.9314 21.4045 13.9315 21.4046 13.9316 21.4047C14.0282 21.4993 14.1425 21.5738 14.268 21.6241C14.3935 21.6742 14.5277 21.6991 14.6628 21.6973ZM14.6628 21.6973C14.663 21.6973 14.6632 21.6973 14.6634 21.6973L14.6625 21.6348L14.6621 21.6973C14.6624 21.6973 14.6626 21.6973 14.6628 21.6973ZM9.31232 5.87763C11.4399 4.45605 13.9412 3.69728 16.4999 3.69727C19.9292 3.70386 23.2161 5.06903 25.6409 7.49387C28.0658 9.91872 29.4309 13.2056 29.4375 16.6349C29.4375 19.1936 28.6787 21.6949 27.2571 23.8225C25.8356 25.95 23.815 27.6082 21.451 28.5875C19.087 29.5667 16.4857 29.8229 13.976 29.3237C11.4664 28.8245 9.16115 27.5923 7.35181 25.783C5.54247 23.9736 4.31029 21.6684 3.8111 19.1587C3.3119 16.6491 3.56811 14.0478 4.54731 11.6838C5.52652 9.31978 7.18475 7.29922 9.31232 5.87763Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.125"
      />
    </svg>
  );
};

export default GreenCheck;

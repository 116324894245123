import { FC, useEffect } from "react";
import { ContactButtonAddonWrapper } from "./contact-button-addon.styled";
import { useContactButton } from "../../hooks/contact-button.hook";
import { PreviewMode } from "../../entities/contact-button.entities";
import ContactButton from "../../containers/contact-button";
import ContactButtonConfigured from "../../components/contact-button-configured";
import { useTranslation } from "react-i18next";

interface ContactButtonAddonProps {
  
}
 
const ContactButtonAddon: FC<ContactButtonAddonProps> = () => {
  const { t } = useTranslation();
  const {
    showOnboarding,
    catalogueUrl,
    viewMode,
    init,
    handleChangePreviewMode,
    handleToggleOnboarding,
  } = useContactButton();

  useEffect(() => {
    init();
  }, []);

  return (
    <ContactButtonAddonWrapper>
      {showOnboarding && (
        <div className="contact-button-onboarding">
          <div className="overlay" />
          <div className="left-side">
            <div className="frame" />
          </div>
          <div className="right-side">
            <div className="title">{t("addons.contact_button.titles.main")}</div>
            <div className="description">{t("addons.contact_button.descriptions.main")}</div>
            <button className="button" onClick={handleToggleOnboarding}>
              {t("addons.contact_button.got_it")}
            </button>
          </div>
        </div>
      )}
      <div className="preview-side">
        <div className="header">
          <div className="toggle-buttons">
            <button 
              className={viewMode === PreviewMode.Desktop ? 'active' : ``}
              onClick={() => handleChangePreviewMode(PreviewMode.Desktop)}>
              <span className="icon icon-light-desktop" />
            </button>
            <button 
              className={viewMode === PreviewMode.Mobile ? 'active' : ``}
              onClick={() => handleChangePreviewMode(PreviewMode.Mobile)}>
              <span className="icon icon-light-mobile" />
            </button>
          </div>
        </div>
        <div className="catalogue">
          <div className={`frame ${viewMode}-frame`}>
            <ContactButtonConfigured />
            <iframe src={`${catalogueUrl}?hideNetworkButtons=true`} className="iframe" />
          </div>
        </div>
      </div>
      <div className="configuration-side">
        <ContactButton />
      </div>
    </ContactButtonAddonWrapper>
  );
}
 
export default ContactButtonAddon;
import {
  ContactButtonPersonalizationOptionId,
  ContactButtonPositionXAxis,
  ContactButtonPositionYAxis,
} from "../constants/contact-button.constants";

export interface AddonContactButtonState {
  isDirty: boolean;
  hasAddon: boolean;
  buttonConfig: ContactButtonEditPayload;
  oldButtonConfig: ContactButtonEditPayload;
  selectedOptionId: ContactButtonPersonalizationOptionId;
  contactButtonConfigurationOptions: ContactButtonPersonalizationOption[];
};

export interface ContactButtonPersonalizationOption {
  id: ContactButtonPersonalizationOptionId;
  icon: string;
  text: string;
};

export interface ContactButtonIconOption {
  icon: string;
  text: string;
};

export interface ContactButtonPositionOption {
  id: ContactButtonPositionXAxis | ContactButtonPositionYAxis;
  icon: string;
  text: string;
};

export enum PreviewMode {
  Desktop = 'desktop',
  Mobile = 'mobile',
};

export interface ContactButtonEditPayload {
  wp_contact?: {
    configuration?: {
      phone?: {
        prefix?: string;
        number?: string;
      };
      is_hidden?: boolean;
    };
    messages?: {
      general?: string;
      product?: string;
    };
    position?: {
      horizontal?: ContactButtonPositionXAxis;
      vertical?: ContactButtonPositionYAxis;
    };
    icon?: string;
    colors?: {
      background?: string;
      icon?: string;
    };
  };
};

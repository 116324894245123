import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HookInformationModalWrapper } from "./hook-information-modal.styled";
import UiModal from "@dashboard/components/ui-modal";
import inConstructionToolImg from '@dashboard/assets/img/in-construction-tool.webp';
import { actions, hookInfoSelector } from "@dashboard/config/redux";

interface HookInformationModalProps {
}
 
const HookInformationModal: FC<HookInformationModalProps> = () => {
  
  const dispatch = useDispatch();
  const hookInfo = useSelector(hookInfoSelector);

  const handleToggleShowHookInfo = () => {
    dispatch(actions.setHookInfo());
  };

  const handleCtaClick = () => {
    if (hookInfo?.ctaLink) {
      window.open(hookInfo?.ctaLink, '_blank');
    } else {
      handleToggleShowHookInfo();
    }
  };

  return (
    <HookInformationModalWrapper>
      <UiModal
        show={!!hookInfo}
        onClose={handleToggleShowHookInfo}
        className="modal"
      >
        <img
          src={hookInfo?.bgImg || inConstructionToolImg}
          alt="En construcción"
          className="img"
        />
        <div className="copys">
          <div className="title">{hookInfo?.title}</div>
          <div className="description">{hookInfo?.description}</div>
          <div className="button" onClick={handleCtaClick}>
            {hookInfo?.ctaText}
          </div>
        </div>
      </UiModal>
    </HookInformationModalWrapper>
  );
}
 
export default HookInformationModal;
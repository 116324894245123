import { css } from "styled-components";

export const tinyFont = css`
   font-size:12px;
   line-height:130%;
   font-weight: 400;
`;

export const tinyFontBold = css`
   font-size:12px;
   line-height:130%;
   font-weight: 700;
`;

export const smallFont = css`
   font-size:14px;
   line-height:140%;
   font-weight: 400;
`;

export const smallFontBold = css`
   font-size:14px;
   line-height:140%;
   font-weight:700;
`;

export const baseManropeFontBold = css`
   font-size:16px;
   line-height:140%;
   font-weight: 600;
`;

export const baseFont = css`
   font-size:16px;
   line-height:140%;
   font-weight: 400;
`;


export const baseFontBold = css`
   font-size:16px;
   line-height:140%;
   font-weight: 700;
`;

export const mediumManropeFont = css`
   font-size:18px;
   line-height:140%;
   font-weight: 400;
`;

export const mediumFont = css`
   font-size:18px;
   line-height:140%;
   font-weight: 400;
`;

export const mediumFontBold = css`
   font-size:18px;
   line-height:140%;
   font-weight: 700;
`;

export const largeFont = css`
   font-size:20px;
   line-height:140%;
   font-weight: 400;
`;

export const largeFontBold = css`
   font-size:20px;
   line-height:140%;
   font-weight: 700;
`;

export const xLargeFont = css`
   font-size:24px;
   line-height:140%;
   font-weight: 400;
`;

export const xLargeFontBold = css`
   font-size:24px;
   line-height:140%;
   font-weight: 700;
`;

export const bLargeFont = css`
   font-size:26px;
   line-height:140%;
   font-weight: 400;
`;

export const bLargeFontBold = css`
   font-size:26px;
   line-height:140%;
   font-weight: 700;
`;

export const mLargeFont = css`
   font-size:34px;
   line-height:140%;
   font-weight: 400;
`;

export const mLargeFontBold = css`
   font-size:34px;
   line-height:140%;
   font-weight: 700;
`;

export const xllFont = css`
   font-size:40px;
   line-height:140%;
   font-weight: 400;
`;

export const xllFontBold = css`
   font-size:40px;
   line-height:140%;
   font-weight: 700;
`;
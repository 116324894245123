import styled from "styled-components";

export const ProductMapContentWrapper = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 250px;
  position: fixed;
  margin-left: 711px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .item {
      display: flex;
      padding: 8px 0px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .text {
        color: var(--Grey-B, #24272e);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        &.isActive {
          text-decoration-line: underline;
        }
      }
      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
`;

import styled from "styled-components";

export const NavigationBarStyled = styled.section`
  background: #fcfcfc;
  box-shadow: 0px 16px 28px -8px rgba(69, 68, 74, 0.24);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  min-height: calc(100vh - var(--bottom-banner-height, 0px));
  padding: 1.5rem 0.875rem;
  position: relative;
  transition: all 0.2s;
  width: 250px;
  z-index: 100;
  overflow-x: auto;
  .navigation-bar-menu-title {
    color: #9b9eab;
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
  }
  .navigation-bar-divider {
    border-top: #E9E9ED;
    border-left: #E9E9ED;
    height: 0.5px;
    margin: 0;
    width: 100%;
    &__full {
      margin: 0 -0.875rem;
      width: auto;
    }
  }
  .navigation-bar-plan {
    align-items: center;
    border-radius: 0.25rem;
    color: #24272e;
    display: flex;
    font-weight: 600;
    height: 22px;
    padding: 0 0.25rem;
    &__free {
      background: #dff7ed;
      color: #3abd7c;
    }
    &__legacy {
      background: #ffbf59;
    }
    &__basic {
      background: #ffd28b;
    }
    &__pro {
      background: #8dd9b3;
    }
    &__ultra {
      background: linear-gradient(
          100deg,
          #a1bbfb -6.63%,
          #a2bffb 12.62%,
          #d8cfe8 43.92%,
          #c7c1eb 72.95%,
          #9fa7eb 91.75%,
          #a5b5f1 114.2%
        ),
        #dff7ed;
    }
  }
  .navigation-bar-header {
    align-items: center;
    color: #24272e;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s;
    .navigation-bar-icon {
      font-size: 1rem;
      overflow: hidden;
      transition: all 0.2s;
    }
    .navigation-bar-toggle {
      align-items: center;
      background: #ffffff;
      border: 1px solid #24272e;
      border-radius: 0.25rem;
      color: #24272e;
      display: flex;
      font-size: 1rem;
      height: 30px;
      justify-content: center;
      width: 30px;
      [class*="icon-"] {
        &:before {
          display: block;
          transition: all 0.4s;
        }
      }
      .icon-arrow-go-up  {
        display: block;
        transform: rotate(-90deg);
      }
    }
  }
  .navigation-bar-menu {
    display: flex;
    gap: 0.875rem;
    flex-direction: column;
    width: 100%;
    .navigation-bar-menu-link {
      align-items: center;
      background-color: transparent;
      border-radius: 0.25rem;
      color: #24272e;
      display: flex;
      gap: 0.5rem;
      font-size: 0.75rem;
      height: 38px;
      padding: 0 0.5rem;
      transition: all 0.2s;
      white-space: nowrap;
      width: 100%;
      [class*="icon-"] {
        font-size: 1.25em;
      }
      .label {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: space-between;
        overflow: hidden;
      }
      &:hover:not(.navigation-bar-menu-link__active) {
        background-color: #e9e9ed;
      }
      &__active {
        background-color: #24272e;
        color: #ffffff;
      }
      @media only screen and (max-height: 40rem) {
        height: 30px;
      }
      @media only screen and (max-height: 35rem) {
        height: 28px;
      }
    }
    .menu-item__evo {
      .navigation-bar-menu-link {
        justify-content: center;
        &:hover {
          background-color: transparent;
        }
      }
      .icon {
        align-items: center;
        background-color: #24272e;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        font-size: 1.5em;
        height: 28px;
        justify-content: center;
        min-width: 28px;
        width: 28px;
      }
    }
    &__principal {
      flex: 1;
    }
    @media only screen and (max-height: 45rem) {
      gap: 0.5rem;
    }
  }

  &.navigation-bar__collapse {
    width: var(--navigation-bar-collapse, 64px);
    .navigation-bar-menu-title {
      display: none;
    }
    .navigation-bar-header {
      gap: 0;
      justify-content: center;
      .navigation-bar-icon {
        opacity: 0;
        width: 0;
      }
      .navigation-bar-toggle {
        [class*="icon-"] {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
    .navigation-bar-menu {
      .navigation-bar-menu-link {
        padding: 0 0.625rem;
      }
    }
  }
  @media only screen and (max-height: 50rem) {
    gap: 0.2rem;
    padding: 0.875rem;
  }
  @media only screen and (max-height: 35rem) {
    padding: 0.5rem;
  }
`;

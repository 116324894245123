import { FC } from "react";
import { FakeDoorsWrapper } from "./fake-doors.styled";
import { useHome } from "../../hooks/home.hooks";

interface FakeDoorsProps {
  
}
 
const FakeDoors: FC<FakeDoorsProps> = () => {
  
  const { fakeDoors, handleFakeDoorClick } = useHome();
  
  return (
    <FakeDoorsWrapper>
      {fakeDoors.map((fakeDoor, index) => (
        <div
          key={`fake-door-${index}`}
          className="item"
          onClick={() => handleFakeDoorClick(fakeDoor)}>
          <img src={fakeDoor.image} alt="Fake door" className="img" />
          <div className="name">{fakeDoor.name}</div>
          <div className="description">{fakeDoor.description}</div>
        </div>
      ))}
    </FakeDoorsWrapper>
  );
}
 
export default FakeDoors;
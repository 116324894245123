import { camelizeKeys } from "humps";
import { getApiUrl, getEnvVar } from "@dashboard/common/utils";
import { CountryDataItem, PlatformCountry } from "@dashboard/common/types";
import { http } from "@dashboard/common/http";
import { LocationInfo } from "@dashboard/entities/location-info";

export const fetchCountries = async (locales: string): Promise<PlatformCountry[]> => {
  const response = await fetch(
    `${getApiUrl()}/api/ms/remote-config/country-data/`,
    {
      method: "GET",
      headers: {
        "api-key-sumer": getEnvVar("REACT_APP_KEY_SP"),
        "Accept-Language": locales || "es",
      } as any,
    }
  );
  if (response.status === 200) {
    const json = camelizeKeys(await response.json()) as CountryDataItem[];
    const item = json?.find((data) => data.key === "countries");
    return !!item?.value.countriesInformation.length
      ? item?.value.countriesInformation
      : [];
  } else return [];
};

export const fetchGetCountryByIp = async (): Promise<LocationInfo> => {
  const response = await http.get(`${getApiUrl()}/api/ms/location/by-ip/current`,
    {
      headers: {
        'api-key-sumer': getEnvVar('REACT_APP_KEY_SP'),
      },
    }
  );
  return camelizeKeys<LocationInfo>(response.data) ;
}

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContactButtonMessageWordsToReplace } from "../../constants/contact-button.constants";
import { useContactButton } from "../../hooks/contact-button.hook";
import ContactButtonMessagesWrapper from "./contact-button-messages.styled";

interface ContactButtonMessagesProps {

};

const ContactButtonMessages: FC<ContactButtonMessagesProps> = () => {

  const { t } = useTranslation();
  const {
    buttonConfig,
    handleMessageChange,
  } = useContactButton();
  const MAX_MESSAGE_LENGTH = 200;

  const handleTagClick = (
    elementId: 'general-message' | 'product-message',
    word: ContactButtonMessageWordsToReplace,
  ) => {
    const el = document.querySelector<HTMLInputElement>(`#${elementId}`);
    if (el && el?.value.length < MAX_MESSAGE_LENGTH) {
      el?.setRangeText(
        word,
        el.selectionStart || 0,
        el.selectionEnd || 0,
        "end",
      );
      el?.focus();
    }
  };

  return (
    <ContactButtonMessagesWrapper>
      <div className="title">
        {t("addons.contact_button.messages_texts.catalogue_title")}
      </div>
      <div className="description">
        {t("addons.contact_button.messages_texts.catalogue_description")}
      </div>
      <div className="textarea">
        <textarea
          rows={2}
          maxLength={MAX_MESSAGE_LENGTH}
          id='general-message'
          className="input"
          placeholder={t(
            "addons.contact_button.messages_texts.write_your_message",
          )}
          value={buttonConfig.wp_contact?.messages?.general}
          onChange={(e) => handleMessageChange("general", e.target.value)}
        />
        <div className="characters">
          {buttonConfig.wp_contact?.messages?.general?.length || 0}/
          {MAX_MESSAGE_LENGTH}{" "}
          {t("addons.contact_button.messages_texts.characters")}
        </div>
        <div className="tags">
          <div
            className="tag"
            onClick={() =>
              handleTagClick(
                "general-message",
                ContactButtonMessageWordsToReplace.StoreName,
              )
            }
          >
            [{t("addons.contact_button.messages_texts.store_name")}]
          </div>
          <div
            className="tag"
            onClick={() =>
              handleTagClick(
                "general-message",
                ContactButtonMessageWordsToReplace.StoreLink,
              )
            }
          >
            [{t("addons.contact_button.messages_texts.store_link")}]
          </div>
        </div>
      </div>
      <div className="title">
        {t("addons.contact_button.messages_texts.product_title")}
      </div>
      <div className="description">
        {t("addons.contact_button.messages_texts.product_description")}
      </div>
      <div className="textarea">
        <textarea
          rows={2}
          maxLength={MAX_MESSAGE_LENGTH}
          id='product-message'
          className="input"
          placeholder={t(
            "addons.contact_button.messages_texts.write_your_message",
          )}
          value={buttonConfig.wp_contact?.messages?.product}
          onChange={(e) => handleMessageChange("product", e.target.value)}
        />
        <div className="characters">
          {buttonConfig.wp_contact?.messages?.product?.length || 0}/
          {MAX_MESSAGE_LENGTH}{" "}
          {t("addons.contact_button.messages_texts.characters")}
        </div>
        <div className="tags">
          <div
            className="tag"
            onClick={() =>
              handleTagClick(
                "product-message",
                ContactButtonMessageWordsToReplace.ProductName,
              )
            }
          >
            [{t("addons.contact_button.messages_texts.product_name")}]
          </div>
          <div
            className="tag"
            onClick={() =>
              handleTagClick(
                "product-message",
                ContactButtonMessageWordsToReplace.ProductLink,
              )
            }
          >
            [{t("addons.contact_button.messages_texts.product_link")}]
          </div>
        </div>
      </div>
    </ContactButtonMessagesWrapper>
  );
};

export default ContactButtonMessages;

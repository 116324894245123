import { useAnalytics } from "@dashboard/common/analytics";
import { partnerSelector } from "@dashboard/config/redux";
import { useSession } from "@dashboard/hooks/use-session.hook";
import {
  EventsOnboarding,
  SubtypeOnboarding,
  OptionOnboarding,
  MethodOnboarding,
} from "@dashboard/modules/shared/components/onboarding-shared/constants/events-onboarding.constants";
import { QUERY_PARAMS_TEMPLATES } from "@dashboard/modules/shared/components/onboarding-shared/constants/params.constants";
import { useOnboarding } from "@dashboard/modules/shared/components/onboarding-shared/hooks/onboarding.hook";
import {
  storeDesignSplitSelector,
  economicSectorsSelector,
  onboardingDataSelector,
} from "@dashboard/modules/shared/components/onboarding-shared/store/onboarding.selectors";
import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const useStepDemo = (nextStep: string) => {
  const {
    getFetchConfigurations,
    templates,
    setTemplates,
    getFetchSplitUsers,
  } = useOnboarding();
  const partner = useSelector(partnerSelector);
  const { setWebStatus } = useSession();
  const storeSplit = useSelector(storeDesignSplitSelector);
  const economicSectors = useSelector(economicSectorsSelector);
  const { logEvent } = useAnalytics();
  const { getEconomicSectors } = useOnboarding();
  const { pathname } = useLocation();
  const onboardingData = useSelector(onboardingDataSelector);

  const getEnonomicSectorText = useMemo(() => {
    if (economicSectors.length) {
      return economicSectors.find(
        (sector) => sector.id === onboardingData.economicSector
      )?.name;
    } else {
      getEconomicSectors();
    }
  }, [economicSectors]);

  const sortTemplates = useMemo(() => {
    if (storeSplit?.config?.order) {
      return templates.sort(
        (a, b) =>
          (storeSplit.config.order as string[]).indexOf(a.id) -
          (storeSplit.config.order as string[]).indexOf(b.id)
      );
    } else {
      return templates;
    }
  }, [storeSplit, templates]);

  const hoverAction = (index: number, isEnter: boolean) => {
    const aux = [...templates];
    aux[index].showControls = isEnter;
    setTemplates([...aux]);
  };

  const selectTemplate = (id: string) => {
    sessionStorage.setItem(QUERY_PARAMS_TEMPLATES.KEY_NAME, id);
    logEvent(EventsOnboarding.SELECT_BUTTON, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      option: OptionOnboarding.PERSONALIZATION,
      method: MethodOnboarding.TEMPLATES,
      other_attributes: id,
    });
    goToPersonalization();
  };

  const goToPersonalization = () => {
    setWebStatus(
      `/${pathname.split("/")[1]}/${nextStep}`,
      partner?.userId || '',
      false,
      true,
      true
    );
  };

  useEffect(() => {
    getFetchSplitUsers();
    getFetchConfigurations();
    logEvent(EventsOnboarding.VIEW_SCREEN, {
      event_subtype: SubtypeOnboarding.ONBOARDING_STEPS,
      option: OptionOnboarding.PERSONALIZATION,
      method: MethodOnboarding.TEMPLATES,
    });
  }, []);

  return {
    getEnonomicSectorText,
    sortTemplates,
    goToPersonalization,
    selectTemplate,
    hoverAction,
    logEvent,
  };
};

import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Slider, { Settings } from "react-slick";
import {
  AiChatMessageStyled,
  MessageWaitResponse,
} from "./chat-message.styled";
import AlertCard from "@dashboard/components/alert-card";
import { AiMessageGroup } from "../../types/messages.types";
import { useTranslation } from "react-i18next";
import { useToast } from "@dashboard/hooks/use-toast.hook";
import { QUICK_ANSWERS_MESSAGE_IDS } from "../../constants/quick-answers.constants";
import { CoreEvents } from "@dashboard/constants/events";
import { AI_ASSISTANT_EVENTS } from "../../constants/events.constants";
import { useAnalytics } from "@dashboard/common/analytics";
import { PartnerData } from "@dashboard/common/types";
import Markdown from "react-markdown";
import Evo from "@sumerkit/icons/evo";

type AiChatMessageProps = {
  message: AiMessageGroup;
  inputMessageId?: string;
  regenerating?: boolean;
  partner?: PartnerData | null;
  enabledRegenerate?: boolean;
  className?: string;
  onApproved?: (approved: boolean) => void;
  onRegenerate?: (inputMessageId?: string) => void;
};

const AiChatMessage = forwardRef<HTMLLIElement, AiChatMessageProps>(
  (
    {
      message,
      inputMessageId,
      regenerating,
      partner,
      enabledRegenerate,
      className,
      onApproved,
      onRegenerate,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { logEvent } = useAnalytics();
    const { toast } = useToast();
    const optionsRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<Slider>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [slideActive, setSlideActive] = useState(1);

    const sliderSettings: Settings = {
      adaptiveHeight: true,
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      beforeChange: (current, next) => setSlideActive(next + 1),
    };

    const storeName = useMemo(() => {
      return partner?.userDetail.enterpriseName?.toUpperCase() || "T";
    }, [partner]);

    const handleSliderNext = () => {
      sliderRef.current && sliderRef.current.slickNext();
    };
    const handleSliderPrevious = () => {
      sliderRef.current && sliderRef.current.slickPrev();
    };

    const handleCloseOptions = useCallback((event?: Event) => {
      if (!event || !optionsRef.current?.contains(event.target as Node)) {
        setShowOptions(false);
        document.removeEventListener("mousedown", handleCloseOptions, false);
      }
    }, []);

    const handleClickOptions = () => {
      setShowOptions(!showOptions);
      document.addEventListener("mousedown", handleCloseOptions, false);
    };

    const handleApproved = (approved: boolean) => {
      onApproved && onApproved(approved);
      handleCloseOptions();
    };

    const handleCopy = async () => {
      handleCloseOptions();
      try {
        await navigator.clipboard.writeText(message.message || "");
        toast.success({ text: t("aiAssistant.alert.copySuccess") });
        logEvent(CoreEvents.SelectButton, {
          event_subtype: AI_ASSISTANT_EVENTS.AI_OPTIONS,
          option: "copy",
        });
      } catch (error) {}
    };

    const handleRegenerate = () => {
      onRegenerate && onRegenerate(inputMessageId);
      handleCloseOptions();
    };

    useEffect(() => {
      setTimeout(() => {
        if (optionsRef.current) {
          optionsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }, [showOptions, optionsRef]);

    const ChatMessage: FC<{ content?: string; regenerating?: boolean }> = ({
      content,
      regenerating,
    }) => {
      return (
        <div
          className={`message-container ${
            showOptions ? "message-container__active-options" : ""
          }`}
        >
          <div className="message-layout">
            {content !== ". . ." ? (
              <div className="message-content">
                {[
                  QUICK_ANSWERS_MESSAGE_IDS.assistant,
                  QUICK_ANSWERS_MESSAGE_IDS.user,
                ].indexOf(message.messageId) >= 0 ? (
                  <div dangerouslySetInnerHTML={{ __html: content || "" }} />
                ) : (
                  <Markdown>{content}</Markdown>
                )}
              </div>
            ) : (
              <div className="message-content">
                <MessageWaitResponse />
              </div>
            )}
            {message.role === "assistant" && (
              <button
                className="message-content-action"
                onClick={handleClickOptions}
              >
                <span className="icon-dots-three-outline" />
              </button>
            )}
          </div>
          {!regenerating && message.approved !== undefined && (
            <div
              className={`message-qualification ${
                message.approved
                  ? "message-qualification__good"
                  : "message-qualification__bad"
              }`}
            >
              {message.approved && (
                <>
                  <span className="icon-thumbs-up" />{" "}
                  {t("aiAssistant.message.options.approved")}
                </>
              )}
              {!message.approved && (
                <>
                  <span className="icon-thumbs-down" />{" "}
                  {t("aiAssistant.message.options.unApproved")}
                </>
              )}
            </div>
          )}
        </div>
      );
    };

    return (
      <AiChatMessageStyled
        ref={ref}
        className={`${
          message.role === "user" ? "ai-chat-message__client" : ""
        } ${className || ""}`}
      >
        <h4 className="message-profile">
          <div className="profile-avatar">
            {message.role === "assistant" ? (
              <Evo width={20} />
            ) : (
              <span>{storeName[0]}</span>
            )}
          </div>
          {message.role === "assistant" && "EVO"}
        </h4>
        {(message.messages || message.message) && (
          <>
            {message.messages && message.messages.length > 1 ? (
              <>
                <Slider
                  {...sliderSettings}
                  ref={sliderRef}
                  className="message-slider"
                >
                  {message.messages?.map((content, index) => (
                    <ChatMessage
                      content={content}
                      key={`chat-message-slider-${index}`}
                    />
                  ))}
                </Slider>
                <div className="message-slider-navigation">
                  <button onClick={handleSliderPrevious}>
                    <span className="icon-arrow-right" />
                  </button>
                  {slideActive} / {message.messages?.length}
                  <button onClick={handleSliderNext}>
                    <span className="icon-arrow-right" />
                  </button>
                </div>
              </>
            ) : (
              <>
                {message.message && <ChatMessage content={message.message} />}
              </>
            )}
            {regenerating && <ChatMessage content=". . ." regenerating />}
          </>
        )}
        {!message.message && (
          <div className="message-error">
            <AlertCard
              copy={t("aiAssistant.chat.expired")}
              type="danger"
              icon={<span className="icon-circle-exclamation" />}
              className="message-error-alert"
            />
            {enabledRegenerate && (
              <button
                className="message-error-retry"
                onClick={handleRegenerate}
              >
                <span className="icon-arrow-counter-clockwise" />
                {t("aiAssistant.message.options.regenerate")}
              </button>
            )}
          </div>
        )}
        {message.role === "assistant" && showOptions && (
          <div className="message-options" ref={optionsRef}>
            <button className="message-option" onClick={handleCopy}>
              {t("aiAssistant.message.options.copy")}{" "}
              <span className="icon-copy" />
            </button>
            {message.messageId !== QUICK_ANSWERS_MESSAGE_IDS.user &&
              message.messageId !== QUICK_ANSWERS_MESSAGE_IDS.assistant && (
                <>
                  {(message.approved === undefined || !message.approved) && (
                    <button
                      className="message-option"
                      onClick={() => handleApproved(true)}
                    >
                      {t("aiAssistant.message.options.approved")}{" "}
                      <span className="icon-thumbs-up" />
                    </button>
                  )}
                  {(message.approved === undefined || message.approved) && (
                    <button
                      className="message-option"
                      onClick={() => handleApproved(false)}
                    >
                      {t("aiAssistant.message.options.unApproved")}{" "}
                      <span className="icon-thumbs-down" />
                    </button>
                  )}
                  {enabledRegenerate && (
                    <button
                      className="message-option"
                      onClick={handleRegenerate}
                    >
                      {t("aiAssistant.message.options.regenerate")}{" "}
                      <span className="icon-arrows-clockwise" />
                    </button>
                  )}
                </>
              )}
          </div>
        )}
      </AiChatMessageStyled>
    );
  }
);

export default AiChatMessage;

import { FC, useEffect, useState } from "react";
import { CustomTextAreaContainer } from "./custom-textarea.styled";
import { TextAreaProps } from "./models/textarea.type";

const CustomTextArea: FC<TextAreaProps> = ({
  id,
  label,
  cols,
  rows,
  placeholder,
  value,
  limit,
  limitText,
  onChange,
  onFocus
}) => {

  const [characterLimit, setCharacterLimit] = useState<number>(0);

  useEffect(() => {
    if (value.length <= (limit || 0)) {
      setCharacterLimit(value.length)
    } else {
      setCharacterLimit(limit || 0)
    }
    }, [value]);

  return (
    <CustomTextAreaContainer>
      {!!label && (
        <label htmlFor={id}>{label}</label>
      )}
      <textarea
        id={id}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        value={value.substring(0, limit && limit)}
        onChange={onChange}
        onFocus={onFocus}
      ></textarea>
      {
        limit && (
          <div className="characters-container">{`${characterLimit}/${limit} ${limitText}`}</div>
        )
      }
    </CustomTextAreaContainer>
  );
};

export default CustomTextArea;

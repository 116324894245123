import styled from "styled-components";

export const LoaderStatusWrapper = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  .container {
    text-align: -webkit-center;
    .spinnerText {
        margin-top: 10px;
    }
  }
`;

export const home = {
  informsTitle: 'Reports',
  toolsTitle: 'Tools',
  learningTitle: 'Learn with videos of Sumer',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 days',
  lastFifteenDays: 'Last 15 days',
  lastThirtyDays: 'Last 30 days',
  addonHook: {
    getFor: 'Get it for',
    orChangeTo: 'or switch to the plan ',
    description: 'Enjoy all the features of Sumer without limits.',
    getAbandonedCart: 'Get abandoned cart',
    getUltraPlan: 'Get Ultra plan',
  },
  planHook: {
    title: 'Upgrade your plan',
    description: 'Functionality available in Pro and Ultra plans. Upgrade to have unlimited features.',
    button: 'Unlock now',
    later: 'Maybe later',
  },
  inConstruction: {
    title: 'Tool in development!',
    description: 'You will be able to have this tool soon',
    button: 'Accept',
  },
  inventory: {
    title: 'My products',
    tooltip: 'Created products number',
    addProducts: 'Add products',
  },
  learning: {
    title: 'Tutorials',
    description: 'Watch all the videos',
    visits: 'Visits',
  },
  orders: {
    title: 'My orders',
    youHas: 'You have',
    newOrdersPlural: 'new orders',
    newOrdersSingular: 'new order',
    noNewOrders: 'You have no new orders',
    newOrder: 'New order',
  },
  stats: {
    visits: 'Visits',
    visitsTooltip: 'Shows the number of sessions on your online store',
    totalSales: 'Total sales',
    totalSalesTooltip: 'It is the amount generated by all your sales in the selected period.',
    totalOrders: 'Total orders',
    totalOrdersTooltip: 'Shows the number of orders that were placed',
    totalUnits: 'Units sold',
    totalUnitsTooltip: 'Number of products sold per unit',
    abandonedCarts: 'Abandoned cart',
    abandonedCartsTooltip: 'Number of users who start the purchase process in your online store, but then abandon the transaction without completing it',
  }
};
import styled from "styled-components";

export const UiGeneralLoaderWrapper = styled.div`
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  .overlay {
    z-index: 11;
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--grey-overlay, rgba(12, 18, 32, 0.80));
  }
  .progress {
    z-index: 12;
    position: relative;
    .img {
      width: 108px;
      height: 108px;
      animation: spin 1s linear infinite reverse;
    }
    .logo {
      top: 50%;
      left: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      border-radius: 8px;
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;
      transform: translate(-50%, -50%);
    }
    .text {
      left: 50%;
      bottom: -46px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      position: absolute;
      width: max-content;
      text-align: center;
      color: var(--grey-w, #FFF);
      transform: translateX(-50%);
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


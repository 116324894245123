import { TFunction } from "i18next";
import * as Yup from "yup";

export const stepInfoSchema = (t: TFunction, hiddeEconomicSector?: boolean) =>
  Yup.object({
    phone: Yup.string()
      .min(10, t("onboarding.info.yupSchema.min_require"))
      .max(11, t("onboarding.info.yupSchema.max_require"))
      .required(t("onboarding.info.yupSchema.require")),
    prefixPhone: Yup.string().required(
      t("onboarding.info.yupSchema.require_country")
    ),
    country: Yup.string().required(
      t("onboarding.info.yupSchema.require_prefix")
    ),
    ...(!hiddeEconomicSector && {
      economicSector: Yup.string()
        .min(3, t("onboarding.info.yupSchema.require_sector_min"))
        .required(t("onboarding.info.yupSchema.require_sector")),
    }),
  });

import styled from "styled-components";

export const ProductUploadImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  .principal-image {
    width: 100%;
    height: 168px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    object-fit: cover;
    flex: 1 0 0;
    position: relative;
    .principal-tag {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      position: absolute;
      margin-top: 8px;
      margin-left: 8px;
      border-radius: 4px;
      border: 1px solid var(--Grey-700, #e9e9ed);
      background: var(--Grey-800, #fcfcfc);
      .text {
        color: var(--Grey-B, #24272e);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .empty-state {
    display: flex;
    height: 164px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--Grey-B, #24272e);
    background: var(--Grey-800, #fcfcfc);
    flex: 1 0 0;
    input {
      display: none;
    }
    .text {
      color: var(--Grey-B, #24272e);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .images-container {
    max-width: 164px;
    height: 164px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 16px;
    .item-container {
      cursor: pointer;
      position: relative;
      .item {
        width: 100%;
        height: 100%;
        position: relative;

        > img {
          width: 100%;
          height: 74px;
          object-fit: cover;
        }
        .upload-image {
          display: flex;
          width: 74px;
          height: 74px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid var(--Grey-B, #24272e);
          background: var(--Grey-800, #fcfcfc);
          input {
            display: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .overlay {
      display: block;
      .count-image {
        position: absolute;
        color: var(--Grey-W, #fff);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 31px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .item-container .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      border-radius: 4px;
      top: 0;
      left: 0;
      display: inline-block;
    }
  }
  .upload-image-error {
    color: rgba(176, 18, 18, 1);
    font-size: 0.75rem;
    line-height: 1.5em;
    p {
      margin: 0;
    }
  }
`;

export const UploadImageError = styled.div`
  color: rgba(176, 18, 18, 1);
  font-size: 0.75rem;
  line-height: 1.5em;
  p {
    margin: 0;
  }
`;

export const PhotosTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  .text {
    color: var(--Grey-B, #24272e);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    flex: 1 0 0;
  }
  .editContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    .editText {
      color: var(--Grey-B, #24272e);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
    .editIcon {
      font-size: 20px;
      color: var(--Grey-B, #24272e);
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const UploadDocuments = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.38477C6.9337 5.38477 6.87011 5.4111 6.82322 5.45799C6.77634 5.50487 6.75 5.56846 6.75 5.63477V27.6348C6.75 27.7011 6.77634 27.7647 6.82322 27.8115C6.87011 27.8584 6.93369 27.8848 7 27.8848H25C25.0663 27.8848 25.1299 27.8584 25.1768 27.8115C25.2237 27.7647 25.25 27.7011 25.25 27.6348V11.9454L18.6893 5.38477H7ZM5.76256 4.39733C6.09075 4.06914 6.53587 3.88477 7 3.88477H19C19.1989 3.88477 19.3897 3.96378 19.5303 4.10444L26.5303 11.1044C26.671 11.2451 26.75 11.4359 26.75 11.6348V27.6348C26.75 28.0989 26.5656 28.544 26.2374 28.8722C25.9092 29.2004 25.4641 29.3848 25 29.3848H7C6.53587 29.3848 6.09075 29.2004 5.76256 28.8722C5.43437 28.544 5.25 28.0989 5.25 27.6348V5.63477C5.25 5.17064 5.43438 4.72552 5.76256 4.39733Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3.88477C19.4142 3.88477 19.75 4.22055 19.75 4.63477V10.8848H26C26.4142 10.8848 26.75 11.2206 26.75 11.6348C26.75 12.049 26.4142 12.3848 26 12.3848H19C18.5858 12.3848 18.25 12.049 18.25 11.6348V4.63477C18.25 4.22055 18.5858 3.88477 19 3.88477Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 19.6348C12.25 19.2206 12.5858 18.8848 13 18.8848H19C19.4142 18.8848 19.75 19.2206 19.75 19.6348C19.75 20.049 19.4142 20.3848 19 20.3848H13C12.5858 20.3848 12.25 20.049 12.25 19.6348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15.8848C16.4142 15.8848 16.75 16.2206 16.75 16.6348V22.6348C16.75 23.049 16.4142 23.3848 16 23.3848C15.5858 23.3848 15.25 23.049 15.25 22.6348V16.6348C15.25 16.2206 15.5858 15.8848 16 15.8848Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UploadDocuments;

export enum Template {
  MADRID = "MADRID",
  PARIS = "PARIS",
  ROMA = "ROMA",
  BERLIN = "BERLIN",
  CLOUD = "CLOUD",
  VITRINA = "VITRINA",
  PERLA = "PERLA",
  ONIX = "ONIX",
  BOURBON = "BOURBON",
  KONA = "KONA",
  ARABUSTA = "ARABUSTA",
  STANDARD = "STANDARD",
  NEW_YORK = "NEW_YORK",
  RIO = "RIO",
  SEUL = "SEUL"
}

export enum TemplateTypeEnum {
  INFORMATION_BAR = "INFORMATION_BAR",
  BENEFITS = "BENEFITS",
  COVER_TYPES = "COVER_TYPES",
  COVER_IMAGES = "COVER_IMAGES",
  TESTIMONIALS = "TESTIMONIALS",
  COLORS = "COLORS",
  CATALOGUE_TEMPLATES = "CATALOGUE_TEMPLATES",
  SHARE_CONFIGURATION = "SHARE_CONFIGURATION",
  CATALOGUE_TYPOGRAPHY = "CATALOGUE_TYPOGRAPHY",
}

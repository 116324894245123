import { FC } from "react";
import { PlanToggleStyled } from "./plans-toggle.styled";
import { useTranslation } from "react-i18next";
import Toggle from "./toggle";
import { PlanGroup } from "@dashboard/modules/plans/plans.types";

type PlanToggleProps = {
  plansBillings: string[];
  billing?: string;
  plans: PlanGroup[];
  onChange?: (plan: string) => void;
};

const EnumToggle = {
  MONTHLY: "Mensual",
  ANNUAL: "Anual",
};

const PlanToggle: FC<PlanToggleProps> = ({ billing, onChange, plans }) => {
  const { t } = useTranslation();
  const findTotalValueWithDiscount = (plans: PlanGroup[]) => {
    const yearlyPlans = plans.find((plan) => plan.name === EnumToggle.ANNUAL);
    const plan = yearlyPlans?.plans[0];
    const discount = plan?.cart?.total_value || 0;
    const total = plan?.cart?.total_value_without_discount || 0;
    if (total > 0) {
      const totalWithDiscount = (discount / total) * 100;
      return Math.floor(totalWithDiscount);
    } else {
      return 0;
    }
  };

  const handleChange = (plan: string) => {
    onChange && onChange(plan);
  };
  
  return (
    <PlanToggleStyled>
      <p className="monthly">{t("toggle.monthly")}</p>
      {billing && (
        <Toggle
          appearance="success"
          onChange={(billing) =>
            handleChange(billing ? EnumToggle.ANNUAL : EnumToggle.MONTHLY)
          }
          value={billing === EnumToggle.ANNUAL}
        />
      )}
      <p className="yearly">{t("toggle.yearly")}</p>
      <span
        className={`percentage ${
          billing === EnumToggle.ANNUAL
            ? "percentage__active"
            : "percentage__disabled"
        }`}
      >
        -{findTotalValueWithDiscount(plans)}%
      </span>
    </PlanToggleStyled>
  );
};

export default PlanToggle;

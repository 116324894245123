import { FC } from "react";
import { LogoutButtonWrapper } from "./logout-button.styled";
import { useTranslation } from "react-i18next";
import { useSession } from "@dashboard/hooks/use-session.hook";

export type LogoutButtonTypes = {
  queryParam?: string
}

const LogoutButton: FC<LogoutButtonTypes> = ({ queryParam }) => {
    
  const { t } = useTranslation();
  const { logOut } = useSession();

  return (
    <LogoutButtonWrapper className="fadeInUp" onClick={() => logOut(queryParam)}>
      <span className="icon icon-arrow-line" />
      <div className="text">{t("logout.title")}</div>
    </LogoutButtonWrapper>
  );
};

export default LogoutButton;

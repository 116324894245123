import { actions, partnerSelector } from "@dashboard/config/redux";
import { StorageItem } from "@dashboard/constants/storage-items";
import { fetchUserAddons } from "@dashboard/services/get-user-addons.services";
import { useDispatch, useSelector } from "react-redux";

export const useUserAddons = () => {
  const dispatch = useDispatch();
  const user = useSelector(partnerSelector);

  const getUserAddons = () => {
    if (!!user) {
      const token =
        localStorage.getItem(StorageItem.Token)?.replaceAll('"', "") || "";
      fetchUserAddons({ token })
        .then((r) => {
          if (!!r) {
            dispatch(actions.setAvailableAddons(r));
          }
        })
        .catch(console.error);
    }
  };

  return {
    getUserAddons,
  };
};

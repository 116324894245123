import { check } from "./animations.styled";
import styled from "styled-components";

export const ToggleStyled = styled.span`
  .container-toggle {
    background-color: var(--Grey-5, #9b9eab);
    border-radius: 1rem;
    cursor: pointer;
    height: 24px;
    position: relative;
    transition: all 0.25s;
    width: 40px;
    .toggle-button-pricing {
      background-color: #ffffff;
      border-radius: 50%;
      display: block;
      height: 20px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: all 0.25s;
      width: 20px;
    }
    .check {
      display: block;
      height: 20px;
      left: 2px;
      position: absolute;
      top: 4px;
      transition: all 0.25s ease 0s;
      width: 20px;
      ${check}
    }
    &.toggle__active {
      background-color: currentColor;
      .toggle-button-pricing {
        left: 18px;
      }
      .check {
        left: 20px;
        transition: all 0.25s;
      }
      &.toggle__primary {
        background-color: #1458f5;
      }
      &.toggle__success {
        background-color: var(--Grey-B, #24272e);
      }
    }
  }
`;
import { FC } from "react";
import { BannerIaWrapper } from "./banner-ia.styled";
import Button from "@sumerkit/ui/button";
import { useTranslation } from "react-i18next";

export type BannerIaType = {
  onContinue: () => void;
};

const BannerIa: FC<BannerIaType> = ({ onContinue }) => {
  const { t } = useTranslation();
  return (
    <BannerIaWrapper>
      <div className="content">
        <div className="title">{t("onboarding.templates.banner.title")}</div>
        <div className="description">
          {t("onboarding.templates.banner.description")}
        </div>
        <Button appearance="tertiary" onClick={onContinue}>
          {t("onboarding.templates.banner.button")}
        </Button>
      </div>
      <img
        src="https://sumerlabs.com/prod/web/onboarding/demo-banner/personalization.png"
        className="banner-img"
        alt="Demo banner"
      />
    </BannerIaWrapper>
  );
};

export default BannerIa;

import { http } from "@dashboard/common/http";

export const fetchOrchestrator = (token: string) => {
  return http.get(`/api/ms/orchestrator/catalogue/owner`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    } as { [key: string]: string },
  });
};

const IconUploadImages = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    >
      <path
        d="M27 9.13477H16.4125L13 5.72227C12.6249 5.34814 12.1173 5.13704 11.5875 5.13477H5C4.46957 5.13477 3.96086 5.34548 3.58579 5.72055C3.21071 6.09562 3 6.60433 3 7.13477V25.2098C3 25.7203 3.20281 26.2099 3.56382 26.5709C3.92483 26.932 4.41446 27.1348 4.925 27.1348H27.1125C27.6121 27.1315 28.0903 26.9316 28.4435 26.5783C28.7968 26.225 28.9967 25.7468 29 25.2473V11.1348C29 10.6043 28.7893 10.0956 28.4142 9.72055C28.0391 9.34548 27.5304 9.13477 27 9.13477ZM5 7.13477H11.5875L13.5875 9.13477H5V7.13477ZM19 19.1348H17V21.1348C17 21.4 16.8946 21.6543 16.7071 21.8419C16.5196 22.0294 16.2652 22.1348 16 22.1348C15.7348 22.1348 15.4804 22.0294 15.2929 21.8419C15.1054 21.6543 15 21.4 15 21.1348V19.1348H13C12.7348 19.1348 12.4804 19.0294 12.2929 18.8419C12.1054 18.6543 12 18.4 12 18.1348C12 17.8695 12.1054 17.6152 12.2929 17.4277C12.4804 17.2401 12.7348 17.1348 13 17.1348H15V15.1348C15 14.8695 15.1054 14.6152 15.2929 14.4277C15.4804 14.2401 15.7348 14.1348 16 14.1348C16.2652 14.1348 16.5196 14.2401 16.7071 14.4277C16.8946 14.6152 17 14.8695 17 15.1348V17.1348H19C19.2652 17.1348 19.5196 17.2401 19.7071 17.4277C19.8946 17.6152 20 17.8695 20 18.1348C20 18.4 19.8946 18.6543 19.7071 18.8419C19.5196 19.0294 19.2652 19.1348 19 19.1348Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconUploadImages;

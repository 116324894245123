import { FC, useEffect, useMemo, useState } from "react";
import { PersonalizationWrapper } from "./personalization.styled";
import { usePersonalization } from "../../hooks/personalization.hooks";
import { IframeEvent, PersonalizationEvents } from "../../constants/events.constants";
import { actions, fetchPartnerByToken, planSelector, useAppDispatch } from "@dashboard/config/redux";
import {
  personalizationActions,
  personalizationExitSelector,
} from "../../store";
import ChangePopup from "../../popups/change-popup/change-popup";
import { useSelector } from "react-redux";
import { Plan } from "@dashboard/constants/plan";
import { PersonalizationFeatures } from "../../constants/features.constants";
import { EVENTS } from "@dashboard/constants/events";
import { useAnalytics } from "@dashboard/common/analytics";
import { useSession } from "@dashboard/hooks/use-session.hook";
import { useSessionCode } from "@dashboard/hooks/session-code.hook";

interface PersonalizationProps {}

const Personalization: FC<PersonalizationProps> = () => {
  const { logEvent } = useAnalytics();
  const { catalogueId, domain, gotToCheckout } = usePersonalization();
  const dispatch = useAppDispatch();
  const requestExit = useSelector(personalizationExitSelector);
  const plan = useSelector(planSelector);
  const { token, isTokenGuest } = useSession();
  const { generate } = useSessionCode();
  const [sessionCode, setSessionCode] = useState<string | undefined>();

  const customizationDesktopUrl = useMemo(() => {
    if (sessionCode)
      return !!catalogueId
        ? `${domain}/customization/${sessionCode}/${catalogueId}/customize/desktop-web/init?code=true`
        : "";
    return;
  }, [sessionCode]);

  useEffect(() => {
    (window as any).messageHandler = {
      postMessage: (eventName: string) => {
        switch (eventName) {
          case IframeEvent.BuyExpert:
            gotToCheckout();
            break;
          case IframeEvent.OpenHook:
            logEvent(EVENTS.SYSTEM_EXPERT_WARNING, {
              event_subtype: PersonalizationEvents.personalizationPro,
              source: "hook",
              method: "free",
            });
            dispatch(
              actions.showCustomPlanHook(
                PersonalizationFeatures.personalizationPro
              )
            );
            break;
          case IframeEvent.PendingChanges:
            dispatch(personalizationActions.enablePendingChanges());
            break;
          case IframeEvent.UpdateUserData:
            dispatch(fetchPartnerByToken(token));
            break;
          case IframeEvent.ChangesSaved:
            dispatch(personalizationActions.disablePendingChanges());
            dispatch(
              personalizationActions.requestExit({
                exit: false,
              })
            );
            break;
          default:
            break;
        }
      },
    };
    return () => {
      (window as any).messageHandler = undefined;
    };
  }, [token, gotToCheckout]);

  const actionChange = () => {
    if (!isTokenGuest()) {
      const iframe = document.querySelector("iframe");
      iframe?.contentWindow?.postMessage("handle_web_onsubmit", "*");
    }
    if (
      plan?.benefit_plan_name.toLowerCase() !== Plan.FREE.toLowerCase() &&
      plan?.benefit_plan_name.toLowerCase() !== Plan.BASIC.toLowerCase()
    ) {
      setTimeout(() => {
        requestExit.onAction && requestExit.onAction();
      }, 1000);
    } else {
      dispatch(personalizationActions.disablePendingChanges());
      dispatch(
        personalizationActions.requestExit({
          exit: false,
        })
      );
    }
  };

  useEffect(() => {
    generate()?.then((code) => {
      if (code) {
        setSessionCode(code);
      }
    });
  }, [token]);

  return (
    <PersonalizationWrapper>
      {requestExit.exit && (
        <ChangePopup
          onAction={actionChange}
          onSkip={() => {
            requestExit.onAction && requestExit.onAction();
            dispatch(personalizationActions.disablePendingChanges());
            dispatch(
              personalizationActions.requestExit({
                exit: false,
              })
            );
          }}
          handleClose={() => {
            dispatch(
              personalizationActions.requestExit({
                exit: false,
              })
            );
          }}
        />
      )}
      <div className="frame">
        {!!customizationDesktopUrl && (
          <iframe src={customizationDesktopUrl} className="iframe" />
        )}
      </div>
    </PersonalizationWrapper>
  );
};

export default Personalization;

import styled from "styled-components";

export const HookUpgradeIaStyled = styled.aside`
  overflow: hidden;
  width: 100%;
  .image {
    img {
      aspect-ratio: 450/219;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      object-fit: cover;
      width: 100%;
    }
  }
  .hook-body {
    background-color: var(--Grey-B);
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    padding: 2.5rem;
    text-align: center;
  }
  .copies {
    color: var(--Grey-W);
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    text-align: center;
    .title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      span {
        background: linear-gradient(
          99.72deg,
          #a1bbfb -6.63%,
          #a2bffb 12.62%,
          #d8cfe8 43.92%,
          #c7c1eb 72.95%,
          #9fa7eb 91.75%,
          #a5b5f1 114.2%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
    }
  }
  .options {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    width: 100%;
    .option-link {
      font-weight: 400;
    }
  }
  .footer {
    color: var(--Grey-500);
  }
`;

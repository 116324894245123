import { Modal } from "@sumerlabs/component-library";
import { FC, useEffect, useState } from "react";
import { PopupWrapper } from "./keep-customization-popup.styled";

export type KeepCustomizationPopupType = {
  image: string;
  title?: string;
  description?: string;
  confirmLabel: string;
  linkLabel: string;
  show: boolean;
  onConfirmLabel: () => void;
  onLinkLabel: () => void;
  onClose: () => void;
};

const KeepCustomizationPopup: FC<KeepCustomizationPopupType> = ({
  image,
  title,
  description,
  confirmLabel,
  linkLabel,
  show,
  onConfirmLabel,
  onLinkLabel,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(show);
  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      show={showModal}
      showHeader={false}
      content={{
        styles: {
          width: "520px",
        },
      }}
      body={{
        styles: {
          padding: "0px",
        },
      }}
    >
      <PopupWrapper>
        <figure className="icon icon-close" onClick={handleClose}></figure>
        <img src={image} alt="Popup customization" />
        <div className="body">
          <div className="copys">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
            <div className="controls">
              <div className="confirmLabel" onClick={onConfirmLabel}>{confirmLabel}</div>
              <div className="linkLabel" onClick={onLinkLabel}>
                <div className="text">{linkLabel}</div>
              </div>
            </div>
          </div>
        </div>
      </PopupWrapper>
    </Modal>
  );
};

export default KeepCustomizationPopup;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContactButtonPersonalizationOptionsWrapper } from "./contact-button-personalization-options.styled";
import { useContactButton } from "../../hooks/contact-button.hook";

interface ContactButtonPersonalizationOptionsProps {

};

const ContactButtonPersonalizationOptions: FC<
  ContactButtonPersonalizationOptionsProps
> = () => {

  const { t } = useTranslation();
  const {
    contactButtonConfigurationOptions,
    handleOnOptionClick
  } = useContactButton();

  return (
    <ContactButtonPersonalizationOptionsWrapper>
      {contactButtonConfigurationOptions.map((option) => (
        <div
          key={`contact-button-personalization-options-${option.id}`}
          className='option-item'
          onClick={() => handleOnOptionClick(option)}
        >
          <span className={`icon-${option.icon} icon`} />
          <span className='text'>
            {t(`addons.contact_button.titles.${option.text}`)}
          </span>
        </div>
      ))}
    </ContactButtonPersonalizationOptionsWrapper>
  );
};

export default ContactButtonPersonalizationOptions;

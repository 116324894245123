import { http } from "@dashboard/common/http";
import { AiStoreData } from "../entities/ai-service.entity";
import { camelizeKeys, decamelizeKeys } from "humps";
import { getEnvVar } from "@dashboard/common/utils";

export const fetchGetAiStore = async (text: string): Promise<AiStoreData> => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.post(
    `${getEnvVar(
      "REACT_APP_AI_STORE_LAMBDA"
    )}/api/ms/ai-onboarding/generate/new/v3`,
    { text },
    {
      headers: {
        "Accept-Language": language,
        "Content-Type": "application/json",
      },
    }
  );
  return camelizeKeys(response.data) as AiStoreData;
};

export const fetchRegenerateAiStore = async (
  text: string,
  token: string
): Promise<AiStoreData> => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.post(
    `${getEnvVar("REACT_APP_AI_STORE_LAMBDA")}/api/ms/ai-onboarding/regenerate`,
    { text },
    {
      headers: {
        "Accept-Language": language,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return camelizeKeys(response.data) as AiStoreData;
};

export const fetchMergeAiStore = async (
  ownerToken: string,
  guestToken: string
): Promise<{ status: string }> => {
  const language = localStorage.getItem("i18nextLng") || "es";
  const response = await http.post(
    `${getEnvVar("REACT_APP_AI_STORE_LAMBDA")}/api/ms/ai-onboarding/merge`,
    decamelizeKeys({ ownerToken, guestToken }),
    {
      headers: {
        "Accept-Language": language,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

import styled from "styled-components";

export const LogoutButtonWrapper = styled.div`
  background: var(--Grey-Border, #e9e9ed);
  height: 32px;
  box-shadow: 0px 2px 4px -2px rgba(135, 135, 136, 0.5);
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  border-radius: 25px;
  overflow: hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  &:hover {
    background: grey;
    cursor: pointer;
    color: white;
    max-width: 300px;
  }
  .icon {
    font-size: 16px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
  }
  .text {
    white-space: nowrap;
    padding-right: 15px;
  }
`;

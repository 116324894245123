import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@dashboard/config/redux";
import { OnboardingState } from "./onboarding.state";

export const onboardingUiSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.ui,
);

export const economicSectorsSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.businessSectors,
);

export const onboardingDataSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.onboardingData,
);

export const couponCodeSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.couponCode,
);

export const personalizationViewModeSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.ui.personalizationViewMode,
);

export const paramsSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.params,
);

export const storeDesignSplitSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.storeDesignSplit,
);

export const sourcePathSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.sourcePath,
);

export const onboardingTypeSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.onboardingType,
);

export const ipInfoSelector = createSelector(
  (state: RootState) => state.onboarding,
  (state: OnboardingState) => state.data.ipInfo,
);

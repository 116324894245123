import { getDomain, getEnvVar } from "@dashboard/common/utils";

export const resizeImage = (
  url: string,
  width: number,
  height: number,
  quality: number
) => {
  return getEnvVar("PUBLIC_ENV") === "prod"
    ? `${getDomain()}/default/image-tool-lambda?new-width=${width}&new-height=${height}&new-quality=${quality}&url-image=${encodeURIComponent(
        url
      )}`
    : url;
};

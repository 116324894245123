import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { useSelector } from 'react-redux';

import { useCallback } from 'react';
import { countryInformationSelector, partnerSelector } from '@dashboard/config/redux';
import { getApiUrl, getEnvVar, isServer } from './utils';
import { useSession } from '@dashboard/hooks/use-session.hook';
import { useClevertap } from '@dashboard/hooks/clevertap.hook';

export const createSumerEvent = async (
    key: string,
    payload?: { [key: string]: string | undefined },
    ownerId?: string,
) => {
    const request = {
        owner_id: ownerId,
        event_type: key,
        information: payload,
        providers: ['clevertap'],
    };

    return fetch(`${getApiUrl()}/api/ms/statistics/event/growth`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api-key-sumer': getEnvVar('PUBLIC_API_KEY_SP'),
        } as { [key: string]: string },
        body: JSON.stringify(request),
    })
        .then((response) => {
            if (response.ok) return response.json();
            else throw Error(response.statusText);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const useAnalytics = () => {
    const analytics: Analytics = (typeof window !== 'undefined' && getAnalytics()) as Analytics;
    const partner = useSelector(partnerSelector);
    const country = useSelector(countryInformationSelector);
    const { decodedToken } = useSession();
    const { sendEvent: sendEventClevertap } = useClevertap();

    const logEventFn = useCallback(
        (key: string, payload?: { [key: string]: string | number | boolean | null }) => {
            const env = getEnvVar('REACT_APP_ENV');
            const eventName = env === 'dev' ? `dev_${key}` : key;

            const userId = partner?.userDetail.userId || decodedToken?.user_id;

            if (!isServer) {
                const newPayload = {
                    ...payload,
                    user_type: 'store',
                    platform: 'web',
                    user_id: userId,
                    city: partner?.userDetail.city,
                    country: partner?.userDetail.country,
                    business_name: partner?.userDetail.enterpriseName,
                    email: partner?.user.email,
                    phone_number: (
                        !!partner?.userDetail.prefixPhone && !!partner?.userDetail.phone
                    ) ? `${partner?.userDetail.prefixPhone}${partner?.userDetail.phone}` : undefined,
                    country_code: country?.id
                };
                logEvent(analytics, eventName, newPayload);
                createSumerEvent(eventName, newPayload, userId);
                sendEventClevertap(eventName, newPayload);
            }
        },
        [partner, country, analytics, decodedToken],
    );

    const appsFlyerEvent = useCallback(({ eventName, eventValue }: {
        eventName: string;
        eventValue: { [key: string]: string | number | boolean | null };
    }) => {
        if (!isServer) {
            (window as any).AF('pba', 'event', {
                eventType: 'EVENT', 
                eventName: eventName,
                eventValue: eventValue
            });
        }
    }, []);

    return {
        logEvent: logEventFn, appsFlyerEvent,
    };
};
